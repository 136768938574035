import classNames from 'classnames'

import { SemiBoldText } from '../SemiBoldText'

type ColoredSquareProps = {
    text: string
    className: string
}

export const ColoredSquare = (props: ColoredSquareProps): JSX.Element => (
    <div className='flex justify-start items-center gap-2'>
        <div className={classNames('w-5 h-5 rounded-sm', props.className)} />
        <div>
            <SemiBoldText>{props.text}</SemiBoldText>
        </div>
    </div>
)

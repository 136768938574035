import classNames from 'classnames'
import { useSetAtom } from 'jotai'
import { addClientDBGroupAtom, openMassBonusAtom } from 'src/atoms/clientDb/addClientDBGroupAtom'
import { Button, EButtonVariant, RegularText, SemiBoldText } from 'src/components'
import { RoundedWrapper } from 'src/components/RoundedWrapper'

import { manageSMSAtom } from '../../atoms/clientDb/manageSMSAtom'

export const ClientDatabaseAdd = (): JSX.Element => {
    const addGroup = useSetAtom(addClientDBGroupAtom)
    const openMassBonus = useSetAtom(openMassBonusAtom)
    const manageSMS = useSetAtom(manageSMSAtom)

    return (
        <div
            className={classNames(
                'flex gap-2 items-center justify-between',
                'min-h-20',
                'border-b-2 border-solid border-primaryColor pl-8 pr-8',
            )}
        >
            <SemiBoldText className='text-lg'>Группы</SemiBoldText>
            <Button icon='plus' onClick={addGroup} variant={EButtonVariant.PRIMARY} className='mr-1.5'>
                <RegularText>Добавить</RegularText>
            </Button>
            <RoundedWrapper icon='sms' onClick={() => manageSMS({ activeTabIndex: 1 })} />
            <RoundedWrapper icon='gift' onClick={openMassBonus} />
        </div>
    )
}

import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'
import moment from 'moment'
import lodash from 'lodash'

type GenerateExcelType = {
    heads: string[]
    keys: string[]
    data: any[]
    fn: (key: string, node: any) => string
    fileName?: string
    columnWidth?: number
    total?: Record<string, any>
}

type GenerateDataType = {
    heads: string[]
    keys: string[]
    data: any[]
    fn: (key: string, node: any) => string
}

type GenerateColumnWidthsType = any[]

class Excel {
    static generateData({ heads, keys, data, fn }: GenerateDataType): any[] {
        const finalData: any[] = []
        data.forEach(item => {
            const obj = {}
            heads.forEach((_, index) => {
                obj[heads[index]] = fn(keys[index], item) || lodash.get(item, keys[index]) || '-'
            })
            finalData.push(obj)
        })
        return finalData
    }
    static generateColumnWidth(finalData: GenerateColumnWidthsType): { wch: number }[] {
        const headLengthsList = {}
        finalData.forEach(item => {
            Object.keys(item).forEach(key => {
                if (headLengthsList[key]) {
                    headLengthsList[key].push(item[key]?.toString()?.length)
                } else {
                    headLengthsList[key] = [key.length, item[key].toString().length]
                }
            })
        })

        return Object.keys(headLengthsList).map(key => {
            const max = Math.max(...headLengthsList[key])
            return { wch: max + 3 }
        })
    }

    static generateFile({ heads, keys, data, fn, fileName = 'locali-table', total = undefined }: GenerateExcelType): void {
        const finalData = this.generateData({ heads, keys, data, fn })
        if (total) {
            finalData.push(total)
        }
        const worksheet = XLSX.utils.json_to_sheet(finalData)
        worksheet['!cols'] = this.generateColumnWidth(finalData)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Страница 1')
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' })
        saveAs(blob, `${fileName}-${moment().format('YYYY-MM-DD_HH-mm-ss')}.xlsx`)
    }
}

export { Excel }

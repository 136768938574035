import { useSetAtom } from 'jotai'
import classNames from 'classnames'
import { Button, RegularText, SemiBoldText, EButtonVariant } from 'src/components'
import { setCourierAddGroupAtom } from 'src/atoms/allCouriers/courierCreateModalAtom'

import styles from './styles.module.scss'

export const AddCourier = (): JSX.Element => {
    const setAddCourierGroup = useSetAtom(setCourierAddGroupAtom)

    return (
        <div className={classNames(styles.container, 'flex gap-2 items-center')}>
            <SemiBoldText className='text-lg'>Группы</SemiBoldText>
            <Button onClick={setAddCourierGroup} icon='plus' variant={EButtonVariant.PRIMARY} className='mr-1.5'>
                <RegularText>Добавить</RegularText>
            </Button>
        </div>
    )
}

import { IconType } from 'src/assets/icons'
import { ERoutes } from 'src/router/routes'

export type MenuConfig = {
    title: string
    icon?: IconType
    path: string
    children?: MenuConfig[]
}

export const MENU_CONFIG: Array<MenuConfig> = [
    {
        title: 'Общее',
        icon: 'document',
        path: ERoutes.MAIN,
        children: [
            {
                title: 'Общее',
                path: ERoutes.MAIN_MAIN,
            },
        ],
    },
    {
        title: 'Клиенты',
        icon: 'shield',
        path: ERoutes.CLIENTS,
        children: [
            {
                title: 'База данных',
                path: ERoutes.CLIENTS_DATABASE,
            },
        ],
    },
    {
        title: 'Рестораны',
        icon: 'dish',
        path: ERoutes.REST,
        children: [
            {
                title: 'Все рестораны',
                path: ERoutes.REST,
            },
            {
                title: 'Карта ресторанов',
                path: ERoutes.REST_MAP,
            },
        ],
    },
    {
        title: 'Курьеры',
        icon: 'fura',
        path: ERoutes.KURA,
        children: [
            {
                title: 'Все курьеры',
                path: ERoutes.ALL_KURAS,
            },
            {
                title: 'Карта курьеров',
                path: ERoutes.KURA_MAP,
            },
        ],
    },
    {
        title: 'Заказы',
        icon: 'box',
        path: '',
        children: [
            {
                title: 'Все заказы',
                path: ERoutes.ALL_ORDERS,
            },
        ],
    },
    {
        title: 'Бухгалтерия',
        icon: 'calc',
        path: '',
        children: [
            {
                title: 'Рестораны',
                path: ERoutes.ACCOUNTING_REST,
            },
            {
                title: 'Курьеры',
                path: ERoutes.ACCOUNTING_COURIERS,
            },
        ],
    },
    {
        title: 'Администраторы',
        icon: 'userLocked',
        path: ERoutes.ADMINS,
        children: [
            {
                title: 'Администраторы',
                path: ERoutes.ADMINS,
            },
        ],
    },
    /*  {
    title: 'Настройки',
    icon: 'settings',
    path: ERoutes.SETTINGS,
    children: [
      {
        title: 'Общее',
        path: ERoutes.SETTINGS_MAIN
      }
    ]
  },*/
]

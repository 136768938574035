import { CSSObjectWithLabel, StylesConfig } from 'react-select'

type Option = {
    value: string
    label: string
}

const baseStyles: StylesConfig<Option, false> = {
    dropdownIndicator: (base: CSSObjectWithLabel) => ({
        ...base,
        color: '#658399',
        fontSize: 20,
        padding: 0,
        marginRight: 20,
    }),
    input: (base: CSSObjectWithLabel) => ({
        ...base,
        fontSize: 10,
    }),
    container: (base: CSSObjectWithLabel) => ({
        ...base,
        height: 30,
    }),
    valueContainer: (base: CSSObjectWithLabel) => ({
        ...base,
        height: '30px',
        padding: '0 6px',
        justifyContent: 'start',
        alignItems: 'center',
    }),
    placeholder: (base: CSSObjectWithLabel) => ({
        ...base,
        color: '#658399',
        fontSize: 16,
        marginBottom: 5,
    }),
}

const PRIMARY: StylesConfig<Option, false> = {
    control: (base: CSSObjectWithLabel) => ({
        ...base,
        background: '#c0daed',
        border: 'none',
        borderRadius: 24,
        minHeight: '30px',
        height: '30px',
        cursor: 'pointer',
    }),

    option: (base: CSSObjectWithLabel) => ({
        ...base,
        color: '#27465d',
        fontSize: 14,
        fontFamily: 'MontRegular',
    }),

    singleValue: (base: CSSObjectWithLabel) => ({
        ...base,
        color: '#27465d',
        fontSize: 14,
        fontFamily: 'MontRegular',
        marginLeft: 15,
        cursor: 'pointer',
    }),

    menu: (base: CSSObjectWithLabel) => ({
        ...base,
        background: '#c0daed',
        cursor: 'pointer',
    }),
    placeholder: (base: CSSObjectWithLabel) => ({
        ...base,
        color: '#27465d',
        fontSize: 16,
    }),
    ...baseStyles,
}

const SECONDARY: StylesConfig<Option, false> = {
    control: (base: CSSObjectWithLabel) => ({
        ...base,
        background: '#FFFFFF',
        borderWidth: 1,
        borderRadius: 24,
        minHeight: '30px',
        height: '30px',
        cursor: 'pointer',
        borderColor: '#658399',
    }),

    option: (base: CSSObjectWithLabel) => ({
        ...base,
        color: '#27465d',
        fontSize: 14,
        fontFamily: 'MontRegular',
    }),

    singleValue: (base: CSSObjectWithLabel) => ({
        ...base,
        color: '#27465d',
        fontSize: 14,
        fontFamily: 'MontRegular',
        marginLeft: 15,
        cursor: 'pointer',
    }),

    menu: (base: CSSObjectWithLabel) => ({
        ...base,
        background: '#c0daed',
        cursor: 'pointer',
    }),
    ...baseStyles,
}

export { PRIMARY, SECONDARY }

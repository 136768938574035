/* eslint-disable max-len */
import L from 'leaflet'
import { useMemo } from 'react'
import { Marker, MarkerProps } from 'react-leaflet'

type BuildingMarkerProps = MarkerProps

const BuildingMarker = (props: BuildingMarkerProps): JSX.Element => {
    const { position = [51.505, -0.09], ...rest } = props

    const locationMarker = useMemo(
        () =>
            L.divIcon({
                html: `
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_5099_2042)">
      <circle cx="20" cy="16" r="16" fill="#F25130"/>
      </g>
      <circle cx="20.5" cy="36.5" r="2.5" fill="#F25130"/>
      <path d="M24.6338 21.3003V9H15.0669V21.3003H13.7002V22.667H26.0005V21.3003H24.6338ZM19.167 10.3667H20.5337V11.7334H19.167V10.3667ZM19.167 13.1001H20.5337V14.4668H19.167V13.1001ZM19.167 15.8335H20.5337V17.2002H19.167V15.8335ZM17.8003 17.2002H16.4336V15.8335H17.8003V17.2002ZM17.8003 14.4668H16.4336V13.1001H17.8003V14.4668ZM17.8003 11.7334H16.4336V10.3667H17.8003V11.7334ZM21.217 21.3003H18.4837V19.9336C18.4837 19.1819 19.0987 18.5669 19.8504 18.5669C20.6021 18.5669 21.217 19.1819 21.217 19.9336V21.3003ZM23.2671 17.2002H21.9004V15.8335H23.2671V17.2002ZM23.2671 14.4668H21.9004V13.1001H23.2671V14.4668ZM23.2671 11.7334H21.9004V10.3667H23.2671V11.7334Z" fill="white"/>
      <defs>
      <filter id="filter0_d_5099_2042" x="0" y="0" width="40" height="40" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="4"/>
      <feGaussianBlur stdDeviation="2"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.898568 0 0 0 0 0.243992 0 0 0 0 0.109825 0 0 0 0.44 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5099_2042"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5099_2042" result="shape"/>
      </filter>
      </defs>
      </svg>
      `,
                className: 'svg-icon',
            }),
        [],
    )

    return <Marker icon={locationMarker} position={position} {...rest} />
}

export { BuildingMarker }

import { atom } from 'jotai'
import { CourierCreateSalaryRate, CourierCreateAssign, ModalCreateCourier } from 'src/features/CourierCreateModals'
import { CourierCreateFormOrder } from 'src/features/CourierEditModals'
import { AddCourierGroup } from 'src/features/Couriers'
import { NotSavedDialog } from 'src/features/Dialogs/NotSavedDialog'
import { CourierCreateSchedule } from 'src/features/CourierEditModals'

import { ModalAtomState, modalAtom, setCloseAllModals, subModalAtom } from '../global/modalAtom'
import { dialogStateAtom, closeDialogAtom } from '../global/dialogAtom'

const setCourierInfoModalAtom = atom(null, (_, set) => {
    const update: ModalAtomState = {
        isOpen: true,
        children: <ModalCreateCourier />,
        title: 'Курьеры',
        onClose: () =>
            set(dialogStateAtom, {
                isOpen: true,
                children: <NotSavedDialog onAccept={() => set(setCloseAllModals)} onAbort={() => set(closeDialogAtom)} />,
            }),
    }

    set(modalAtom, update)
})

const setCourierScheduleModalAtom = atom(null, (_, set) => {
    set(subModalAtom, {
        isOpen: true,
        title: 'Добавление графика доступности курьера',
        children: <CourierCreateSchedule />,
        width: 750,
        height: 700,
    })
})

const setSalaryRateSubmodalAtom = atom(null, (_, set) => {
    set(subModalAtom, {
        isOpen: true,
        title: 'Ставка оплаты труда',
        children: <CourierCreateSalaryRate />,
        width: 1200,
        height: 700,
    })
})

const setCourierFormOrderAtom = atom(null, (_, set) => {
    set(subModalAtom, {
        isOpen: true,
        title: 'Сформировать заказ',
        children: <CourierCreateFormOrder />,
        width: 1200,
        height: 1000,
    })
})

const setCourierAssignOrderAtom = atom(null, (_, set) => {
    set(subModalAtom, {
        isOpen: true,
        title: 'Присвоить заказ',
        children: <CourierCreateAssign />,
        width: 700,
        height: 1000,
    })
})

const setCourierAddGroupAtom = atom(null, (_, set) => {
    set(modalAtom, {
        isOpen: true,
        title: 'Группа',
        children: <AddCourierGroup />,
    })
})

export {
    setCourierFormOrderAtom,
    setCourierScheduleModalAtom,
    setSalaryRateSubmodalAtom,
    setCourierAssignOrderAtom,
    setCourierInfoModalAtom,
    setCourierAddGroupAtom,
}

import { useAtomValue, useSetAtom } from 'jotai'
import { closeDialogAtom } from 'src/atoms/global/dialogAtom'
import { deleteRestAtom } from 'src/atoms/restaraunts/deleteRestAtom'
import { AppImage, Button, EButtonVariant, MediumText, Nbsp, RegularText } from 'src/components'
import { Assets } from 'src/utils'

import { currentRestAtom } from '../../atoms/restaraunts/restAtom'
import { useState } from 'react'

const DialogDeleteRest = (): JSX.Element => {
    const [loading, setLoading] = useState(false)
    const deleteRest = useSetAtom(deleteRestAtom)
    const closeDialog = useSetAtom(closeDialogAtom)
    const currentRest = useAtomValue(currentRestAtom)

    const deleteClick = async () => {
        setLoading(true)
        await deleteRest()
        setLoading(false)
    }

    return (
        <div className='flex flex-col items-center justify-center h-full p-8'>
            <div className='size-14'>
                <AppImage src={Assets.sadSmiley} />
            </div>
            <div className='p-2 text-center'>
                <RegularText>
                    Вы действительно хотите удалить ресторан
                    <Nbsp />
                </RegularText>
                <MediumText>“{currentRest?.restName}”?</MediumText>
            </div>
            <div className='flex gap-2'>
                <Button loader={loading} className='!w-44 flex justify-center !py-5' variant={EButtonVariant.RED} onClick={deleteClick}>
                    <RegularText className='!text-base'>Удалить</RegularText>
                </Button>
                <Button className='!w-44 !py-5 flex justify-center' onClick={closeDialog}>
                    <RegularText>Отмена</RegularText>
                </Button>
            </div>
        </div>
    )
}

export { DialogDeleteRest }

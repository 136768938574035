import Logo from '../assets/images/logo.png'
import Logo2 from '../assets/images/logo2.png'
import AdminPanel from '../assets/images/admin_panel.png'
import ImagePlaceholder from '../assets/images/image_placeholder.png'
import Fura from '../assets/images/fura.png'
import People from '../assets/images/people.png'
import Settings from '../assets/images/settings.png'
import SadSmiley from '../assets/images/sad_smiley.png'
import HappySmiley from '../assets/images/happySmiley.png'
import LocationMarker from '../assets/icons/location_marker.svg'

class Assets {
    static logo: string = Logo
    static logo2: string = Logo2
    static adminPanel: string = AdminPanel
    static imagePlaceholder: string = ImagePlaceholder
    static fura: string = Fura
    static people: string = People
    static settings: string = Settings
    static sadSmiley: string = SadSmiley
    static locationMarker: string = LocationMarker
    static happySmiley: string = HappySmiley
}

export { Assets }

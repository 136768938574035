import { atomWithRefresh } from 'jotai/utils'
import { CourierAvailability, LokaliApi } from 'src/services'
import { atom } from 'jotai'
import { atomWithImmer } from 'jotai-immer'
import { HttpStatusCode } from 'src/types'
import { EditCourierAvailabilityRequest } from 'src/services/LocaliRepository/types/createAvailability'
import { CourierEditSchedule } from 'src/features/CourierEditModals/CourierEditSchedule'
import dayjs from 'dayjs'
import moment from 'moment'

import { currentCourierAtom } from '../allOrders/couriersListAtom'
import { setCloseSubModalAtom, subModalAtom } from '../global/modalAtom'

import { getCourierAvailabilityAtom } from './courierAvailabilityAtom'

const openEditCourierAvailAtom = atom(null, (_, set, update?: CourierAvailability) => {
    if (update) {
        set(subModalAtom, {
            isOpen: true,
            children: <CourierEditSchedule availability={update} />,
            title: 'Редактировать доступность',
            width: 750,
            height: 700,
        })
    }
})

type ScheduleConfig = {
    time: string
    selected: boolean
}
const editScheduleStartAtom = atom<string | undefined>(undefined)

export const SCHEDULE: ScheduleConfig[] = [
    { time: '0:00', selected: false },
    { time: '1:00', selected: false },
    { time: '2:00', selected: false },
    { time: '3:00', selected: false },
    { time: '4:00', selected: false },
    { time: '5:00', selected: false },
    { time: '6:00', selected: false },
    { time: '7:00', selected: false },
    { time: '8:00', selected: false },
    { time: '9:00', selected: false },
    { time: '10:00', selected: false },
    { time: '11:00', selected: false },
    { time: '12:00', selected: false },
    { time: '13:00', selected: false },
    { time: '14:00', selected: false },
    { time: '15:00', selected: false },
    { time: '16:00', selected: false },
    { time: '17:00', selected: false },
    { time: '18:00', selected: false },
    { time: '19:00', selected: false },
    { time: '20:00', selected: false },
    { time: '21:00', selected: false },
    { time: '22:00', selected: false },
    { time: '23:00', selected: false },
]

type Update = {
    parentIndex: number
    index: number
}
const editScheduleConfigAtom = atomWithImmer<(typeof SCHEDULE)[]>([SCHEDULE, SCHEDULE, SCHEDULE, SCHEDULE, SCHEDULE, SCHEDULE, SCHEDULE])

const editScheduleTitleAtom = atom('')
const scheduleDaysAtom = atom<number>(0)
export const editScheduleDaysAtom = atom<number | null>(null)

const editOnClickTimeAtom = atom(null, (_, set, { parentIndex, index }: Update) => {
    set(editScheduleConfigAtom, old => {
        old[parentIndex][index].selected = !old[parentIndex][index].selected
    })
})

const createAvailabilityNetworkStateAtom = atom<HttpStatusCode>(HttpStatusCode.REQUEST_TIMEOUT)

const editCourierAvailabilityAtom = atom(null, async (get, set, arg: CourierAvailability) => {
    const title = get(editScheduleTitleAtom)
    const schedule = get(editScheduleConfigAtom)
    const courierId = get(currentCourierAtom)?.item.courier_id
    const scheduleStart = get(editScheduleStartAtom)
    const dayCount = get(editScheduleDaysAtom)

    if (!courierId || !scheduleStart) {
        set(createAvailabilityNetworkStateAtom, HttpStatusCode.BAD_REQUEST)
        return
    }

    const mappedSchedule = schedule.map((el, index) => ({
        day: index + 1,
        time: el.filter(time => time.selected).map(filtered => filtered.time),
    }))

    try {
        set(createAvailabilityNetworkStateAtom, HttpStatusCode.LOADING)

        const req: EditCourierAvailabilityRequest = {
            title,
            schedule: mappedSchedule,
            courier_id: courierId,
            avialschedule_id: arg.avialschedule_id,
            scheduleStart: moment(scheduleStart, 'DD.MM.YYYY').toISOString(),
            dayCount: dayCount ?? 0,
        }

        const statusCode = await LokaliApi.editCourierAvailability(req)

        if (statusCode === HttpStatusCode.OK) {
            set(setCloseSubModalAtom)
            set(createAvailabilityNetworkStateAtom, HttpStatusCode.OK)
            set(getCourierAvailabilityAtom)
            set(editScheduleConfigAtom, [SCHEDULE, SCHEDULE, SCHEDULE, SCHEDULE, SCHEDULE, SCHEDULE, SCHEDULE])
            set(editScheduleTitleAtom, '')
        } else {
            set(createAvailabilityNetworkStateAtom, statusCode)
        }
    } catch (e) {
        console.error('Error editing courier availability:', e)
        set(createAvailabilityNetworkStateAtom, HttpStatusCode.INTERNAL_SERVER_ERROR)
    }
})

export {
    editCourierAvailabilityAtom,
    createAvailabilityNetworkStateAtom,
    editOnClickTimeAtom,
    editScheduleConfigAtom,
    editScheduleTitleAtom,
    openEditCourierAvailAtom,
    editScheduleStartAtom,
}

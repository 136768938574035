import { atom } from 'jotai'
import { CancelOrder, CancelReasonModal } from 'src/features/Orders'
import { CancelOrderRequest, LokaliApi, Order } from 'src/services'
import { HttpStatusCode } from 'src/types'

import { setCloseAllModals, subModalAtom } from '../global/modalAtom'
import { dialogStateAtom } from '../global/dialogAtom'
import { currentClientAtom } from '../clientDb/clientsAtom'

import { currentOrderAtom, getOrdersAtom } from './ordersAtom'

const contextMenuOrderAtom = atom<Order | null>(null)

const cancelOrderNetworkStateAtom = atom<HttpStatusCode>(HttpStatusCode.I_AM_A_TEAPOT)

const cancelOrderAtom = atom(null, async (get, set, update: string) => {
    const orderId = get(currentOrderAtom)?.order_id
    set(cancelOrderNetworkStateAtom, HttpStatusCode.LOADING)

    try {
        if (orderId?.trim()) {
            const req: CancelOrderRequest = {
                order_id: orderId,
                cancel_reason: update,
            }
            const response = await LokaliApi.cancelOrder(req)

            if (response === HttpStatusCode.OK) {
                set(cancelOrderNetworkStateAtom, HttpStatusCode.OK)
                set(setCloseAllModals)
                set(getOrdersAtom)
            }
            set(setCloseAllModals)
            set(cancelOrderNetworkStateAtom, HttpStatusCode.I_AM_A_TEAPOT)
        }
    } catch (e) {
        set(cancelOrderNetworkStateAtom, HttpStatusCode.I_AM_A_TEAPOT)
    }
})

const openCancelDialogAtom = atom(null, (_, set, update?: Order) => {
    if (update) {
        set(contextMenuOrderAtom, update)
        set(currentOrderAtom, update)
    }

    set(dialogStateAtom, {
        isOpen: true,
        children: <CancelOrder />,
    })
})

const cancelReasonAtom = atom(null, (_, set) => {
    set(subModalAtom, {
        isOpen: true,
        children: <CancelReasonModal />,
        title: 'Причина отмены',
        height: 400,
        width: 500,
        zIndex: 50,
    })
})

export { cancelReasonAtom, openCancelDialogAtom, cancelOrderAtom }

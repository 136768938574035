import { atom } from 'jotai'
import { ReactNode } from 'react'

import { closeDialogAtom } from './dialogAtom'

export type ModalAtomState = {
    isOpen: boolean
    title?: string
    children: ReactNode
    onClose?: VoidFunction
}

export type SubModalAtomState = {
    isOpen: boolean
    title: string
    children?: ReactNode
    width?: number
    height?: number
    zIndex?: number
    icon?: ReactNode
}

const modalAtom = atom<ModalAtomState>({
    isOpen: false,
    title: '',
    children: null,
})

const setModalAtom = atom(null, (_, set, update: ModalAtomState) => {
    set(modalAtom, update)
})

const setModalCloseAtom = atom(null, (_, set) => {
    set(modalAtom, { isOpen: false, title: '', children: null })
})

const setCloseAllModals = atom(null, (_, set) => {
    set(setModalCloseAtom)
    set(closeDialogAtom)
    set(setCloseSubModalAtom)
})

const subModalAtom = atom<SubModalAtomState>({
    isOpen: false,
    title: '',
    width: 440,
    height: 440,
})

const setCloseSubModalAtom = atom(null, (_, set) => {
    set(subModalAtom, { isOpen: false, title: '' })
})

export { setModalAtom, modalAtom, setModalCloseAtom, subModalAtom, setCloseSubModalAtom, setCloseAllModals }

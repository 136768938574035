import { Button, MediumText, RegularText, Tabs, EButtonVariant } from 'src/components'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useSetAtom } from 'jotai'
import { createCourierAtom } from 'src/atoms/allCouriers/createCourierAtom'
import { DateUtils } from 'src/utils'
import { setNotSavedDialogAtom } from 'src/atoms/global/dialogAtom'

import { CreateCourierRequest } from '../../services'

import { CREATE_COURIER_TABS } from './CourierCreateTabs'
import { CourierCreateFormData } from './types'
import { CourierSchema } from './schema'
import { useState } from 'react'

export const ModalCreateCourier = (): JSX.Element => {
    const [loading, setLoading] = useState(false)
    const createCourier = useSetAtom(createCourierAtom)

    const setNotSaved = useSetAtom(setNotSavedDialogAtom)

    const methods = useForm<CourierCreateFormData>({
        resolver: zodResolver(CourierSchema),
        reValidateMode: 'onBlur',
    })

    const handleOnValid = async (data: CourierCreateFormData) => {
        setLoading(true)
        const {
            sysName,
            tabNumber,
            firstName,
            surname,
            patronymic,
            deliveryType,
            gender,
            birthday,
            phoneNumber,
            whatsApp,
            telegram,
            email,
            comment,
            password,
            login,
            passSeries,
            passIssuedBy,
            passIssueDate,
            passEndDate,
            passSnils,
            passInn,
            passCity,
            passStreet,
            passHouse,
            passHousing,
            passNumber,
            passApartment,
            cityKey,
            rest_id,
            isSalary,
            weekends,
        } = data

        const courierData: CreateCourierRequest = {
            deliveryType: deliveryType.value,
            sysName,
            tabNumber,
            firstName,
            surname,
            patronymic,
            gender: gender?.value ?? '',
            birthday: birthday ? DateUtils.parseDateInput(birthday) : undefined,
            phoneNumber,
            whatsApp,
            telegram,
            email,
            comment,
            password,
            login,
            passSeries: passSeries ?? '',
            passIssuedBy: passIssuedBy ?? '',
            passIssueDate: passIssueDate ? (DateUtils.parseDateInput(passIssueDate) ?? '') : '',
            passEndDate: passEndDate ? (DateUtils.parseDateInput(passEndDate) ?? '') : '',
            passSnils: passSnils ?? '',
            passInn: passInn ?? '',
            passCity: passCity ?? '',
            passStreet: passStreet ?? '',
            passHouse: passHouse ?? '',
            passHousing: passHousing ?? '',
            passNumber: passNumber ?? '',
            passApartment: passApartment ?? '',
            cityKey,
            rest_id,
            isSalary,
            weekends,
        }
        await createCourier(courierData)
        setLoading(false)
    }

    return (
        <form onSubmit={methods.handleSubmit(handleOnValid)}>
            <FormProvider {...methods}>
                <Tabs tabs={CREATE_COURIER_TABS} />
                <div className='flex justify-between px-5 py-3'>
                    <div className='flex flex-1 justify-end gap-3'>
                        <Button type='submit' loader={loading} icon='save' variant={EButtonVariant.BLUE}>
                            <MediumText>Сохранить</MediumText>
                        </Button>
                        <Button type='button' onClick={setNotSaved} variant={EButtonVariant.SECONDARY}>
                            <RegularText>Выйти</RegularText>
                        </Button>
                    </div>
                </div>
            </FormProvider>
        </form>
    )
}

import Select, { ActionMeta, OnChangeValue, PropsValue } from 'react-select'
import classNames from 'classnames'
import { Icon } from 'src/assets/icons'
import { FocusEvent, forwardRef } from 'react'
import { useSetAtom } from 'jotai'
import { Moment } from 'moment'

import { selectOpenedAtom } from '../../atoms/global/selectAtom'

import { ErrorText } from '../ErrorText'

import styles from './styles.module.scss'
import { PRIMARY, SECONDARY } from './config'

export type SelectOption = {
    value: any
    label: string
    date?: { start: Moment; end: Moment }
}

export type AppSelectProps = {
    options?: SelectOption[]
    isDisabled?: boolean
    isSearchable?: boolean
    isClearable?: boolean
    isLoading?: boolean
    className?: string
    placeholder?: string
    selectVariant?: ESelectVariant
    onChange?: (newValue: OnChangeValue<SelectOption, false>, actionMeta: ActionMeta<SelectOption>) => void
    onBlur?: (ev: FocusEvent<Element, Element>) => void
    value?: any
    error?: string
    defaultValue?: PropsValue<SelectOption>
}

export enum ESelectVariant {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
    QUARTERY = 'quartery',
}

export const AppSelect = (props: AppSelectProps): JSX.Element => {
    const {
        options = [],
        isDisabled = false,
        isSearchable = false,
        isClearable,
        isLoading,
        className,
        placeholder = '',
        selectVariant = ESelectVariant.PRIMARY,
        onChange,
        onBlur,
        value,
        error,
        defaultValue,
    } = props

    const setIsSelectOpened = useSetAtom(selectOpenedAtom)

    const customStyle = {
        [ESelectVariant.PRIMARY]: PRIMARY,
        [ESelectVariant.SECONDARY]: SECONDARY,
        [ESelectVariant.TERTIARY]: SECONDARY,
        [ESelectVariant.QUARTERY]: PRIMARY,
    }[selectVariant]

    const handleFocus = () => {
        setIsSelectOpened(true)
    }

    const handleBlur = (ev: FocusEvent<Element, Element>) => {
        setIsSelectOpened(false)

        if (onBlur) {
            onBlur(ev)
        }
    }

    const handleChange = (newValue: OnChangeValue<SelectOption, false>, actionMeta: ActionMeta<SelectOption>) => {
        // setIsSelectOpened(false);
        if (onChange) {
            onChange(newValue, actionMeta)
        }
    }

    return (
        <div className='flex flex-col'>
            <Select
                defaultValue={defaultValue}
                className={classNames(styles.select, className, {
                    [styles.primary]: selectVariant === ESelectVariant.PRIMARY,
                    [styles.secondary]: selectVariant === ESelectVariant.SECONDARY,
                    [styles.tertiary]: selectVariant === ESelectVariant.TERTIARY,
                    [styles.quartery]: selectVariant === ESelectVariant.QUARTERY,
                    [styles.clearable]: isClearable,
                })}
                classNamePrefix='select'
                onChange={handleChange}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isClearable={isClearable}
                isSearchable={isSearchable}
                styles={customStyle}
                value={value}
                placeholder={placeholder}
                onBlur={handleBlur}
                onFocus={handleFocus}
                components={{
                    IndicatorSeparator: () => null,
                    ClearIndicator: props => {
                        const { children = <Icon type='cross' height='20' width='20' />, innerProps } = props

                        return (
                            <div {...innerProps} style={{ marginRight: '14px' }}>
                                {children}
                            </div>
                        )
                    },
                }}
                name='color'
                options={options}
            />
            {<ErrorText error={error} />}
        </div>
    )
}

import { z } from 'zod'

export const AdminEditSchema = z.object({
    systemName: z.string({ required_error: 'Обязательное поле' }).min(1, 'Обязательное поле'),
    tabNumber: z.string().optional(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    middleName: z.string().optional(),
    gender: z.string().optional(),
    birthday: z.string().optional(),
    phone: z.string().optional(),
    whatsapp: z.string().optional(),
    telegram: z.string().optional(),
    email: z.string().optional(),
    comment: z.string().optional(),
    login: z.string({ required_error: 'Обязательное поле' }).min(1, 'Обязательное поле'),
    password: z.string({ required_error: 'Обязательное поле' }).min(1, 'Обязательное поле'),
    passNum: z.string().optional(),
    passSeries: z.string().optional(),
    passIssuedBy: z.string().optional(),
    passIssuedWhen: z.string().optional(),
    passActualTo: z.string().optional(),
    snils: z.string().optional(),
    inn: z.string().optional(),
    city: z.string().optional(),
    street: z.string().optional(),
    house: z.string().optional(),
    corpus: z.string().optional(),
    apartment: z.string().optional(),
    adminPhoto: z.string().optional(),
    rights: z.array(z.string()).optional(),

    passData: z
        .object({
            passSeries: z.string().optional(),
            passNum: z.string().optional(),
            passIssuedBy: z.string().optional(),
            passIssuedWhen: z.string().optional(),
            passActualTo: z.string().optional(),
            snils: z.string().optional(),
            inn: z.string().optional(),
            city: z.string().optional(),
            street: z.string().optional(),
            house: z.string().optional(),
            corpus: z.string().optional(),
            apartment: z.string().optional(),
            adminPhoto: z.string().optional(),
        })
        .optional(),
})

export type AdminEditData = z.infer<typeof AdminEditSchema>

import classNames from 'classnames'

import styles from './styles.module.scss'

type SpinnerProps = {
    className?: string
}

export const Spinner = (props: SpinnerProps): JSX.Element => (
    <div className='h-full w-full flex justify-center'>
        <div className={classNames(styles.spinner, props.className)}></div>
    </div>
)

type WorkDay = {
    day: 'ВСЕ' | 'ПН' | 'ВТ' | 'СР' | 'ЧТ' | 'ПТ' | 'СБ' | 'ВС'
    workStart: string
    workEnd: string
    lunchStart: string
    lunchEnd: string
    isActive: boolean
}

const WORK_DAYS: WorkDay[] = [
    { day: 'ПН', workStart: '', workEnd: '', lunchStart: '', lunchEnd: '', isActive: false },
    { day: 'ВТ', workStart: '', workEnd: '', lunchStart: '', lunchEnd: '', isActive: false },
    { day: 'СР', workStart: '', workEnd: '', lunchStart: '', lunchEnd: '', isActive: false },
    { day: 'ЧТ', workStart: '', workEnd: '', lunchStart: '', lunchEnd: '', isActive: false },
    { day: 'ПТ', workStart: '', workEnd: '', lunchStart: '', lunchEnd: '', isActive: false },
    { day: 'СБ', workStart: '', workEnd: '', lunchStart: '', lunchEnd: '', isActive: false },
    { day: 'ВС', workStart: '', workEnd: '', lunchStart: '', lunchEnd: '', isActive: false },
]

export { WORK_DAYS }

export type { WorkDay }

import { atom } from 'jotai'
import { EditCourierGroupRequest, LokaliApi } from 'src/services'
import { HttpStatusCode } from 'src/types'

import { setCloseAllModals } from '../global/modalAtom'

import { allCourierGroupsAtom } from './allCourierGroupsAtom'

const editCourierGroupNetworkStateAtom = atom<HttpStatusCode>(HttpStatusCode.I_AM_A_TEAPOT)

const editCourierGroupAtom = atom(null, async (_, set, req: EditCourierGroupRequest) => {
    try {
        set(editCourierGroupNetworkStateAtom, HttpStatusCode.LOADING)

        const statusCode = await LokaliApi.editCourierGroup(req)

        if (statusCode === HttpStatusCode.OK) {
            set(setCloseAllModals)
            set(editCourierGroupNetworkStateAtom, HttpStatusCode.OK)

            set(allCourierGroupsAtom)
        }
    } catch (e) {
        set(editCourierGroupNetworkStateAtom, HttpStatusCode.INTERNAL_SERVER_ERROR)
    }
})

export { editCourierGroupAtom, editCourierGroupNetworkStateAtom }

import { useSetAtom } from 'jotai'
import { deleteCourierAtom } from 'src/atoms/allCouriers/editCourierAtom'
import { closeDialogAtom } from 'src/atoms/global/dialogAtom'
import { AppImage, Button, EButtonVariant, MediumText, Nbsp, RegularText } from 'src/components'
import { Assets } from 'src/utils'
import { useState } from 'react'

type DeleteCourierProps = {
    courier_id: string
    name?: string
}

const DeleteCourierDialog = ({ courier_id, name }: DeleteCourierProps): JSX.Element => {
    const [loader, setLoader] = useState(false)
    const closeDialog = useSetAtom(closeDialogAtom)
    const deleteCourier = useSetAtom(deleteCourierAtom)

    const deleteClick = async () => {
        setLoader(true)
        await deleteCourier({ courier_id })
        setLoader(false)
    }

    return (
        <div className='flex flex-col items-center justify-center h-full p-8'>
            <div className='size-14'>
                <AppImage src={Assets.sadSmiley} />
            </div>
            <div className='p-2 text-center'>
                <RegularText>
                    Вы действительно хотите удалить курьера безвозвратно <Nbsp />
                </RegularText>
                <MediumText>“{name}”?</MediumText>
            </div>
            <div className='flex gap-2'>
                <Button loader={loader} className='!w-44 flex justify-center !py-5' variant={EButtonVariant.RED} onClick={deleteClick}>
                    <RegularText className='!text-base'>Удалить</RegularText>
                </Button>
                <Button className='!w-44 !py-5 flex justify-center' onClick={closeDialog}>
                    <RegularText>Отмена</RegularText>
                </Button>
            </div>
        </div>
    )
}

export { DeleteCourierDialog }

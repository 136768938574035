import { DateInput, DateInputProps } from '../DateInput'
import { RegularText } from '../RegularText'

type NamedDateInputProps = {
    name: string
    dateInputProps?: DateInputProps
}

export const NamedDateInput = (props: NamedDateInputProps): JSX.Element => {
    const { name, dateInputProps } = props
    return (
        <div className='flex items-center gap-3'>
            <RegularText>{name}</RegularText>
            <DateInput {...dateInputProps} />
        </div>
    )
}

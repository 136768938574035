import { z } from 'zod'

enum ECourierStatus {
    NEW = 'new',
    GOING = 'going',
    SHIPPING = 'shipping',
}

enum EOrderStatus {
    BUSY = 'busy',
    NEW = 'new',
    COOKING = 'cooking',
    GOING = 'going',
    COOKED = 'cooked',
    SHIPPING = 'shipping',
    COMPLETE = 'complete',
    CANCELLED = 'cancelled',
}

const CourierStatusEnumSchema = z.nativeEnum(ECourierStatus)

const OrderStatusEnumSchema = z.nativeEnum(EOrderStatus)

export { CourierStatusEnumSchema, OrderStatusEnumSchema, EOrderStatus, ECourierStatus }

import React, { useState } from 'react'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { AppMap, Button, MediumText, RegularText, SemiBoldText, TextInput } from 'src/components'
import { EButtonVariant } from 'src/components/Button'
import { RoundedWrapper } from 'src/components/RoundedWrapper'
import { setCloseSubModalAtom } from 'src/atoms/global/modalAtom'
import { ordersToAssignAtom, selectedOrderAtom, courierTakeOrderAtom } from 'src/atoms/allCouriers/assignOrderAtom'
import { EOrderStatus, Order } from 'src/services'

import { getCourierDeliveries } from '../../atoms/allCouriers/editDeliveryAtoms'

import { CourierCreateAssignOrderItem } from './CourierCreateAssignOrderItem'

export const CourierCreateAssign: React.FC = () => {
    const [loading, setLoading] = useState(false)
    const closeSubModal = useSetAtom(setCloseSubModalAtom)
    const orders = useAtomValue(ordersToAssignAtom)
    const [selectedOrder, setSelectedOrder] = useAtom(selectedOrderAtom)
    const courierTakeOrder = useSetAtom(courierTakeOrderAtom)
    const [, refreshDeliveries] = useAtom(getCourierDeliveries)

    const handleAssign = async () => {
        if (selectedOrder) {
            setLoading(true)
            await courierTakeOrder()

            closeSubModal()
            refreshDeliveries()
            setLoading(false)
        }
    }

    const onOpenClick = (order: Order) => {
        window.open(`${window.location.origin}/all-orders?order-id=${order.order_id}`, '_blank')
    }

    return (
        <div className='flex flex-col h-full max-h-L'>
            <div className='flex gap-2 h-full'>
                <div className='flex flex-col overflow-scroll max-h-96'>
                    {!!orders?.length &&
                        orders
                            .filter(
                                (order: Order) =>
                                    ![EOrderStatus.CANCELLED, EOrderStatus.COMPLETE, EOrderStatus.CANCELLED].includes(order.status),
                            )
                            .map((order: Order) => (
                                <CourierCreateAssignOrderItem
                                    key={order.order_id}
                                    orderNumber={order.orderNumber}
                                    orderDate={order.createdAt}
                                    orderPrice={order.price}
                                    orderStatus={order.status}
                                    spentTimeOnCooking={order.spentTimeOnCooking || null}
                                    onClick={() => setSelectedOrder(order)}
                                    onOpenClick={() => onOpenClick(order)}
                                    isSelected={selectedOrder?.order_id === order.order_id}
                                    restAddress={order.restAddress || ''}
                                    deliveryAddress={order.deliveryAddress || ''}
                                />
                            ))}
                </div>
                {/*<div className="border border-solid border-mainBackgroundColor flex-1 p-1">
          <AppMap/>
        </div>*/}
            </div>
            <div className='flex justify-between gap-4 p-4'>
                {/*<div className="flex gap-5">
          <SemiBoldText>
            Номер заказа:
            <RegularText>
              {selectedOrder?.orderNumber || ''}
            </RegularText>
          </SemiBoldText>
          <TextInput className="w-inputL" rightIcon="search"/>
          <RoundedWrapper icon="move"/>
        </div>*/}
                <div />
                <div className='flex gap-4'>
                    <Button variant={EButtonVariant.PRIMARY} onClick={handleAssign} loader={loading} disabled={!selectedOrder}>
                        <MediumText>Присвоить</MediumText>
                    </Button>
                    <Button variant={EButtonVariant.SECONDARY} onClick={() => closeSubModal()}>
                        <RegularText>Назад</RegularText>
                    </Button>
                </div>
            </div>
        </div>
    )
}

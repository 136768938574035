import { atom } from 'jotai'
import { AdminEditData } from 'src/features/AdminsEditModals/schema'
import { DeleteAdminRequest, EditAdminRequest, FireAdminRequest, FireCourierRequest, LokaliApi } from 'src/services'
import { HttpStatusCode, NetworkStatusState } from 'src/types'

import { setCloseAllModals } from '../global/modalAtom'
import { fetchAllCouriersAtom } from '../allCouriers/allCouriersAtom'
import { fireCourierNetworkStateAtom } from '../allCouriers/editCourierAtom'

import { currentAdminAtom, getAdminsAtom } from './adminsTableAtom'
import {
    AdminRight,
    adminRightAtom,
    clientRightAtom,
    courierRightAtom,
    generalRightAtom,
    orderRightAtom,
    restRightAtom,
} from './adminRightsAtom'

const initialAdminPhotoAtom = atom<string | undefined>(undefined)
const editAdminPhotoAtom = atom<File | null>(null)

const editAdminNetworkStateAtom = atom<HttpStatusCode>(HttpStatusCode.I_AM_A_TEAPOT)
const isAdminPhotoDeletedAtom = atom<boolean>(false)

const editAdminAtom = atom(null, async (get, set, req: AdminEditData) => {
    const photo = get(editAdminPhotoAtom)
    const currentAdmin = get(currentAdminAtom)

    const generalRights = get(generalRightAtom)
    const clientRights = get(clientRightAtom)
    const courierRights = get(courierRightAtom)
    const restRights = get(restRightAtom)
    const orderRights = get(orderRightAtom)
    const adminRights = get(adminRightAtom)
    const isPhotoDeleted = get(isAdminPhotoDeletedAtom)
    const rights: AdminRight[] = [...generalRights, ...clientRights, ...courierRights, ...restRights, ...orderRights, ...adminRights]

    const filtered = rights.filter(el => el.checked)
    const mapped = filtered.map(right => right.id)

    try {
        if (currentAdmin?.admin_id) {
            set(editAdminNetworkStateAtom, HttpStatusCode.LOADING)

            let photoId

            if (photo instanceof File) {
                const formData = new FormData()
                formData.append('file', photo)
                photoId = await LokaliApi.uploadFiles(formData)
            }

            let updatedAdminPhoto
            if (isPhotoDeleted) {
                updatedAdminPhoto = null
            } else if (photoId?.file) {
                updatedAdminPhoto = photoId.file
            } else {
                updatedAdminPhoto = currentAdmin.passData?.adminPhoto || req.passData?.adminPhoto
            }

            const updatedPassData = {
                ...req.passData,
                adminPhoto: updatedAdminPhoto,
            }

            const statusCode = await LokaliApi.editAdmin({
                ...req,
                passData: updatedPassData,
                admin_id: currentAdmin.admin_id,
                rights: mapped,
            })

            if (statusCode === HttpStatusCode.OK) {
                set(setCloseAllModals)
                set(editAdminNetworkStateAtom, HttpStatusCode.OK)
                set(getAdminsAtom)
                set(isAdminPhotoDeletedAtom, false)
            }
        }
    } catch (e) {
        set(editAdminNetworkStateAtom, HttpStatusCode.I_AM_A_TEAPOT)
    }
})
const deleteAdminNetworkStateAtom = atom<NetworkStatusState>({
    statusCode: HttpStatusCode.I_AM_A_TEAPOT,
})

// const fireCourierNetworkStateAtom = atom<NetworkStatusState>({
//   statusCode: HttpStatusCode.I_AM_A_TEAPOT
// });

const deleteAdminAtom = atom(null, async (_, set, req: DeleteAdminRequest) => {
    try {
        set(deleteAdminNetworkStateAtom, {
            statusCode: HttpStatusCode.LOADING,
        })

        const statusCode = await LokaliApi.deleteAdmin(req)

        if (statusCode === HttpStatusCode.OK) {
            set(setCloseAllModals)
            set(deleteAdminNetworkStateAtom, {
                statusCode: HttpStatusCode.OK,
            })

            set(getAdminsAtom)
        }
    } catch (e) {
        set(deleteAdminNetworkStateAtom, {
            statusCode: HttpStatusCode.INTERNAL_SERVER_ERROR,
        })
    }
})

const fireAdminNetworkStateAtom = atom<NetworkStatusState>({
    statusCode: HttpStatusCode.I_AM_A_TEAPOT,
})

const fireAdminAtom = atom(null, async (_, set, req: FireAdminRequest) => {
    try {
        set(fireCourierNetworkStateAtom, {
            statusCode: HttpStatusCode.LOADING,
        })

        const statusCode = await LokaliApi.fireAdmin(req)

        if (statusCode === HttpStatusCode.OK) {
            set(setCloseAllModals)
            set(fireAdminNetworkStateAtom, {
                statusCode: HttpStatusCode.OK,
            })

            set(getAdminsAtom)
        }
    } catch (e) {
        set(fireAdminNetworkStateAtom, {
            statusCode: HttpStatusCode.INTERNAL_SERVER_ERROR,
        })
    }
})

export {
    initialAdminPhotoAtom,
    editAdminPhotoAtom,
    editAdminAtom,
    editAdminNetworkStateAtom,
    deleteAdminAtom,
    fireAdminAtom,
    isAdminPhotoDeletedAtom,
}

import { useEffect, useState } from 'react'
import { Button, MediumText, RegularText, Tabs } from 'src/components'
import { Tab } from 'src/components/Tabs'
import { EButtonVariant } from 'src/components/Button'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useAtomValue, useSetAtom } from 'jotai'
import { currentCourierAtom } from 'src/atoms/allOrders/couriersListAtom'
import { selectedCourierGroupAtom } from 'src/atoms/allCouriers/allCouriersAtom'
import { editCourierAtom, initialCourierPhotoAtom } from 'src/atoms/allCouriers/editCourierAtom'
import { DateUtils } from 'src/utils'
import { setNotSavedDialogAtom } from 'src/atoms/global/dialogAtom'

import { ClientGroup, CourierGroup } from '../../services'
import { allCourierGroupsAtom } from '../../atoms/allCouriers/allCourierGroupsAtom'
import { EditCourierRequest } from '../../services/LocaliRepository'
import { getCourierDeliveries } from '../../atoms/allCouriers/editDeliveryAtoms'

import { CourierEditInfo } from './CourierEditInfo'
import { CourierEditPassport } from './CourierEditPassport'
import { CourierDelivery } from './CourierDelivery'
import { CourierPayment } from './CourierPayment'
import { CourierEditAvailability } from './CourierEditAvailability'
import { CourierEditSchema } from './schema'
import { CourierEditFormData } from './types'

const TABS: Tab[] = [
    {
        tabOption: {
            tabName: 'Инфо',
        },
        child: <CourierEditInfo />,
    },
    {
        tabOption: {
            tabName: 'Паспорт',
        },
        child: <CourierEditPassport />,
    },
    {
        tabOption: {
            tabName: 'Доставки',
        },
        child: <CourierDelivery />,
    },
    {
        tabOption: {
            tabName: 'Оплата',
        },
        child: <CourierPayment />,
    },
    {
        tabOption: {
            tabName: 'Доступность',
        },
        child: <CourierEditAvailability />,
    },
]

export const ModalEditCourier = (): JSX.Element => {
    const [loader, setLoader] = useState(false)
    const methods = useForm<CourierEditFormData>({
        resolver: zodResolver(CourierEditSchema),
        reValidateMode: 'onBlur',
    })

    const currentCourier = useAtomValue(currentCourierAtom)
    const groups = useAtomValue(allCourierGroupsAtom)
    const currentCourierGroup = useAtomValue(selectedCourierGroupAtom)
    const setInitialPhoto = useSetAtom(initialCourierPhotoAtom)
    const deliveriesState = useAtomValue(getCourierDeliveries)
    const editCourier = useSetAtom(editCourierAtom)
    const setNotSaved = useSetAtom(setNotSavedDialogAtom)

    let totalDeliveries = 0
    let totalAmount = 0

    if (deliveriesState.data && deliveriesState.data.courierDeliv) {
        totalDeliveries = deliveriesState.data.courierDeliv.filter(delivery => delivery.status !== 'cancelled').length
        totalAmount = deliveriesState.data.courierDeliv.reduce((sum, delivery) => {
            if (delivery.status !== 'cancelled') {
                return sum + (delivery.price || 0)
            }
            return sum
        }, 0)
    }

    const onSubmit = async (data: CourierEditFormData) => {
        if (currentCourier?.item.courier_id) {
            setLoader(true)
            const editData: EditCourierRequest = {
                courier_id: currentCourier.item.courier_id,
                deliveryType: data.deliveryType.value,
                sysName: data.sysName,
                tabNumber: data.tabNumber,
                firstName: data.firstName,
                patronymic: data.patronymic,
                surname: data.surname,
                gender: data.gender?.value,
                birthday: DateUtils.parseDateInput(data.birthday),
                phoneNumber: data.phoneNumber,
                whatsApp: data.whatsApp,
                telegram: data.telegram,
                email: data.email,
                comment: data.comment,
                password: data.password,
                login: data.login,
                cityKey: data.cityKey,
                rest_id: data.rest_id,
                isSalary: data.isSalary,
                weekends: data.weekends,
                passData: {
                    passSeries: data.passSeries,
                    passIssuedBy: data.passIssuedBy,
                    passIssueDate: DateUtils.parseDateInput(data.passIssueDate),
                    passEndDate: DateUtils.parseDateInput(data.passEndDate),
                    passSnils: data.passSnils,
                    passInn: data.passInn,
                    passCity: data.passCity,
                    passStreet: data.passStreet,
                    passHouse: data.passHouse,
                    passHousing: data.passHousing,
                    passNumber: data.passNumber,
                    passApartment: data.passApartment,
                    courierPhoto: data.courierPhoto,
                },
            }

            await editCourier(editData)
            setLoader(false)
        }
    }
    const handleReset = () => {
        let thisGroup: CourierGroup | undefined
        if (currentCourier?.item?.group_id && groups?.data?.length) {
            thisGroup = groups.data.find(item => item.item.group_id === currentCourier.item.group_id)
        }

        if (currentCourier?.item) {
            const courier = currentCourier.item
            const { passData } = currentCourier.item
            console.log(passData)

            if (passData?.courierPhoto) {
                setInitialPhoto(passData.courierPhoto)
            } else {
                setInitialPhoto(undefined)
            }

            if (thisGroup) {
                methods.setValue('deliveryType', {
                    label: thisGroup.item.title,
                    value: thisGroup.item.group_id,
                })
            }
            methods.setValue('sysName', courier.sysName ?? undefined)
            methods.setValue('birthday', DateUtils.formatDate(courier.birthday) ?? undefined)
            methods.setValue('tabNumber', courier.tabNumber ?? undefined)
            methods.setValue('firstName', courier.firstName ?? '')
            methods.setValue('surname', courier.surname ?? '')
            methods.setValue('patronymic', courier.patronymic ?? '')
            if (['Мужской', 'М', 'Женский', 'Ж'].includes(courier.gender || '')) {
                methods.setValue('gender', {
                    label: courier.gender === 'М' ? 'Мужской' : 'Женский',
                    value: courier.gender === 'Ж' ? 'Женский' : 'Мужской',
                })
            }
            methods.setValue('phoneNumber', courier.phoneNumber ?? '')
            methods.setValue('whatsApp', courier.whatsApp ?? undefined)
            methods.setValue('telegram', courier.telegram ?? undefined)
            methods.setValue('email', courier.email ?? undefined)
            methods.setValue('comment', courier.comment ?? undefined)
            methods.setValue('login', courier.login ?? '')
            methods.setValue('password', courier.password ?? '')
            methods.setValue('passSeries', passData?.passSeries ?? '')
            methods.setValue('passNumber', passData?.passNumber ?? '')
            methods.setValue('passIssuedBy', passData?.passIssuedBy ?? '')
            methods.setValue('passIssueDate', DateUtils.formatDate(passData?.passIssueDate) ?? '')
            methods.setValue('passEndDate', DateUtils.formatDate(passData?.passIssueDate) ?? '')
            methods.setValue('passSnils', passData?.passSnils ?? '')
            methods.setValue('passInn', passData?.passInn ?? '')
            methods.setValue('passCity', passData?.passCity ?? '')
            methods.setValue('passStreet', passData?.passStreet ?? '')
            methods.setValue('passHouse', passData?.passHouse ?? '')
            methods.setValue('passHousing', passData?.passHousing ?? '')
            methods.setValue('passApartment', passData?.passApartment ?? '')
        }
    }

    useEffect(() => {
        handleReset()
    }, [currentCourier, currentCourierGroup])

    useEffect(() => {
        // handleReset();
    }, [])

    return (
        <form onSubmit={methods.handleSubmit(onSubmit)} className='h-full'>
            <FormProvider {...methods}>
                <Tabs tabs={TABS} />
                <div className='flex justify-between px-5 py-3'>
                    <div className='flex flex-col'>
                        <MediumText>Общее количество доставок: {totalDeliveries}</MediumText>
                        <MediumText>Общая сумма доставок: {totalAmount.toFixed(2)} ₽</MediumText>
                    </div>
                    <div className='flex flex-1 justify-end gap-3'>
                        <Button icon='refresh' variant={EButtonVariant.SECONDARY} onClick={handleReset}>
                            <RegularText>Обновить</RegularText>
                        </Button>
                        <Button icon='save' loader={loader} type='submit' variant={EButtonVariant.BLUE}>
                            <MediumText>Сохранить</MediumText>
                        </Button>
                        <Button type='button' onClick={setNotSaved} variant={EButtonVariant.SECONDARY}>
                            <RegularText>Выйти</RegularText>
                        </Button>
                    </div>
                </div>
            </FormProvider>
        </form>
    )
}

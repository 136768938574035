import { z } from 'zod'

const FormOrderSchema = z.object({
    from_street: z.string(),
    to_street: z.string(),

    distance: z.string().optional(),
    price: z.string().optional(),
    when: z.string().optional(),
    title: z.string().optional(),
    order_data: z.string().optional(),
    paymentType: z
        .object({
            label: z.string(),
            value: z.string(),
        })
        .optional(),
    deliveryType: z
        .object({
            label: z.string(),
            value: z.string(),
        })
        .optional(),
    client: z
        .object({
            label: z.string(),
            value: z.string(),
        })
        .optional(),
    clientId: z.string().optional(),
    comment: z.string().optional(),
    restId: z.string().optional(),
    courier_id: z.string().optional(),
    deliveryPrice: z.number().optional(),
    foodCost: z.number().optional(),
    shipCost: z.number().optional(),
    serviceFee: z.number().optional(),
    isOurCouriers: z.string().optional(),
    deliveryTime: z.string().optional(),
    courierfinance_id: z.string().optional(),
})

export type FormOrderData = z.infer<typeof FormOrderSchema>

export { FormOrderSchema }

/*
const currentRestAtom = atom<Rest | null>(null);

const getRestFiltersAtom = atom<GetRestRequest | object>({});

const updateRestFiltersAtom = atom(null, (get, set, update: GetRestRequest) => {
  const filters = get(getRestFiltersAtom);
  const newFilters = { ...filters, ...update };
  set(getRestFiltersAtom, newFilters);
});

const getRestAtom = atomWithRefresh<Promise<DefaultRequestState<Rest[]>>>(async get=> {
  const currentGroupId = get(currentRestGroupAtom)?.restgroup_id;
  const getRestFilters = get(getRestFiltersAtom);

  try{
    const data = await LokaliApi.getRest({ restgroup_id: currentGroupId || undefined, ...getRestFilters });

    return {
      isLoading: false,
      data: data,
      error: null,
    };

  }catch(e) {
    return {
      isLoading: false,
      data: [],
      error: e as Error,
    };
  }
});

const getRestOrdersAtom = atomWithRefresh(async get=> {
  const restId = get(currentRestAtom)?.rest_id;

  if(!restId) {
    return {
      isLoading: false,
      data: [],
      error: null,
    };
  }

  try{
    if(restId) {
      const data = await LokaliApi.getRestOrders({ rest_id: restId });

      return {
        isLoading: false,
        data: data,
        error: null,
      };

    }

    return {
      data: [],
      isLoading: false,
      error: null
    };

  }catch(e) {
    return {
      isLoading: false,
      data: [],
      error: e as Error,
    };
  }
});

const openChangeRestGroupAtom = atom(null, (_, set) => {
  set(subModalAtom, {
    isOpen: true,
    children: <ChangeRestGroupModal />,
    title: 'Переместить в группу',
    icon: <Icon type="moveWhite" width={20} height={20}/>,
    height: 700,
  });
});


export {
  getRestAtom,
  currentRestAtom,
  getRestOrdersAtom,
  getRestFiltersAtom,
  updateRestFiltersAtom,
  openChangeRestGroupAtom
};
*/

import { atomWithRefresh } from 'jotai/utils'
import { atom } from 'jotai'
import { LatLng } from 'leaflet'

import { DefaultRequestState } from '../../types'
import { LokaliApi, Rest, RestMapFilters } from '../../services'

const restMapAtom = atom<Rest[]>([])
const currentRestMapAtom = atom<Rest | null>(null)
const getRestMapFiltersAtom = atom<RestMapFilters | object>({})

const restMapZoomCoordinatesAtom = atom<{ lat: number; lng: number } | null>(null)

const updateRestMapFiltersAtom = atom(null, (get, set, update: RestMapFilters) => {
    const filters = get(getRestMapFiltersAtom)
    const newFilters = { ...filters, ...update }
    set(getRestMapFiltersAtom, newFilters)
})

const getRestMapAtom = atomWithRefresh<Promise<DefaultRequestState<Rest[]>>>(async get => {
    const getRestMapFilters = get(getRestMapFiltersAtom)

    try {
        const data = await LokaliApi.getRest(getRestMapFilters)

        return {
            isLoading: false,
            data: data,
            error: null,
        }
    } catch (e) {
        return {
            isLoading: false,
            data: [],
            error: e as Error,
        }
    }
})

export { getRestMapFiltersAtom, restMapZoomCoordinatesAtom, updateRestMapFiltersAtom, getRestMapAtom, restMapAtom, currentRestMapAtom }

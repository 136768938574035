import { Suspense, useEffect } from 'react'
import { useAtomValue } from 'jotai/index'
import { useSetAtom } from 'jotai'

import { RestaurantsMapHeader } from '../../features/Restaraunts/RestaurantsMapHeader'
import { RestaurantsMapFilters } from '../../features/Restaraunts/RestaurantsMapFilters'
import { RestaurantsMap } from '../../features/Restaraunts/RestaurantsMap'
import { getRestMapAtom, restMapAtom } from '../../atoms/restaraunts/restMapAtom'

export const RestMapPage = (): JSX.Element => (
    <div>
        <RestaurantsMapHeader />
        <Suspense>
            <RestaurantsMapFilters />
        </Suspense>
        <Suspense>
            <Comp />
        </Suspense>
        <RestaurantsMap />
    </div>
)

const Comp = (): JSX.Element => {
    // to avoid map re-rendering inside Suspense tag
    const getRestMap = useAtomValue(getRestMapAtom)
    const setRestMapA = useSetAtom(restMapAtom)

    useEffect(() => {
        if (getRestMap?.data) {
            setRestMapA(getRestMap?.data)
        }
    }, [getRestMap])

    return <></>
}

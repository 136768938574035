import { useAtomValue, useSetAtom } from 'jotai'
import { refreshRestsAtom } from 'src/atoms/restaraunts/refreshRestsAtom'
import { setCreateRestModal } from 'src/atoms/restaraunts/restModalsAtom'
import { AppSelect, Button, EButtonVariant, ESelectVariant, MediumText, NamedCheckbox, SelectOption, TextInput } from 'src/components'
import { RoundedWrapper } from 'src/components/RoundedWrapper'
import { useEffect, useState } from 'react'
import { PropsValue } from 'react-select'
import { useAtom } from 'jotai'
import moment from 'moment/moment'

import { currentRestAtom, getRestAtom, openChangeRestGroupAtom, restsAtom, updateRestFiltersAtom } from '../../atoms/restaraunts/restAtom'
import { AppCitySelect } from '../../components/AppCitySelect'
import { openDeleteRestDialogAtom } from '../../atoms/restaraunts/deleteRestAtom'
import { createCurrentDeliveryZoneAtom, createRestDeliveryZonesAtom } from '../../atoms/restaraunts/createRestAtom'
import { currentDeliveryZoneAtom } from '../../atoms/restaraunts/deliveryZoneAtom'
import { Excel } from '../../services/Excel'
import { Rest } from '../../services'

const restScheduleOptions = [
    { value: 'working', label: 'Работающие' },
    { value: 'closed', label: 'Закрытые' },
]

const THEADS = [
    'Таб. номер',
    'Название',
    'Телефон',
    'Управляющий (ФИО)',
    'Адрес',
    'Время начала работы',
    'Время конца работы',
    'Статус работы ( Открыт/Закрыт )',
    'Телеграм',
    'Сайт',
    'Типы оплат',
    'Статус блокировки',
]

const getTodaySchedule = (list: Rest['schedule']): Rest['schedule'][0] | undefined => {
    const today = moment().day()
    const schedule = list.find(item => +item.day === today)
    return schedule
}

export const RestFilters = (): JSX.Element => {
    const currentRest = useAtomValue(currentRestAtom)
    const addRest = useSetAtom(setCreateRestModal)
    const setCreateRestDeliveryZones = useSetAtom(createRestDeliveryZonesAtom)
    const setCurrentDZone = useSetAtom(currentDeliveryZoneAtom)

    const refreshRests = useSetAtom(refreshRestsAtom)
    const updateRestFilters = useSetAtom(updateRestFiltersAtom)
    const onClickDeleteRest = useSetAtom(openDeleteRestDialogAtom)
    const openChangeRestGroup = useSetAtom(openChangeRestGroupAtom)
    const [searchVal, setSearchVal] = useState('')
    const [selectedCity, setSelectedCity] = useState<SelectOption>()
    const [selectedRestSchedule, setSelectedRestSchedule] = useState<SelectOption>()
    const data = useAtomValue(restsAtom)

    const onRefreshClick = () => {
        if (searchVal) {
            setSearchVal('')
        } else {
            refreshRests()
        }
    }

    const handleSelectCity = (val: PropsValue<SelectOption>) => {
        const value = val as SelectOption
        setSelectedCity(value)
        updateRestFilters({ city: value?.value ? value.value.split(',')[0] : '' })
    }

    const handleSelectRestSchedule = (val: PropsValue<SelectOption>) => {
        const value = val as SelectOption
        setSelectedRestSchedule(value)
        updateRestFilters({ restaurantSchedule: value?.value })
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            updateRestFilters({ q: searchVal })
        }, 300)

        return () => clearTimeout(timer)
    }, [searchVal])

    const onDownloadClick = () => {
        if (!data) return
        const KEYS = [
            'tab_number',
            'restName',
            'phoneNumber',
            'manager',
            'address',
            'scheduleStart',
            'scheduleEnd',
            'isOpen',
            'telegram',
            'website',
            'paymentMethods',
            'is_blocked',
        ]

        const FN = (key: string, node: any) => {
            switch (key) {
                case 'isOpen':
                    return node[key] ? 'Открыт' : 'Закрыт'
                case 'is_blocked':
                    return node[key] ? 'Заблокирован' : 'Не заблокирован'
                case 'address':
                    const addr = node[key]
                    return `${addr.city}, ${addr.street}, ${addr.house}`
                case 'paymentMethods':
                    return Array.isArray(node[key]) ? node[key].join(', ') : node[key]
                case 'scheduleStart':
                    return getTodaySchedule(node.schedule)?.from
                case 'scheduleEnd':
                    return getTodaySchedule(node.schedule)?.from
                default:
                    return ''
            }
        }

        Excel.generateFile({
            heads: THEADS,
            keys: KEYS,
            data: data,
            fn: FN,
            fileName: 'restaraunts',
        })
    }
    return (
        <div className='w-full flex flex-col pb-4' style={{ minWidth: '1224px' }}>
            <div className='flex pt-6 justify-between'>
                <div className='flex gap-2'>
                    <Button
                        onClick={() => {
                            addRest()
                            setCreateRestDeliveryZones([])
                            setCurrentDZone(undefined)
                        }}
                        icon='plus'
                        variant={EButtonVariant.PRIMARY}
                    >
                        <MediumText>Добавить ресторан</MediumText>
                    </Button>
                    <TextInput placeholder='Поиск' rightIcon='search' value={searchVal} onChange={ev => setSearchVal(ev.target.value)} />
                    <RoundedWrapper icon='refresh' onClick={onRefreshClick} />
                    <RoundedWrapper icon='trash' disabled={!currentRest} onClick={onClickDeleteRest} />
                    <RoundedWrapper icon='move' disabled={!currentRest} onClick={openChangeRestGroup} />
                </div>
                <div className='flex gap-2'>
                    <AppCitySelect
                        value={selectedCity}
                        onChange={handleSelectCity}
                        selectVariant={ESelectVariant.TERTIARY}
                        placeholder='Все города'
                        isClearable
                    />
                    <AppSelect
                        value={selectedRestSchedule}
                        onChange={handleSelectRestSchedule}
                        selectVariant={ESelectVariant.TERTIARY}
                        placeholder='Все рестораны'
                        options={restScheduleOptions}
                        isClearable
                    />
                    <Button variant={EButtonVariant.GREEN} icon='excel' onClick={onDownloadClick}>
                        <MediumText>Download</MediumText>
                    </Button>
                </div>
            </div>
        </div>
    )
}

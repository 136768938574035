import React from 'react'
import { Icon } from 'src/assets/icons'
import { MediumText, RegularText, SemiBoldText } from 'src/components'
import { ShadowContainer } from 'src/components/ShadowContainer'
import { useAtomValue } from 'jotai'

import { mainStatisticsAtom } from '../../atoms/mainStatistics/getMainStatisticsAtom'

export const MainAppActivity = () => {
    const mainStatistics = useAtomValue(mainStatisticsAtom)

    const totalDownloads = (mainStatistics?.downloadCountAndroid ?? 0) + (mainStatistics?.downloadCountIos ?? 0)

    const getPercentageColor = (percent: number | undefined) => {
        if (percent === undefined) return 'text-gray-800'
        return percent > 100 ? 'text-green-800' : 'text-red-700'
    }

    const getPercentageChange = (percent: number | undefined) => {
        if (percent === undefined) return '0%'
        return `${percent - 100}%`
    }

    return (
        <ShadowContainer className='flex gap-40'>
            <div className='flex flex-col gap-5 w-60 justify-between'>
                <SemiBoldText className='text-xl'>Количество загрузок с маркетов:</SemiBoldText>
                <div>
                    <div className='flex gap-2 items-center'>
                        <Icon type='android' />
                        <MediumText className='text-3xl text-blue-700'>{mainStatistics?.downloadCountAndroid ?? 0}</MediumText>
                    </div>
                    <div className='flex gap-2 items-center'>
                        <Icon type='apple' />
                        <MediumText className='text-3xl text-blue-700'>{mainStatistics?.downloadCountIos ?? 0}</MediumText>
                    </div>
                </div>

                <RegularText>{totalDownloads} всего</RegularText>
            </div>
            <div className='flex flex-col gap-5 w-60 justify-between'>
                <SemiBoldText className='text-xl'>Количество входов в приложение</SemiBoldText>
                <MediumText className='text-blue-900 text-4xl'>{mainStatistics?.appEnterCount ?? 0}</MediumText>
                <div className='flex items-center gap-2'>
                    <RegularText className={getPercentageColor(mainStatistics?.orderPercent)}>
                        {getPercentageChange(mainStatistics?.orderPercent)}
                    </RegularText>
                    <RegularText>(за день)</RegularText>
                </div>
            </div>
            <div className='flex flex-col gap-5 w-60 justify-between'>
                <SemiBoldText className='text-xl'>Количество активных пользователей:</SemiBoldText>
                <MediumText className='text-blue-900 text-4xl'>{mainStatistics?.activeUsersCount ?? 0}</MediumText>
                <div className='flex items-center gap-2'>
                    <RegularText className='text-green-800'>+10%</RegularText>
                    <RegularText>(за день)</RegularText>
                </div>
            </div>
            <div className='flex flex-col gap-5 w-60 justify-between'>
                <SemiBoldText className='text-xl'>Среднее число загрузок приложения</SemiBoldText>
                <MediumText className='text-blue-900 text-4xl'>{mainStatistics?.averageDownloadCount ?? 0}</MediumText>
                <div className='flex items-center gap-2'>
                    <RegularText className='text-green-800'>+11%</RegularText>
                    <RegularText>(за день)</RegularText>
                </div>
            </div>
        </ShadowContainer>
    )
}

import { createPortal } from 'react-dom'
import classNames from 'classnames'
import { Icon } from 'src/assets/icons'
import { useAtomValue, useSetAtom } from 'jotai'
import { dialogStateAtom, closeDialogAtom } from 'src/atoms/global/dialogAtom'

import styles from './styles.module.scss'

export const Dialog = (): JSX.Element => {
    const { isOpen, children, showCloseIcon = true } = useAtomValue(dialogStateAtom)
    const setCloseModal = useSetAtom(closeDialogAtom)

    return (
        <>
            {createPortal(
                <div
                    className={classNames({
                        [styles.dialog]: true,
                        [styles.show]: isOpen,
                    })}
                >
                    <div className={classNames(styles.dialogContent, 'rounded-lg shadow-lg flex flex-col h-full z-max')}>
                        {showCloseIcon ? <Icon type='close' size={50} className={styles.closeIcon} onClick={setCloseModal} /> : null}
                        {children}
                    </div>
                </div>,
                document.getElementById('dialog') as HTMLElement,
            )}
        </>
    )
}

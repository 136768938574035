import { useSetAtom } from 'jotai'
import { closeDialogAtom } from 'src/atoms/global/dialogAtom'
import { Button, EButtonVariant, RegularText } from 'src/components'

export const DialogDownloadExcel = (): JSX.Element => {
    const closeDialog = useSetAtom(closeDialogAtom)
    return (
        <div className='flex flex-col h-full'>
            <div className='flex flex-1 justify-center items-center'>
                <RegularText>Скачать Excel файл?</RegularText>
            </div>
            <div className='flex gap-2 justify-center items-center py-8'>
                <Button onClick={closeDialog} className='!max-h-10   w-44'>
                    <RegularText>Отмена</RegularText>
                </Button>
                <Button className='!max-h-10 w-44' variant={EButtonVariant.PRIMARY}>
                    <RegularText>Скачать</RegularText>
                </Button>
            </div>
        </div>
    )
}

import { SuspenseWithSpinner } from 'src/components'
import { AdminsHeader, AdminsTable } from 'src/features/Admins'
import { useAtomValue, useSetAtom } from 'jotai/index'
import { useEffect } from 'react'

import { adminsListAtom, getAdminsAtom } from '../../atoms/admins/adminsTableAtom'

export const AdminsPage = (): JSX.Element => (
    <div>
        <AdminsHeader />
        <SuspenseWithSpinner>
            <Comp />
            <AdminsTable />
        </SuspenseWithSpinner>
    </div>
)

const Comp = (): JSX.Element => {
    // to avoid map re-rendering inside Suspense tag
    const getAdmins = useAtomValue(getAdminsAtom)
    const setAdmins = useSetAtom(adminsListAtom)

    useEffect(() => {
        if (getAdmins?.data) {
            setAdmins(getAdmins?.data)
        }
    }, [getAdmins])

    return <></>
}

import { z } from 'zod'

const ScheduleSchema = z.array(
    z.object({
        to: z.string(),
        days: z.array(z.number()),
        from: z.string(),
    }),
)

const CourierAvailabilitySchema = z.object({
    avialschedule_id: z.string(),
    createdAt: z.string(),
    updatedAt: z.string(),
    title: z.string(),
    user_id: z.string(),
    schedule: z.array(
        z.object({
            day: z.number(),
            time: z.array(z.string()),
        }),
    ),
    scheduleStart: z.string(),
    dayCount: z.number(),
})

const financesCouriersSchema = z.object({
    createdAt: z.string(),
    date: z.string(),
    title: z.string().nullish(),
    order_id: z.string(),
    totalPayment: z.number(),
    updatedAt: z.string(),
    was_paid: z.boolean(),
    courierfinance_id: z.string(),
    rest_id: z.string().nullish(),
    courierTabNumber: z.string(),
    courierName: z.string(),
    orderCount: z.number(),
    courier_id: z.string(),
    totalPaymentSnapshot: z.number(),
    paid: z.number(),
})

const CouriersAccountingSchema = z.object({
    finances: financesCouriersSchema.array(),
    total: z.object({
        orderCountCollected: z.number(),
        totalPaymentSnapshotCollected: z.number(),
        paidCollected: z.number(),
        totalPaymentCollected: z.number(),
    }),
})

const CouriersAccountingOrdersSchema = z.object({
    ordersArray: z
        .object({
            restName: z.string(),
            orderNumber: z.number(),
            orderPayment: z.number(),
            orderTime: z.string(),
        })
        .array(),
    cashPayment: z.number(),
    onlinePayment: z.number(),
    fullPayment: z.number(),
})
const CourierPaymentSchema = z.object({
    sum: z.number(),
    paymentType: z.number(),
    courier_id: z.number(),
    createdAt: z.string(),
})

export { CourierAvailabilitySchema, ScheduleSchema, financesCouriersSchema, CouriersAccountingOrdersSchema, CourierPaymentSchema }

type CourierAvailability = z.infer<typeof CourierAvailabilitySchema>
type Schedule = z.infer<typeof ScheduleSchema>

type FinancesCourierAccounting = z.infer<typeof financesCouriersSchema>
type CouriersAccounting = z.infer<typeof CouriersAccountingSchema>
type CouriersAccountingOrders = z.infer<typeof CouriersAccountingOrdersSchema>
type CourierPayments = z.infer<typeof CourierPaymentSchema>

export type { CouriersAccounting, CourierAvailability, Schedule, FinancesCourierAccounting, CouriersAccountingOrders, CourierPayments }

import { Checkbox, InputWrapper, NamedCheckbox } from 'src/components'
import { useSetAtom, useAtom } from 'jotai'
import React, { useCallback, useEffect, useState } from 'react'

import { DateFilterAuto } from '../../components/DateFilterAuto'
import { datePeriodOptions, EDateRangeOptions } from '../../utils'
import { updateCouriersFiltersAtom, courierDateRangeAtom } from '../../atoms/allCouriers/allCouriersQueryParamsAtom'
import { fetchAllCouriersAtom, showFiredCouriersAtom } from '../../atoms/allCouriers/allCouriersAtom'
import { DateFilterSelect } from '../../components/DateFilterSelect'

export const CouriersTableFilter = (): JSX.Element => {
    const updateFilters = useSetAtom(updateCouriersFiltersAtom)
    const [dateRange, setDateRange] = useAtom(courierDateRangeAtom)
    const [showFiredCouriers, setShowFiredCouriers] = useAtom(showFiredCouriersAtom)
    const refreshCouriers = useSetAtom(fetchAllCouriersAtom)

    const setDate = (fieldName: 'dateFrom' | 'dateTo') => (val: string) => {
        setDateRange(prev => ({ ...prev, [fieldName]: val }))
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            updateFilters(dateRange)
        }, 300)

        return () => clearTimeout(timer)
    }, [dateRange, updateFilters])

    const handleShowFiredCouriersChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setShowFiredCouriers(event.target.checked)
            refreshCouriers()
        },
        [setShowFiredCouriers, refreshCouriers],
    )

    return (
        <DateFilterSelect
            dateFrom={dateRange.dateFrom || ''}
            dateTo={dateRange.dateTo || ''}
            setDateFrom={setDate('dateFrom')}
            setDateTo={setDate('dateTo')}
            selectProps={{ options: datePeriodOptions }}
            rightElement={
                <InputWrapper className='self-end' name='Показывать уволенных'>
                    <Checkbox checked={Boolean(showFiredCouriers)} onChange={handleShowFiredCouriersChange} />
                </InputWrapper>
            }
        />
    )
}

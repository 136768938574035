function* keyGenerator() {
    let i = 0
    while (true) {
        yield i++
    }
}

const keygen = keyGenerator()

export { keygen }

import classNames from 'classnames'
import { useAtom, useSetAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import {} from 'src/atoms/allCouriers/courierAvailabilityAtom'
import {
    editCourierAvailabilityAtom,
    editOnClickTimeAtom,
    editScheduleConfigAtom,
    editScheduleDaysAtom,
    editScheduleStartAtom,
    editScheduleTitleAtom,
    SCHEDULE,
} from 'src/atoms/allCouriers/editCourierAvailAtom'
import { setCloseSubModalAtom } from 'src/atoms/global/modalAtom'
import {
    Button,
    DateInput,
    Divider,
    MediumText,
    RegularText,
    SemiBoldText,
    TextInput,
    EButtonVariant,
    ETextInputVariant,
} from 'src/components'
import { CourierAvailability } from 'src/services'
import dayjs from 'dayjs'
import { isDay } from './CourierCreateSchedule'

type CourierEditScheduleProps = {
    availability: CourierAvailability
}

export const CourierEditSchedule = (props: CourierEditScheduleProps): JSX.Element => {
    const [loading, setLoading] = useState(false)
    const editCourierAvailability = useSetAtom(editCourierAvailabilityAtom)
    const [scheduleConfig, setScheduleConfig] = useAtom(editScheduleConfigAtom)
    const onClickTime = useSetAtom(editOnClickTimeAtom)
    const [title, onChangeTitle] = useAtom(editScheduleTitleAtom)
    const closeSubModal = useSetAtom(setCloseSubModalAtom)
    const [dateBlurred, setDateBlurred] = useState(false)
    const [scheduleStart, setScheduleStart] = useAtom(editScheduleStartAtom)
    const [scheduleDays, setScheduleDays] = useAtom(editScheduleDaysAtom)

    const editClick = async () => {
        setLoading(true)
        await editCourierAvailability(props.availability)
        setLoading(false)
    }

    useEffect(() => {
        const mapPropsToInitialState = props.availability.schedule.map(el => {
            const schedule = SCHEDULE.map(e => (el.time.includes(e.time) ? { ...e, selected: true } : e))
            return schedule
        })

        onChangeTitle(props.availability.title)
        setScheduleConfig(mapPropsToInitialState)
        setScheduleDays(props.availability.dayCount)
    }, [props])

    return (
        <div className='flex flex-col h-full'>
            <div className='flex gap-4 p-7'>
                <div>
                    <SemiBoldText className='text-xs'>Название</SemiBoldText>
                    <TextInput
                        placeholder='Название'
                        variant={ETextInputVariant.SECONDARY}
                        value={title}
                        onChange={e => onChangeTitle(e.currentTarget.value)}
                    />
                </div>

                <div>
                    <SemiBoldText className='text-xs'>Начало действия</SemiBoldText>
                    <DateInput value={scheduleStart} onChange={e => setScheduleStart(e.target.value)} />
                    {dateBlurred && scheduleStart && !dayjs(scheduleStart).isValid() && (
                        <RegularText className='text-red-700'>Введите корректную дату</RegularText>
                    )}
                </div>

                <div>
                    <MediumText>Кол-во дней</MediumText>
                    <TextInput
                        type='number'
                        className='w-inputS'
                        value={scheduleDays === null ? '' : scheduleDays}
                        onChange={e => {
                            const { value } = e.target
                            setScheduleDays(value === '' ? null : Number(value))
                        }}
                    />
                </div>
            </div>
            <div className='m-1.5 flex max-h-96 overflow-scroll'>
                {scheduleConfig.map((schedule, parentIndex) => (
                    <div key={parentIndex} className='flex flex-col w-full px-px'>
                        <div className='bg-aqua-400 flex justify-center items-center p-2'>{isDay(parentIndex + 1)}</div>
                        {schedule.map((item, index) => (
                            <div
                                onClick={() => onClickTime({ parentIndex, index })}
                                className={classNames(
                                    'flex justify-center items-center cursor-pointer',
                                    'p-2',
                                    index % 2 !== 0 && 'bg-aqua-100',
                                    item.selected && '!bg-aqua-600',
                                )}
                                key={item.time}>
                                {item.time}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div className='p-6'>
                <div className='mt-2'>
                    <Divider />
                </div>
                <div className='flex h-full justify-end items-end pt-4'>
                    <Button onClick={editClick} loader={loading} icon='save' variant={EButtonVariant.BLUE} className='mr-2'>
                        <MediumText>Сохранить</MediumText>
                    </Button>
                    <Button onClick={closeSubModal}>
                        <RegularText>Отмена</RegularText>
                    </Button>
                </div>
            </div>
        </div>
    )
}

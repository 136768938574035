import { z } from 'zod'

const CourierGroupSchema = z.object({
    item: z.object({
        group_id: z.string(),
        title: z.string(),
        createdAt: z.string(),
        updatedAt: z.string(),
        range: z.number().nullish(),
        cargo_size: z.number().nullish(),
        mass: z.number().nullish(),
        comment: z.string().nullish(),
        independent: z.object({ price: z.number(), distance_to: z.string(), distance_from: z.string() }).array(),
        regular: z.object({ price: z.number(), distance_to: z.string(), distance_from: z.string() }).array(),
    }),
    orderCount: z.number(),
    orderPrice: z.number(),
})

type CourierGroup = z.infer<typeof CourierGroupSchema>

export { CourierGroupSchema }

export type { CourierGroup }

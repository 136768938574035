import { SuspenseWithSpinner, Tab } from 'src/components'

import { CourierCreateInfo } from './CourierCreateInfo'
import { CourierCreatePassport } from './CourierCreatePassport'

const CREATE_COURIER_TABS: Tab[] = [
    {
        tabOption: {
            tabName: 'Инфо',
        },
        child: (
            <SuspenseWithSpinner>
                <CourierCreateInfo />
            </SuspenseWithSpinner>
        ),
    },
    {
        tabOption: {
            tabName: 'Паспорт',
        },
        child: <CourierCreatePassport />,
    },
]

export { CREATE_COURIER_TABS }

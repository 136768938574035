enum EChangeBonusType {
    REDUCE = 'reduce',
    ADD = 'add',
}

type ChangeBonusRequest = {
    type: EChangeBonusType
    count: number
    client_id: string
}

export type { ChangeBonusRequest }

export { EChangeBonusType }

import { PropsWithChildren } from 'react'
import { SpanProps } from 'src/types/html'
import classNames from 'classnames'

import styles from './styles.module.scss'

export const MediumText = (props: PropsWithChildren<SpanProps>): JSX.Element => {
    const { className = 'text-base text-regularTextColor', children, ...spanProps } = props
    return (
        <span {...spanProps} className={classNames(styles.text, className)}>
            {children}
        </span>
    )
}

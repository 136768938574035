import { Checkbox, ColoredSquare, HeaderLayout, RegularText } from 'src/components'
import { useAtomValue, useSetAtom } from 'jotai/index'

import { getRestMapFiltersAtom, restMapAtom, updateRestMapFiltersAtom } from '../../atoms/restaraunts/restMapAtom'
import { RestMapFilters } from '../../services'

export const RestaurantsMapHeader = (): JSX.Element => {
    const restMapFilters: RestMapFilters = useAtomValue(getRestMapFiltersAtom)
    const restMap = useAtomValue(restMapAtom)
    const updateRestMapFilters = useSetAtom(updateRestMapFiltersAtom)

    const handleCheckbox = () => {
        updateRestMapFilters({ showOnlyNoCourier: !restMapFilters?.showOnlyNoCourier || undefined })
    }

    return (
        <HeaderLayout title='Рестораны' className='flex justify-between items-center'>
            <div className='flex justify-start gap-3 items-center pr-6 h-3/4'>
                <div className='flex items-center gap-2'>
                    <Checkbox checked={restMapFilters?.showOnlyNoCourier} onChange={handleCheckbox} />
                    <RegularText className='w-52'>Показывать только “курьер не назначен”</RegularText>
                </div>
                <div>
                    <ColoredSquare
                        text={restMap?.length ? restMap.filter(item => !item.isOpen).length.toString() : '0'}
                        className='bg-red-700'
                    />
                    <ColoredSquare
                        text={restMap?.length ? restMap.filter(item => item.isOpen).length.toString() : '0'}
                        className='bg-yellow-400'
                    />
                </div>
                <div className='h-full bg-grey-400 w-0.5' />
                <div className='flex flex-col'>
                    <RegularText>Закрыто</RegularText>
                    <RegularText>Открыто</RegularText>
                </div>
            </div>
        </HeaderLayout>
    )
}

import { PropsWithChildren } from 'react'
import { DivProps } from 'src/types/html'
import classNames from 'classnames'

import styles from './styles.module.scss'

type RowProps = DivProps & {
    includeHover?: boolean
}

export const Row = (props: PropsWithChildren<RowProps>): JSX.Element => {
    const { children, className, includeHover, ...rest } = props

    return (
        <div {...rest} className={classNames(styles.row, { [styles.hoverEffect]: includeHover }, className)}>
            {children}
        </div>
    )
}

import { useAtomValue, useSetAtom } from 'jotai'
import classNames from 'classnames'
import { AppImage } from 'src/components/AppImage'
import { Assets } from 'src/utils/assets'
import { Icon } from 'src/assets/icons'
import { isNavbarFullAtom, setNavbarCollapseAtom } from 'src/atoms/leftNavBar/leftNavBarAtom'
import { useEffect, useState } from 'react'
import { Timeout } from 'react-number-format/types/types'

import styles from './styles.module.scss'
const LeftNavBarHeader = (): JSX.Element => {
    const isLeftBarOpen = useAtomValue(isNavbarFullAtom)
    const toggleNavbar = useSetAtom(setNavbarCollapseAtom)

    const [showText, setShowText] = useState(isLeftBarOpen)

    useEffect(() => {
        let timer: Timeout
        if (isLeftBarOpen) {
            timer = setTimeout(() => {
                setShowText(isLeftBarOpen)
            }, 150)
        } else {
            setShowText(isLeftBarOpen)
        }

        return () => clearTimeout(timer)
    }, [isLeftBarOpen])
    return (
        <div
            className={classNames(
                'flex items-center relative min-h-20 border-b-white border-solid border',
                { 'p-2': !isLeftBarOpen },
                styles.leftNavHeader,
            )}
        >
            <Icon type={'logo'} size={40} />
            <p className={classNames({ hidden: !showText }, styles.logoText)}>Admin Panel</p>
            <div className={styles.collapseButton} onClick={toggleNavbar}>
                <Icon
                    width={5}
                    height={15}
                    className={classNames('transition-transform', {
                        [styles.iconOpen]: !isLeftBarOpen,
                        [styles.iconClose]: isLeftBarOpen,
                    })}
                    type='arrowLeft'
                />
            </div>
        </div>
    )
}

export { LeftNavBarHeader }

import { DivProps } from 'src/types/html'
import classNames from 'classnames'
import { PropsWithChildren } from 'react'
import { useSetAtom } from 'jotai/index'
import { useAtomValue } from 'jotai'

import { SemiBoldText } from '..'

import { selectOpenedAtom } from '../../atoms/global/selectAtom'

import styles from './styles.module.scss'

type HeaderLayout = DivProps & {
    title: string
    wrapperClassName?: string
}

export const HeaderLayout = (props: PropsWithChildren<HeaderLayout>): JSX.Element => {
    const { title, className, wrapperClassName, ...rest } = props
    const isSelectOpened = useAtomValue(selectOpenedAtom)

    return (
        <div {...rest} className={classNames(styles.container, isSelectOpened && styles.fixedContainer, wrapperClassName)}>
            <div className={classNames('flex items-center h-full', styles.header, className)}>
                <SemiBoldText className='ml-8 text-2xl min-w-80'>{title}</SemiBoldText>
                {props.children}
            </div>
        </div>
    )
}

import { BoldText, Button, EButtonVariant, RegularText } from 'src/components'

type AccountingCourierDialogProps = {
    onAccept: VoidFunction
    onAbort: VoidFunction
    closeAll: VoidFunction
    amount: number
    paymentSuccess: boolean
    loading: boolean
}

export const AccountingCouriersDialog = (props: AccountingCourierDialogProps): JSX.Element => (
    <div className='flex flex-col h-full gap-4 justify-center items-center'>
        <div className='text-center'>
            {props.paymentSuccess ? (
                <>
                    <RegularText>Оплачено&nbsp;</RegularText>
                    <BoldText>{props.amount}</BoldText>!
                </>
            ) : (
                <>
                    <RegularText>Провести инкассацию</RegularText>
                    <br />
                    <BoldText>{props.amount}</BoldText>?
                </>
            )}
        </div>

        <div className='flex gap-2 mt-4'>
            {props.paymentSuccess ? (
                <Button variant={EButtonVariant.PRIMARY} onClick={props.closeAll} className='!w-44 !py-5 flex justify-center'>
                    <RegularText>OК</RegularText>
                </Button>
            ) : (
                <>
                    <Button onClick={props.onAbort} className='!w-44 !py-5 flex justify-center'>
                        <RegularText>Отмена</RegularText>
                    </Button>
                    <Button
                        loader={props.loading}
                        onClick={props.onAccept}
                        variant={EButtonVariant.PRIMARY}
                        className='!w-44 flex justify-center !py-5'>
                        <RegularText>Оплатить</RegularText>
                    </Button>
                </>
            )}
        </div>
    </div>
)

import { atom } from 'jotai'
import { EditClientDBRequest, LokaliApi } from 'src/services/LocaliRepository'
import { HttpStatusCode } from 'src/types'
import { ModalEditClientDB } from 'src/features/ClientDatabaseModals/ModalEditClientDB'

import { modalAtom, setCloseAllModals } from '../global/modalAtom'
import { setNotSavedDialogAtom } from '../global/dialogAtom'

import { currentClientAtom, dbClientsAtom } from './clientsAtom'

const refreshEditClientAtom = atom(null, async (get, set) => {
    await set(dbClientsAtom)

    const clients = await get(dbClientsAtom)

    const currentClient = get(currentClientAtom)

    if (currentClient?.clientData) {
        const refreshed = clients.data?.find(el => el.clientData.client_id === currentClient.clientData.client_id)

        if (refreshed) {
            set(currentClientAtom, refreshed)
        }
    }
})

const editClientDBRequestStatusAtom = atom<HttpStatusCode | null>(null)

const openEditClientAtom = atom(null, (_, set) => {
    set(modalAtom, {
        isOpen: true,
        title: 'Клиенты',
        onClose: () => set(setNotSavedDialogAtom),
        children: <ModalEditClientDB />,
    })
})

const editClientDBAtom = atom(null, async (_, set, req: EditClientDBRequest) => {
    set(editClientDBRequestStatusAtom, HttpStatusCode.LOADING)

    try {
        const response = await LokaliApi.editClientDB(Object.assign(req))

        if (response === HttpStatusCode.CREATED) {
            set(editClientDBRequestStatusAtom, HttpStatusCode.OK)
        }

        set(setCloseAllModals)
        set(editClientDBRequestStatusAtom, null)
        set(dbClientsAtom)
    } catch (e) {
        set(editClientDBRequestStatusAtom, HttpStatusCode.INTERNAL_SERVER_ERROR)
    }
})

export { editClientDBRequestStatusAtom, editClientDBAtom, openEditClientAtom, refreshEditClientAtom }

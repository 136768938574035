import { atom } from 'jotai'
import { LocationCoordinates, nominatimApi, SearchAddressRequest, SearchResponse } from 'src/services/NominatimRepository'
import { HttpStatusCode } from 'src/types'

const firstLocationAtom = atom<LocationCoordinates | null>(null)
const secondLocationAtom = atom<LocationCoordinates | null>(null)

const firstPointValuesAtom = atom<SearchResponse[]>([])
const secondPointValuesAtom = atom<SearchResponse[]>([])

const orderPolylineAtom = atom<[number, number][]>([])

const firstPointLoadingAtom = atom(false)
const secondPointLoadingAtom = atom(false)

const searchFirstPointAtom = atom(null, async (_, set, params: SearchAddressRequest) => {
    if (!params.search.trim()) {
        set(firstPointValuesAtom, [])
        return
    }

    try {
        set(firstPointLoadingAtom, true)
        const response = await nominatimApi.search(params)
        if (response.status === HttpStatusCode.OK) {
            set(firstPointValuesAtom, response.data)
        }
        return response
    } catch (e) {
        console.error(e)
    } finally {
        set(firstPointLoadingAtom, false)
    }
})

const searchSecondPointAtom = atom(null, async (_, set, params: SearchAddressRequest) => {
    if (!params.search.trim()) {
        set(secondPointValuesAtom, [])
        return
    }

    try {
        set(secondPointLoadingAtom, true)
        const response = await nominatimApi.search(params)
        if (response.status === HttpStatusCode.OK) {
            set(secondPointValuesAtom, response.data)
        }
        return response
    } catch (e) {
        console.error(e)
    } finally {
        set(secondPointLoadingAtom, false)
    }
})

export {
    searchFirstPointAtom,
    searchSecondPointAtom,
    firstPointValuesAtom,
    secondPointValuesAtom,
    firstLocationAtom,
    secondLocationAtom,
    orderPolylineAtom,
    firstPointLoadingAtom,
    secondPointLoadingAtom,
}

import { atom } from 'jotai'
import { BlockRestRequest, LokaliApi } from 'src/services'
import { HttpStatusCode } from 'src/types'

import { currentRestAtom, getRestAtom } from './restAtom'

const blockRestAtom = atom(null, async (get, set, arg: BlockRestRequest['action']) => {
    const rest = get(currentRestAtom)

    try {
        if (rest?.rest_id) {
            const req: BlockRestRequest = {
                action: arg,
                rest_id: rest?.rest_id,
            }

            const response = await LokaliApi.blockRest(req)
            set(getRestAtom)
            const updatedRest = await get(getRestAtom)

            if (response === HttpStatusCode.OK && updatedRest.data) {
                set(currentRestAtom, () => updatedRest.data?.find(item => item.rest_id === rest.rest_id) || null)
            }
        }
    } catch (e) {}
})

export { blockRestAtom }

import { AppMap, Button, EButtonVariant, ErrorText, GridTable, MediumItalic, RegularText, RoundedWrapper } from 'src/components'
import { Circle, Polygon, Polyline, useMapEvents } from 'react-leaflet'
import classNames from 'classnames'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { currentDeliveryZoneAtom, deleteDeliveryZoneAtom, selectDeliveryZoneAtom } from 'src/atoms/restaraunts/deliveryZoneAtom'

import { addRestDeliveryZoneAtom, createRestDeliveryZonesAtom, createRestMapMarkersAtom } from '../../atoms/restaraunts/createRestAtom'

import { ModalCreateDeliveryZoneFooter } from './ModalCreateDeliveryZoneFooter'
import styles from './styles.module.scss'

const THEADS = [
    'Название',
    // 'Описание'
]

const CreateRestMapElements = (): JSX.Element => {
    const [markers, setMarkers] = useAtom(createRestMapMarkersAtom)
    const [currentDeliveryZone, setCurrentDZone] = useAtom(currentDeliveryZoneAtom)

    useMapEvents({
        click: e => {
            setMarkers(old => [...old, e.latlng])
        },
    })

    useEffect(() => {
        /*if(currentRest && currentRest.order_region && Array.isArray(currentRest.order_region)) {
      editRestMapMarkers(currentRest.order_region.map((el, idx) => ({
        title: `Зона доставки ${idx + 1}`,
        description: 'Описание',
        latLng: el as unknown as LatLng[]
      })));
    }*/
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (currentDeliveryZone) {
        return (
            <>
                <Polygon color='#f42d2e' positions={currentDeliveryZone.latLng} />
                <div className='absolute top-5 left-5 z-max'>
                    <Button
                        variant={EButtonVariant.PRIMARY}
                        onClick={() => {
                            setMarkers([])
                            setCurrentDZone(undefined)
                        }}>
                        <RegularText>Очистить</RegularText>
                    </Button>
                </div>
            </>
        )
    }

    return (
        <>
            {markers.length === 2 && <Polyline color='#f42d2e' positions={markers} />}
            {markers.map((marker, idx) => (
                <Circle center={marker} key={idx} radius={5} className={styles.customCursor} color='#f42d2e' />
            ))}
            {markers.length > 2 && <Polygon color='#f42d2e' positions={markers} />}
            <div className='absolute top-5 left-5 z-max'>
                <Button variant={EButtonVariant.PRIMARY} onClick={() => setMarkers([])}>
                    <RegularText>Очистить</RegularText>
                </Button>
            </div>
        </>
    )
}

export const ModalCreateRestDeliveryZone = (): JSX.Element => {
    const deliveryZones = useAtomValue(createRestDeliveryZonesAtom)
    const markers = useAtomValue(createRestMapMarkersAtom)
    const selectDeliveryZone = useSetAtom(selectDeliveryZoneAtom)
    const currentDeliveryZone = useAtomValue(currentDeliveryZoneAtom)
    const deleteDeliveryZone = useSetAtom(deleteDeliveryZoneAtom)
    const createDeliveryZone = useSetAtom(addRestDeliveryZoneAtom)
    const [message, setMessage] = useState(false)

    return (
        <div className='flex flex-col h-full'>
            <div className='flex gap-2 h-full'>
                <div className='flex border border-solid border-mainBackgroundColor px-10 py-4'>
                    <div className='flex flex-col gap-2'>
                        <MediumItalic className='flex justify-center pt-12'>~Зоны доставки~</MediumItalic>
                        <div className='h-64 overflow-scroll'>
                            <GridTable
                                /*theads={THEADS.map(thead =>
                  <span className="bg-aqua-400 p-4" key={thead}>{thead}</span>)
                }*/
                                theads={[]}>
                                {deliveryZones.map(el => (
                                    <tr key={el.title}>
                                        <span
                                            onClick={() => selectDeliveryZone(el)}
                                            className={classNames(
                                                'p-4 cursor-pointer',
                                                currentDeliveryZone?.title === el.title && 'bg-darkBlue-700',
                                            )}>
                                            {el.title}
                                        </span>
                                        {/*<span
                      onClick={()=> selectDeliveryZone(el)}
                      className={classNames(
                        'p-4 cursor-pointer',
                        currentDeliveryZone?.title === el.title && 'bg-darkBlue-700'
                      )}
                    >
                      {el.description}
                    </span>*/}
                                    </tr>
                                ))}
                            </GridTable>
                        </div>
                        <div className='flex gap-2'>
                            <Button
                                onClick={() => {
                                    if (markers.length > 3) {
                                        setMessage(false)
                                        createDeliveryZone(deliveryZones.length + 1)
                                    } else {
                                        setMessage(true)
                                    }
                                }}
                                variant={EButtonVariant.PRIMARY}
                                disabled={markers.length < 3}
                                icon='plus'>
                                <RegularText>Создать зону доставки</RegularText>
                            </Button>
                            <RoundedWrapper icon='trash' onClick={() => deleteDeliveryZone()} disabled={!currentDeliveryZone} />
                        </div>
                        {message && <ErrorText error='Зона доставки должна включать больше 4 точек на карте' />}
                    </div>
                </div>
                <div className={classNames('border border-solid border-mainBackgroundColor flex-1 p-1')}>
                    <AppMap mapClassname={styles.customCursor}>
                        <CreateRestMapElements />
                    </AppMap>
                </div>
            </div>
            <ModalCreateDeliveryZoneFooter />
        </div>
    )
}

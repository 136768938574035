import { atom } from 'jotai'
import { atomWithRefresh } from 'jotai/utils'
import { ClientGroup, LokaliApi } from 'src/services/LocaliRepository'
import { DefaultRequestState } from 'src/types'

import { currentClientAtom } from './clientsAtom'

const currentClientGroupAtom = atom<ClientGroup | null>(null)

const setCurrentClientGroupAtom = atom(null, (_, set, update: ClientGroup | null) => {
    set(currentClientGroupAtom, update)
    set(currentClientAtom, null)
})

const allClientGroupsAtom = atomWithRefresh<Promise<DefaultRequestState<ClientGroup[]>>>(async () => {
    try {
        const data = await LokaliApi.getClientGroups()
        return {
            data: data,
            error: null,
        }
    } catch (e) {
        return {
            data: [],
            error: e as Error,
        }
    }
})

const clientGroupsSelectAtom = atom(async get =>
    (await get(allClientGroupsAtom)).data?.map(el => ({ value: el.clientgroup_id, label: el.title })),
)

export { allClientGroupsAtom, currentClientGroupAtom, setCurrentClientGroupAtom, clientGroupsSelectAtom }

import classNames from 'classnames'
import { PropsWithChildren, ReactNode, useEffect } from 'react'
import { SpanProps } from 'src/types'

import { useWindowSize } from '../../hooks/useWindowResize'

import { MediumText } from '../MediumText'
import { RegularText } from '../RegularText'

import styles from './styles.module.scss'

type GridTableProps = {
    className?: string
    style?: object
    theads: ReactNode[]
}

type GridTableHead = {
    title: string
    className?: string
}

type GridTableItem = {
    child: ReactNode | string
    className?: string
    isActive?: boolean
} & SpanProps

const GridTableHead = (props: GridTableHead): JSX.Element => (
    <th className={classNames('p-2 bg-aqua-400 text-nowrap whitespace-nowrap text-center sticky top-0', props.className)}>{props.title}</th>
)

const GridTableItem = (props: GridTableItem): JSX.Element => {
    const { onClick, className, isActive, child, ...rest } = props

    return (
        <td
            onClick={onClick}
            className={classNames(
                'p-2 cursor-pointer text-nowrap whitespace-nowrap text-center',
                isActive && 'bg-darkBlue-700',
                styles.cell,
                className,
            )}
            {...rest}>
            {child}
        </td>
    )
}

const GridTable = (props: PropsWithChildren<GridTableProps>): JSX.Element => {
    const [width] = useWindowSize()

    const { className, children, theads, style = {} } = props

    return (
        <div
            className={classNames(styles.container, className)}
            style={{
                maxHeight: `calc(${100 / Math.min(width / 2018, 1)}vh - 150px)`,
            }}>
            <table style={{ width: '100%' }}>
                <thead>
                    <tr>{theads}</tr>
                </thead>
                <tbody>{children}</tbody>
            </table>
        </div>
    )
}

export { GridTable, GridTableHead, GridTableItem }

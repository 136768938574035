import React from 'react'
import { useAtom } from 'jotai'
import {
    adminRightAtom,
    clientRightAtom,
    courierRightAtom,
    generalRightAtom,
    orderRightAtom,
    restRightAtom,
} from 'src/atoms/admins/adminRightsAtom'
import { MediumText, RegularText, Switch } from 'src/components'

type CheckboxValue = {
    label: string
    checked: boolean
    id: string
}

type SwitchGroupProps = {
    parent: string
    children: CheckboxValue[]
    onClick: (index: number) => void
    onClickParent: () => void
    parentChecked: boolean
}

const SwitchGroup = ({ parent, children, onClick, onClickParent, parentChecked }: SwitchGroupProps): JSX.Element => (
    <div className='flex flex-col gap-3'>
        <div className='flex gap-2'>
            <Switch
                id={parent}
                checked={parentChecked}
                onClick={e => {
                    e.stopPropagation()
                    onClickParent()
                }}
            />
            <MediumText>{parent}</MediumText>
        </div>
        {children.map((child, idx) => (
            <div key={child.label} className='flex gap-2 pl-5'>
                <Switch onClick={() => onClick(idx)} checked={child.checked} id={child.id} />
                <RegularText className='text-nowrap whitespace-nowrap'>{child.label}</RegularText>
            </div>
        ))}
    </div>
)

const useRights = () => {
    const rightsAtoms = [
        { childAtom: generalRightAtom, label: 'Общая статистика' },
        { childAtom: clientRightAtom, label: 'Клиенты' },
        { childAtom: courierRightAtom, label: 'Курьеры' },
        { childAtom: restRightAtom, label: 'Рестораны' },
        { childAtom: orderRightAtom, label: 'Заказы' },
        { childAtom: adminRightAtom, label: 'Администрирование' },
    ]

    return rightsAtoms.map(({ childAtom, label }) => {
        const [children, setChildren] = useAtom(childAtom)

        const toggleChild = (idx: number) => {
            setChildren(draft => {
                const updatedDraft = [...draft]
                updatedDraft[idx] = { ...updatedDraft[idx], checked: !updatedDraft[idx].checked }
                return updatedDraft
            })
        }

        const toggleParent = () => {
            setChildren(old => {
                const allChecked = old.every(child => child.checked)
                return old.map(child => ({ ...child, checked: !allChecked }))
            })
        }

        const parentChecked = children.every(child => child.checked)

        return { parent: label, children, onClickParent: toggleParent, onClick: toggleChild, parentChecked }
    })
}

export const ModalEditAdminAccess = (): JSX.Element => {
    const rights = useRights()

    return (
        <div className='grid grid-cols-3 p-4 gap-2'>
            {rights.map(({ parent, children, onClickParent, onClick, parentChecked }) => (
                <div key={parent} className='flex flex-col gap-10'>
                    <SwitchGroup
                        parent={parent}
                        children={children}
                        onClickParent={onClickParent}
                        onClick={onClick}
                        parentChecked={parentChecked}
                    />
                </div>
            ))}
        </div>
    )
}

import ReactInputMask, { Props } from 'react-input-mask'
import { InputProps } from 'src/types/html'
import { Icon } from 'src/assets/icons'
import classNames from 'classnames'
import { FieldError } from 'react-hook-form'

import { ErrorText } from '../ErrorText'

import styles from './styles.module.scss'

export type DateInputProps = InputProps &
    Partial<Props> & {
        error?: FieldError
    }

export const DateInput = (props: DateInputProps): JSX.Element => {
    const { mask = '99.99.9999', maskPlaceholder = 'DD.MM.YYYY', placeholder = 'DD.MM.YYYY', className, error, ...rest } = props

    return (
        <div className={styles.inputWrapper}>
            <div>
                <ReactInputMask
                    mask={mask}
                    maskPlaceholder={maskPlaceholder}
                    placeholder={placeholder}
                    className={classNames(styles.input, className, 'max-h-8')}
                    {...rest}
                />
                <Icon className={styles.icon} type='calendar' size={14} />
            </div>
            {<ErrorText error={error?.message} />}
        </div>
    )
}

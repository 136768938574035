import { PropsWithChildren, ReactElement } from 'react'
import classNames from 'classnames'

import styles from './styles.module.scss'

type AccordionProps = {
    isActive: boolean
    parent: ReactElement
    keyVal: string
}

export const Accordion = (props: PropsWithChildren<AccordionProps>): JSX.Element => (
    <div key={props.keyVal}>
        <div>{props.parent}</div>

        <div
            className={classNames(
                {
                    [styles.collapsed]: !props.isActive,
                    [styles.expanded]: true,
                },
                styles.accordion,
            )}
        >
            {props.children}
        </div>
    </div>
)

import { z, ZodType } from 'zod'

import { CourierCreateFormData } from './types'

export const CourierSchema: ZodType<CourierCreateFormData> = z.object({
    deliveryType: z.object({
        label: z.string(),
        value: z.string(),
    }),
    sysName: z.string().optional(),
    tabNumber: z.string().optional(),
    name: z.string().optional(),
    firstName: z.string().optional(),
    patronymic: z.string().optional(),
    surname: z.string().optional(),
    gender: z
        .object({
            label: z.string(),
            value: z.string(),
        })
        .optional(),
    birthday: z.string().optional(),
    phoneNumber: z.string(),
    whatsApp: z.string().optional(),
    telegram: z.string().optional(),
    email: z.string().optional(),
    comment: z.string().optional(),
    password: z.string(),
    login: z.string(),
    passSeries: z.string().optional(),
    passIssuedBy: z.string().optional(),
    passIssueDate: z.string().optional(),
    passEndDate: z.string().optional(),
    passSnils: z.string().optional(),
    passInn: z.string().optional(),
    passCity: z.string().optional(),
    passStreet: z.string().optional(),
    passHouse: z.string().optional(),
    passHousing: z.string().optional(),
    passNumber: z.string().optional(),
    passApartment: z.string().optional(),
    cityKey: z.string().optional(),
    rest_id: z.string().optional(),
    isSalary: z.string().optional(),
    weekends: z.string().optional(),
})

import classNames from 'classnames'
import { Button, EButtonVariant } from 'src/components/Button'
import { GlassContainer } from 'src/components/GlassContainer'
import { ETextInputVariant, TextInput } from 'src/components/TextInput'
import { AppImage } from 'src/components/AppImage'
import { MediumText } from 'src/components/MediumText'
import { Assets } from 'src/utils/assets'
import { useAtomValue, useSetAtom } from 'jotai'
import { authAtom, authStateAtom } from 'src/atoms/auth/authAtom'
import { LightText } from 'src/components/LightText'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

import styles from './styles.module.scss'

type LoginDataState = {
    phoneNumber: string
    password: string
}

export const Login = (): JSX.Element => {
    const login = useSetAtom(authAtom)
    const { hasError } = useAtomValue(authStateAtom)
    const navigate = useNavigate()
    const [loginData, setLoginData] = useState<LoginDataState>({
        phoneNumber: '',
        password: '',
    })

    const onChangeLoginData = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
        setLoginData(old => ({ ...old, [name]: e.target.value }))
    }

    return (
        <GlassContainer className={classNames(styles.container)}>
            <div className='flex flex-col h-full items-center mt-28 w-full z-10'>
                <AppImage src={Assets.logo} className={classNames(styles.logo, 'mb-5')} />
                <MediumText className='mb-8'>Добро пожаловать в Lokali</MediumText>

                <TextInput
                    onChange={e => onChangeLoginData(e, 'phoneNumber')}
                    defaultValue={loginData.phoneNumber}
                    variant={ETextInputVariant.TERTIARY}
                    leftIcon='user'
                    placeholder='Логин'
                    className='mb-3 min-w-96'
                />

                <TextInput
                    onChange={e => onChangeLoginData(e, 'password')}
                    defaultValue={loginData.password}
                    variant={ETextInputVariant.TERTIARY}
                    leftIcon='lock'
                    placeholder='Пароль'
                    className='min-w-96 mb-3'
                />

                <Button
                    variant={EButtonVariant.TERTIARY}
                    className='mb-3'
                    onClick={() =>
                        login({
                            phoneNumber: loginData.phoneNumber,
                            password: loginData.password,
                            navigate: navigate,
                        })
                    }
                >
                    <MediumText>Войти</MediumText>
                </Button>
                {hasError && <LightText>Неверные данные</LightText>}
            </div>
        </GlassContainer>
    )
}

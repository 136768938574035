import classNames from 'classnames'
import { PropsWithChildren } from 'react'

import styles from './styles.module.scss'

type GlassContainerProps = {
    className?: string
}

export const GlassContainer = (props: PropsWithChildren<GlassContainerProps>): JSX.Element => (
    <div className={classNames(props.className, styles.container)}>{props.children}</div>
)

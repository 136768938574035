import { Controller, useForm } from 'react-hook-form'
import { Button, EButtonVariant, MediumItalic, NamedTextInput, RegularText, TextArea } from 'src/components'
import { useAtomValue, useSetAtom } from 'jotai'
import { setNotSavedDialogAtom } from 'src/atoms/global/dialogAtom'
import { zodResolver } from '@hookform/resolvers/zod'
import { createClientDBGroupAtom, createClientDBGroupStatusAtom } from 'src/atoms/clientDb/addClientDBGroupAtom'
import { HttpStatusCode } from 'src/types'

import { AddClientGroupFormData } from './types'
import { AddGroupSchema } from './schema'

export const ModalClientDBAddGroup = (): JSX.Element => {
    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        watch,
    } = useForm<AddClientGroupFormData>({
        resolver: zodResolver(AddGroupSchema),
        reValidateMode: 'onBlur',
    })

    const onClose = useSetAtom(setNotSavedDialogAtom)
    const createClientGroup = useSetAtom(createClientDBGroupAtom)
    const requestState = useAtomValue(createClientDBGroupStatusAtom)

    const onSubmit = (data: AddClientGroupFormData) => {
        createClientGroup(data)
    }

    const onRefresh = () => {
        reset(
            { title: '', comment: '' },
            {
                keepValues: false,
            },
        )
    }

    return (
        <form action='submit' className='h-full' onSubmit={handleSubmit(onSubmit)}>
            <div className='flex flex-col h-full'>
                <div
                    className='
        flex flex-col h-full
        border-solid border
        border-mainBackgroundColor
        m-4
        rounded-lg'>
                    <div className='mt-2 flex justify-center'>
                        <MediumItalic>~Основная информация~</MediumItalic>
                    </div>
                    <div className='flex justify-center w-full mt-9 gap-8 px-12'>
                        <div>
                            <div className='flex flex-col gap-2 justify-start items-end'>
                                <Controller
                                    control={control}
                                    name='title'
                                    render={props => (
                                        <NamedTextInput
                                            name='Название'
                                            textInputProps={{
                                                ...props.field,
                                                error: errors.title,
                                            }}
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name='comment'
                                    render={props => <TextArea placeholder='Комментарий' {...props.field} error={errors.comment} />}
                                />
                            </div>
                        </div>
                        {/* <div className="flex flex-col  items-end gap-2">
            <NamedSwitch checked={false} name="Рестатус"/>
            <NamedTextInput
              nameClassName="!text-darkBlue-900"
              placeholder="Кол-во дней для рестатуса"
              name="Дни рестатуса"/>
            <RegularText className="text-right">
              *Укажите количество дней, при котором не активный пользователь будет переходить в “общую группу”
            </RegularText>
            <RegularText className="text-right">
              *Активный пользователь — это пользователь, который блаблабла Селим придумает
            </RegularText>
          </div> */}
                    </div>
                    {/* <div className="flex w-ful justify-betweenl p-4 h-full">
            <Button
              variant={EButtonVariant.QUARTERY}
              className="self-end">
              <RegularText
                className="underline !text-darkBlue-900">
                Удалить группу
              </RegularText>
            </Button>
            <div className="flex justify-center flex-1 self-end">
              <AppImage src={Assets.people}/>
            </div>
            <Button
              variant={EButtonVariant.QUARTERY}
              className="self-end">
              <RegularText
                className="underline !text-green-800">
                Начислить бонусы
              </RegularText>
            </Button>
          </div> */}
                </div>

                <div className='flex justify-end gap-4 p-4'>
                    <Button icon='refresh' onClick={onRefresh}>
                        <RegularText>Обновить</RegularText>
                    </Button>
                    <Button
                        disabled={!watch('title') || !watch('comment')}
                        loader={requestState === HttpStatusCode.LOADING}
                        type='submit'
                        variant={EButtonVariant.PRIMARY}
                        icon='save'>
                        <RegularText>Сохранить</RegularText>
                    </Button>
                    <Button onClick={onClose}>
                        <RegularText>Выйти</RegularText>
                    </Button>
                </div>
            </div>
        </form>
    )
}

import { nominatim } from 'src/axios'
import { AxiosResponse } from 'axios'

import { SearchAddressRequest, SearchResponse } from './types'

class NominatimRepository {
    private static instance: NominatimRepository

    static getInstance(): NominatimRepository {
        if (!NominatimRepository.instance) {
            NominatimRepository.instance = new NominatimRepository()
        }

        return NominatimRepository.instance
    }

    async search({ search, city }: SearchAddressRequest): Promise<AxiosResponse<SearchResponse[]>> {
        try {
            const response = await nominatim.get('search', {
                params: {
                    street: search,
                    countrycodes: 'ru',
                    format: 'jsonv2',
                    'accept-language': 'ru',
                    city: city,
                },
            })

            return response
        } catch (error) {
            throw Error(`Error fetching search data: ${error}`)
        }
    }
}

const nominatimApi = new NominatimRepository()

export { nominatimApi }

import { atom } from 'jotai'
import { RestGroup } from 'src/services'

import { currentRestAtom } from './restAtom'

const currentRestGroupAtom = atom<RestGroup | null>(null)

const restGroupAtom = atom(
    get => get(currentRestGroupAtom),
    (_, set, update: RestGroup | null) => {
        set(currentRestGroupAtom, update)
        set(currentRestAtom, null)
    },
)

export { currentRestGroupAtom, restGroupAtom }

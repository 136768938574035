import { zodResolver } from '@hookform/resolvers/zod'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import L from 'leaflet'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Polyline, useMap } from 'react-leaflet'
import { firstLocationAtom, orderPolylineAtom, secondLocationAtom } from 'src/atoms/allCouriers/formOrderMap'
import {
    AppMap,
    AppSelect,
    Button,
    ESelectVariant,
    InputWrapper,
    MediumItalic,
    MediumText,
    RegularText,
    SelectOption,
    SuspenseWithSpinner,
    TextArea,
    TextInput,
} from 'src/components'
import { EButtonVariant } from 'src/components/Button'
import { BuildingMarker } from 'src/components/Map/BuildingMarker'
import { CourierMarker } from 'src/components/Map/CourierMarker'
import { CreateOrderRequest, GetPriceAndDistanceRequest, LokaliApi, osrmApi } from 'src/services'
import { formOrderAtom } from 'src/atoms/allCouriers/formOrderAtom'

import { setNotSavedDialogAtom } from '../../atoms/global/dialogAtom'
import { NamedCitySelect } from '../../components/NamedCitySelect'
import { createOrderLoadingAtom } from '../../atoms/allOrders/createOrderAtom'

import { FormOrderData, FormOrderSchema } from './FormOrderSchema'

const POLYLINE_COLOR = '#904bea'

const CreateOrderMap = (): JSX.Element => {
    const firstLocation = useAtomValue(firstLocationAtom)
    const secondLocation = useAtomValue(secondLocationAtom)

    const [polyline, setPolyline] = useAtom(orderPolylineAtom)

    const map = useMap()

    useEffect(() => {
        if (firstLocation) {
            map.flyTo([firstLocation?.lat, firstLocation?.lon])
        }
    }, [firstLocation, map])

    useEffect(() => {
        if (firstLocation && secondLocation) {
            osrmApi
                .getPolylineFromApi([
                    [firstLocation.lat, firstLocation.lon],
                    [secondLocation.lat, secondLocation.lon],
                ])
                .then(({ position }) => {
                    setPolyline(position)

                    const polyline = new L.Polyline(position)

                    map.fitBounds(polyline.getBounds())
                })
        }
    }, [firstLocation, map, secondLocation, setPolyline])

    return (
        <div>
            {firstLocation && <BuildingMarker position={[firstLocation.lat, firstLocation.lon]} />}
            {secondLocation && <CourierMarker position={[secondLocation.lat, secondLocation.lon]} />}
            {firstLocation && secondLocation && polyline && polyline.length && <Polyline positions={polyline} color={POLYLINE_COLOR} />}
        </div>
    )
}

const PaymentTypeOptions = [
    { label: 'Наличные', value: 'cash' },
    { label: 'Оплата картой', value: 'online' },
]

export type CourierCreateFormOrderProps = {
    onSave?: (val: CreateOrderRequest) => void
}

export const CourierCreateFormOrder = (props: CourierCreateFormOrderProps): JSX.Element => {
    const [loading, setLoading] = useState(false)
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        watch,
    } = useForm<FormOrderData>({
        resolver: zodResolver(FormOrderSchema),
    })

    const notSavedExit = useSetAtom(setNotSavedDialogAtom)

    const [firstLocation, setFirstLocation] = useAtom(firstLocationAtom)
    const [secondLocation, setSecondLocation] = useAtom(secondLocationAtom)

    const [createOrderLoading, setCreateOrderLoading] = useAtom(createOrderLoadingAtom)
    const formOrder = useSetAtom(formOrderAtom)

    const [courierGroups, setCourierGroups] = useState<SelectOption[]>([])
    const [clientOptions, setClientOptions] = useState<SelectOption[]>([])

    const onSubmit = async (data: FormOrderData) => {
        data.clientId = data.client?.value
        setLoading(true)
        if (typeof props?.onSave === 'function') {
            // creating in Orders page
            const newData: CreateOrderRequest = {
                ...(data as unknown as CreateOrderRequest),
                paymentType: data.paymentType?.value || '',
                deliveryType: data.deliveryType?.value || '',
                from_street: data.from_street || '',
                to_street: data.to_street || '',
            }

            await props.onSave(newData)
        } else {
            // creating in Couriers page
            await formOrder(data)
        }
        setLoading(false)
    }

    const getPriceAndDistance = async (body: GetPriceAndDistanceRequest) => {
        try {
            const data = await LokaliApi.getPriceAndDistance(body)

            if (data) {
                setValue('price', data.price?.toString())
                setValue('distance', data.distance?.toString())
            }
        } catch (e) {
            console.warn(e)
        }
    }

    const getCourierGroups = async () => {
        try {
            const data = await LokaliApi.getCourierGroups()

            if (data?.length) {
                setCourierGroups(
                    data.map(item => ({
                        value: item.item.group_id,
                        label: item.item.title,
                    })),
                )
            }
        } catch (e) {
            console.warn(e)
        }
    }

    const getClients = async () => {
        try {
            const data = await LokaliApi.getClientsDB({})

            if (data?.length) {
                setClientOptions(
                    data.map(item => ({
                        value: item.clientData.client_id,
                        label: item.clientData.firstName + ' ' + item.clientData.middleName + ' ' + item.clientData.lastName,
                    })),
                )
            }
        } catch (e) {
            console.warn(e)
        }
    }

    useEffect(() => {
        if (firstLocation?.lat && firstLocation?.lon && secondLocation?.lat && secondLocation?.lon) {
            getPriceAndDistance({
                fromLat: firstLocation.lat,
                fromLong: firstLocation.lon,
                toLat: secondLocation.lat,
                toLong: secondLocation.lon,
                deliveryType: watch('deliveryType')?.value || '',
            }).then()
        } else {
            setValue('price', '')
            setValue('distance', '')
        }
    }, [firstLocation, secondLocation, watch('deliveryType')?.value])

    useEffect(() => {
        setCreateOrderLoading(false)
        setFirstLocation(null)
        setSecondLocation(null)
        getCourierGroups().then()
        getClients().then()

        return () => {
            setFirstLocation(null)
            setSecondLocation(null)
        }
    }, [])

    return (
        <form
            onSubmit={handleSubmit(onSubmit, er => {
                console.log(er)
            })}>
            <div className='flex flex-col justify-start'>
                <div className='flex gap-2'>
                    <div className='flex border border-solid border-mainBackgroundColor px-10 py-4'>
                        <div className='flex flex-col gap-2'>
                            <MediumItalic className='flex justify-center'>~Откуда~</MediumItalic>
                            <div className='flex flex-col gap-2'>
                                <InputWrapper name='Адрес*'>
                                    <div className='relative pr-9'>
                                        <Controller
                                            name='from_street'
                                            control={control}
                                            render={props => (
                                                <NamedCitySelect
                                                    name=''
                                                    address
                                                    selectProps={{
                                                        ...props.field,
                                                        placeholder: 'город, улица, дом',
                                                        selectVariant: ESelectVariant.SECONDARY,
                                                        value: getValues('from_street')
                                                            ? { value: getValues('from_street')!, label: getValues('from_street')! }
                                                            : undefined,
                                                        onChange: val => {
                                                            setValue('from_street', val?.label || '')
                                                            if (!val?.value) return
                                                            const [lon, lat] = val.value.split(',')
                                                            setFirstLocation({ lat: +lat, lon: +lon })
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </InputWrapper>
                            </div>
                            <MediumItalic className='flex justify-center'>~Куда~</MediumItalic>
                            <div className='flex flex-col gap-2 justify-between'>
                                <InputWrapper name='Адрес*'>
                                    <div className='relative pr-9'>
                                        <Controller
                                            name='to_street'
                                            control={control}
                                            render={props => (
                                                <NamedCitySelect
                                                    name=''
                                                    address
                                                    selectProps={{
                                                        ...props.field,
                                                        selectVariant: ESelectVariant.SECONDARY,
                                                        placeholder: 'город, улица, дом',
                                                        value: getValues('to_street')
                                                            ? { value: getValues('to_street')!, label: getValues('to_street')! }
                                                            : undefined,
                                                        onChange: val => {
                                                            setValue('to_street', val?.label || '')
                                                            if (!val?.value) return
                                                            const [lon, lat] = val.value.split(',')
                                                            setSecondLocation({ lat: +lat, lon: +lon })
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </InputWrapper>
                            </div>
                            <div className='flex gap-2'>
                                <div>
                                    <MediumText className='text-xs'>Расстояние*</MediumText>
                                    <Controller
                                        control={control}
                                        name='distance'
                                        render={props => (
                                            <TextInput
                                                className='w-inputS'
                                                placeholder='KM'
                                                {...props.field}
                                                error={errors.distance}
                                                contentEditable={false}
                                                disabled
                                            />
                                        )}
                                    />
                                </div>
                                <div>
                                    <MediumText className='text-xs'>Стоимость*</MediumText>
                                    <Controller
                                        control={control}
                                        name='price'
                                        render={props => (
                                            <TextInput
                                                className='w-inputS'
                                                placeholder='Р'
                                                {...props.field}
                                                error={errors.price}
                                                contentEditable={false}
                                                disabled
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className='flex flex-col gap-2 mt-6'>
                                <div className='flex flex-col items-end gap-2 pr-16 mt-6'>
                                    <InputWrapper name='Клиент*'>
                                        <Controller
                                            control={control}
                                            name='client'
                                            render={props => (
                                                <AppSelect
                                                    placeholder='Имя клиента'
                                                    options={clientOptions as SelectOption[]}
                                                    selectVariant={ESelectVariant.SECONDARY}
                                                    {...props.field}
                                                    error={errors.client?.message}
                                                    isSearchable
                                                />
                                            )}
                                        />
                                    </InputWrapper>

                                    <Controller
                                        control={control}
                                        name='order_data'
                                        render={props => (
                                            <InputWrapper name='Состав заказа*'>
                                                <TextInput className='w-inputL' placeholder='Состав заказа' {...props.field} />
                                            </InputWrapper>
                                        )}
                                    />

                                    <InputWrapper name='Тип оплаты*'>
                                        <Controller
                                            control={control}
                                            name='paymentType'
                                            render={props => (
                                                <AppSelect
                                                    placeholder='Тип оплаты'
                                                    options={PaymentTypeOptions}
                                                    selectVariant={ESelectVariant.SECONDARY}
                                                    {...props.field}
                                                    error={errors.paymentType?.message}
                                                />
                                            )}
                                        />
                                    </InputWrapper>

                                    <InputWrapper name='Тип курьера*'>
                                        <Controller
                                            control={control}
                                            name='deliveryType'
                                            render={props => (
                                                <AppSelect
                                                    placeholder='Тип курьера'
                                                    options={courierGroups}
                                                    selectVariant={ESelectVariant.SECONDARY}
                                                    {...props.field}
                                                    error={errors.deliveryType?.message}
                                                />
                                            )}
                                        />
                                    </InputWrapper>
                                </div>
                                <br />
                                <Controller
                                    control={control}
                                    name='comment'
                                    render={props => <TextArea placeholder='Комментарий' className='self-end' {...props.field} />}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='border border-solid border-mainBackgroundColor flex-1 p-1'>
                        <SuspenseWithSpinner>
                            <AppMap>
                                <CreateOrderMap />
                            </AppMap>
                        </SuspenseWithSpinner>
                    </div>
                </div>
                <div className='flex justify-end items-end gap-4 p-4'>
                    <Button loader={loading} type='submit' variant={EButtonVariant.PRIMARY} disabled={createOrderLoading}>
                        <MediumText>Сформировать</MediumText>
                    </Button>
                    <Button variant={EButtonVariant.SECONDARY} onClick={() => notSavedExit()}>
                        <RegularText>Назад</RegularText>
                    </Button>
                </div>
            </div>
        </form>
    )
}

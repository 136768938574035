import { atomWithRefresh } from 'jotai/utils'
import { LokaliApi, Order } from 'src/services'
import { HttpStatusCode } from 'src/types'
import { atom } from 'jotai'

import { currentCourierAtom } from '../allOrders/couriersListAtom'
import { setCloseSubModalAtom } from '../global/modalAtom'

const ordersToAssignAtom = atomWithRefresh<Promise<Order[]>>(async () => {
    try {
        const data = await LokaliApi.getAllOrders()
        return data
    } catch (e) {
        return []
    }
})

export const selectedOrderAtom = atom<Order | null>(null)

export const courierTakeOrderAtom = atom(null, async (get, set) => {
    const currentCourier = get(currentCourierAtom)
    const selectedOrder = get(selectedOrderAtom)

    if (currentCourier?.item.courier_id && selectedOrder?.order_id) {
        try {
            const req = {
                order_id: selectedOrder.order_id,
                courier_id: currentCourier.item.courier_id,
            }

            const response = await LokaliApi.assignOrder(req)

            if (response === HttpStatusCode.OK) {
                set(setCloseSubModalAtom)
                set(ordersToAssignAtom)
            }
        } catch (e) {
            console.error('Error assigning order:', e)
        }
    }
})

export { ordersToAssignAtom }

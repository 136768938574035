import { MainAppActivity } from 'src/features/Main/MainAppActivity'
import { MainBestCouriers } from 'src/features/Main/MainBestCouriers'
import { MainCashTurnOver } from 'src/features/Main/MainCashTurnOver'
import { MainLogisticRevenue } from 'src/features/Main/MainLogisticRevenue'
import { MainLokaliRevenue } from 'src/features/Main/MainLokaliRevenue'
import { MainOrdersAmount } from 'src/features/Main/MainOrdersAmount'
import { MainOrdersInfo } from 'src/features/Main/MainOrdersInfo'
import { MainPageHeader } from 'src/features/Main/MainPageHeader'
import { MainProfitInfo } from 'src/features/Main/MainProfitInfo'
import { MainRestRevenue } from 'src/features/Main/MainRestRevenue'
import classNames from 'classnames'
import React, { Suspense } from 'react'

import { useWindowSize } from '../../hooks/useWindowResize'

import styles from './styles.module.scss'

export const MainPage = (): JSX.Element => {
    const [width] = useWindowSize()

    return (
        <Suspense>
            <div className='overflow-hidden'>
                <MainPageHeader />
                <div className={classNames('p-8 flex flex-col gap-5', styles.mainContainer)}>
                    <div
                        className={styles.mainContainerInner}
                        style={{
                            maxHeight: `calc(${100 / Math.min(width / 2018, 1)}vh - 220px)`,
                        }}
                    >
                        <MainOrdersInfo />
                        <MainProfitInfo />
                        <MainCashTurnOver />
                        <MainRestRevenue />
                        <MainLogisticRevenue />
                        <MainLokaliRevenue />
                        <MainAppActivity />
                        <MainOrdersAmount />
                        <MainBestCouriers />
                        <div style={{ height: '30px' }} />
                    </div>
                </div>
            </div>
        </Suspense>
    )
}

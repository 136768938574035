import React from 'react'
import { MediumText, RegularText, SemiBoldItalicText, SemiBoldText } from 'src/components'
import { ShadowContainer } from 'src/components/ShadowContainer'
import { useAtomValue } from 'jotai'
import { Icon } from 'src/assets/icons'

import { mainStatisticsAtom } from '../../atoms/mainStatistics/getMainStatisticsAtom'

type BestCourierProps = {
    name: string
    tabNumber: number
    orderCount: number
    workHour: number
    orderTotalPrice: number
    earned: number
}

const BestCourier = ({ name, tabNumber, orderCount, workHour, orderTotalPrice, earned }: BestCourierProps): JSX.Element => (
    <div className='gap-40 flex'>
        <div className='flex flex-col gap-1 w-60 justify-between'>
            <SemiBoldItalicText>{name}</SemiBoldItalicText>
            <div className='flex justify-between'>
                <div className='flex gap-1'>
                    <Icon type='copy' />
                    <RegularText>Таб. номер:</RegularText>
                </div>
                <RegularText>{tabNumber}</RegularText>
            </div>
            <div className='flex justify-between'>
                <RegularText>Количество доставок:</RegularText>
                <RegularText>{orderCount}</RegularText>
            </div>
            <div className='flex justify-between'>
                <RegularText>Часов работы:</RegularText>
                <RegularText>{workHour}</RegularText>
            </div>
            <div className='flex justify-between'>
                <RegularText>Сумма заказов:</RegularText>
                <RegularText>{orderTotalPrice}</RegularText>
            </div>
            <RegularText>Заработано:</RegularText>
            <MediumText className='text-blue-900 text-4xl'>{earned}</MediumText>
        </div>
    </div>
)

export const MainBestCouriers = (): JSX.Element => {
    const mainStatistics = useAtomValue(mainStatisticsAtom)

    return (
        <ShadowContainer className='flex flex-col'>
            <div className='flex justify-between'>
                <SemiBoldText className='text-xl'>Лучшие курьеры</SemiBoldText>
            </div>
            <div className='flex gap-40'>
                {mainStatistics?.bestCouriers.map((courier, index) => (
                    <BestCourier
                        key={index}
                        name={courier.name}
                        tabNumber={courier.tabNumber}
                        orderCount={courier.orderCount}
                        workHour={courier.workHour}
                        orderTotalPrice={courier.orderTotalPrice}
                        earned={courier.earned}
                    />
                ))}
            </div>
        </ShadowContainer>
    )
}

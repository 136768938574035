import classNames from 'classnames'
import { AppMap, Button, Divider, EButtonVariant, MediumText, RegularText } from 'src/components'
import React, { useEffect, useMemo, useState } from 'react'
import { useAtom, useAtomValue } from 'jotai'
import { Marker, Polygon, Popup, useMap, useMapEvents } from 'react-leaflet'
import { Icon, LatLng } from 'leaflet'
import moment from 'moment/moment'
import { useNavigate } from 'react-router-dom'

import { currentRestMapAtom, getRestMapFiltersAtom, restMapAtom, restMapZoomCoordinatesAtom } from '../../atoms/restaraunts/restMapAtom'
import { LokaliApi, Rest, RestMapFilters } from '../../services'
import SocketServices from '../../services/SocketIO/socket'
import GroznyiCoordinates from '../../utils/groznyiCoordinates'
import { useWindowSize } from '../../hooks/useWindowResize'
import { mapCouriersCityCoordinatesAtom, mapCouriersSelectedAtom } from '../../atoms/allCouriers/mapCouriersAtom'

import styles from './styles.module.scss'

const getTodaySchedule = (list: Rest['schedule']): Rest['schedule'][0] | undefined => {
    let today = moment().day()
    today = today === 0 ? 7 : today
    const schedule = list.find(item => +item.day === today)
    return schedule
}

const MapInner = () => {
    const map = useMap()
    const restMapZoomCoordinates = useAtomValue(restMapZoomCoordinatesAtom)

    useEffect(() => {
        if (restMapZoomCoordinates?.lng && restMapZoomCoordinates?.lat) {
            map.flyTo([restMapZoomCoordinates.lng, restMapZoomCoordinates.lat])
        }
    }, [restMapZoomCoordinates])

    return <></>
}

const PopupComponent = ({ item, onMoreClick }: { item: Rest; onMoreClick: (item: Rest) => void }) => (
    <Popup>
        <div className={styles.markerPopup}>
            <ul>
                <li>
                    <MediumText>Статус:</MediumText> <RegularText>{item.isOpen ? 'Открыто' : 'Закрыто'}</RegularText>
                </li>
                {/*<li><MediumText>Курьер не назначен: </MediumText> <RegularText>-</RegularText></li>*/}
                <li>
                    <MediumText>Ресторан:</MediumText> <RegularText>{item.restName}</RegularText>
                </li>
                <li>
                    <MediumText>Телефон:</MediumText> <RegularText>{item.phoneNumber}</RegularText>
                </li>
                <li>
                    <MediumText>Администратор:</MediumText> <RegularText>{item.manager}</RegularText>
                </li>
                <li>
                    <MediumText>Группа:</MediumText> <RegularText>{item.restGroup?.title || '-'}</RegularText>
                </li>
                <li>
                    <MediumText>Адрес:</MediumText> <RegularText>{`${item.address.street} ${item.address.house}`}</RegularText>
                </li>
                <li>
                    <MediumText>Время работы:</MediumText>{' '}
                    <RegularText>{getTodaySchedule(item.schedule)?.from + ' - ' + getTodaySchedule(item.schedule)?.to}</RegularText>
                </li>
                <li>
                    <MediumText>Тип курьеров:</MediumText> <RegularText>{item.isOurCouriers ? 'Lokali' : 'Не Locali'}</RegularText>
                </li>
                <li>
                    <MediumText>Интеграция:</MediumText> <RegularText>iiko</RegularText>
                </li>
                <li className='p-4'>
                    <Divider />
                </li>
            </ul>
            <Button className='ml-auto' variant={EButtonVariant.SECONDARY} onClick={() => onMoreClick(item)}>
                <RegularText>Подробнее</RegularText>
            </Button>
        </div>
    </Popup>
)

export const RestaurantsMap = (): JSX.Element => {
    const navigate = useNavigate()
    const restMap = useAtomValue(restMapAtom)
    const [currentRestMap, setCurrentRestMap] = useAtom(currentRestMapAtom)
    const [width, height] = useWindowSize()

    const mapHeight = useMemo(() => (width ? height / Math.min(width / 2018, 1) - 142 : 0), [width, height])

    const onMarkerClick = (rest: Rest) => {
        setCurrentRestMap(rest)
    }

    const onMoreClick = (rest: Rest) => {
        window.open(`${window.location.origin}/restaraunts?rest-id=${rest?.rest_id}`, '_blank')
    }

    useEffect(() => {
        ;(async () => {
            try {
                const data = await LokaliApi.restMapRequest()
                if (data?.message === 'success') return
                navigate('../restaraunts')
            } catch (e) {
                navigate('../restaraunts')
            }
        })()
    }, [])

    return (
        <div
            className={classNames('w-full border-t-primaryColor border-t-2 border-solid relative overflow-hidden')}
            style={{ height: mapHeight + 'px' }}
        >
            {mapHeight > 0 && (
                <AppMap mapHeight={'100%'} center={GroznyiCoordinates}>
                    {restMap?.length
                        ? restMap.map(item => (
                              <Marker
                                  icon={
                                      new Icon({
                                          iconUrl: item.isOpen
                                              ? 'https://loca-li.com/images/map_marker_yellow.png'
                                              : 'https://loca-li.com/images/map_marker_red.png',
                                          iconSize: [32, 32],
                                      })
                                  }
                                  key={item.rest_id}
                                  position={{ lat: item.latitude, lng: item.longitude }}
                                  eventHandlers={{
                                      click: () => onMarkerClick(item),
                                  }}
                              >
                                  <PopupComponent item={item} onMoreClick={onMoreClick} />
                              </Marker>
                          ))
                        : null}
                    {currentRestMap?.order_region?.length
                        ? currentRestMap.order_region.map((region, index) => (
                              <Polygon key={index} color='#f42d2e' positions={region as unknown as LatLng[]} />
                          ))
                        : null}

                    <MapInner />
                </AppMap>
            )}
        </div>
    )
}

import classNames from 'classnames'
import { MediumText, RegularText, SemiBoldItalicText, Switch, TimePicker } from 'src/components'
import { RoundedWrapper } from 'src/components/RoundedWrapper'
import { useAtom } from 'jotai'
import { ChangeEvent, useEffect } from 'react'
import { editAllDaysAtom, editWorkTimeAtom, editWorkTimeIndexAtom, editWorkTimeModeAtom } from 'src/atoms/restaraunts/editRestAtom'

import { WORK_DAYS } from './utils'

export const ModalEditRestWorkTime = (): JSX.Element => {
    const [workTime, setWorkTime] = useAtom(editWorkTimeAtom)
    const [mode, setMode] = useAtom(editWorkTimeModeAtom)
    const [currentIndex, setCurrentIndex] = useAtom(editWorkTimeIndexAtom)
    const [allDays, setAllDays] = useAtom(editAllDaysAtom)

    const dayClassName = classNames(
        'w-11 h-11 border-aqua-300 border-solid border flex justify-center items-center',
        'rounded-md cursor-pointer',
    )

    const onChangeMode = (e: ChangeEvent<HTMLInputElement>) => {
        setMode(e.target.checked)
        if (e.target.checked) {
            setCurrentIndex(-1)
            setAllDays({
                workStart: '',
                workEnd: '',
                lunchStart: '',
                lunchEnd: '',
                day: 'ВСЕ',
                isActive: false,
            })
        } else {
            setCurrentIndex(0)
        }
    }

    useEffect(() => {
        setCurrentIndex(-1)
    }, [])

    return (
        <div className='grid grid-cols-2 gap-12 justify-items-center my-12'>
            <div className='flex flex-col gap-12'>
                <div className='flex flex-col gap-2'>
                    <SemiBoldItalicText className='self-center text-base text-blue-700'>~Дни работы~</SemiBoldItalicText>
                    <Switch id='mode' checked={mode} onChange={onChangeMode} className='!justify-start' />
                    <div className='flex flex-col gap-2'>
                        <div className='flex gap-2'>
                            <button
                                type='button'
                                className={classNames(dayClassName, mode && 'bg-darkBlue-600 border border-darkBlue-500')}>
                                <RegularText
                                    className={classNames({
                                        'text-aqua-300': !mode,
                                        'text-darkBlue-900': mode,
                                    })}>
                                    ВСЕ
                                </RegularText>
                            </button>
                            {workTime.map((el, idx) => (
                                <button
                                    type='button'
                                    key={el.day}
                                    onClick={() => setCurrentIndex(idx)}
                                    disabled={mode}
                                    className={classNames(dayClassName, {
                                        'bg-darkBlue-600 border border-darkBlue-500': !mode && currentIndex === idx,
                                    })}>
                                    <RegularText
                                        className={classNames({
                                            'text-aqua-300': mode,
                                            'text-darkBlue-500': !mode,
                                            'text-darkBlue-900': currentIndex === idx,
                                        })}>
                                        {el.day}
                                    </RegularText>
                                </button>
                            ))}
                        </div>

                        {workTime.map((el, index) => (
                            <div
                                key={el.day}
                                className={classNames({
                                    block: index === currentIndex,
                                    hidden: index !== currentIndex,
                                })}>
                                <div className='flex flex-col items-center gap-2'>
                                    <SemiBoldItalicText className='self-center text-base text-blue-700'>~Время работы~</SemiBoldItalicText>
                                    <div className='flex gap-2 items-center'>
                                        <div className='flex flex-col'>
                                            <RegularText className='text-xs font-semibold'>Открывается в</RegularText>
                                            <TimePicker
                                                value={workTime[index].workStart}
                                                onChange={e => {
                                                    setWorkTime(draft => {
                                                        draft[index].workStart = e.target.value
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div>-</div>
                                        <div className='flex flex-col'>
                                            <RegularText className='text-xs font-semibold'>Закрывается в</RegularText>
                                            <TimePicker
                                                value={workTime[index].workEnd}
                                                onChange={e => {
                                                    setWorkTime(draft => {
                                                        draft[index].workEnd = e.target.value
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className='flex self-end'>
                                            <RoundedWrapper
                                                icon='refresh'
                                                onClick={() => {
                                                    setWorkTime(draft => {
                                                        draft[index].workStart = ''
                                                        draft[index].workEnd = ''
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='flex flex-col items-center gap-2'>
                                    <SemiBoldItalicText className='self-center text-base text-blue-700 pt-4'>~Перерыв~</SemiBoldItalicText>
                                    <div className='flex gap-2 items-center'>
                                        <div className='flex flex-col'>
                                            <RegularText className='text-xs font-semibold'>Начало в</RegularText>
                                            <TimePicker
                                                value={workTime[currentIndex]?.lunchStart}
                                                onChange={e => {
                                                    setWorkTime(draft => {
                                                        draft[currentIndex].lunchStart = e.target.value
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div>-</div>
                                        <div className='flex flex-col'>
                                            <RegularText className='text-xs font-semibold'>Конец в</RegularText>
                                            <TimePicker
                                                value={workTime[currentIndex]?.lunchEnd}
                                                onChange={e => {
                                                    setWorkTime(draft => {
                                                        draft[currentIndex].lunchEnd = e.target.value
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className='flex self-end'>
                                            <RoundedWrapper
                                                icon='refresh'
                                                onClick={() => {
                                                    setWorkTime(draft => {
                                                        draft[index].lunchEnd = ''
                                                        draft[index].lunchStart = ''
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        {mode && (
                            <div
                                className={classNames({
                                    hidden: !mode,
                                })}>
                                <div className='flex flex-col items-center gap-2'>
                                    <SemiBoldItalicText className='self-center text-base text-blue-700'>~Время работы~</SemiBoldItalicText>
                                    <div className='flex gap-2 items-center'>
                                        <div className='flex flex-col'>
                                            <RegularText className='text-xs font-semibold'>Открывается в</RegularText>
                                            <TimePicker
                                                value={allDays.workStart}
                                                onChange={e => {
                                                    setAllDays(draft => {
                                                        draft.workStart = e.target.value
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div>-</div>
                                        <div className='flex flex-col'>
                                            <RegularText className='text-xs font-semibold'>Закрывается в</RegularText>
                                            <TimePicker
                                                value={allDays.workEnd}
                                                onChange={e => {
                                                    setAllDays(draft => {
                                                        draft.workEnd = e.target.value
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className='flex self-end'>
                                            <RoundedWrapper
                                                icon='refresh'
                                                onClick={() => {
                                                    setAllDays(draft => {
                                                        draft.workStart = ''
                                                        draft.workEnd = ''
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='flex flex-col items-center gap-2'>
                                    <SemiBoldItalicText className='self-center text-base text-blue-700 pt-4'>~Перерыв~</SemiBoldItalicText>
                                    <div className='flex gap-2 items-center'>
                                        <div className='flex flex-col'>
                                            <RegularText className='text-xs font-semibold'>Начало в</RegularText>
                                            <TimePicker
                                                value={allDays.lunchStart}
                                                onChange={e => {
                                                    setAllDays(draft => {
                                                        draft.lunchStart = e.target.value
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div>-</div>
                                        <div className='flex flex-col'>
                                            <RegularText className='text-xs font-semibold'>Конец в</RegularText>
                                            <TimePicker
                                                value={allDays.lunchEnd}
                                                onChange={e => {
                                                    setAllDays(draft => {
                                                        draft.lunchEnd = e.target.value
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className='flex self-end'>
                                            <RoundedWrapper
                                                icon='refresh'
                                                onClick={() => {
                                                    setAllDays(draft => {
                                                        draft.lunchStart = ''
                                                        draft.lunchEnd = ''
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className='flex flex-col gap-6'>
                <SemiBoldItalicText className='self-center text-base text-blue-700'>~Недельное расписание~</SemiBoldItalicText>
                {mode ? (
                    <div className='flex gap-3'>
                        <div className='flex flex-col gap-3'>
                            <MediumText className='font-semibold'>ПН</MediumText>
                            <MediumText className='font-semibold'>ВТ</MediumText>
                            <MediumText className='font-semibold'>СР</MediumText>
                            <MediumText className='font-semibold'>ЧТ</MediumText>
                            <MediumText className='font-semibold'>ПТ</MediumText>
                            <MediumText className='font-semibold'>СБ</MediumText>
                            <MediumText className='font-semibold'>ВС</MediumText>
                        </div>
                        <div className='w-px h-full bg-primaryColor' />

                        <div className='flex flex-col gap-3'>
                            {[1, 2, 3, 4, 5, 6, 7].map(item => (
                                <RegularText key={item} className={classNames(!allDays.workStart && 'text-red-700')}>
                                    {!allDays.workStart && 'не работает'}
                                    {allDays.workStart && `${allDays.workStart} - ${allDays.workEnd}`}
                                    {allDays.lunchStart && allDays.workStart && ` ( перерыв ${allDays.lunchStart}-${allDays.lunchEnd} )`}
                                </RegularText>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className='flex gap-3'>
                        <div className='flex flex-col gap-3'>
                            {WORK_DAYS.map(el => (
                                <MediumText key={el.day} className='font-semibold'>
                                    {el.day}
                                </MediumText>
                            ))}
                        </div>
                        <div className='w-px h-full bg-primaryColor' />

                        <div className='flex flex-col gap-3'>
                            {workTime.map(time => (
                                <RegularText key={time.day} className={classNames(!time.workStart && 'text-red-700')}>
                                    {!time.workStart && 'не работает'}

                                    {time.workStart && `${time.workStart} - ${time.workEnd}`}

                                    {time.lunchStart && time.workStart && ` ( перерыв ${time.lunchStart}-${time.lunchEnd} )`}
                                </RegularText>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

import { useSetAtom } from 'jotai'
import { ChangeEvent, useCallback, useState } from 'react'
import { createCourierGroupAtom } from 'src/atoms/allCouriers/createCourierGroupAtom'
import { setModalCloseAtom } from 'src/atoms/global/modalAtom'
import { AppImage, Button, InputWrapper, MediumItalic, RegularText, SemiBoldText, TextArea, TextInput } from 'src/components'
import { EButtonVariant } from 'src/components/Button'
import { CreateCourierGroupRequest } from 'src/services'
import { Icon } from '../../assets/icons'

export interface IShippingCostInput {
    id: string
    from: string
    to: string
    price: number | undefined
}

export const AddCourierGroup = (): JSX.Element => {
    const [loading, setLoading] = useState(false)
    const [shipCostInputs, setShipCostInputs] = useState<IShippingCostInput[]>(() => [
        {
            id: '1',
            from: '',
            to: '',
            price: undefined,
        },
    ])
    const createCourierGroup = useSetAtom(createCourierGroupAtom)

    const closeModal = useSetAtom(setModalCloseAtom)
    const [formState, setFormState] = useState<{
        range?: number | null
        mass?: number | null
        cargo_size?: number | null
        comment?: string | null
        title: string
    }>({
        title: '',
        cargo_size: null,
        range: null,
        mass: null,
        comment: '',
    })

    const onClickCreateGroup = async () => {
        const independent = shipCostInputs.map(item => ({
            distance_from: item.from,
            distance_to: item.to,
            price: item.price || 0,
        }))
        setLoading(true)

        await createCourierGroup({ ...formState, independent })
        setLoading(false)
    }

    const handleUpdate = () => {
        setFormState({
            title: '',
            cargo_size: null,
            range: null,
            mass: null,
            comment: '',
        })
    }

    const onChangeFormField = (name: keyof CreateCourierGroupRequest, val: string) => {
        setFormState(old => ({ ...old, [name]: val }))
    }

    const addInputs = () => {
        const newItem: IShippingCostInput = {
            id: Date.now() + Math.random().toString(),
            from: '',
            to: '',
            price: undefined,
        }

        setShipCostInputs([...shipCostInputs, newItem])
    }

    const deleteInputs = (id: string) => {
        const filterItem: IShippingCostInput[] = shipCostInputs.filter((item: IShippingCostInput) => item.id !== id)
        setShipCostInputs(filterItem)
    }

    const change = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setShipCostInputs(
                shipCostInputs.map(item => {
                    if (item.id === e.target.id) {
                        return { ...item, [e.target.name]: e.target.value }
                    }
                    return item
                }),
            )
        },
        [shipCostInputs],
    )

    return (
        <div className='flex flex-col' style={{ flex: 2 }}>
            <div className='flex flex-col h-full border-solid border border-mainBackgroundColor m-4 rounded-lg' style={{ flex: 2 }}>
                <div className='mt-5 ml-44'>
                    <MediumItalic>~Основная информация~</MediumItalic>
                </div>
                <div className='flex justify-center w-full mt-9 gap-8'>
                    <div>
                        <div className='flex flex-col gap-2 justify-start'>
                            <InputWrapper name='Название'>
                                <TextInput
                                    name='Название'
                                    placeholder='Название'
                                    value={formState.title}
                                    onChange={e => onChangeFormField('title', e.target.value)}
                                />
                            </InputWrapper>
                            <InputWrapper name='Дальность (км)'>
                                <TextInput
                                    name='Дальность (км)'
                                    placeholder='Дальность (км)'
                                    type='number'
                                    value={formState.range ?? ''}
                                    onChange={e => onChangeFormField('range', e.target.value)}
                                />
                            </InputWrapper>
                            <InputWrapper name='Масса (кг)'>
                                <TextInput
                                    name='Масса (кг)'
                                    placeholder='Масса (кг)'
                                    type='number'
                                    value={formState.mass ?? ''}
                                    onChange={e => onChangeFormField('mass', e.target.value)}
                                />
                            </InputWrapper>
                        </div>
                    </div>
                    <div className='flex flex-col items-end gap-2'>
                        <InputWrapper name='Размеры груза (м²)'>
                            <TextInput
                                name='Размеры груза (м²)'
                                placeholder='Размеры груза (м²)'
                                type='number'
                                value={formState.cargo_size ?? ''}
                                onChange={e => onChangeFormField('cargo_size', e.target.value)}
                            />
                        </InputWrapper>
                        <TextArea
                            value={formState.comment ?? ''}
                            onChange={e => onChangeFormField('comment', e.currentTarget.value)}
                            placeholder='Комментарий'
                        />
                    </div>
                </div>
                {/*<div className='flex w-full p-4 h-full'>*/}
                {/*    <div className='flex justify-center flex-1 self-end'>*/}
                {/*        <AppImage src={Assets.fura} />*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className='m-4 mt-8'>
                    <div className='flex  justify-between'>
                        <MediumItalic>Стоимость доставки (Сервис доставки)</MediumItalic>
                    </div>
                    <div className='flex justify-between'>
                        <div>
                            {shipCostInputs.map((item, index) => (
                                <div key={item.id} className='mt-2 mb-2 flex gap-1 align-items-center'>
                                    {index + 1})
                                    <TextInput
                                        name='from'
                                        style={{ maxWidth: '150px' }}
                                        placeholder='Расстояние (от)'
                                        type='number'
                                        value={item.from}
                                        onChange={change}
                                        id={item.id}
                                    />
                                    <span style={{ marginRight: '5px' }}>км</span>
                                    <TextInput
                                        name='to'
                                        style={{ maxWidth: '150px' }}
                                        placeholder='Расстояние (до)'
                                        type='number'
                                        value={item.to}
                                        onChange={change}
                                        id={item.id}
                                    />
                                    <span style={{ marginRight: '5px' }}>км</span>
                                    <TextInput
                                        onChange={change}
                                        name='price'
                                        style={{ maxWidth: '100px' }}
                                        placeholder='Цена'
                                        type='number'
                                        value={item.price}
                                        id={item.id}
                                    />
                                    {index === 0 ? null : (
                                        <Icon type='cross' style={{ cursor: 'pointer' }} onClick={() => deleteInputs(item.id)} />
                                    )}
                                </div>
                            ))}

                            {shipCostInputs.length > 5 ? null : (
                                <MediumItalic style={{ fontSize: '13px', cursor: 'pointer' }} onClick={addInputs}>
                                    Добавить еще
                                </MediumItalic>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className='flex justify-end gap-4 p-4'>
                <Button icon='refresh' onClick={handleUpdate}>
                    <RegularText>Обновить</RegularText>
                </Button>
                <Button
                    loader={loading}
                    disabled={!formState.title.length && !formState.cargo_size && !formState.range}
                    onClick={onClickCreateGroup}
                    variant={EButtonVariant.BLUE}
                    icon='save'>
                    <RegularText>Сохранить</RegularText>
                </Button>
                <Button onClick={closeModal}>
                    <RegularText>Выйти</RegularText>
                </Button>
            </div>
        </div>
    )
}

import classNames from 'classnames'
import { useAtomValue } from 'jotai'
import { useState } from 'react'
import { Icon } from 'src/assets/icons'
import { isNavbarFullAtom } from 'src/atoms/leftNavBar/leftNavBarAtom'
import { MediumText } from 'src/components'

export const AutoDistribution = (): JSX.Element => {
    const [state, setState] = useState(false)
    const isNavBarOpen = useAtomValue(isNavbarFullAtom)

    const handleClick = () => {
        setState(!state)
    }
    return (
        <div
            onClick={handleClick}
            className={classNames(
                'h-11 w-full flex justify-center items-center rounded-full min-w-7 min-h-10 cursor-pointer mb-4',
                {
                    'bg-green-800': !state,
                    'bg-red-700': state,
                },
                'pressableSimple',
                'backgroundTransition',
            )}
        >
            {isNavBarOpen ? <MediumText className='!text-white'>Автораспределение</MediumText> : <Icon type='A' fill='#FFFFFF' size={20} />}
        </div>
    )
}

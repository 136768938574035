import { atomWithRefresh } from 'jotai/utils'
import { CreateAdminAvailabilityRequest, EditAdminAvailabilityRequest, LokaliApi } from 'src/services'
import { atom } from 'jotai'
import { atomWithImmer } from 'jotai-immer'
import { HttpStatusCode } from 'src/types'
import dayjs from 'dayjs'
import moment from 'moment'

import { AdminAvailability } from '../../services/LocaliRepository/LocaliRepositorySchemas/AdminAvailabilitySchema'

import { setCloseSubModalAtom } from '../global/modalAtom'

import { currentAdminAtom } from './adminsTableAtom'

export const SCHEDULE = [
    { time: '0:00', selected: false },
    { time: '1:00', selected: false },
    { time: '2:00', selected: false },
    { time: '3:00', selected: false },
    { time: '4:00', selected: false },
    { time: '5:00', selected: false },
    { time: '6:00', selected: false },
    { time: '7:00', selected: false },
    { time: '8:00', selected: false },
    { time: '9:00', selected: false },
    { time: '10:00', selected: false },
    { time: '11:00', selected: false },
    { time: '12:00', selected: false },
    { time: '13:00', selected: false },
    { time: '14:00', selected: false },
    { time: '15:00', selected: false },
    { time: '16:00', selected: false },
    { time: '17:00', selected: false },
    { time: '18:00', selected: false },
    { time: '19:00', selected: false },
    { time: '20:00', selected: false },
    { time: '21:00', selected: false },
    { time: '22:00', selected: false },
    { time: '23:00', selected: false },
]
const adminAvailabilitiesAtom = atomWithImmer<AdminAvailability[]>([])
type Update = {
    parentIndex: number
    index: number
}
const scheduleConfigAtom = atomWithImmer<(typeof SCHEDULE)[]>([SCHEDULE, SCHEDULE, SCHEDULE, SCHEDULE, SCHEDULE, SCHEDULE, SCHEDULE])

const scheduleTitleAtom = atom('')

const onClickTimeAtom = atom(null, (_, set, { parentIndex, index }: Update) => {
    set(scheduleConfigAtom, old => {
        old[parentIndex][index].selected = !old[parentIndex][index].selected
    })
})

export const getAdminAvailabilityAtom = atomWithRefresh(async get => {
    const currentAdmin = get(currentAdminAtom)
    if (!currentAdmin) {
        return { data: [], error: new Error('No current admin') }
    }
    const currentAdminId = currentAdmin.admin_id
    if (!currentAdminId) {
        console.error('Current admin has no ID')
        return { data: [], error: new Error('Admin has no ID') }
    }
    try {
        const data = await LokaliApi.getAdminAvailability(currentAdminId)
        return { data, error: null }
    } catch (e) {
        return { data: [], error: e as Error }
    }
})
const createAvailabilityNetworkStateAtom = atom<HttpStatusCode>(HttpStatusCode.REQUEST_TIMEOUT)
const createAvailabilityStartAtom = atom<string | undefined>(undefined)
const scheduleStartAtom = atom<string | undefined>(undefined)
const scheduleDaysAtom = atom<number | null>(null)
const createAdminAvailabilityAtom = atom(null, async (get, set) => {
    const title = get(scheduleTitleAtom)
    const schedule = get(scheduleConfigAtom)
    const admin = get(currentAdminAtom)
    const scheduleStart = get(scheduleStartAtom)
    const dayCount = get(scheduleDaysAtom)

    if (!admin || !admin.admin_id || !scheduleStart) {
        set(createAvailabilityNetworkStateAtom, HttpStatusCode.BAD_REQUEST)
        return
    }

    const adminId = admin.admin_id

    const mappedSchedule = schedule.map((el, index) => ({
        day: index + 1,
        time: el.filter(time => time.selected).map(filtered => filtered.time),
    }))

    try {
        set(createAvailabilityNetworkStateAtom, HttpStatusCode.LOADING)

        const req: CreateAdminAvailabilityRequest = {
            title,
            schedule: mappedSchedule,
            admin_id: adminId,
            scheduleStart: moment(scheduleStart, 'DD.MM.YYYY').toISOString(),
            dayCount: dayCount || 0,
        }

        const statusCode = await LokaliApi.createAdminAvailability(req)

        if (statusCode === HttpStatusCode.OK) {
            set(setCloseSubModalAtom)
            set(createAvailabilityNetworkStateAtom, HttpStatusCode.OK)
            set(getAdminAvailabilityAtom)
            set(scheduleConfigAtom, [SCHEDULE, SCHEDULE, SCHEDULE, SCHEDULE, SCHEDULE, SCHEDULE, SCHEDULE])
            set(scheduleTitleAtom, '')
        } else {
            set(createAvailabilityNetworkStateAtom, statusCode)
        }
    } catch (e) {
        console.error('Error creating courier availability:', e)
        set(createAvailabilityNetworkStateAtom, HttpStatusCode.INTERNAL_SERVER_ERROR)
    }
})

const editAdminAvailabilityAtom = atom(null, async (get, set, payload: EditAdminAvailabilityRequest) => {
    const admin = get(currentAdminAtom)
    const adminAvailabilities = get(adminAvailabilitiesAtom)

    if (!admin || !admin.admin_id) {
        set(createAvailabilityNetworkStateAtom, HttpStatusCode.BAD_REQUEST)
        return
    }

    try {
        set(createAvailabilityNetworkStateAtom, HttpStatusCode.LOADING)

        const statusCode = await LokaliApi.editAdminAvailability(payload)
        if (statusCode === HttpStatusCode.OK) {
            set(setCloseSubModalAtom)
            set(createAvailabilityNetworkStateAtom, HttpStatusCode.OK)
            set(getAdminAvailabilityAtom)
            // set(scheduleConfigAtom, [SCHEDULE, SCHEDULE, SCHEDULE, SCHEDULE, SCHEDULE, SCHEDULE, SCHEDULE]);
            set(scheduleTitleAtom, '')
            set(scheduleStartAtom, '')
            set(scheduleDaysAtom, null)
        } else {
            set(createAvailabilityNetworkStateAtom, statusCode)
        }
    } catch (e) {
        console.error('Error editing admin availability:', e)
        set(createAvailabilityNetworkStateAtom, HttpStatusCode.INTERNAL_SERVER_ERROR)
    }
})
export {
    createAdminAvailabilityAtom,
    editAdminAvailabilityAtom,
    createAvailabilityNetworkStateAtom,
    onClickTimeAtom,
    scheduleConfigAtom,
    scheduleTitleAtom,
    createAvailabilityStartAtom,
    scheduleStartAtom,
    scheduleDaysAtom,
    adminAvailabilitiesAtom,
}

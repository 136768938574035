import { Rest } from '../services'

function getAddressFromObject(object: Rest['address'], startCity?: boolean): string {
    const list = (
        startCity ? [object.city, object.street, object.house, object.corpus] : [object.street, object.house, object.corpus, object.city]
    ).filter(i => i)

    if (!list.length) return ''

    return list.join(', ')
}

export { getAddressFromObject }

import { atom } from 'jotai'
import { FormOrderData } from 'src/features/CourierEditModals/FormOrderSchema'
import { ClientDB, FormOrderRequest, LokaliApi } from 'src/services'
import { HttpStatusCode } from 'src/types'

import { currentCourierAtom } from '../allOrders/couriersListAtom'
import { setCloseSubModalAtom } from '../global/modalAtom'
import { createOrderLoadingAtom } from '../allOrders/createOrderAtom'

import { getCourierDeliveries } from './editDeliveryAtoms'

const formOrderAtom = atom(null, async (get, set, data: FormOrderData) => {
    const currentCourier = get(currentCourierAtom)
    set(createOrderLoadingAtom, true)

    try {
        if (currentCourier?.item.courier_id) {
            const req: FormOrderRequest = {
                from_street: data.from_street || '',
                to_street: data.to_street || '',
                distance: data.distance || '',
                price: data.price || '',
                courier_id: currentCourier.item.courier_id,
                paymentType: data.paymentType?.value || '',
                deliveryType: data.deliveryType?.value || '',
                clientId: data.clientId || '',
                order_data: data.order_data || '',
                comment: data.comment || '',
            }

            const response = await LokaliApi.formOrder(req)

            if (response === HttpStatusCode.OK) {
                set(setCloseSubModalAtom)
                const refreshDeliveries = get(getCourierDeliveries)[1]
                await refreshDeliveries()
            }
        }
    } catch (e) {
        set(createOrderLoadingAtom, false)
        console.error('Error creating order:', e)
    }
})

const clientDataInOrderAtom = atom<ClientDB['clientData'] | string>('')

const getClientDataAtom = atom(null, async (_, set, id: string) => {
    try {
        const response = await LokaliApi.getClientData(id)

        if (response?.client_id) {
            set(clientDataInOrderAtom, response)
        }
    } catch (e) {
        set(clientDataInOrderAtom, 'Не найдено')
    }
})
export { formOrderAtom, getClientDataAtom, clientDataInOrderAtom }

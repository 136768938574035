import { useEffect, useState } from 'react'

function useDebounce<T = string>(value: T, delay: number = 1500): T {
    const [debouncedValue, setDebouncedValue] = useState(value)

    useEffect(() => {
        const t = setTimeout(() => {
            setDebouncedValue(value)
        }, delay)

        return () => {
            clearTimeout(t)
        }
    }, [value, delay])
    return debouncedValue
}

export { useDebounce }

import classNames from 'classnames'
import { useAtomValue, useSetAtom } from 'jotai'
import {
    activeClientOrdersAtom,
    finishedClientOrdersAtom,
    getClientsOrdersAtom,
    openCancelClientOrderDialogAtom,
    openClientOrderDetailsAtom,
    openDeleteClientOrderDialogAtom,
} from 'src/atoms/clientDb/clientsAtom'
import { Button, EButtonVariant, Nbsp, RegularText, SemiBoldItalicText, SemiBoldText } from 'src/components'
import { DateUtils } from 'src/utils'
import { useEffect } from 'react'

import getWordForm, { WORDS_LIST } from '../../utils/getWordForm'
import { getOrderStatusNames, statusNames } from '../../utils/statusNames'
import { openOrderInfoModalAtom } from '../../atoms/allOrders/orderInfoAtom'
import { currentOrderAtom } from '../../atoms/allOrders/ordersAtom'
import { ClientOrder, Order } from '../../services'
import { openCancelDialogAtom } from '../../atoms/allOrders/cancelOrderAtom'
import { openDeleteOrderDialogAtom } from '../../atoms/allOrders/deleteOrderAtom'

enum EOrderStatus {
    BUSY = 'busy',
    NEW = 'new',
    COOKING = 'cooking',
    GOING = 'going',
    COOKED = 'cooked',
    SHIPPING = 'shipping',
    COMPLETE = 'complete',
    ACCEPTED = 'accepted',
}

type ClientOrderStatusProps = {
    status: string
    date: string
    orderNumber: number
    orderPrice: number
    isOurCouriers: boolean
    pickup: boolean
    isDeliveryServices: boolean
    paymentType: string
    bonuses: number
    dishes: number
    isActive: boolean
    onCancel?: () => void
    onDelete?: () => void
    openOrder?: () => void
    fullOrder: ClientOrder
}

export const ClientOrderStatus = (props: ClientOrderStatusProps): JSX.Element => (
    <div
        className={classNames(
            'w-full h-44 bg-white',
            'flex justify-between gap-16',
            'border-solid border border-mainBackgroundColor rounded-lg',
            'p-5',
        )}
    >
        <div className='flex flex-col gap-2'>
            <RegularText>
                {props.date} | №{props.orderNumber}
            </RegularText>
            <SemiBoldText>Заказ на {props.orderPrice}р</SemiBoldText>
            <RegularText>
                {props.isDeliveryServices
                    ? 'Независимый заказ'
                    : props.pickup
                      ? 'Самовывоз'
                      : props.isOurCouriers
                        ? 'Доставка локали'
                        : 'Доставка силами рест.'}{' '}
                | <Nbsp />
                {props.paymentType === 'cash' ? 'Оплата наличными' : 'Онлайн оплата'}
            </RegularText>
            <RegularText>
                {/*<RegularText className="text-orange-500">
          +{props.bonuses} бонус
        </RegularText> <Nbsp/> | <Nbsp/>*/}
                {props.dishes} {getWordForm(props.dishes, WORDS_LIST.position)}
            </RegularText>
        </div>
        <div className='flex flex-col gap-2 justify-between'>
            <div>
                <div
                    className={classNames('flex justify-center items-center py-1 rounded-xl', {
                        ['bg-red-600']: props.status === EOrderStatus.ACCEPTED,
                        ['bg-green-500']: [EOrderStatus.GOING, EOrderStatus.COOKED].includes(props.status as EOrderStatus),
                        ['bg-yellow-500']: [EOrderStatus.COOKING, EOrderStatus.SHIPPING].includes(props.status as EOrderStatus),
                        ['bg-transparent']: props.status === EOrderStatus.COMPLETE,
                    })}
                >
                    <RegularText className='text-center'>{getOrderStatusNames(props.fullOrder as unknown as Order)}</RegularText>
                </div>
                <Button onClick={props.openOrder} variant={EButtonVariant.QUARTERY} className='justify-end text-nowrap whitespace-nowrap'>
                    <RegularText className='text-darkBlue-900 underline justify-end'>Открыть заказ</RegularText>
                </Button>
            </div>
            {props.onCancel ? (
                <Button variant={EButtonVariant.QUARTERY} onClick={props.onCancel} className='justify-end'>
                    <RegularText className='text-darkBlue-900 underline justify-end'>Отменить</RegularText>
                </Button>
            ) : (
                <Button variant={EButtonVariant.QUARTERY} onClick={props.onDelete} className='justify-end'>
                    <RegularText className='text-darkBlue-900 underline justify-end'>Удалить</RegularText>
                </Button>
            )}
        </div>
    </div>
)

export const ModalEditClientDBOrders = (): JSX.Element => {
    const openOrderDetails = useSetAtom(openClientOrderDetailsAtom)
    const setCurrentOrder = useSetAtom(currentOrderAtom)

    const activeOrders: ClientOrder[] = useAtomValue(activeClientOrdersAtom)
    const finishedOrders: ClientOrder[] = useAtomValue(finishedClientOrdersAtom)

    const openCancelDialog = useSetAtom(openCancelDialogAtom)
    const openDeleteDialog = useSetAtom(openDeleteOrderDialogAtom)

    return (
        <div className='flex gap-5 items-start justify-evenly'>
            <div className='flex flex-col justify-center gap-2 items-center' style={{ width: '520px' }}>
                <SemiBoldItalicText className='text-blue-700 text-lg'>~Активные~</SemiBoldItalicText>
                <div className='flex flex-col justify-start gap-2 overflow-y-auto' style={{ maxHeight: '400px' }}>
                    {activeOrders &&
                        activeOrders.map(order => (
                            <ClientOrderStatus
                                isActive={true}
                                onCancel={() => openCancelDialog(order as Order)}
                                key={order.clientId}
                                status={order.status}
                                orderPrice={order.price || 0}
                                date={DateUtils.formatToDay(order.createdAt)}
                                orderNumber={order.orderNumber || 0}
                                isOurCouriers={order.isOurCouriers || false}
                                pickup={order.pickup || false}
                                isDeliveryServices={Boolean(order.isDeliveryServices)}
                                paymentType={order.paymentType || ''}
                                bonuses={61}
                                dishes={order.dishIdArray?.length || 0}
                                openOrder={() => {
                                    setCurrentOrder(order as Order)
                                    openOrderDetails(order)
                                }}
                                fullOrder={order}
                            />
                        ))}
                </div>
            </div>
            <div className='flex flex-col justify-center gap-2 items-center' style={{ width: '520px' }}>
                <SemiBoldItalicText className='text-blue-700 text-lg'>~Завершенные~</SemiBoldItalicText>
                <div className='flex flex-col justify-start gap-2 overflow-y-auto' style={{ maxHeight: '400px' }}>
                    {finishedOrders &&
                        finishedOrders.map(order => (
                            <ClientOrderStatus
                                isActive={false}
                                key={order.clientId}
                                status={order.status}
                                orderPrice={order.price || 0}
                                date={DateUtils.formatToDay(order.createdAt)}
                                orderNumber={order.orderNumber || 0}
                                isOurCouriers={order.isOurCouriers || false}
                                pickup={order.pickup || false}
                                isDeliveryServices={Boolean(order.isDeliveryServices)}
                                paymentType={order.paymentType || ''}
                                bonuses={61}
                                dishes={order.dishIdArray?.length || 0}
                                onDelete={() => {
                                    setCurrentOrder(order as Order)
                                    openDeleteDialog()
                                }}
                                openOrder={() => {
                                    setCurrentOrder(order as Order)
                                    openOrderDetails(order)
                                }}
                                fullOrder={order}
                            />
                        ))}
                </div>
            </div>
        </div>
    )
}

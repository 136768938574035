import { Order } from '../services'

export const statusNames = {
    busy: 'Новый заказ',
    new: 'Новый заказ',
    cooking: 'Готовится',
    cooked: 'Готово',
    shipping: 'В пути',
    complete: 'Завершен',
    going: 'Курьер на пути в рест.',
    goingIndOrder: 'Курьер едет за посылкой',
    cancelled: 'Отменён',
}

export const getCourierStatusNames = (order: Order | null): string => {
    if (!order) return '-'

    const isIndOrder = Boolean(order.isDeliveryServices)
    const isOurCouriers = Boolean(order.isOurCouriers)
    const { courierStatus, pickup } = order

    if (courierStatus === 'going' && isIndOrder) return statusNames['goingIndOrder']
    if (pickup === true) return 'Самовывоз'

    return statusNames[courierStatus] || courierStatus || '-'
}

export const getOrderStatusNames = (order: Order | null): string => {
    if (!order) return '-'

    const isIndOrder = Boolean(order.isDeliveryServices)
    const { courierStatus, status } = order

    if (courierStatus === 'going' && isIndOrder) return statusNames['goingIndOrder']

    return statusNames[status] || status || '-'
}

export const paymentNames = {
    cash: 'Наличными',
    online: 'Картой',
}

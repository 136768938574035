import { atom } from 'jotai'
import { DialogDeleteRest } from 'src/features/Restaraunts'
import { HttpStatusCode } from 'src/types'
import { LokaliApi } from 'src/services'

import { dialogStateAtom } from '../global/dialogAtom'
import { setCloseAllModals } from '../global/modalAtom'

import { currentRestAtom, getRestAtom } from './restAtom'

const deleteRestNetworkStateAtom = atom<HttpStatusCode>(HttpStatusCode.I_AM_A_TEAPOT)

const openDeleteRestDialogAtom = atom(null, (_, set) => {
    set(dialogStateAtom, {
        isOpen: true,
        children: <DialogDeleteRest />,
    })
})

const deleteRestAtom = atom(null, async (get, set) => {
    const rest = get(currentRestAtom)

    set(deleteRestNetworkStateAtom, HttpStatusCode.LOADING)
    try {
        if (rest?.rest_id) {
            const response = await LokaliApi.deleteRest(rest.rest_id)

            if (response === HttpStatusCode.OK) {
                set(deleteRestNetworkStateAtom, HttpStatusCode.OK)
                set(dialogStateAtom, {
                    isOpen: false,
                    children: null,
                })
                set(getRestAtom)
                set(setCloseAllModals)
            }
            set(deleteRestNetworkStateAtom, HttpStatusCode.I_AM_A_TEAPOT)
        }
    } catch (e) {
        set(deleteRestNetworkStateAtom, HttpStatusCode.INTERNAL_SERVER_ERROR)
    } finally {
        set(deleteRestNetworkStateAtom, HttpStatusCode.I_AM_A_TEAPOT)
    }
})

export { openDeleteRestDialogAtom, deleteRestAtom, deleteRestNetworkStateAtom }

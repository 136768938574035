import { useSetAtom } from 'jotai'
import { useState } from 'react'
import { fireCourierAtom } from 'src/atoms/allCouriers/editCourierAtom'
import { closeDialogAtom } from 'src/atoms/global/dialogAtom'
import { setCloseSubModalAtom } from 'src/atoms/global/modalAtom'
import { Button, EButtonVariant, RegularText, SemiBoldItalicText, TextArea } from 'src/components'

type FireReasonModalProps = {
    courier_id: string
}

const FireReasonModal = ({ courier_id }: FireReasonModalProps): JSX.Element => {
    const [reason, setReason] = useState('')
    const fireCourier = useSetAtom(fireCourierAtom)
    const closeSubModal = useSetAtom(setCloseSubModalAtom)
    const closeDialog = useSetAtom(closeDialogAtom)

    return (
        <div className='flex flex-col justify-center items-center gap-5'>
            <SemiBoldItalicText>~Напишите причину увольнения~</SemiBoldItalicText>
            <TextArea placeholder='Причина увольнения' rows={5} value={reason} onChange={e => setReason(e.currentTarget.value)} />
            <div className='flex gap-2 py-2'>
                <Button onClick={() => fireCourier({ fire_reason: reason, courier_id })} className='w-32' variant={EButtonVariant.PRIMARY}>
                    <RegularText>ОК</RegularText>
                </Button>
                <Button
                    onClick={() => {
                        closeSubModal()
                        closeDialog()
                    }}
                    className='w-32'
                >
                    <RegularText>Отменить</RegularText>
                </Button>
            </div>
        </div>
    )
}

export { FireReasonModal }

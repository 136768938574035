import { atomWithRefresh } from 'jotai/utils'
import { atom } from 'jotai'

import { LokaliApi, MainSettingsRequest } from '../../services'

export type MainStatisticsFilters = {
    dateFrom?: string
    dateTo?: string
    city?: string
    restCategory?: string
    restId?: string
}

const mainStatisticsAtom = atom<MainSettingsRequest | null>(null)
const mainStatisticsLoadingAtom = atom<boolean>(false)
const mainStatisticsFiltersAtom = atom<MainStatisticsFilters>({})

const updateMainStatisticsFiltersAtom = atom(null, async (get, set, body: MainStatisticsFilters) => {
    const currentVal = get(mainStatisticsFiltersAtom)
    const newVal = { ...currentVal, ...body }
    set(mainStatisticsFiltersAtom, newVal)

    set(mainStatisticsLoadingAtom, true)
    const fetchedVal = await get(getMainStatisticsAtom)
    set(mainStatisticsLoadingAtom, false)
    set(mainStatisticsAtom, fetchedVal)
})

const getMainStatisticsAtom = atomWithRefresh<Promise<MainSettingsRequest | null>>(async get => {
    const mainStatisticsFilters = get(mainStatisticsFiltersAtom)

    try {
        return await LokaliApi.getMainStatistics(mainStatisticsFilters)
    } catch (e) {
        return null
    }
})

export { mainStatisticsAtom, mainStatisticsLoadingAtom, mainStatisticsFiltersAtom, updateMainStatisticsFiltersAtom, getMainStatisticsAtom }

import { AppImage, AppTable, Button, EButtonVariant, MediumText, RegularText } from 'src/components'
import { RoundedWrapper } from 'src/components/RoundedWrapper'
import { useCallback, useEffect, useState } from 'react'
import { useAtomValue, useSetAtom } from 'jotai'
import { useAtom } from 'jotai/index'
import { date } from 'zod'
import classNames from 'classnames'
import moment from 'moment/moment'

import { CreateMassNotificationRequest, MassNotification } from '../../services/LocaliRepository/types/pushNotifications'
import { Courier, LokaliApi } from '../../services'
import { subModalAtom } from '../../atoms/global/modalAtom'
import { currentMassSendAtom, smsTemplatesAtom } from '../../atoms/clientDb/manageSMSAtom'
import getWordForm, { WORDS_LIST } from '../../utils/getWordForm'
import { dialogStateAtom } from '../../atoms/global/dialogAtom'
import { Assets } from '../../utils'

import styles from './styles.module.scss'
import { MassSendAddModal } from './MassSendAddModal'

const THEADS = ['Шаблон', 'Группы', 'Время от', 'Число от', 'Число до', 'Периодичность', 'Статус активности', 'Статус']

export const ModalMassNewsletter = (): JSX.Element => {
    const setSubModal = useSetAtom(subModalAtom)
    const setDialogModal = useSetAtom(dialogStateAtom)
    const setSmsTemplates = useSetAtom(smsTemplatesAtom)
    const [currentMassSend, setCurrentMassSend] = useAtom(currentMassSendAtom)

    const [massNotifications, setMassNotifications] = useState<MassNotification[]>([])
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)

    const getMassNotifications = useCallback(async () => {
        try {
            const data = await LokaliApi.getMassNotifications()
            if (data?.length) {
                setMassNotifications(data.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)))
            }
        } catch (e) {
            console.warn(e)
        }
    }, [])

    const createMassNotification = useCallback(async (data: CreateMassNotificationRequest) => {
        try {
            await LokaliApi.createMassNotifications(data)

            getMassNotifications().then()
            setSubModal({ isOpen: false, children: null, title: '' })
        } catch (e) {
            console.warn(e)
        }
    }, [])

    const getSmsTemplates = useCallback(async () => {
        try {
            const data = await LokaliApi.getSmsTemplates()
            setSmsTemplates(data)
        } catch (e) {
            console.warn(e)
        }
    }, [])

    const onAddClick = () => {
        setSubModal({
            isOpen: true,
            children: (
                <MassSendAddModal
                    onAccept={createMassNotification}
                    onAbort={() => setSubModal({ isOpen: false, children: null, title: '' })}
                />
            ),
            title: 'Добавить новую рассылку',
            width: 900,
            height: 750,
        })
    }

    const onDelete = async () => {
        if (!currentMassSend?.masssend_id) return
        try {
            setDeleteLoading(true)
            const res = await LokaliApi.deleteMassNotifications({ masssend_id: currentMassSend.masssend_id })

            if (res?.message !== 'success') return
            setCurrentMassSend(null)
            await getMassNotifications()
            closeDialog()
        } catch (e) {
            console.warn(e)
        } finally {
            setDeleteLoading(false)
        }
    }

    const closeDialog = () => {
        setDialogModal({
            isOpen: false,
            children: null,
        })
    }

    const onDeleteClick = () => {
        setDialogModal({
            isOpen: true,
            children: (
                <div className='flex flex-col items-center justify-center h-full p-8'>
                    <div className='size-14'>
                        <AppImage src={Assets.sadSmiley} />
                    </div>
                    <div className='flex-1 flex justify-center items-center'>
                        <RegularText className='text-center'>Вы действительно хотите удалить рассылку?</RegularText>
                    </div>
                    <div className='flex gap-2'>
                        <Button
                            className='!w-44 flex justify-center !py-5'
                            variant={EButtonVariant.RED}
                            onClick={onDelete}
                            disabled={deleteLoading}
                        >
                            <RegularText className='!text-base'>Да, удалить</RegularText>
                        </Button>
                        <Button className='!w-44 !py-5 flex justify-center' onClick={closeDialog} disabled={deleteLoading}>
                            <RegularText>Назад</RegularText>
                        </Button>
                    </div>
                </div>
            ),
        })
    }

    useEffect(() => {
        getSmsTemplates().then()
        getMassNotifications().then()
    }, [])

    const renderCellFn = (element: MassNotification, index: number) => {
        const isActive = currentMassSend?.masssend_id === element.masssend_id
        const TextComponent = isActive ? MediumText : RegularText

        if (!currentMassSend?.masssend_id && index === 0) {
            setCurrentMassSend(element)
        }

        return (
            <tr
                key={element.masssend_id}
                className={classNames({ [styles.activeTd]: isActive }, 'cursor-pointer')}
                onClick={() => setCurrentMassSend(element)}
            >
                <td className={styles.orderTd}>
                    <TextComponent>{element.template.title}</TextComponent>
                </td>
                <td className={styles.orderTd}>
                    <TextComponent>
                        {element.group_id.length < 3
                            ? element.group_id.map(item => item.title).join(', ')
                            : element.group_id.length + ` ${getWordForm(element.group_id.length, WORDS_LIST.group)}`}
                    </TextComponent>
                </td>
                <td className={styles.orderTd}>
                    <TextComponent>{element.timeFrom}</TextComponent>
                </td>
                <td className={styles.orderTd}>
                    <TextComponent>{moment(element.dateFrom).format('DD.MM.YYYY')}</TextComponent>
                </td>
                <td className={styles.orderTd}>
                    <TextComponent>{moment(element.dateTo).format('DD.MM.YYYY')}</TextComponent>
                </td>
                <td className={styles.orderTd}>
                    <TextComponent>{element.periodicity}</TextComponent>
                </td>
                <td className={styles.orderTd}>
                    <TextComponent>{element.activity}</TextComponent>
                </td>
                <td className={styles.orderTd}>
                    <TextComponent>{element.status}</TextComponent>
                </td>
            </tr>
        )
    }

    return (
        <div className='flex justify-between h-full flex-col p-5'>
            <div className='flex flex-col gap-5'>
                <Button className='flex w-80' icon='plus' variant={EButtonVariant.PRIMARY} onClick={onAddClick}>
                    <MediumText>Добавить новую рассылку</MediumText>
                </Button>
                <AppTable table theads={THEADS} tbody={massNotifications} renderCellFn={renderCellFn} wrapperClassname={styles.table} />
            </div>
            <div className='flex justify-between'>
                <Button variant={EButtonVariant.QUARTERY} disabled={!currentMassSend?.masssend_id} onClick={onDeleteClick}>
                    <RegularText className='text-darkBlue-900 underline'>Удалить рассылку</RegularText>
                </Button>
                <div className='flex gap-5'>
                    {/*<Button icon="save" variant={EButtonVariant.BLUE}>
            <RegularText className="text-white">
              Сохранить
            </RegularText>
          </Button>*/}
                    <RoundedWrapper onClick={getMassNotifications} icon='refresh' />
                </div>
            </div>
        </div>
    )
}

import { Item, Menu } from 'react-contexify'
import { RegularText } from 'src/components'
import { useAtomValue, useSetAtom } from 'jotai'
import {
    clientDbCopiedTabNumberAtom,
    currentClientAtom,
    doubleClickedClientAtom,
    openClientDeleteModalAtom,
} from 'src/atoms/clientDb/clientsAtom'

import { CLIENT_DATABASE_CTX_ID } from './utils'

export const ClientDBContextMenu = (): JSX.Element => {
    const currentClient = useAtomValue(currentClientAtom)
    const doubleClickedClient = useAtomValue(doubleClickedClientAtom)
    const confirmCopy = useSetAtom(clientDbCopiedTabNumberAtom)
    const openClientDeleteModal = useSetAtom(openClientDeleteModalAtom)

    const onClickCopy = () => {
        confirmCopy(true)
        navigator.clipboard.writeText(currentClient?.clientData.tabNumber || '')
    }

    const onClickDelete = () => {
        openClientDeleteModal(doubleClickedClient)
    }

    return (
        <Menu id={CLIENT_DATABASE_CTX_ID}>
            <Item onClick={onClickCopy}>
                <RegularText>Копировать таб. номер</RegularText>
            </Item>
            <Item onClick={onClickDelete}>
                <RegularText>Удалить клиента</RegularText>
            </Item>
        </Menu>
    )
}

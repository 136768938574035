import { RegularText } from '../RegularText'
import { AppCitySelect, AppCitySelectProps } from '../AppCitySelect'
import { AppAddressSelect } from '../AppAddressSelect'

type NamedSelectProps = {
    name: string
    selectProps?: AppCitySelectProps
    address?: boolean
}

export const NamedCitySelect = (props: NamedSelectProps): JSX.Element => {
    const { name } = props
    return (
        <div className='flex gap-3 justify-end'>
            <RegularText>{name}</RegularText>
            {props.address ? (
                <AppAddressSelect
                    {...props.selectProps}
                    placeholder={props.selectProps?.placeholder ? props.selectProps?.placeholder : name}
                    isAddress
                />
            ) : (
                <AppCitySelect
                    {...props.selectProps}
                    placeholder={props.selectProps?.placeholder ? props.selectProps?.placeholder : name}
                />
            )}
        </div>
    )
}

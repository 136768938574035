import React from 'react'
import { MediumText, RegularText, SemiBoldText } from 'src/components'
import { ShadowContainer } from 'src/components/ShadowContainer'
import { useAtomValue } from 'jotai'

import { mainStatisticsAtom } from '../../atoms/mainStatistics/getMainStatisticsAtom'

export const MainProfitInfo = () => {
    const mainStatistics = useAtomValue(mainStatisticsAtom)

    const formatCurrency = (value: number) => new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(value)

    const getPercentageColor = (percent: number | undefined) => {
        if (percent === undefined) return 'text-gray-800'
        return percent > 100 ? 'text-green-800' : 'text-red-800'
    }

    return (
        <ShadowContainer className='flex gap-40'>
            <div className='flex flex-col gap-5 w-60'>
                <SemiBoldText className='text-xl'>Выручка за день:</SemiBoldText>
                <MediumText className='text-blue-900 text-4xl'>{formatCurrency(mainStatistics?.dailyPayoff ?? 0)}</MediumText>
                <div>
                    <RegularText className={getPercentageColor(mainStatistics?.orderPercent)}>
                        {mainStatistics?.orderPercent ?? 0}% &nbsp;
                    </RegularText>
                    <RegularText>(за неделю)</RegularText>
                </div>
            </div>
            <div className='flex flex-col gap-5 w-60'>
                <SemiBoldText className='text-xl'>Общий оборот:</SemiBoldText>
                <MediumText className='text-blue-900 text-4xl'>{formatCurrency(mainStatistics?.totalTurnoverSingle ?? 0)}</MediumText>
                <RegularText>{mainStatistics?.totalOrderCount ?? 0} Зарезервировано</RegularText>
            </div>
            <div className='flex flex-col gap-5 w-60'>
                <SemiBoldText className='text-xl'>Сумма списаний:</SemiBoldText>
                <MediumText className='text-blue-900 text-4xl'>{formatCurrency(mainStatistics?.writeOffSum ?? 0)}</MediumText>
                <RegularText>Дополнительно</RegularText>
            </div>
            <div className='flex flex-col gap-5 w-60'>
                <SemiBoldText className='text-xl'>Средний чек:</SemiBoldText>
                <MediumText className='text-blue-900 text-4xl'>{formatCurrency(mainStatistics?.averageCheck ?? 0)}</MediumText>
                <div>
                    <RegularText className='text-green-800'>120% &nbsp;</RegularText>
                    <RegularText>(за месяц)</RegularText>
                </div>
            </div>
        </ShadowContainer>
    )
}

import axios, { AxiosError } from 'axios'
// import { authTokenAtom } from 'src/atoms/auth/authAtom';
// import { store } from 'src/atoms/store';
import { AppLocalStorage } from 'src/services/LocalStorage'
import { HttpStatusCode } from 'src/types'
import { authTokenAtom } from '../atoms/auth/authAtom'
import { store } from '../atoms/store'

export const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
})

export const osrm = axios.create({
    baseURL: 'https://router.project-osrm.org',
})

export const nominatim = axios.create({
    baseURL: 'https://nominatim.openstreetmap.org',
})

api.interceptors.request.use(
    function (config) {
        const token = AppLocalStorage.getToken()

        config.headers.Authorization = 'Bearer' + ' ' + token

        return config
    },
    function (error) {
        return Promise.reject(error)
    },
)

api.interceptors.response.use(
    function (config) {
        return config
    },
    function (error: AxiosError) {
        const UNAUTHORIZED = error.response?.status === HttpStatusCode.UNAUTHORIZED
        const HAS_NO_ACCESS = error.response?.status === HttpStatusCode.METHOD_NOT_ALLOWED

        // if (UNAUTHORIZED) {
        //     store.set(authTokenAtom, null)
        //     window.location.href = '/login'
        // }

        if (HAS_NO_ACCESS) {
            alert('У вас нет доступа к этой функции')
        }

        // return (window.location.href = 'error-server')

        // return Promise.reject(error)
    },
)

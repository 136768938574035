import { Tab, Tabs } from 'src/components'

import { ModalManageSMSTemplate } from './ModalManageSMSTemplate'
import { ModalMassNewsletter } from './ModalMassNewsletter'
import { ModalFastNotification } from './ModalFastNotification'

const TABS: Tab[] = [
    {
        tabOption: {
            tabName: 'Управление шаблонами',
        },
        child: <ModalManageSMSTemplate />,
    },
    {
        tabOption: {
            tabName: 'Массовая рассылка',
        },
        child: <ModalMassNewsletter />,
    },
    {
        tabOption: {
            tabName: 'Быстрое уведомление',
        },
        child: <ModalFastNotification />,
    },
]

export const ModalSMSTemplate = ({ activeTabIndex }: { activeTabIndex?: number } = {}): JSX.Element => (
    <Tabs tabs={TABS} activeTabIndex={activeTabIndex} />
)

import { Controller, useFormContext } from 'react-hook-form'
import { AppImage, AppSelect, InputWrapper, SemiBoldItalicText, TextInput } from 'src/components'
import { Assets } from 'src/utils'

import { CreateRestFormData } from './CreateRestSchema'
import { useEffect, useState } from 'react'
import { useDebounce } from '../../hooks'
import { api } from '../../axios'

export const ModalCreateRestIntegration = (): JSX.Element => {
    const [paymentTypes, setPaymentTypes] = useState<
        {
            name: string
            id: string
            code: string
            paymentTypeKind: string
        }[]
    >([])
    const {
        control,
        formState: { errors },
        watch,
        setValue,
    } = useFormContext<CreateRestFormData>()

    const debounceValueApiKey = useDebounce(watch('apiKey'), 500)
    const debounceValueOrganizationId = useDebounce(watch('organizationId'), 500)

    const getPaymentTypes = async (apiKey: string, organizationId: string) => {
        try {
            const { data } = await api({ url: '/admin/rest/payment-types', params: { apiKey, organizationId } })
            if (Array.isArray(data)) {
                setPaymentTypes(data)
            }
        } catch (e) {
            setValue('onlinePaymentId', '')
            setValue('onlinePaymentKind', '')
            setValue('cashPaymentKind', '')
            setValue('cashPaymentId', '')
            console.log(e)
        }
    }

    useEffect(() => {
        getPaymentTypes(debounceValueApiKey || '', debounceValueOrganizationId || '')
    }, [debounceValueApiKey, debounceValueOrganizationId])

    return (
        <div className='flex flex-col justify-center items-center pt-10 gap-10 h-full'>
            <SemiBoldItalicText className='text-base text-blue-700 self-center'>~Настройки интеграции~</SemiBoldItalicText>
            <div className='flex justify-between gap-4'>
                <div className='flex flex-col gap-4'>
                    <InputWrapper name='ApiLogin'>
                        <Controller
                            control={control}
                            name='apiLogin'
                            render={props => <TextInput placeholder='ApiLogin' {...props.field} error={errors.apiLogin} />}
                        />
                    </InputWrapper>
                    <InputWrapper name='API Ключ'>
                        <Controller
                            control={control}
                            name='apiKey'
                            render={props => <TextInput placeholder='API Ключ' {...props.field} error={errors.apiKey} />}
                        />
                    </InputWrapper>
                    <InputWrapper name='Способ оплаты Айко (налич)'>
                        <Controller
                            control={control}
                            name='cashPaymentId'
                            render={props => (
                                <AppSelect
                                    onChange={val => {
                                        props.field.onChange(val?.value)
                                        const i = paymentTypes.find(item => item.id === val?.value)
                                        setValue('cashPaymentKind', i?.paymentTypeKind || '')
                                    }}
                                    options={paymentTypes.map(item => ({ value: item.id, label: item.name }))}
                                />
                            )}
                        />
                    </InputWrapper>
                </div>
                <div className='flex flex-col gap-4'>
                    <InputWrapper name='ID Организации'>
                        <Controller
                            control={control}
                            name='organizationId'
                            render={props => <TextInput placeholder='ID Организации' {...props.field} error={errors.organizationId} />}
                        />
                    </InputWrapper>
                    <InputWrapper name='ID Группы терминала'>
                        <Controller
                            control={control}
                            name='terminalgroupId'
                            render={props => <TextInput placeholder='ID Группы терминала' {...props.field} error={errors.organizationId} />}
                        />
                    </InputWrapper>
                    <InputWrapper name='Способ оплаты Айко (безнал)'>
                        <Controller
                            control={control}
                            name='onlinePaymentId'
                            render={props => (
                                <AppSelect
                                    onChange={val => {
                                        props.field.onChange(val?.value)
                                        const i = paymentTypes.find(item => item.id === val?.value)
                                        setValue('onlinePaymentKind', i?.paymentTypeKind || '')
                                    }}
                                    options={paymentTypes.map(item => ({ value: item.id, label: item.name }))}
                                />
                            )}
                        />
                    </InputWrapper>
                </div>
            </div>
            <div className='h-full flex items-end pb-12'>
                <AppImage src={Assets.settings} />
            </div>
        </div>
    )
}

import { atom } from 'jotai'
import { ModalCreateRestGroup } from 'src/features/Restaraunts/ModalCreateRestGroup'
import { CreateRestGroupRequest, LokaliApi, RestGroup } from 'src/services'
import { DefaultRequestState, HttpStatusCode } from 'src/types'
import { atomWithRefresh } from 'jotai/utils'

import { subModalAtom } from '../global/modalAtom'

const createRestGroupNetworkStateAtom = atom<HttpStatusCode>(HttpStatusCode.I_AM_A_TEAPOT)

const openCreateRestGroupAtom = atom(null, (_, set) => {
    set(subModalAtom, {
        isOpen: true,
        title: 'Создать группу',
        children: <ModalCreateRestGroup />,
        width: 440,
        height: 300,
    })
})

const createRestGroupAtom = atom(null, async (_, set, req: CreateRestGroupRequest) => {
    try {
        set(createRestGroupNetworkStateAtom, HttpStatusCode.LOADING)

        const response = await LokaliApi.createRestGroup(req)

        if (response === HttpStatusCode.OK) {
            set(createRestGroupNetworkStateAtom, HttpStatusCode.OK)
            set(subModalAtom, {
                isOpen: false,
                title: '',
                children: null,
            })
            set(getRestGroupAtom)
        }
        set(createRestGroupNetworkStateAtom, HttpStatusCode.I_AM_A_TEAPOT)
    } catch (e) {
        set(createRestGroupNetworkStateAtom, HttpStatusCode.INTERNAL_SERVER_ERROR)
    }
})

const getRestGroupAtom = atomWithRefresh<Promise<DefaultRequestState<RestGroup[]>>>(async () => {
    try {
        const data = await LokaliApi.getRestGroup()
        return {
            isLoading: false,
            data: data,
            error: null,
        }
    } catch (e) {
        return {
            isLoading: false,
            data: [],
            error: e as Error,
        }
    }
})

export { openCreateRestGroupAtom, createRestGroupAtom, getRestGroupAtom }

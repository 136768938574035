import { useAtom, useSetAtom } from 'jotai'
import { setCloseSubModalAtom } from 'src/atoms/global/modalAtom'
import { Button, EButtonVariant, MediumText, RegularText } from 'src/components'
import { useAtomValue } from 'jotai/index'

import { createRestMapMarkersAtom } from '../../atoms/restaraunts/createRestAtom'
import { currentRestAtom } from '../../atoms/restaraunts/restAtom'
import { editRestDeliveryZonesAtom } from '../../atoms/restaraunts/editRestDeliveryZoneAtom'

export const ModalCreateDeliveryZoneFooter = (): JSX.Element => {
    const closeSubModal = useSetAtom(setCloseSubModalAtom)
    const setMarkers = useSetAtom(createRestMapMarkersAtom)
    const deliveryZones = useAtomValue(editRestDeliveryZonesAtom)
    const [currentRest, setCurrentRest] = useAtom(currentRestAtom)

    const onClickSave = () => {
        if (currentRest) {
            // @ts-ignore
            setCurrentRest({ ...currentRest, order_region: deliveryZones.map(item => item.latLng) })
        }
        closeSubModal()
    }

    return (
        <div className='flex px-5 py-3'>
            <div className='flex flex-1 justify-end gap-3'>
                {/*<Button icon="refresh" variant={EButtonVariant.SECONDARY}>
          <RegularText>
            Обновить
          </RegularText>
        </Button>*/}
                <Button onClick={onClickSave} icon='save' variant={EButtonVariant.PRIMARY}>
                    <MediumText>Сохранить</MediumText>
                </Button>
                <Button
                    onClick={() => {
                        closeSubModal()
                        setMarkers([])
                    }}
                    variant={EButtonVariant.SECONDARY}>
                    <RegularText>Выйти</RegularText>
                </Button>
            </div>
        </div>
    )
}

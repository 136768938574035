import React from 'react'
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, TooltipProps } from 'recharts'
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent'
import { colors } from 'src/utils'

interface TurnoverData {
    name: string
    pv: number
    amt: number
}

const CustomTooltip = ({ active, payload, tooltip }: TooltipProps<ValueType, NameType> & { tooltip?: string }) => {
    if (active && payload && payload.length) {
        return (
            <div className='custom-tooltip'>
                <p>{`${tooltip || payload[0].name}: ${payload[0].value}`}</p>
            </div>
        )
    }
    return null
}

interface AppBarChartProps {
    data: TurnoverData[]
    tooltip?: string
}

export const AppBarChart: React.FC<AppBarChartProps> = ({ data, tooltip }) => {
    if (data.length === 0) {
        return <div>Нет данных о прибыли</div>
    }

    return (
        <ResponsiveContainer width='100%' height='100%'>
            <BarChart
                height={250}
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <XAxis dataKey='name' />
                <YAxis />
                <Tooltip content={<CustomTooltip tooltip={tooltip} />} cursor={{ fill: colors.white }} />
                <Bar aria-placeholder='Общий оборот' dataKey='pv' fill={colors.primaryColor} barSize={20} />
            </BarChart>
        </ResponsiveContainer>
    )
}

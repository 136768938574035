import classNames from 'classnames'
import { InputProps } from 'src/types/html'
import { Icon, IconType } from 'src/assets/icons'
import { FieldError } from 'react-hook-form'

import styles from './styles.module.scss'

export enum ETextInputVariant {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
}

export type TextInputProps = {
    variant?: ETextInputVariant
    leftIcon?: IconType
    rightIcon?: IconType
    error?: FieldError
} & InputProps

export function TextInput(props: TextInputProps): JSX.Element {
    const { leftIcon, variant = ETextInputVariant.PRIMARY, rightIcon, className = 'w-inputL', error, ...rest } = props

    const cn = classNames(
        {
            [styles.primary]: variant === ETextInputVariant.PRIMARY,
            [styles.secondary]: variant === ETextInputVariant.SECONDARY,
            [styles.tertiary]: variant === ETextInputVariant.TERTIARY,
        },
        className,
    )

    return (
        <div className='relative flex flex-col'>
            {!!leftIcon && (
                <div className='absolute top-2 left-2'>
                    <Icon type={leftIcon} />
                </div>
            )}
            {rightIcon && (
                <div className='absolute top-2 right-5'>
                    <Icon type={rightIcon} size={14} />
                </div>
            )}
            <input type='text' className={cn} {...rest} />
            {<span className='text-red-800 text-xs'>{error?.message}</span>}
        </div>
    )
}

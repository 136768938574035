import { atom } from 'jotai'
import { CreateCourierRequest, LokaliApi } from 'src/services/LocaliRepository'
import { HttpStatusCode, NetworkStatusState } from 'src/types'

import { setCloseAllModals } from '../global/modalAtom'

import { fetchAllCouriersAtom } from './allCouriersAtom'

const createCourierNetworkStateAtom = atom<NetworkStatusState>({
    statusCode: HttpStatusCode.SERVICE_UNAVAILABLE,
})

const createCourierPhotoAtom = atom<File | null>(null)

const createCourierAtom = atom(null, async (get, set, req: CreateCourierRequest) => {
    const photo = get(createCourierPhotoAtom)

    try {
        set(createCourierNetworkStateAtom, {
            statusCode: HttpStatusCode.LOADING,
        })

        let photoId

        if (photo) {
            const formData = new FormData()
            formData.append('file', photo)

            photoId = await LokaliApi.uploadFiles(formData)
        }

        const statusCode = await LokaliApi.createCourier(photoId?.file ? { ...req, courierPhoto: photoId.file } : req)

        if (statusCode === HttpStatusCode.OK) {
            set(setCloseAllModals)
            set(createCourierNetworkStateAtom, {
                statusCode: HttpStatusCode.OK,
            })

            set(fetchAllCouriersAtom)
        }
    } catch (e) {
        set(createCourierNetworkStateAtom, {
            statusCode: HttpStatusCode.INTERNAL_SERVER_ERROR,
        })
    }
})

export { createCourierNetworkStateAtom, createCourierAtom, createCourierPhotoAtom }

import { atom } from 'jotai'

import { CourierCreateFormOrder as CreateFormOrder, CourierCreateFormOrderProps } from '../../features/CourierEditModals'

import { modalAtom } from '../global/modalAtom'
import { setNotSavedDialogAtom } from '../global/dialogAtom'

const setCreateOrderModalAtom = atom(null, (_, set, update: { pageProps: CourierCreateFormOrderProps }) => {
    set(modalAtom, {
        isOpen: true,
        title: 'Сформировать заказ',
        children: <CreateFormOrder {...update.pageProps} />,
        onClose: () => set(setNotSavedDialogAtom),
    })
})

export { setCreateOrderModalAtom }

import { atom } from 'jotai'
import { Courier } from 'src/services/LocaliRepository'

const currentCourierAtom = atom<Courier | null>(null)
const ordersCouriersSearchAtom = atom<string>('')

const setCurrentCourierAtom = atom(null, (_, set, update: Courier) => {
    set(currentCourierAtom, update)
})

export { currentCourierAtom, setCurrentCourierAtom, ordersCouriersSearchAtom }

import classNames from 'classnames'
import dayjs from 'dayjs'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { setCloseSubModalAtom } from 'src/atoms/global/modalAtom'
import {
    Button,
    DateInput,
    Divider,
    MediumText,
    RegularText,
    SemiBoldText,
    TextInput,
    EButtonVariant,
    ETextInputVariant,
} from 'src/components'
import { useEffect, useState } from 'react'
import moment from 'moment'

import {
    createAdminAvailabilityAtom,
    onClickTimeAtom,
    SCHEDULE,
    scheduleConfigAtom,
    scheduleDaysAtom,
    scheduleStartAtom,
    scheduleTitleAtom,
} from '../../atoms/admins/adminAvailabilityAtom'
import { isDay } from '../CourierEditModals'

export const AdminCreateSchedule = (): JSX.Element => {
    const [loading, setLoading] = useState(false)
    const createAdminAvailability = useSetAtom(createAdminAvailabilityAtom)
    const [scheduleConfig, setScheduleConfig] = useAtom(scheduleConfigAtom)
    const onClickTime = useSetAtom(onClickTimeAtom)
    const onChangeTitle = useSetAtom(scheduleTitleAtom)
    const closeSubModal = useSetAtom(setCloseSubModalAtom)
    const [scheduleStart, setScheduleStart] = useAtom(scheduleStartAtom)
    const setScheduleDays = useSetAtom(scheduleDaysAtom)
    const [dateBlurred, setDateBlurred] = useState(false)

    const createAdmin = async () => {
        setLoading(true)
        await createAdminAvailability()
        setLoading(false)
    }

    useEffect(() => {
        setScheduleConfig([SCHEDULE, SCHEDULE, SCHEDULE, SCHEDULE, SCHEDULE, SCHEDULE, SCHEDULE])
    }, [])

    return (
        <div className='flex flex-col h-full'>
            <div className='flex gap-4 p-7'>
                <div>
                    <SemiBoldText className='text-xs'>Название</SemiBoldText>
                    <TextInput
                        placeholder='Название'
                        variant={ETextInputVariant.SECONDARY}
                        onChange={e => onChangeTitle(e.currentTarget.value)}
                    />
                </div>

                <div>
                    <SemiBoldText className='text-xs'>Начало действия</SemiBoldText>
                    <DateInput
                        onChange={e => setScheduleStart(e.target.value)}
                        onBlur={() => setDateBlurred(true)}
                        onFocus={() => setDateBlurred(false)}
                    />
                    {dateBlurred && scheduleStart && !moment(scheduleStart, 'DD.MM.YYYY').isValid() && (
                        <RegularText className='text-red-700'>Введите корректную дату</RegularText>
                    )}
                </div>

                <div>
                    <MediumText>Кол-во дней</MediumText>
                    <TextInput type='number' className='w-inputS' onChange={e => setScheduleDays(Number(e.target.value))} />
                </div>
            </div>

            <div className='m-1.5 flex max-h-96 overflow-scroll'>
                {scheduleConfig.map((schedule, parentIndex) => (
                    <div key={parentIndex} className='flex flex-col w-full px-px'>
                        <div className='bg-aqua-400 flex justify-center items-center p-2'>{isDay(parentIndex + 1)}</div>
                        {schedule.map((item, index) => (
                            <div
                                onClick={() => onClickTime({ parentIndex, index })}
                                className={classNames(
                                    'flex justify-center items-center cursor-pointer',
                                    'p-2',
                                    index % 2 !== 0 && 'bg-aqua-100',
                                    item.selected && '!bg-aqua-600',
                                )}
                                key={item.time}>
                                {item.time}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div className='p-6'>
                <div className='mt-2'>
                    <Divider />
                </div>
                <div className='flex h-full justify-end items-end pt-4'>
                    <Button onClick={createAdmin} icon='save' variant={EButtonVariant.BLUE} className='mr-2' type='submit'>
                        <MediumText>Сохранить</MediumText>
                    </Button>
                    <Button onClick={closeSubModal}>
                        <RegularText>Отмена</RegularText>
                    </Button>
                </div>
            </div>
        </div>
    )
}

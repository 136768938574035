import { z, ZodType } from 'zod'

import { AddClientFormData, AddClientGroupFormData, EditClientFormData } from './types'

const AddGroupSchema: ZodType<AddClientGroupFormData> = z.object({
    title: z.string({ required_error: 'Обязательное поле' }).min(1, 'Обязательное поле'),
    comment: z.string({ required_error: 'Обязательное поле' }).min(1, 'Обязательное поле'),
})

const AddClientSchema: ZodType<AddClientFormData> = z.object({
    tabNumber: z.string().optional(),
    phoneNumber: z.string().optional(),
    paymentInfo: z.string().optional(),
    cityKey: z.string().optional(),
    firstName: z.string({ required_error: 'Обязательное поле' }).min(1, 'Обязательное поле'),
    lastName: z.string({ required_error: 'Обязательное поле' }).min(1, 'Обязательное поле'),
    middleName: z.string({ required_error: 'Обязательное поле' }).min(1, 'Обязательное поле'),
    address: z.string({ required_error: 'Обязательное поле' }).min(1, 'Обязательное поле'),
    email: z.string().optional(),
    whatsapp: z.string().optional(),
    telegram: z.string().optional(),
    gender: z
        .object({
            label: z.string(),
            value: z.string(),
        })
        .optional(),
    clientgroup_id: z
        .object({
            label: z.string(),
            value: z.string(),
        })
        .optional()
        .nullish(),
    birthday: z.string().optional(),
    comment: z.string().optional(),
})

const EditClientSchema: ZodType<EditClientFormData> = z.object({
    tabNumber: z.string().optional(),
    phoneNumber: z.string().optional(),
    paymentInfo: z.string().optional(),
    cityKey: z.string().optional(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    middleName: z.string().optional(),
    email: z.string().optional(),
    whatsapp: z.string().optional(),
    telegram: z.string().optional(),
    house: z.string().optional(),
    street: z.string().optional(),
    corpus: z.string().optional(),
    gender: z
        .object({
            label: z.string(),
            value: z.string(),
        })
        .optional(),
    clientgroup_id: z
        .object({
            label: z.string(),
            value: z.string(),
        })
        .optional()
        .nullish(),
    comment: z.string().optional(),
    birthday: z.string().optional(),
    apartment: z.string().optional(),
    isBanned: z.boolean().nullish(),
})

export { AddGroupSchema, AddClientSchema, EditClientSchema }

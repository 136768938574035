import classNames from 'classnames'
import { Icon, IconType } from 'src/assets/icons'
import { RegularText } from 'src/components/RegularText'
import { AppColors } from 'src/utils/appColors'

import styles from './styles.module.scss'

type MenuCategoryProps = {
    icon?: IconType
    title: string
    onClick?: VoidFunction
    isActive: boolean
    isExpanded: boolean
}

export const MenuCategory = (props: MenuCategoryProps): JSX.Element => (
    <div
        className={classNames(
            'p-2.5 bg-white flex rounded-full justify-between items-center box-border',
            { 'p-2': !props.isExpanded, [styles.outlineStyle]: !props.isExpanded },
            props.isActive ? 'border border-solid border-blue-800' : 'border border-solid border-white',
            styles.menuButton,
        )}
        onClick={props.onClick}
    >
        <div className={classNames('flex gap-1.5')}>
            {!!props.icon && <Icon type={props.icon} fill={props.isActive ? AppColors.blue800 : AppColors.darkBlue800} />}
            <RegularText className='select-none'>{props.title}</RegularText>
        </div>
        {props.isExpanded && (
            <Icon
                className={classNames(
                    {
                        [styles.arrowCollapsed]: props.isActive,
                        [styles.arrowExpanded]: !props.isActive,
                        // [styles.outlineStyle]: !props.isActive
                    },
                    styles.menuArrow,
                )}
                size={15}
                type={'menuArrow'}
            />
        )}
    </div>
)

import { useSetAtom } from 'jotai'
import { closeDialogAtom } from 'src/atoms/global/dialogAtom'
import { AppImage, Button, EButtonVariant, MediumText, Nbsp, RegularText } from 'src/components'
import { Assets } from 'src/utils'

import { deleteAdminAtom } from '../../atoms/admins/adminEditAtom'
import { useState } from 'react'

type DeleteAdminProps = {
    admin_id: string
    name?: string
}

const DeleteAdminDialog = ({ admin_id, name }: DeleteAdminProps): JSX.Element => {
    const [loading, setLoading] = useState(false)
    const closeDialog = useSetAtom(closeDialogAtom)
    const deleteAdmin = useSetAtom(deleteAdminAtom)

    const deleteClick = async () => {
        setLoading(true)
        await deleteAdmin({ admin_id })
        setLoading(false)
    }

    return (
        <div className='flex flex-col items-center justify-center h-full p-8'>
            <div className='size-14'>
                <AppImage src={Assets.sadSmiley} />
            </div>
            <div className='p-2 text-center'>
                <RegularText>
                    Вы действительно хотите удалить админа безвозвратно <Nbsp />
                </RegularText>
                <MediumText>“{name}”?</MediumText>
            </div>
            <div className='flex gap-2'>
                <Button className='!w-44 flex justify-center !py-5' variant={EButtonVariant.RED} onClick={deleteClick} loader={loading}>
                    <RegularText className='!text-base'>Удалить</RegularText>
                </Button>
                <Button className='!w-44 !py-5 flex justify-center' onClick={closeDialog}>
                    <RegularText>Отмена</RegularText>
                </Button>
            </div>
        </div>
    )
}

export { DeleteAdminDialog }

import { atom } from 'jotai'
import { matchRoutes } from 'react-router'
import { ERoutes } from 'src/router/routes'

const currentCategoryIndexAtom = atom(initCurrentCategory())

const setCurrentCategoryIndexAtom = atom(null, (_, set, update: number) => {
    set(currentCategoryIndexAtom, update)
})

function initCurrentCategory(): number {
    const { location } = window

    const isMatchMain = matchRoutes([{ path: ERoutes.MAIN }], location)

    const isMatchClients = matchRoutes(
        [
            { path: ERoutes.CLIENT_GENERAL_STATISTICS },
            { path: ERoutes.CLIENTS_DATABASE },
            { path: ERoutes.CLIENT_GROUPS },
            { path: ERoutes.CLIENT_PUSH_NOTIFICATION },
        ],
        location,
    )

    const isMatchRest = matchRoutes([{ path: ERoutes.REST }, { path: ERoutes.REST_MAP }], location)

    const isMatchCourier = matchRoutes([{ path: ERoutes.ALL_KURAS }, { path: ERoutes.KURA_MAP }], location)

    const isMatchOrders = matchRoutes([{ path: ERoutes.ALL_ORDERS }], location)

    const isMatchAdmins = matchRoutes([{ path: ERoutes.ADMINS }], location)

    const isMatchAccounting = matchRoutes([{ path: ERoutes.ACCOUNTING_COURIERS }, { path: ERoutes.ACCOUNTING_REST }], location)
    if (isMatchMain) {
        return 0
    }

    if (isMatchClients) {
        return 1
    }

    if (isMatchRest) {
        return 2
    }

    if (isMatchCourier) {
        return 3
    }

    if (isMatchOrders) {
        return 4
    }

    if (isMatchAccounting) {
        return 5
    }

    if (isMatchAdmins) {
        return 6
    }

    return 0
}

export { currentCategoryIndexAtom, setCurrentCategoryIndexAtom }

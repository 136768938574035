import { atom } from 'jotai'
import { LokaliApi } from 'src/services'
import { HttpStatusCode } from 'src/types'
import { AdminCreateData } from 'src/features/AdminsCreateModals/schema'

import { setCloseAllModals } from '../global/modalAtom'

import { getAdminsAtom } from './adminsTableAtom'
import { AdminRight } from './adminRightsAtom'
import {
    createAdminRightAtom,
    createClientRightAtom,
    createCourierRightAtom,
    createGeneralRightAtom,
    createOrderRightAtom,
    createRestRightAtom,
} from './adminCreateRightsAtom'

const createAdminPhotoAtom = atom<File | null>(null)
const createAdminNetworkStateAtom = atom<HttpStatusCode>(HttpStatusCode.I_AM_A_TEAPOT)

const createAdminAtom = atom(null, async (get, set, req: AdminCreateData) => {
    const photo = get(createAdminPhotoAtom)
    const generalRights = get(createGeneralRightAtom)
    const clientRights = get(createClientRightAtom)
    const courierRights = get(createCourierRightAtom)
    const restRights = get(createRestRightAtom)
    const orderRights = get(createOrderRightAtom)
    const adminRights = get(createAdminRightAtom)

    const rights: AdminRight[] = [...generalRights, ...clientRights, ...courierRights, ...restRights, ...orderRights, ...adminRights]

    const filtered = rights.filter(el => el.checked)
    const mapped = filtered.map(right => right.id)
    try {
        set(createAdminNetworkStateAtom, HttpStatusCode.LOADING)

        let photoId

        if (photo) {
            const formData = new FormData()
            formData.append('file', photo)

            photoId = await LokaliApi.uploadFiles(formData)
        }

        const statusCode = await LokaliApi.createAdmin(
            photoId?.file ? { ...req, adminPhoto: photoId.file, rights: mapped } : { ...req, rights: mapped },
        )

        if (statusCode === HttpStatusCode.OK) {
            set(setCloseAllModals)
            set(createAdminNetworkStateAtom, HttpStatusCode.OK)
            set(getAdminsAtom)
        }
    } catch (e) {
        set(createAdminNetworkStateAtom, HttpStatusCode.I_AM_A_TEAPOT)
    }
})

const createAdminRightsAtom = atom<string[]>([])

export { createAdminPhotoAtom, createAdminAtom, createAdminRightsAtom }

import { atom } from 'jotai'
import { AssignOrderDialog } from 'src/features/Orders'
import { LokaliApi, Order } from 'src/services'
import { HttpStatusCode } from 'src/types'

import { dialogStateAtom } from '../global/dialogAtom'
import { setCloseAllModals } from '../global/modalAtom'

import { currentOrderAtom, getOrdersAtom } from './ordersAtom'
import { currentCourierAtom } from './couriersListAtom'

const assignOrderNetworkStateAtom = atom<HttpStatusCode>(HttpStatusCode.I_AM_A_TEAPOT)

const assignOrderAtom = atom(null, async (get, set) => {
    set(assignOrderNetworkStateAtom, HttpStatusCode.LOADING)
    const currentCourier = get(currentCourierAtom)
    const currentOrder = get(currentOrderAtom)

    try {
        if (currentCourier && currentOrder) {
            const response = await LokaliApi.assignOrder({
                courier_id: currentCourier?.item.courier_id,
                order_id: currentOrder?.order_id,
            })

            if (response === HttpStatusCode.OK) {
                set(assignOrderNetworkStateAtom, HttpStatusCode.OK)
                set(setCloseAllModals)
                set(getOrdersAtom)
            }
        }
        set(setCloseAllModals)
        set(assignOrderNetworkStateAtom, HttpStatusCode.I_AM_A_TEAPOT)
    } catch (e) {
        set(assignOrderNetworkStateAtom, HttpStatusCode.I_AM_A_TEAPOT)
    }
})

const openAssignDialogAtom = atom(null, (_, set) => {
    set(dialogStateAtom, {
        isOpen: true,
        children: <AssignOrderDialog />,
    })
})

export { openAssignDialogAtom, assignOrderAtom }

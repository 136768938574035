import { useContextMenu } from 'react-contexify'
import { AppTable, GridTable, GridTableHead, GridTableItem, MediumText, RegularText } from 'src/components'
import { useAtomValue, useSetAtom } from 'jotai'
import { currentClientAtom, dbClientsAtom, doubleClickedClientAtom, setCurrentClientAtom } from 'src/atoms/clientDb/clientsAtom'
import { ClientDB } from 'src/services/LocaliRepository/LocaliRepositorySchemas/ClientDBSchema'
import { tableActiveClassName } from 'src/utils'
import { openEditClientAtom } from 'src/atoms/clientDb/editClientDBAtom'
import React from 'react'

import getWordForm, { WORDS_LIST } from '../../utils/getWordForm'

import { CLIENT_DATABASE_CTX_ID } from './utils'

const THEADS = [
    'Таб. номер',
    'Телефон',
    'ФИО',
    'Город',
    'Заказ',
    'Общая сумма',
    'Общее количество заказов',
    'Бонусы',
    'Статус активности',
    'Статус блокировки',
    'Комментарий',
]

export const ClientDatabaseTable = (): JSX.Element => {
    const { data } = useAtomValue(dbClientsAtom)

    const currentClient = useAtomValue(currentClientAtom)
    const setDoubleClickedClient = useSetAtom(doubleClickedClientAtom)
    const setCurrentClient = useSetAtom(setCurrentClientAtom)
    const openEditModal = useSetAtom(openEditClientAtom)

    const { show } = useContextMenu({
        id: CLIENT_DATABASE_CTX_ID,
    })

    const renderCellFn = (item: ClientDB): JSX.Element => {
        const isActive = currentClient?.clientData.client_id === item.clientData.client_id
        const TextComponent = isActive ? MediumText : RegularText

        return (
            <tr>
                <GridTableItem
                    child={<TextComponent>{item.clientData.tabNumber || '-'}</TextComponent>}
                    onDoubleClick={() => {
                        setCurrentClient({ ...item })
                        openEditModal()
                    }}
                    className={tableActiveClassName(isActive)}
                    onClick={() => setCurrentClient(item)}
                    onContextMenu={e => {
                        setDoubleClickedClient(item)
                        show({ event: e })
                    }}
                />
                <GridTableItem
                    child={<TextComponent>{item.clientData.phoneNumber || '-'}</TextComponent>}
                    onDoubleClick={() => {
                        setCurrentClient({ ...item })
                        openEditModal()
                    }}
                    className={tableActiveClassName(isActive)}
                    onClick={() => setCurrentClient(item)}
                    onContextMenu={e => {
                        setDoubleClickedClient(item)
                        show({ event: e })
                    }}
                />
                <GridTableItem
                    child={
                        <TextComponent>
                            {`${item.clientData.firstName || ''} ${item.clientData.middleName || ''} ${item.clientData.lastName || ''}`}
                        </TextComponent>
                    }
                    onDoubleClick={() => {
                        setCurrentClient({ ...item })
                        openEditModal()
                    }}
                    className={tableActiveClassName(isActive)}
                    onClick={() => setCurrentClient(item)}
                    onContextMenu={e => {
                        setDoubleClickedClient(item)
                        show({ event: e })
                    }}
                />
                <GridTableItem
                    child={<TextComponent>{item.clientData.cityKey || '-'}</TextComponent>}
                    onDoubleClick={() => {
                        setCurrentClient({ ...item })
                        openEditModal()
                    }}
                    className={tableActiveClassName(isActive)}
                    onClick={() => setCurrentClient(item)}
                    onContextMenu={e => {
                        setDoubleClickedClient(item)
                        show({ event: e })
                    }}
                />
                <GridTableItem
                    child={
                        <TextComponent>
                            {Number(item.activeOrderCount) ? (
                                <span className='text-orange-500'>
                                    {item.activeOrderCount} {getWordForm(Number(item.activeOrderCount), WORDS_LIST.active)}
                                </span>
                            ) : (
                                'Нет активных'
                            )}
                        </TextComponent>
                    }
                    onDoubleClick={() => {
                        setCurrentClient({ ...item })
                        openEditModal()
                    }}
                    className={tableActiveClassName(isActive)}
                    onClick={() => setCurrentClient(item)}
                    onContextMenu={e => {
                        setDoubleClickedClient(item)
                        show({ event: e })
                    }}
                />
                <GridTableItem
                    child={<TextComponent>{item.totalPrice || '-'}</TextComponent>}
                    onDoubleClick={() => {
                        setCurrentClient({ ...item })
                        openEditModal()
                    }}
                    className={tableActiveClassName(isActive)}
                    onClick={() => setCurrentClient(item)}
                    onContextMenu={e => {
                        setDoubleClickedClient(item)
                        show({ event: e })
                    }}
                />
                <GridTableItem
                    child={<TextComponent>{item.orderCount || '-'}</TextComponent>}
                    onDoubleClick={() => {
                        setCurrentClient({ ...item })
                        openEditModal()
                    }}
                    className={tableActiveClassName(isActive)}
                    onClick={() => setCurrentClient(item)}
                    onContextMenu={e => {
                        setDoubleClickedClient(item)
                        show({ event: e })
                    }}
                />
                <GridTableItem
                    child={<TextComponent>{item.clientData.bonuscount ?? '-'}</TextComponent>}
                    onDoubleClick={() => {
                        setCurrentClient({ ...item })
                        openEditModal()
                    }}
                    className={tableActiveClassName(isActive)}
                    onClick={() => setCurrentClient(item)}
                    onContextMenu={e => {
                        setDoubleClickedClient(item)
                        show({ event: e })
                    }}
                />
                <GridTableItem
                    child={
                        <TextComponent>{item.isActive ? <span className='text-orange-500'>Активный</span> : 'Неактивный'}</TextComponent>
                    }
                    onDoubleClick={() => {
                        setCurrentClient({ ...item })
                        openEditModal()
                    }}
                    className={tableActiveClassName(isActive)}
                    onClick={() => setCurrentClient(item)}
                    onContextMenu={e => {
                        setDoubleClickedClient(item)
                        show({ event: e })
                    }}
                />
                <GridTableItem
                    child={
                        <TextComponent>
                            {item.clientData.isBanned ? 'Забанен' : <span className='text-orange-500'>Активный</span>}
                        </TextComponent>
                    }
                    onDoubleClick={() => {
                        setCurrentClient({ ...item })
                        openEditModal()
                    }}
                    className={tableActiveClassName(isActive)}
                    onClick={() => setCurrentClient(item)}
                    onContextMenu={e => {
                        setDoubleClickedClient(item)
                        show({ event: e })
                    }}
                />
                <GridTableItem
                    child={<TextComponent>{item.clientData.comment || '-'}</TextComponent>}
                    onDoubleClick={() => {
                        setCurrentClient({ ...item })
                        openEditModal()
                    }}
                    className={tableActiveClassName(isActive)}
                    onClick={() => setCurrentClient(item)}
                    onContextMenu={e => {
                        setDoubleClickedClient(item)
                        show({ event: e })
                    }}
                />
            </tr>
        )
    }

    return (
        <div>
            {/*<AppTable<ClientDB>
        theads={THEADS}
        tbody={data}
        renderCellFn={renderCellFn}
      />*/}
            <GridTable
                theads={THEADS.map(head => (
                    <GridTableHead key={head} title={head} />
                ))}>
                {!!data?.length && data.map(renderCellFn)}
            </GridTable>
        </div>
    )
}

import { atom } from 'jotai'

import { createRestDeliveryZonesAtom, DeliveryZone } from './createRestAtom'

const currentDeliveryZoneAtom = atom<DeliveryZone | undefined>(undefined)

const selectDeliveryZoneAtom = atom(null, (_, set, update: DeliveryZone) => {
    set(currentDeliveryZoneAtom, update)
})

const deleteDeliveryZoneAtom = atom(null, (get, set) => {
    const currentDeliveryZone = get(currentDeliveryZoneAtom)

    set(createRestDeliveryZonesAtom, old => old.filter(zone => zone.title !== currentDeliveryZone?.title))
})

export { currentDeliveryZoneAtom, selectDeliveryZoneAtom, deleteDeliveryZoneAtom }

import React, { useEffect, Suspense, useState } from 'react'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { AppTable, Button, MediumItalic, RegularText } from 'src/components'
import classNames from 'classnames'
import moment from 'moment'

import { setEditAdminScheduleModalAtom } from '../../atoms/admins/adminEditModalAtom'
import { AdminAvailability } from '../../services/LocaliRepository/LocaliRepositorySchemas/AdminAvailabilitySchema'
import { getAdminAvailabilityAtom, adminAvailabilitiesAtom } from '../../atoms/admins/adminAvailabilityAtom'
import { LokaliApi } from '../../services/LocaliRepository/lokali'

import styles from '../Orders/styles.module.scss'

const THEADS = ['Название', 'Начало действия']

interface SubModalState {
    isOpen: boolean
    title?: string
    children?: React.ReactNode
    width?: number
    height?: number
}

const ModalEditAdminAvailability = (): JSX.Element => {
    const [adminAvailabilities, setAdminAvailabilities] = useAtom(adminAvailabilitiesAtom)
    const adminAvailabilityResult = useAtomValue(getAdminAvailabilityAtom)
    const setAdminScheduleModal = useSetAtom(setEditAdminScheduleModalAtom)
    const [selectedAvailability, setSelectedAvailability] = useState<AdminAvailability | null>(null)
    const [subModal, setSubModal] = useState<SubModalState>({ isOpen: false })

    useEffect(() => {
        if (adminAvailabilityResult && adminAvailabilityResult.data) {
            setAdminAvailabilities(adminAvailabilityResult.data)
        }
    }, [adminAvailabilityResult, setAdminAvailabilities])

    const handleEdit = () => {
        if (selectedAvailability) {
            setAdminScheduleModal({ ...selectedAvailability })
            setSelectedAvailability(null)
        }
    }

    const handleAdd = () => {
        setAdminScheduleModal(null)
    }

    const handleDelete = async () => {
        if (selectedAvailability) {
            try {
                const response = await LokaliApi.deleteAdminAvailability({ avialschedule_id: selectedAvailability.avialschedule_id })
                if (response === 200) {
                    setAdminAvailabilities(prev => prev.filter(item => item.avialschedule_id !== selectedAvailability.avialschedule_id))
                    setSelectedAvailability(null)
                }
            } catch (error) {
                console.error('Error deleting admin availability:', error)
                alert('Failed to delete admin availability')
            }
        } else {
            alert('Please select an availability to delete')
        }
    }

    const renderCellFn = (element: AdminAvailability) => (
        <tr
            key={element.avialschedule_id}
            className={classNames('cursor-pointer', { 'bg-blue-100': selectedAvailability?.avialschedule_id === element.avialschedule_id })}
            onClick={() => setSelectedAvailability(element)}
        >
            <td className={styles.orderTd}>
                <RegularText>{element.title}</RegularText>
            </td>
            <td className={styles.orderTd}>
                <RegularText>{moment(element.scheduleStart).format('DD.MM.YYYY')}</RegularText>
            </td>
        </tr>
    )

    return (
        <div className='mx-4 my-6 flex gap-5 flex-col'>
            <div className='flex gap-14'>
                <MediumItalic>~График доступности администратора~</MediumItalic>
                <div className='flex gap-5'>
                    <Button onClick={handleAdd}>
                        <RegularText>Добавить</RegularText>
                    </Button>
                    <Button onClick={handleEdit}>
                        <RegularText>Редактировать</RegularText>
                    </Button>
                    <Button onClick={handleDelete}>
                        <RegularText className='!text-red-600'>Удалить</RegularText>
                    </Button>
                </div>
            </div>

            <div>
                <AppTable theads={THEADS} tbody={adminAvailabilities} renderCellFn={renderCellFn} table />
            </div>

            {subModal.isOpen && (
                <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center'>
                    <div className='bg-white p-4 rounded' style={{ width: subModal.width, height: subModal.height }}>
                        <h2 className='text-lg font-bold mb-4'>{subModal.title}</h2>
                        {subModal.children}
                    </div>
                </div>
            )}
        </div>
    )
}

export const SuspenseModalEditAdminAvailability = () => (
    <Suspense fallback={<div>Loading...</div>}>
        <ModalEditAdminAvailability />
    </Suspense>
)

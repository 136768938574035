import { useSetAtom } from 'jotai'
import { setCloseAllModals } from 'src/atoms/global/modalAtom'
import { AppImage, Button, EButtonVariant, MediumText, RegularText } from 'src/components'
import { ClientDB, LokaliApi } from 'src/services'
import { useCallback, useState } from 'react'

import { Assets } from '../../utils'
import { refreshDbClientsAtom } from '../../atoms/clientDb/clientsAtom'
import { closeDialogAtom } from '../../atoms/global/dialogAtom'

type ModalDeleteClient = {
    client: ClientDB | null
}

export const ModalDeleteClient = (props: ModalDeleteClient): JSX.Element => {
    const closeAllModals = useSetAtom(setCloseAllModals)
    const closeModal = useSetAtom(closeDialogAtom)
    const refreshDbClients = useSetAtom(refreshDbClientsAtom)

    const [deletingLoading, setDeletingLoading] = useState(false)

    const deleteClient = useCallback(async () => {
        try {
            if (!props.client?.clientData.client_id) return
            setDeletingLoading(true)
            const data = await LokaliApi.deleteClient({ client_id: props.client?.clientData.client_id })

            if (data?.message === 'success') {
                refreshDbClients()
                closeAllModals()
            }
        } catch (e) {
            console.warn(e)
        } finally {
            setDeletingLoading(false)
        }
    }, [props.client?.clientData.client_id])

    return (
        <div className='flex flex-col items-center justify-center h-full p-8'>
            <div className='size-14'>
                <AppImage src={Assets.sadSmiley} />
            </div>
            <div className='flex-1 flex justify-center items-center'>
                <RegularText className='text-center'>
                    Вы действительно хотите удалить клиента
                    <br />
                    <MediumText>
                        "
                        {props.client?.clientData.firstName +
                            ' ' +
                            props.client?.clientData.middleName +
                            ' ' +
                            props.client?.clientData.lastName}
                        "
                    </MediumText>
                    <RegularText>&nbsp;?</RegularText>
                </RegularText>
            </div>
            <div className='flex gap-2'>
                <Button
                    className='!w-44 flex justify-center !py-5'
                    variant={EButtonVariant.RED}
                    onClick={deleteClient}
                    loader={deletingLoading}>
                    <RegularText className='!text-base'>Да, удалить</RegularText>
                </Button>
                <Button className='!w-44 !py-5 flex justify-center' onClick={closeModal} disabled={deletingLoading}>
                    <RegularText>Назад</RegularText>
                </Button>
            </div>
        </div>
    )
}

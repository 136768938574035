import { useAtomValue, useSetAtom } from 'jotai'
import { openCreateRestGroupAtom } from 'src/atoms/restaraunts/createRestGroupAtom'
import { openDeleteRestGroupDialogAtom } from 'src/atoms/restaraunts/deleteRestGroupAtom'
import { currentRestGroupAtom } from 'src/atoms/restaraunts/restGroupAtom'
import { Button, EButtonVariant, MediumText, SemiBoldText } from 'src/components'
import { RoundedWrapper } from 'src/components/RoundedWrapper'
import { useState } from 'react'

export const RestAddGroup = (): JSX.Element => {
    const onClickAddGroup = useSetAtom(openCreateRestGroupAtom)
    const onClickDeleteGroup = useSetAtom(openDeleteRestGroupDialogAtom)
    const currentRestGroup = useAtomValue(currentRestGroupAtom)

    return (
        <div className='flex items-center min-h-20 pl-3 gap-2 border-b-2 border-solid border-b-primaryColor pl-8 pr-8'>
            <SemiBoldText className='text-lg'>Группы</SemiBoldText>
            <Button onClick={onClickAddGroup} icon='plus' variant={EButtonVariant.PRIMARY} className='ml-auto'>
                <MediumText>Добавить группу</MediumText>
            </Button>
            <RoundedWrapper
                disabled={!Boolean(currentRestGroup?.restgroup_id)}
                onClick={() => onClickDeleteGroup(currentRestGroup?.title)}
                icon='trash'
            />
        </div>
    )
}

import React, { useState, useCallback, useMemo } from 'react'
import { useSetAtom, useAtomValue } from 'jotai'
import moment from 'moment/moment'
import { openCreateAdminAtom } from 'src/atoms/admins/adminModalsAtom'
import { adminsListAtom, getAdminsAtom, refreshAdminsTableAtom } from 'src/atoms/admins/adminsTableAtom'
import { adminsSearchQueryAtom } from 'src/atoms/admins/adminsSearchAtom'
import { AppSelect, Button, EButtonVariant, ESelectVariant, HeaderLayout, RegularText, RoundedWrapper, TextInput } from 'src/components'

import { Excel } from '../../services/Excel'

const THEADS = [
    'Таб.номер',
    'ИНН',
    'Телефон',
    'Фамилия',
    'Имя',
    'Отчество',
    'Пол',
    'Дата рождения',
    'Статус смены',
    'Дата регистрации',
    'Дата увольнения',
    'Комментарий',
]

export const AdminsHeader = (): JSX.Element => {
    const refreshAdmins = useSetAtom(refreshAdminsTableAtom)
    const openCreateAdminModal = useSetAtom(openCreateAdminAtom)
    const setSearchQuery = useSetAtom(adminsSearchQueryAtom)
    const [searchInputValue, setSearchInputValue] = useState('')
    const data = useAtomValue(adminsListAtom)

    const handleSearchChange = useCallback(
        (value: string) => {
            setSearchInputValue(value)
            const timer = setTimeout(() => {
                setSearchQuery(value)
            }, 300)
            return () => clearTimeout(timer)
        },
        [setSearchQuery],
    )

    const onDownloadClick = () => {
        if (!data) return
        const KEYS = [
            'tabNumber',
            'passData.inn',
            'phone',
            'lastName',
            'firstName',
            'middleName',
            'gender',
            'birthday',
            'isWorkingToday',
            'createdAt',
            'fireDate',
            'comment',
        ]

        const FN = (key: string, node: any) => {
            switch (key) {
                case 'isWorkingToday':
                    return node[key] ? 'Работает' : 'Не работает'
            }
            return ''
        }

        Excel.generateFile({
            heads: THEADS,
            keys: KEYS,
            data: data,
            fn: FN,
            fileName: 'admins',
        })
    }

    // const adminOptions = useMemo(() => {
    //   const options = [
    //     { value: 'all', label: 'Все администраторы (кроме уволенных)' },
    //   ];
    //
    //   if (data && data.length > 0) {
    //     if (data.some(admin => admin.isWorkingToday)) {
    //       options.push({ value: 'onShift', label: 'На смене' });
    //     }
    //     if (data.some(admin => !admin.isWorkingToday)) {
    //       options.push({ value: 'dayOff', label: 'Выходной' });
    //     }
    //     if (data.some(admin => admin.fireDate)) {
    //       options.push({ value: 'fired', label: 'Только уволенные' });
    //     }
    //   }
    //
    //   return options;
    // }, [data]);

    return (
        <HeaderLayout title='Администраторы'>
            <div className='flex justify-between w-full'>
                <div className='flex gap-2'>
                    <Button icon='plus' variant={EButtonVariant.PRIMARY} onClick={() => openCreateAdminModal()}>
                        <RegularText>Добавить</RegularText>
                    </Button>
                    <TextInput
                        placeholder='Поиск'
                        rightIcon='search'
                        className='w-72'
                        value={searchInputValue}
                        onChange={e => handleSearchChange(e.target.value)}
                    />
                    <RoundedWrapper icon='refresh' onClick={refreshAdmins} />
                </div>
                <div className='flex gap-2'>
                    {/*<AppSelect*/}
                    {/*  selectVariant={ESelectVariant.PRIMARY}*/}
                    {/*  placeholder="Все администраторы"*/}
                    {/*  options={adminOptions}*/}
                    {/*/>*/}
                    <Button icon='excel' variant={EButtonVariant.GREEN} onClick={onDownloadClick}>
                        <RegularText>Download</RegularText>
                    </Button>
                </div>
            </div>
        </HeaderLayout>
    )
}

import Select, { ActionMeta, OnChangeValue, PropsValue } from 'react-select'
import classNames from 'classnames'
import { Icon } from 'src/assets/icons'
import { FocusEvent, useCallback, useEffect, useState } from 'react'
import { useSetAtom } from 'jotai'

import { selectOpenedAtom } from '../../atoms/global/selectAtom'
import { LokaliApi } from '../../services'

import { ErrorText } from '../ErrorText'

import styles from './styles.module.scss'
import { PRIMARY, SECONDARY } from './config'

export type SelectOption = {
    value: string
    label: string
}

export type AppCitySelectProps = {
    isDisabled?: boolean
    isSearchable?: boolean
    isClearable?: boolean
    isLoading?: boolean
    className?: string
    placeholder?: string
    selectVariant?: ESelectVariant
    onChange?: (newValue: OnChangeValue<SelectOption, false>, actionMeta?: ActionMeta<SelectOption>) => void
    onBlur?: (ev: FocusEvent<Element, Element>) => void
    value?: PropsValue<SelectOption>
    error?: string
    defaultValue?: PropsValue<SelectOption>
    coordinatesForValue?: boolean
}

export enum ESelectVariant {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
    QUARTERY = 'quartery',
}

export const AppCitySelect = (props: AppCitySelectProps): JSX.Element => {
    const {
        isDisabled = false,
        isSearchable = true,
        isClearable,
        isLoading,
        className,
        placeholder = '',
        selectVariant = ESelectVariant.PRIMARY,
        onChange,
        onBlur,
        value,
        error,
        defaultValue,
        coordinatesForValue,
    } = props

    const [inputVal, setInputVal] = useState<string>('')
    const [options, setOptions] = useState<SelectOption[]>([])
    const setIsSelectOpened = useSetAtom(selectOpenedAtom)

    const customStyle = {
        [ESelectVariant.PRIMARY]: PRIMARY,
        [ESelectVariant.SECONDARY]: SECONDARY,
        [ESelectVariant.TERTIARY]: SECONDARY,
        [ESelectVariant.QUARTERY]: PRIMARY,
    }[selectVariant]

    const handleFocus = () => {
        setIsSelectOpened(true)
    }

    const handleBlur = (ev: FocusEvent<Element, Element>) => {
        setIsSelectOpened(false)

        if (onBlur) {
            onBlur(ev)
        }
    }

    const handleChange = (newValue: OnChangeValue<SelectOption, false>, actionMeta?: ActionMeta<SelectOption>) => {
        // setIsSelectOpened(false);
        if (onChange) {
            onChange(newValue, actionMeta)
        }
    }

    const fetchCities = useCallback(async (val: string) => {
        if (!val.length) {
            return
        }
        const cities = await LokaliApi.getCities({ query: val })

        if (cities?.length) {
            const newOptions = cities.map(item => ({
                value: coordinatesForValue ? item.coordinates.join(',') : item.place_name,
                label: item.place_name.split(',')[0],
            }))
            setOptions(newOptions)
        }
    }, [])

    useEffect(() => {
        const timer = setTimeout(() => {
            fetchCities(inputVal).then()
        }, 700)

        return () => clearTimeout(timer)
    }, [inputVal])

    return (
        <div className='flex flex-col'>
            <div className='flex gap-2' style={{ alignItems: 'center' }}>
                <Select
                    defaultValue={defaultValue}
                    className={classNames(styles.select, className, {
                        [styles.primary]: selectVariant === ESelectVariant.PRIMARY,
                        [styles.secondary]: selectVariant === ESelectVariant.SECONDARY,
                        [styles.tertiary]: selectVariant === ESelectVariant.TERTIARY,
                        [styles.quartery]: selectVariant === ESelectVariant.QUARTERY,
                    })}
                    classNamePrefix='select'
                    onChange={handleChange}
                    isDisabled={isDisabled}
                    isLoading={isLoading}
                    isClearable={isClearable}
                    isSearchable={isSearchable}
                    styles={customStyle}
                    value={value}
                    placeholder={placeholder}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    onInputChange={setInputVal}
                    components={{
                        IndicatorSeparator: () => null,
                        ClearIndicator: props => {
                            const { children = <Icon type='cross' height='20' width='20' />, innerProps } = props

                            return (
                                <div {...innerProps} style={{ marginRight: '14px' }}>
                                    {children}
                                </div>
                            )
                        },
                    }}
                    name='color'
                    options={options}
                    noOptionsMessage={() => 'Не найдено'}
                />
            </div>
            {<ErrorText error={error} />}
        </div>
    )
}

import { useAtomValue, useSetAtom } from 'jotai'
import React, { useState } from 'react'
import { allCourierGroupsAtom } from 'src/atoms/allCouriers/allCourierGroupsAtom'
import { reAssignCourierGroupAtom, selectedCourierAtom } from 'src/atoms/allCouriers/allCouriersAtom'
import { setCloseSubModalAtom } from 'src/atoms/global/modalAtom'
import { Button, Divider, EButtonVariant, RadioButton, RegularText } from 'src/components'

export const ModalMoveCourierGroup = (): JSX.Element => {
    const closeSubModal = useSetAtom(setCloseSubModalAtom)
    const groups = useAtomValue(allCourierGroupsAtom)
    const currentCourier = useAtomValue(selectedCourierAtom)
    const reAssign = useSetAtom(reAssignCourierGroupAtom)

    const [selectedGroup, setSelectedGroup] = useState<string | undefined>()

    const onClickMove = () => {
        const courier_id = currentCourier?.item.courier_id

        if (courier_id && selectedGroup) {
            reAssign({
                courier_id,
                group_id: selectedGroup,
            })
        }
    }

    return (
        <div>
            <div className='flex flex-col p-4'>
                {groups.data?.map(group => (
                    <tr key={group?.item.group_id}>
                        <div className='flex gap-2'>
                            <RadioButton
                                name='group'
                                checked={selectedGroup === group?.item.group_id}
                                onChange={() => setSelectedGroup(group?.item.group_id)}
                            />
                            <RegularText>{group.item.title}</RegularText>
                        </div>
                    </tr>
                ))}
            </div>
            <Divider />
            <div className='flex gap-2 mt-6 items-center justify-center pb-4'>
                <Button onClick={closeSubModal}>
                    <RegularText>Отменить</RegularText>
                </Button>
                <Button disabled={!selectedGroup} onClick={onClickMove} variant={EButtonVariant.PRIMARY}>
                    <RegularText>Переместить</RegularText>
                </Button>
            </div>
        </div>
    )
}

const PATHNAMES = new Map<string, string>([['couriers/all-couriers', 'Курьеры']])

export class PathHelper {
    static generatePath(arg: string[]): string {
        return arg.reduce((acc, current) => acc + '/' + current, '')
    }

    static mapCurrentRoute(arg: string): string {
        const sliced = arg.slice(1)
        return PATHNAMES.get(sliced) || '###'
    }

    static getSearchParam(key: string): string | null {
        const searchParams = new URLSearchParams(window.location.search)
        const param = searchParams.get(key)
        return param
    }

    static setSearchParam<T>(key: string, value: T): void {
        const url = new URL(window.location.href)
        url.searchParams.set(key, String(value))
        window.history.pushState({}, '', url)
    }
}

import {
    AppImage,
    Button,
    DateInput,
    EButtonVariant,
    EMediumItalicVariant,
    ETextInputVariant,
    ImageInput,
    InputWrapper,
    MediumItalic,
    MediumText,
    SemiBoldText,
    TextArea,
    TextInput,
} from 'src/components'
import { Assets } from 'src/utils'
import { Controller, useFormContext } from 'react-hook-form'
import { createAdminPhotoAtom } from 'src/atoms/admins/adminCreateAtom'
import { useAtom } from 'jotai'
import React from 'react'

import styles from './styles.module.scss'
import { AdminCreateData } from './schema'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const ModalCreateAdminPass = ({ fileError, onFileChange }): JSX.Element => {
    const {
        control,
        formState: { errors },
        reset,
    } = useFormContext<AdminCreateData>()
    const [file, setFile] = useAtom(createAdminPhotoAtom)

    React.useEffect(() => {
        const subscription = control._subjects.state.subscribe({
            next: formState => {
                if (formState.isSubmitSuccessful) {
                    reset({})
                }
            },
        })

        return () => subscription.unsubscribe()
    }, [reset, control])

    return (
        <div className='flex my-12 justify-evenly'>
            <div className='left flex flex-col gap-3'>
                <MediumItalic className='flex justify-center'>~Паспортные данные~</MediumItalic>
                <ul className='flex flex-col gap-3'>
                    <li>
                        <InputWrapper name='Серия'>
                            <Controller
                                control={control}
                                name='passSeries'
                                render={props => <TextInput placeholder='Серия' {...props.field} error={errors.passSeries} />}
                            />
                        </InputWrapper>
                    </li>
                    <li>
                        <InputWrapper name='Номер'>
                            <Controller
                                control={control}
                                name='passNum'
                                render={props => <TextInput placeholder='Номер' {...props.field} error={errors?.passNum} />}
                            />
                        </InputWrapper>
                    </li>
                    <li className='flex justify-end'>
                        <Controller
                            control={control}
                            name='passIssuedBy'
                            render={({ field }) => (
                                <TextArea placeholder='Кем выдан' {...field} value={field.value || ''} error={errors.passIssuedBy} />
                            )}
                        />
                    </li>
                    <li>
                        <div className='flex justify-end gap-3'>
                            <div>
                                <SemiBoldText className='text-xs'>Дата выдачи</SemiBoldText>
                                <Controller
                                    control={control}
                                    name='passIssuedWhen'
                                    render={props => <DateInput {...props.field} error={errors.passIssuedWhen} />}
                                />
                            </div>
                            <div className='flex items-end'>-</div>
                            <div>
                                <SemiBoldText className='text-xs'>Действителен до</SemiBoldText>
                                <Controller
                                    control={control}
                                    name='passActualTo'
                                    render={props => <DateInput {...props.field} error={errors.passActualTo} />}
                                />
                            </div>
                        </div>
                    </li>
                    <li>
                        <InputWrapper name='СНИЛС'>
                            <Controller
                                control={control}
                                name='snils'
                                render={props => <TextInput placeholder='СНИЛС' {...props.field} error={errors.snils} />}
                            />
                        </InputWrapper>
                    </li>
                    <li>
                        <InputWrapper name='ИНН'>
                            <Controller
                                control={control}
                                name='inn'
                                render={props => <TextInput placeholder='ИНН' {...props.field} error={errors.inn} />}
                            />
                        </InputWrapper>
                    </li>
                    <li>
                        <div className='flex justify-between'>
                            <div className='flex flex-col gap-3'>
                                <InputWrapper name='Город'>
                                    <Controller
                                        control={control}
                                        name='city'
                                        render={props => (
                                            <TextInput
                                                placeholder='Город'
                                                {...props.field}
                                                error={errors.city}
                                                className='!w-40'
                                                variant={ETextInputVariant.PRIMARY}
                                            />
                                        )}
                                    />
                                </InputWrapper>

                                <InputWrapper name='Улица'>
                                    <Controller
                                        control={control}
                                        name='street'
                                        render={props => (
                                            <TextInput
                                                placeholder='Улица'
                                                {...props.field}
                                                error={errors.street}
                                                className='!w-40'
                                                variant={ETextInputVariant.PRIMARY}
                                            />
                                        )}
                                    />
                                </InputWrapper>
                            </div>
                            <div className='flex flex-col gap-3'>
                                <InputWrapper name='Дом'>
                                    <Controller
                                        control={control}
                                        name='house'
                                        render={props => (
                                            <TextInput
                                                placeholder='Дом'
                                                {...props.field}
                                                error={errors.house}
                                                className='!w-40'
                                                variant={ETextInputVariant.PRIMARY}
                                            />
                                        )}
                                    />
                                </InputWrapper>

                                <InputWrapper name='Корпус'>
                                    <Controller
                                        control={control}
                                        name='corpus'
                                        render={props => (
                                            <TextInput
                                                placeholder='Корпус'
                                                {...props.field}
                                                error={errors.corpus}
                                                className='!w-40'
                                                variant={ETextInputVariant.PRIMARY}
                                            />
                                        )}
                                    />
                                </InputWrapper>
                                <InputWrapper name='Квартира'>
                                    <Controller
                                        control={control}
                                        name='apartment'
                                        render={props => (
                                            <TextInput
                                                placeholder='Квартира'
                                                {...props.field}
                                                error={errors.apartment}
                                                className='!w-40'
                                                variant={ETextInputVariant.PRIMARY}
                                            />
                                        )}
                                    />
                                </InputWrapper>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div className='right flex flex-col gap-3'>
                <MediumItalic className='flex justify-center'>~Фото админа~</MediumItalic>
                <div className='flex justify-center items-center'>
                    <div className={styles.photoContainer}>
                        <AppImage className={styles.photo} src={!!file ? URL.createObjectURL(file as Blob) : Assets.imagePlaceholder} />
                        {fileError && <p style={{ color: 'red' }}>{fileError}</p>}
                    </div>
                </div>

                <div className='flex justify-center gap-2 my-5'>
                    <Button icon='minus' onClick={() => setFile(null)} variant={EButtonVariant.RED}>
                        <MediumText>Удалить</MediumText>
                    </Button>
                    <ImageInput
                        onChange={e => {
                            if (!e.target.files) {
                                return
                            }
                            setFile(e.target.files[0])
                        }}
                    >
                        <Button icon='download' variant={EButtonVariant.PRIMARY} onChange={onFileChange}>
                            <MediumText>Загрузить</MediumText>
                        </Button>
                    </ImageInput>
                </div>
                <div>
                    <MediumItalic variant={EMediumItalicVariant.SECONDARY} className='!text-3xl'>
                        Загрузите фото админа
                    </MediumItalic>
                </div>
            </div>
        </div>
    )
}

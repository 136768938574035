import React, { useEffect } from 'react'
import 'dayjs/locale/ru'
import './app.styles.scss'
import { Outlet } from 'react-router-dom'
import { Provider } from 'jotai'
import dayjs from 'dayjs'
import customParser from 'dayjs/plugin/customParseFormat'

import { LeftNavBar } from './features/LeftNavBar/LeftNavBar'
import { SubModal, Dialog, AppModal } from './components'
import { store } from './atoms/store'
import { useWindowSize } from './hooks/useWindowResize'

dayjs.locale('ru')
dayjs.extend(customParser)

// document.body.style.overflowY = 'auto';

function zoom(scale: number) {
    const container = document.getElementById('zoom-container')
    const content = document.getElementById('zoom-content')

    if (!container || !content) return
    content.style.transform = `scale(${scale})`
    content.style.height = `${100 / scale}vh`
}

const App = (): JSX.Element => {
    const [width] = useWindowSize()

    useEffect(() => {
        zoom(Math.min(1, width / 2018))
    }, [width])

    return (
        <div id='zoom-container'>
            <div id='zoom-content'>
                <Provider store={store}>
                    <div className='app-container'>
                        <LeftNavBar />
                        <div className='outlet-container'>
                            <Outlet />
                        </div>
                    </div>
                    <AppModal />
                    <SubModal />
                    <Dialog />
                </Provider>
            </div>
        </div>
    )
}

export { App }

import { PropsWithChildren } from 'react'
import { SpanProps } from 'src/types/html'
import classNames from 'classnames'

import styles from './styles.module.scss'

export enum EMediumItalicVariant {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
}

type MediumItalicProps = PropsWithChildren<SpanProps> & {
    variant?: EMediumItalicVariant
}

export const MediumItalic = (props: MediumItalicProps): JSX.Element => {
    const { variant = EMediumItalicVariant.PRIMARY, children, className, ...rest } = props
    return (
        <span
            {...rest}
            className={classNames(
                styles.text,
                {
                    [styles.primary]: variant === EMediumItalicVariant.PRIMARY,
                    [styles.secondary]: variant === EMediumItalicVariant.SECONDARY,
                },
                className,
            )}
        >
            {children}
        </span>
    )
}

import { useSetAtom } from 'jotai'
import { Button, EButtonVariant, MediumText, RegularText } from 'src/components'

import { setNotSavedDialogAtom } from '../../atoms/global/dialogAtom'
import { FC } from 'react'

export const ModalEditRestInfoFooter: FC<{ loading: boolean }> = ({ loading }): JSX.Element => {
    const closeModal = useSetAtom(setNotSavedDialogAtom)

    return (
        <div className='flex px-5 py-3'>
            <div className='flex flex-1 justify-end gap-3'>
                {/*<Button icon="refresh" variant={EButtonVariant.SECONDARY}>
          <RegularText>
            Обновить
          </RegularText>
        </Button>*/}
                <Button loader={loading} type='submit' icon='save' variant={EButtonVariant.PRIMARY}>
                    <MediumText>Сохранить</MediumText>
                </Button>
                <Button onClick={closeModal} variant={EButtonVariant.SECONDARY}>
                    <RegularText>Выйти</RegularText>
                </Button>
            </div>
        </div>
    )
}

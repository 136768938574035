import { DefaultEventsMap } from '@socket.io/component-emitter'
import { Socket, io } from 'socket.io-client'

class SocketServices {
    socket: Socket<DefaultEventsMap, DefaultEventsMap>
    fn: (data: any) => void

    constructor(fn: (data: any) => void) {
        console.log('Socket connecting...')
        this.socket = io('https://localockarmaps.ru/get-coordinates')

        this.socket.on('connect', () => {
            console.log('Socket connected!')
            this.socket.emit('get-cords', { id: 'admin' + Math.random() })
        })

        this.fn = fn
        this.socket.on('coords', fn)
    }

    disconnect(): void {
        console.log('Socket disconnecting...')
        this.socket.off('coords', this.fn)
        this.socket.disconnect()
        console.log('Socket disconnected!')
    }
}

export default SocketServices

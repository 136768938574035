import { useAtomValue, useSetAtom } from 'jotai'
import { assignOrderAtom } from 'src/atoms/allOrders/assignOrderAtom'
import { currentCourierAtom } from 'src/atoms/allOrders/couriersListAtom'
import { closeDialogAtom } from 'src/atoms/global/dialogAtom'
import { Button, EButtonVariant, MediumText, Nbsp, RegularText } from 'src/components'

import { currentOrderAtom } from '../../atoms/allOrders/ordersAtom'
import { useState } from 'react'

const AssignOrderDialog = (): JSX.Element => {
    const [loading, setLoading] = useState(false)
    const closeDialog = useSetAtom(closeDialogAtom)
    const currentCourier = useAtomValue(currentCourierAtom)
    const currentOrder = useAtomValue(currentOrderAtom)
    const assignOrder = useSetAtom(assignOrderAtom)

    const assingClick = async () => {
        setLoading(true)
        await assignOrder()
        setLoading(false)
    }

    return (
        <div className='flex flex-col items-center justify-center h-full p-8'>
            <div className='flex-1 flex justify-center items-center'>
                <div>
                    <RegularText>
                        Вы действительно хотите назначить заказ <Nbsp />
                    </RegularText>
                    <MediumText>
                        “№{currentOrder?.orderNumber}” <Nbsp />
                    </MediumText>
                    <RegularText>
                        курьеру <Nbsp />
                    </RegularText>
                    <MediumText>
                        {`${currentCourier?.item.firstName} ${currentCourier?.item.surname}`} <Nbsp />
                    </MediumText>
                    <RegularText>?</RegularText>
                </div>
            </div>
            <div className='flex gap-2'>
                <Button className='!w-44 flex justify-center !py-5' onClick={closeDialog}>
                    <RegularText>Отмена</RegularText>
                </Button>
                <Button loader={loading} variant={EButtonVariant.PRIMARY} className='!w-44 !py-5 flex justify-center' onClick={assingClick}>
                    <RegularText>Добавить</RegularText>
                </Button>
            </div>
        </div>
    )
}

export { AssignOrderDialog }

import { SuspenseWithSpinner, Tab, Tabs } from 'src/components'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useSetAtom } from 'jotai'
import { createClientDBAtom } from 'src/atoms/clientDb/createClientDBAtom'
import { DateUtils } from 'src/utils'

import { AddClientFormData } from './types'
import { AddClientSchema } from './schema'
import { ModalClientInfo, ModalDBClientInfoFooter } from './ModalClientInfo'
import { useState } from 'react'

export const ModalClientDBAddClient = (): JSX.Element => {
    const createClient = useSetAtom(createClientDBAtom)
    const [loader, setLoader] = useState<boolean>(false)
    const methods = useForm<AddClientFormData>({
        resolver: zodResolver(AddClientSchema),
        reValidateMode: 'onBlur',
    })

    const onSubmit = async (data: AddClientFormData) => {
        setLoader(true)
        const {
            firstName,
            middleName,
            lastName,
            clientgroup_id,
            address,
            birthday,
            whatsapp,
            telegram,
            email,
            phoneNumber,
            comment,
            gender,
            paymentInfo,
        } = data

        const body = {
            clientgroup_id: clientgroup_id?.value || '',

            firstName,
            middleName,
            lastName,
            gender: gender?.value,

            phoneNumber,
            email,
            whatsapp,
            telegram,
            paymentInfo,

            birthday: birthday ? DateUtils.parseDateInput(birthday) : undefined,

            address,
            comment,
        }

        await createClient(body)
        setLoader(false)
    }

    return (
        <form
            onSubmit={methods.handleSubmit(onSubmit, er => {
                console.log(er)
            })}>
            <FormProvider {...methods}>
                <SuspenseWithSpinner>
                    <Tabs
                        tabs={[
                            {
                                tabOption: {
                                    tabName: 'Инфо',
                                },
                                child: <ModalClientInfo />,
                                footerChild: <ModalDBClientInfoFooter />,
                            },
                        ]}
                    />
                </SuspenseWithSpinner>
            </FormProvider>
        </form>
    )
}

import { Button, EButtonVariant, GridTable, GridTableHead, GridTableItem, HeaderLayout, MediumText } from 'src/components'
import React, { useCallback, useState, useEffect, useMemo } from 'react'
import moment from 'moment/moment'
import { useAtomValue, useSetAtom } from 'jotai'

import { CouriersAccounting, FinancesAccounting, FinancesCourierAccounting, LokaliApi } from '../../services'
import { datePeriodOptions, EDateRangeOptions } from '../../utils'
import { setCouriersAccountingModalAtom } from '../../atoms/allCouriers/couriersAccountingAtom'
import { DateFilterAuto } from '../../components/DateFilterAuto'
import { Excel } from '../../services/Excel'
import { DateFilterSelect } from '../../components/DateFilterSelect'
import { modalAtom } from '../../atoms/global/modalAtom'

const THEADS = [
    'Таб. номер',
    'ФИО Курьера',
    'Количество заказов',
    'Сумма выплаты',
    'ЗП',
    'За вычетом ЗП',
    'Дата выплаты',
    'Статус инкассации',
]

export const AccountingCouriersPage = (): JSX.Element => {
    const setCouriersAccountingModal = useSetAtom(setCouriersAccountingModalAtom)
    const modal = useAtomValue(modalAtom)

    const [total, setTotal] = useState<{
        orderCountCollected: number
        totalPaymentSnapshotCollected: number
        paidCollected: number
        totalPaymentCollected: number
    }>({
        orderCountCollected: 0,
        paidCollected: 0,
        totalPaymentCollected: 0,
        totalPaymentSnapshotCollected: 0,
    })

    const [couriersAccounting, setCouriersAccounting] = useState<FinancesCourierAccounting[]>([])
    const [dateFrom, setDateFrom] = useState<string>(moment().startOf('week').format('YYYY-MM-DD'))
    const [dateTo, setDateTo] = useState<string>(moment().endOf('week').format('YYYY-MM-DD'))
    const [selectedCourier, setSelectedCourier] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const getCouriersAccounting = useCallback(async () => {
        setIsLoading(true)
        try {
            const params = {
                dateFrom,
                dateTo,
            }

            const data = await LokaliApi.getCouriersAccounting(params)
            setCouriersAccounting(data.finances)
            setTotal(data.total)
        } catch (e) {
            console.warn('Error fetching couriers accounting:', e)
        } finally {
            setIsLoading(false)
        }
    }, [dateFrom, dateTo, modal.isOpen])

    useEffect(() => {
        const timer = setTimeout(() => {
            getCouriersAccounting().then()
        }, 300)

        return () => clearTimeout(timer)
    }, [dateFrom, dateTo, modal.isOpen])

    const handleClick = useCallback(
        (id: string) => () => {
            setSelectedCourier(id)
        },
        [],
    )
    const onRefreshClick = () => getCouriersAccounting()

    const handleDoubleClick = useCallback(
        (accounting: FinancesCourierAccounting) => () => {
            if (selectedCourier !== accounting.courierfinance_id) {
                setSelectedCourier(accounting.courierfinance_id)
            }
            setCouriersAccountingModal({
                id: accounting.courierfinance_id,
                setCouriersAccounting,
                courierAccounting: accounting,
            })
        },
        [selectedCourier, setCouriersAccountingModal],
    )

    const formatCurrency = (value: number): string => new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(value)

    const formatDate = (date: string): string => (moment(date).isValid() ? moment(date).format('DD.MM.YYYY') : 'Invalid date')

    const onDownloadClick = useCallback(() => {
        if (!couriersAccounting.length) return
        const KEYS = ['courierTabNumber', 'courierName', 'orderCount', 'totalPayment', 'date', 'was_paid']

        const FN = (key: string, node: any) => {
            if (key === 'was_paid') {
                return node[key] ? 'Проведена' : 'Не проведена'
            }
            if (key === 'date') {
                return formatDate(node[key])
            }
            if (key === 'orderCount') {
                return node[key].toString() || '0'
            }
            if (key === 'totalPayment') {
                return formatCurrency(node[key])
            }
            return ''
        }

        Excel.generateFile({
            heads: THEADS,
            keys: KEYS,
            data: couriersAccounting,
            fn: FN,
            fileName: 'couriers_accounting',
            total: {
                'Дата выплаты': '',
                ЗП: total.paidCollected,
                'За вычетом ЗП': total.totalPaymentCollected,
                'Количество заказов': total.orderCountCollected,
                'Статус инкассации': '',
                'Сумма выплаты': total.totalPaymentSnapshotCollected,
                'Таб. номер': 'Итого',
                'ФИО Курьера': '',
            },
        })
    }, [couriersAccounting])

    return (
        <div className='flex flex-col'>
            <HeaderLayout title='Бухгалтерия / Курьеры' className='gap-5 items-center' />
            <div className='p-8'>
                <DateFilterSelect
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    setDateFrom={setDateFrom}
                    setDateTo={setDateTo}
                    onClickRefresh={onRefreshClick}
                    selectProps={{ options: datePeriodOptions }}
                    rightElement={
                        <Button
                            variant={EButtonVariant.GREEN}
                            icon='excel'
                            onClick={onDownloadClick}
                            disabled={isLoading || !couriersAccounting.length}>
                            <MediumText>Download</MediumText>
                        </Button>
                    }
                />
            </div>
            <div className='py-4 px-0'>
                <GridTable
                    theads={THEADS.map(head => (
                        <GridTableHead key={head} title={head} />
                    ))}>
                    {couriersAccounting
                        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
                        .map(accountingGroup => (
                            <tr key={accountingGroup.courierfinance_id}>
                                <GridTableItem
                                    onClick={handleClick(accountingGroup.courierfinance_id)}
                                    onDoubleClick={handleDoubleClick(accountingGroup)}
                                    isActive={selectedCourier === accountingGroup.courierfinance_id}
                                    child={accountingGroup.courierTabNumber || '-'}
                                />
                                <GridTableItem
                                    onClick={handleClick(accountingGroup.courierfinance_id)}
                                    onDoubleClick={handleDoubleClick(accountingGroup)}
                                    isActive={selectedCourier === accountingGroup.courierfinance_id}
                                    child={accountingGroup.courierName}
                                />
                                <GridTableItem
                                    onClick={handleClick(accountingGroup.courierfinance_id)}
                                    onDoubleClick={handleDoubleClick(accountingGroup)}
                                    isActive={selectedCourier === accountingGroup.courierfinance_id}
                                    child={accountingGroup.orderCount}
                                />
                                <GridTableItem
                                    onClick={handleClick(accountingGroup.courierfinance_id)}
                                    onDoubleClick={handleDoubleClick(accountingGroup)}
                                    isActive={selectedCourier === accountingGroup.courierfinance_id}
                                    child={formatCurrency(accountingGroup.totalPaymentSnapshot)}
                                />
                                <GridTableItem
                                    onClick={handleClick(accountingGroup.courierfinance_id)}
                                    onDoubleClick={handleDoubleClick(accountingGroup)}
                                    isActive={selectedCourier === accountingGroup.courierfinance_id}
                                    child={formatCurrency(accountingGroup.paid)}
                                />
                                <GridTableItem
                                    onClick={handleClick(accountingGroup.courierfinance_id)}
                                    onDoubleClick={handleDoubleClick(accountingGroup)}
                                    isActive={selectedCourier === accountingGroup.courierfinance_id}
                                    child={formatCurrency(accountingGroup.totalPayment)}
                                />
                                <GridTableItem
                                    onClick={handleClick(accountingGroup.courierfinance_id)}
                                    onDoubleClick={handleDoubleClick(accountingGroup)}
                                    isActive={selectedCourier === accountingGroup.courierfinance_id}
                                    child={formatDate(accountingGroup.date)}
                                />
                                <GridTableItem
                                    onClick={handleClick(accountingGroup.courierfinance_id)}
                                    onDoubleClick={handleDoubleClick(accountingGroup)}
                                    isActive={selectedCourier === accountingGroup.courierfinance_id}
                                    child={accountingGroup.was_paid ? 'Проведена' : 'Не проведена'}
                                />
                            </tr>
                        ))}
                    <GridTableItem child='Итого' />
                    <GridTableItem child={''} />
                    <GridTableItem child={total?.orderCountCollected} />
                    <GridTableItem child={formatCurrency(total.totalPaymentSnapshotCollected)} />
                    <GridTableItem child={formatCurrency(total.paidCollected)} />
                    <GridTableItem child={formatCurrency(total.totalPaymentCollected)} />
                    <GridTableItem child={''} />
                    <GridTableItem child={''} />
                </GridTable>
            </div>
        </div>
    )
}

import { atom } from 'jotai'
import { ReactNode } from 'react'
import { NotSavedDialog } from 'src/features/Dialogs/NotSavedDialog'

import { setCloseAllModals } from './modalAtom'

type DialogAtomState = {
    isOpen: boolean
    children?: ReactNode
    showCloseIcon?: boolean
}

const dialogStateAtom = atom<DialogAtomState>({
    isOpen: false,
})

const closeDialogAtom = atom(null, (_, set) => {
    set(dialogStateAtom, {
        isOpen: false,
        children: null,
    })
})

const setNotSavedDialogAtom = atom(null, (_, set) => {
    set(dialogStateAtom, {
        isOpen: true,
        children: <NotSavedDialog onAccept={() => set(setCloseAllModals)} onAbort={() => set(closeDialogAtom)} />,
    })
})

export { dialogStateAtom, closeDialogAtom, setNotSavedDialogAtom }

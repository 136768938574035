import { useSetAtom } from 'jotai'
import { setNotSavedDialogAtom } from 'src/atoms/global/dialogAtom'
import { Button, EButtonVariant, MediumText, RegularText } from 'src/components'
import { FC } from 'react'

export const ModalEditAdminPaymentFooter: FC<{ loading: boolean }> = ({ loading }): JSX.Element => {
    const notSavedExit = useSetAtom(setNotSavedDialogAtom)

    return (
        <div className='flex justify-end px-5 py-3'>
            <div className='flex flex-1 justify-end gap-3'>
                <Button type='submit' icon='save' variant={EButtonVariant.BLUE} loader={loading}>
                    <MediumText>Сохранить</MediumText>
                </Button>

                <Button variant={EButtonVariant.SECONDARY} onClick={notSavedExit}>
                    <RegularText>Отмена</RegularText>
                </Button>
            </div>
        </div>
    )
}

import React, { useEffect, useState } from 'react'
import { useAtom, useAtomValue } from 'jotai'
import { Button, GridTable, GridTableItem, MediumItalic, RegularText, TextInput } from 'src/components'

import { setAdminPaymentModalAtom } from '../../atoms/admins/adminEditModalAtom'
import { currentAdminAtom } from '../../atoms/admins/adminsTableAtom'
import { getAdminPaymentsAtom, adminPaymentsAtom } from '../../atoms/admins/adminPaymentAtom'

const THEADS = ['Дата с', 'Дата по', 'Должность', 'Оклад руб.', 'Тариф руб/час', 'График']

export const ModalEditAdminPayment = (): JSX.Element => {
    const [, setEditSalaryRate] = useAtom(setAdminPaymentModalAtom)
    const [, getAdminPayments] = useAtom(getAdminPaymentsAtom)
    const adminPaymentsData = useAtomValue(adminPaymentsAtom)
    const currentAdmin = useAtomValue(currentAdminAtom)
    const [avance, setAvance] = useState<number>(0)

    useEffect(() => {
        if (currentAdmin?.admin_id) {
            getAdminPayments().then()
        }
    }, [currentAdmin, getAdminPayments])

    useEffect(() => {
        if (adminPaymentsData) {
            setAvance(adminPaymentsData.avance)
        }
    }, [adminPaymentsData])

    return (
        <div className='mt-7 ml-3'>
            <div className='flex gap-4'>
                <Button icon='cash' className='w-96' onClick={() => setEditSalaryRate()}>
                    <RegularText className='!text-sm'>Редактировать ставку оплаты труда</RegularText>
                </Button>
                <div className='flex gap-2 items-center'>
                    <RegularText>Аванс (руб)</RegularText>
                    <TextInput className='w-24' placeholder='0.00' value={avance.toString()} />
                </div>
            </div>
            <div className='mt-6'>
                <MediumItalic className='ml-3'>~История изменений~</MediumItalic>
            </div>
            <div>
                <GridTable
                    theads={THEADS.map(head => (
                        <span className='text-nowrap whitespace-nowrap p-4 bg-aqua-400' key={head}>
                            {head}
                        </span>
                    ))}>
                    {!!adminPaymentsData?.adminpayment?.length &&
                        adminPaymentsData.adminpayment.map((payment, index) => (
                            <tr key={index}>
                                <GridTableItem child={payment.dateFrom ? new Date(payment.dateFrom).toLocaleDateString() : '-'} />
                                <GridTableItem child={payment.dateTo ? new Date(payment.dateTo).toLocaleDateString() : '-'} />
                                <GridTableItem child='Администратор' />
                                <GridTableItem child={payment.salary} />
                                <GridTableItem child={payment.rate} />
                                <GridTableItem child={payment.schedule === 'salary' ? 'Оклад' : 'Тариф'} />
                            </tr>
                        ))}
                </GridTable>
            </div>
        </div>
    )
}

import { atom } from 'jotai'
import { LokaliApi, Order } from 'src/services'
import { HttpStatusCode } from 'src/types'

import { NotifyRestaurantSuccess } from '../../features/Orders/NotifyRestaurantSuccess'

import { dialogStateAtom } from '../global/dialogAtom'

const notifyRestaurantAtom = atom(null, async (_, set, update: Order) => {
    try {
        const response = await LokaliApi.notifyRestaurant({
            rest_id: update.restId,
        })

        if (response === HttpStatusCode.OK) {
            set(openNotifySuccessDialogAtom, { success: true })
        }
    } catch (e) {
        set(openNotifySuccessDialogAtom, { success: false })
    }
})

const openNotifySuccessDialogAtom = atom(null, (_, set, update: { success: boolean }) => {
    set(dialogStateAtom, {
        isOpen: true,
        children: <NotifyRestaurantSuccess success={update.success} />,
    })
})

export { notifyRestaurantAtom }

import { AppSelect, HeaderLayout, SelectOption } from 'src/components'
import { ESelectVariant } from 'src/components/AppSelect'
import { PropsValue } from 'react-select'
import { useSetAtom } from 'jotai/index'
import classNames from 'classnames'

import { AppCitySelect } from '../../components/AppCitySelect'
import { updateMapCouriersFiltersAtom } from '../../atoms/allCouriers/mapCouriersAtom'

import styles from './styles.module.scss'

const heatMapOptions = [
    { value: 'off', label: 'Тепловая карта зоны (Выкл)' },
    { value: 'on', label: 'Тепловая карта зоны (Вкл)' },
]

export const CourierMapHeader = (): JSX.Element => {
    const updateMapCouriersFilters = useSetAtom(updateMapCouriersFiltersAtom)

    const handleShowHeatMap = (val: PropsValue<SelectOption>) => {
        if ((val as SelectOption)?.value === 'on') {
            updateMapCouriersFilters({ showHeatMap: true })
        } else {
            updateMapCouriersFilters({ showHeatMap: false })
        }
    }

    return (
        <HeaderLayout title='Курьеры' className={'flex justify-between items-center'} wrapperClassName={styles.shortHeader}>
            <div className='flex gap-2'>
                <AppSelect
                    options={heatMapOptions}
                    selectVariant={ESelectVariant.SECONDARY}
                    placeholder='Тепловая карта зоны (Выкл)'
                    defaultValue={heatMapOptions[0]}
                    onChange={handleShowHeatMap}
                />
            </div>
        </HeaderLayout>
    )
}

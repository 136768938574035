import { atom } from 'jotai'
import { CreateOrderRequest, LokaliApi } from 'src/services'
import { HttpStatusCode } from 'src/types'

import { setCloseAllModals, setCloseSubModalAtom } from '../global/modalAtom'

import { getOrdersAtom } from './ordersAtom'

const createOrderLoadingAtom = atom(false)

const createOrderAtom = atom(null, async (_, set, data: CreateOrderRequest) => {
    try {
        set(createOrderLoadingAtom, true)
        const response = await LokaliApi.createOrder(data)

        if (response === HttpStatusCode.OK) {
            set(setCloseSubModalAtom)
            set(getOrdersAtom)
            set(setCloseAllModals)
        }
    } catch (e) {
        set(createOrderLoadingAtom, false)
    }
})

export { createOrderAtom, createOrderLoadingAtom }

import { useAtomValue, useSetAtom } from 'jotai'
import { useState } from 'react'
import { allClientGroupsAtom } from 'src/atoms/clientDb/clientGroupsAtom'
import { setCloseSubModalAtom } from 'src/atoms/global/modalAtom'
import { Button, Divider, EButtonVariant, RegularText } from 'src/components'

import { LokaliApi } from '../../services'
import { currentClientAtom, refreshDbClientsAtom } from '../../atoms/clientDb/clientsAtom'

import styles from './styles.module.scss'

type ClientGroupProps = {
    title: string
    checked: boolean
    onClick: () => void
}

const ClientGroup = (props: ClientGroupProps): JSX.Element => (
    <div className='flex gap-2 items-center ml-7 mb-1 pressableSimple align-middle' onClick={props.onClick}>
        <div className={styles.wrapper}>{props.checked && <div className={styles.inner}></div>}</div>
        <RegularText>{props.title}</RegularText>
    </div>
)

export const ChangeClientGroupModal = (): JSX.Element => {
    const currentClient = useAtomValue(currentClientAtom)
    const groups = useAtomValue(allClientGroupsAtom)
    const closeSubModal = useSetAtom(setCloseSubModalAtom)
    const refreshDbClients = useSetAtom(refreshDbClientsAtom)

    const [checkedId, setCheckedId] = useState<string>('')
    const [movingLoading, setMovingLoading] = useState<boolean>(false)

    const onGroupClick = (id: string) => {
        setCheckedId(id)
    }

    const onMoveClick = async () => {
        try {
            if (!checkedId || !currentClient?.clientData.client_id) return

            setMovingLoading(true)
            const data = await LokaliApi.editClientGroup({
                client_id: currentClient.clientData.client_id,
                clientgroup_id: checkedId,
            })

            if (data?.message === 'success') {
                closeSubModal()
                refreshDbClients()
            }
        } catch (e) {
            console.warn(e)
        } finally {
            setMovingLoading(false)
        }
    }

    return (
        <div className='flex flex-col justify-between flex-1'>
            <div className='px-7 flex flex-col gap-2'>
                <div className='h-96 overflow-scroll py-2'>
                    {groups.data?.map(group => (
                        <ClientGroup
                            key={group.clientgroup_id}
                            onClick={() => onGroupClick(group.clientgroup_id)}
                            checked={checkedId === group.clientgroup_id}
                            title={group.title}
                        />
                    ))}
                </div>
            </div>
            <Divider />
            <div className='flex justify-center items-center p-5 gap-4'>
                <Button onClick={closeSubModal} disabled={movingLoading}>
                    <RegularText>Отменить</RegularText>
                </Button>
                <Button disabled={!checkedId || movingLoading} onClick={onMoveClick} variant={EButtonVariant.PRIMARY}>
                    <RegularText>Переместить</RegularText>
                </Button>
            </div>
        </div>
    )
}

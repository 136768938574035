import classNames from 'classnames'

import { ReactComponent as ArrowLeft } from './arrow_left.svg'
import { ReactComponent as User } from './user.svg'
import { ReactComponent as Lock } from './lock.svg'
import { ReactComponent as Document } from './document.svg'
import { ReactComponent as UserShield } from './shield.svg'
import { ReactComponent as Dish } from './dish.svg'
import { ReactComponent as Fura } from './fura.svg'
import { ReactComponent as UserLocked } from './user_locked.svg'
import { ReactComponent as Settings } from './settings.svg'
import { ReactComponent as MenuArrow } from './menu_arrow.svg'
import { ReactComponent as Box } from './box.svg'
import { ReactComponent as BoldArrow } from './bold_arrow.svg'
import { ReactComponent as Plus } from './plus.svg'
import { ReactComponent as Trash } from './trash.svg'
import { ReactComponent as SmallArrow } from './small_arrow.svg'
import { ReactComponent as Calendar } from './calendar.svg'
import { ReactComponent as Refresh } from './refresh.svg'
import { ReactComponent as Search } from './search.svg'
import { ReactComponent as Move } from './move.svg'
import { ReactComponent as MoveWhite } from './move_white.svg'
import { ReactComponent as Excel } from './excel.svg'
import { ReactComponent as Close } from './close.svg'
import { ReactComponent as Save } from './save.svg'
import { ReactComponent as Minus } from './minus.svg'
import { ReactComponent as Download } from './download.svg'
import { ReactComponent as Cash } from './cash.svg'
import { ReactComponent as Exit } from './exit.svg'
import { ReactComponent as A } from './A.svg'
import { ReactComponent as Android } from './android.svg'
import { ReactComponent as Apple } from './apple.svg'
import { ReactComponent as Copy } from './copy.svg'
import { ReactComponent as SMS } from './sms.svg'
import { ReactComponent as Gift } from './gift.svg'
import { ReactComponent as Edit } from './edit.svg'
import { ReactComponent as Courier } from './courier.svg'
import { ReactComponent as UserStar } from './user_star.svg'
import { ReactComponent as Dishes } from './dishes.svg'
import { ReactComponent as LocationMarker } from './location_marker.svg'
import { ReactComponent as Cross } from './cross.svg'
import { ReactComponent as Cursor } from './cursor.svg'
import { ReactComponent as WhiteGift } from './white_gift.svg'
import { ReactComponent as BuildingMarker } from './building_marker.svg'
import { ReactComponent as CourierMarker } from './courier_marker.svg'
import { ReactComponent as Calc } from './calc.svg'
import { ReactComponent as LogoSvg } from './logo.svg'

export const icons = {
    arrowLeft: ArrowLeft,
    user: User,
    lock: Lock,
    document: Document,
    shield: UserShield,
    dish: Dish,
    fura: Fura,
    box: Box,
    settings: Settings,
    menuArrow: MenuArrow,
    userLocked: UserLocked,
    boldArrow: BoldArrow,
    plus: Plus,
    trash: Trash,
    arrowSm: SmallArrow,
    calendar: Calendar,
    refresh: Refresh,
    search: Search,
    move: Move,
    moveWhite: MoveWhite,
    excel: Excel,
    close: Close,
    save: Save,
    minus: Minus,
    download: Download,
    cash: Cash,
    exit: Exit,
    A: A,
    android: Android,
    apple: Apple,
    copy: Copy,
    gift: Gift,
    sms: SMS,
    edit: Edit,
    courier: Courier,
    userStar: UserStar,
    dishes: Dishes,
    locationMarker: LocationMarker,
    cross: Cross,
    cursor: Cursor,
    whiteGift: WhiteGift,
    buildingMarker: BuildingMarker,
    courierMarker: CourierMarker,
    calc: Calc,
    logo: LogoSvg,
}

export type IconType = keyof typeof icons

export type IconProps = React.SVGProps<SVGSVGElement> & {
    type: IconType
    size?: number
}

export const Icon = (props: IconProps): JSX.Element => {
    const { type, size = 24, ...rest } = props
    const SelectedIcon = icons[type]

    return <SelectedIcon width={size} height={size} className={classNames('icon', rest.className)} {...rest} />
}

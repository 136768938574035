import { atom } from 'jotai'
import { ChangeBonusRequest, LokaliApi } from 'src/services/LocaliRepository'
import { HttpStatusCode } from 'src/types'
import { BonusDialog, BonusDialogProps } from 'src/features/ClientDatabaseModals/BonusDialog'
import { ClientGiveGroupBonusRequest } from 'src/services/LocaliRepository/types/ClientDB'

import { dialogStateAtom } from '../global/dialogAtom'
import { setCloseAllModals } from '../global/modalAtom'

import { dbClientsAtom } from './clientsAtom'

const clientBonusNetworkStateAtom = atom<HttpStatusCode | null>(null)
const clientGroupBonusNetworkStateAtom = atom<HttpStatusCode | null>(null)

const openBonusDialogAtom = atom(null, (_, set, props: BonusDialogProps) => {
    set(dialogStateAtom, {
        isOpen: true,
        children: <BonusDialog {...props} />,
    })
})

const changeBonusAtom = atom(null, async (_, set, update: ChangeBonusRequest) => {
    set(clientBonusNetworkStateAtom, HttpStatusCode.LOADING)

    try {
        const code = await LokaliApi.changeBonus(update)

        if (code === HttpStatusCode.OK) {
            set(clientBonusNetworkStateAtom, HttpStatusCode.OK)
            set(dbClientsAtom)
        }
        set(clientBonusNetworkStateAtom, null)
    } catch (e) {
        set(clientBonusNetworkStateAtom, HttpStatusCode.INTERNAL_SERVER_ERROR)
    }
})

const clientGiveGroupBonusAtom = atom(null, async (_, set, req: ClientGiveGroupBonusRequest) => {
    set(clientGroupBonusNetworkStateAtom, HttpStatusCode.LOADING)

    try {
        const code = await LokaliApi.giveGroupBonus(req)

        if (code === HttpStatusCode.OK) {
            set(clientGroupBonusNetworkStateAtom, HttpStatusCode.OK)
            set(dbClientsAtom)
            set(setCloseAllModals)
        }
        set(clientGroupBonusNetworkStateAtom, HttpStatusCode.I_AM_A_TEAPOT)
    } catch (e) {
        set(clientGroupBonusNetworkStateAtom, HttpStatusCode.INTERNAL_SERVER_ERROR)
    }
})

export { clientBonusNetworkStateAtom, changeBonusAtom, openBonusDialogAtom, clientGiveGroupBonusAtom, clientGroupBonusNetworkStateAtom }

import { atom } from 'jotai'

import GroznyiCoordinates from '../../utils/groznyiCoordinates'

type MapCouriersFilters = {
    showHeatMap?: boolean
    groupId?: string
    busyType?: 'busy' | 'free' | ''
}

type MapCouriersDropDown = {
    value: string
    label: string
    coordinates: [number, number]
}

const freeMapCouriersCountAtom = atom<number>(0)
const workingMapCouriersCountAtom = atom<number>(0)

const mapCouriersFiltersAtom = atom<MapCouriersFilters>({
    showHeatMap: false,
    groupId: '',
    busyType: '',
})

const mapCouriersCityCoordinatesAtom = atom<[number, number]>([GroznyiCoordinates[0], GroznyiCoordinates[1]])

const mapCouriersDropdownAtom = atom<MapCouriersDropDown[]>([])
const mapCouriersSelectedAtom = atom<MapCouriersDropDown | null>(null)

const updateMapCouriersFiltersAtom = atom(null, (get, set, update: MapCouriersFilters) => {
    const filters = get(mapCouriersFiltersAtom)
    const newFilters = { ...filters, ...update }
    set(mapCouriersFiltersAtom, newFilters)
})

export {
    freeMapCouriersCountAtom,
    mapCouriersDropdownAtom,
    mapCouriersSelectedAtom,
    workingMapCouriersCountAtom,
    mapCouriersFiltersAtom,
    updateMapCouriersFiltersAtom,
    mapCouriersCityCoordinatesAtom,
}

import {
    AppSelect,
    Button,
    Checkbox,
    DateInput,
    Divider,
    EButtonVariant,
    InputWrapper,
    MediumText,
    RegularText,
    TimePicker,
} from 'src/components'
import { useAtomValue } from 'jotai'
import React, { useMemo, useState } from 'react'
import moment from 'moment'

import { CreateMassNotificationRequest } from '../../services/LocaliRepository/types/pushNotifications'
import { smsTemplatesAtom } from '../../atoms/clientDb/manageSMSAtom'
import { allClientGroupsAtom } from '../../atoms/clientDb/clientGroupsAtom'
import { DateUtils } from '../../utils'

type Props = {
    onAccept: (data: CreateMassNotificationRequest) => void
    onAbort: () => void
}

const periodicOptions = [
    { value: 'Один раз', label: 'Один раз' },
    { value: 'Ежедневно', label: 'Ежедневно' },
    { value: 'Через день', label: 'Через день' },
    { value: 'Раз в 3 дня', label: 'Раз в 3 дня' },
    { value: 'Раз в неделю', label: 'Раз в неделю' },
    { value: 'Раз в 2 недели', label: 'Раз в 2 недели' },
    { value: 'Раз в месяц', label: 'Раз в месяц' },
    { value: 'Раз в 2 месяца', label: 'Раз в 2 месяца' },
    { value: 'Раз в квартал', label: 'Раз в квартал' },
    { value: 'Раз в полгода', label: 'Раз в полгода' },
    { value: 'Раз в год', label: 'Раз в год' },
]

const activityStatusOptions = [
    { value: 'Все', label: 'Все' },
    { value: 'Активные', label: 'Активные' },
    { value: 'Неактивные', label: 'Неактивные' },
]

const statusOptions = [
    { value: 'Все', label: 'Все' },
    { value: 'Активные', label: 'Активные' },
    { value: 'Заблокированные', label: 'Заблокированные' },
]

type ClientGroupProps = {
    title: string
    checked: boolean
    onChange: () => void
}

const ClientGroup = (props: ClientGroupProps): JSX.Element => (
    <div className='flex gap-2 items-center ml-7'>
        <Checkbox onChange={props.onChange} checked={props.checked} />
        <RegularText>{props.title}</RegularText>
    </div>
)

export const MassSendAddModal = (props: Props): JSX.Element => {
    const smsTemplates = useAtomValue(smsTemplatesAtom)
    const { data: clientGroups } = useAtomValue(allClientGroupsAtom)
    const [data, setData] = useState<any>({})
    const [selectedGroups, setSelectedGroups] = useState<string[]>([])

    const canAdd = useMemo<boolean>(() => {
        const countOfSelected = Object.values(data).filter(item => Boolean(item)).length
        return countOfSelected >= 7 && selectedGroups.length > 0
    }, [data, selectedGroups])

    const handleData = (field: string) => (val: any) => {
        if (val?.target) {
            setData(prev => ({ ...prev, [field]: val.target.value }))
        } else {
            setData(prev => ({ ...prev, [field]: val }))
        }
    }

    const onAllGroupsClick = () => {
        const isAllSelected = selectedGroups.length === clientGroups?.length
        if (isAllSelected) {
            setSelectedGroups([])
        } else {
            const mapped = clientGroups?.map(gr => gr.clientgroup_id) || []
            setSelectedGroups(mapped)
        }
    }

    const onGroupClick = (val: string) => {
        if (selectedGroups.includes(val)) {
            setSelectedGroups(prev => prev.filter(item => item !== val))
        } else {
            setSelectedGroups(prev => [...prev, val])
        }
    }

    const onAdd = async () => {
        if (!canAdd) return
        const body: CreateMassNotificationRequest = {
            template_id: data.template.value,
            group_id: selectedGroups,
            timeFrom: data.time,
            dateFrom: DateUtils.parseDateInput(data.dateFrom),
            dateTo: DateUtils.parseDateInput(data.dateTo),
            periodicity: data.periodic.value,
            activity: data.activityStatus.value,
            status: data.status.value,
        }

        props.onAccept(body)
    }

    return (
        <div className='flex flex-col justify-between flex-1' style={{ minHeight: '700px' }}>
            <div className='flex p-7 items-end gap-2 flex-col flex-wrap' style={{ height: '566px' }}>
                <div className='px-7 flex flex-col gap-2'>
                    <div className='flex gap-2 items-center'>
                        <Checkbox checked={selectedGroups.length === clientGroups?.length} onChange={onAllGroupsClick} />
                        <MediumText>Выбрать все группы клиентов</MediumText>
                    </div>
                    <div className='h-96 overflow-scroll py-2'>
                        {clientGroups?.map(group => (
                            <ClientGroup
                                key={group.clientgroup_id}
                                onChange={() => onGroupClick(group.clientgroup_id)}
                                checked={selectedGroups.includes(group.clientgroup_id)}
                                title={group.title}
                            />
                        ))}
                    </div>

                    <InputWrapper name='Время от' className='mt-10'>
                        <TimePicker value={data['time']} onChange={handleData('time')} />
                    </InputWrapper>
                </div>

                <InputWrapper name='Шаблон' className='mb-14'>
                    <AppSelect
                        placeholder='Шаблон'
                        options={smsTemplates?.length ? smsTemplates.map(item => ({ value: item.pushtemplate_id, label: item.title })) : []}
                        onChange={handleData('template')}
                        value={data['template']}
                    />
                </InputWrapper>

                <InputWrapper name='Периодичность' className='mb-14'>
                    <AppSelect
                        placeholder='Периодичность'
                        options={periodicOptions}
                        onChange={handleData('periodic')}
                        value={data['periodic']}
                    />
                </InputWrapper>

                <InputWrapper name='Статус активности' className='mb-14'>
                    <AppSelect
                        placeholder='Статус активности'
                        options={activityStatusOptions}
                        onChange={handleData('activityStatus')}
                        value={data['activityStatus']}
                    />
                </InputWrapper>

                <InputWrapper name='Статус' className='mb-14'>
                    <AppSelect placeholder='Статус' options={statusOptions} onChange={handleData('status')} value={data['status']} />
                </InputWrapper>

                <InputWrapper name='Число от' className='mb-14'>
                    <DateInput value={data['dateFrom']} onChange={handleData('dateFrom')} />
                </InputWrapper>

                <InputWrapper name='Число до'>
                    <DateInput value={data['dateTo']} onChange={handleData('dateTo')} />
                </InputWrapper>
            </div>
            <Divider />
            <div className='flex justify-center items-center p-5 gap-4'>
                <Button onClick={props.onAbort}>
                    <RegularText>Отменить</RegularText>
                </Button>
                <Button disabled={!canAdd} onClick={onAdd} variant={EButtonVariant.PRIMARY}>
                    <RegularText>Добавить</RegularText>
                </Button>
            </div>
        </div>
    )
}

import styles from './styles.module.scss'

type RadioButtonProps = React.InputHTMLAttributes<HTMLInputElement>

export const RadioButton = (props: RadioButtonProps): JSX.Element => {
    const { onChange, checked, ...rest } = props

    return (
        <div className={styles.wrapper}>
            <label className={styles.container}>
                <input type='radio' onChange={onChange} checked={checked} {...rest} />
                <span className={styles.checkmark} />
            </label>
        </div>
    )
}

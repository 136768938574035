import { ReactNode, useMemo, useState, useEffect } from 'react'
import classNames from 'classnames'

import { RegularText } from '../RegularText'

import styles from './styles.module.scss'

export type TabOption = {
    tabName: string
}

export type Tab = {
    tabOption: TabOption
    child: ReactNode
    footerChild?: ReactNode
    disabled?: boolean
}

type TabProps = {
    tabs: Tab[]
    activeTabIndex?: number
}

export const Tabs = (props: TabProps): JSX.Element => {
    const { tabs } = props
    const [currentTab, setCurrentTab] = useState(props.activeTabIndex || 0)

    useEffect(() => {
        if (tabs.length > 0 && currentTab >= tabs.length) {
            setCurrentTab(0)
        }
    }, [tabs, currentTab])

    const CurrentTabChild = useMemo(() => {
        if (tabs.length === 0 || currentTab >= tabs.length) {
            return null
        }
        return tabs[currentTab].child
    }, [currentTab, tabs])

    const CurrentTabChildFooter = useMemo(() => {
        if (tabs.length === 0 || currentTab >= tabs.length) {
            return null
        }
        return tabs[currentTab].footerChild || null
    }, [currentTab, tabs])

    const onClickTab = (index: number) => {
        if (index < tabs.length && !tabs[index].disabled) {
            setCurrentTab(index)
        }
    }

    if (tabs.length === 0) {
        return <div>No tabs available</div>
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.tabs}>
                {tabs.map((tab, idx) => {
                    if (tab.tabOption.tabName.length) {
                        return (
                            <div
                                className={classNames(
                                    {
                                        [styles.tabNameContainer]: true,
                                        [styles.tabActive]: idx === currentTab,
                                        [styles.tabDisabled]: tab.disabled,
                                    },
                                    'cursor-pointer',
                                )}
                                key={tab.tabOption.tabName}
                                onClick={() => onClickTab(idx)}>
                                <RegularText
                                    className={classNames({
                                        '!text-darkBlue-900': idx === currentTab,
                                        '!text-gray-400': tab.disabled,
                                    })}>
                                    {tab.tabOption.tabName}
                                </RegularText>
                                {idx === currentTab && <div className={styles.separator} />}
                            </div>
                        )
                    }
                })}
            </div>

            <div className={styles.tabWrapper}>{CurrentTabChild}</div>
            {CurrentTabChildFooter}
        </div>
    )
}

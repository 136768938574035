import { EditCourierGroup } from 'src/features/Couriers/EditCourierGroup'
import { Courier, CourierGroup } from 'src/services/LocaliRepository'
import { atom } from 'jotai'
import { ModalEditCourier } from 'src/features/CourierEditModals'
import { SuspenseWithSpinner } from 'src/components'
import { NotSavedDialog } from 'src/features/Dialogs/NotSavedDialog'
import { DeleteCourierDialog } from 'src/features/CourierEditModals/DeleteCourierDialog'
import { FireCourierDialog } from 'src/features/CourierEditModals/FireCourierDialog'
import { FireReasonModal } from 'src/features/CourierEditModals/FireCourierReasonModal'

import { modalAtom, setCloseAllModals, subModalAtom } from '../global/modalAtom'
import { currentCourierAtom } from '../allOrders/couriersListAtom'
import { closeDialogAtom, dialogStateAtom } from '../global/dialogAtom'

import { selectedCourierGroupAtom } from './allCouriersAtom'

const setCourierEditGroupAtom = atom(null, (_, set, update: CourierGroup) => {
    set(modalAtom, {
        isOpen: true,
        title: 'Группа',
        children: <EditCourierGroup />,
    })

    set(selectedCourierGroupAtom, update)
})

const setCourierEditAtom = atom(null, (_, set, update: Courier) => {
    set(currentCourierAtom, update)
    set(modalAtom, {
        isOpen: true,
        title: 'Курьер',
        children: (
            <SuspenseWithSpinner>
                <ModalEditCourier />
            </SuspenseWithSpinner>
        ),
        onClose: () =>
            set(dialogStateAtom, {
                isOpen: true,
                children: <NotSavedDialog onAbort={() => set(closeDialogAtom)} onAccept={() => set(setCloseAllModals)} />,
            }),
    })
})

type DeleteUpdate = {
    courier_id?: string
    firstName?: string
}

const setCourierDeleteAtom = atom(null, (_, set, update: DeleteUpdate) => {
    if (update.courier_id) {
        set(dialogStateAtom, {
            isOpen: true,
            children: <DeleteCourierDialog courier_id={update.courier_id} name={update.firstName} />,
        })
    }
})

type FireUpdate = {
    courier_id?: string
    firstName?: string
}

const setCourierFireAtom = atom(null, (_, set, update: FireUpdate) => {
    if (update.courier_id) {
        set(dialogStateAtom, {
            isOpen: true,
            children: <FireCourierDialog courier_id={update.courier_id} name={update.firstName} />,
        })
    }
})

const setCourierFireReasonAtom = atom(null, (_, set, update: FireUpdate) => {
    if (update.courier_id) {
        set(subModalAtom, {
            isOpen: true,
            title: 'Причина отмены',
            children: <FireReasonModal courier_id={update.courier_id} />,
            height: 350,
            width: 400,
            zIndex: 50,
        })
    }
})

export { setCourierEditGroupAtom, setCourierEditAtom, setCourierDeleteAtom, setCourierFireAtom, setCourierFireReasonAtom }

import { useAtomValue } from 'jotai'
import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { authTokenAtom } from 'src/atoms/auth/authAtom'
import { ERoutes } from 'src/router/routes'

export const UnProtectedRoute = (): JSX.Element => {
    const navigate = useNavigate()
    const token = useAtomValue(authTokenAtom)

    useEffect(() => {
        if (token) {
            navigate(ERoutes.MAIN_MAIN)
        }
    }, [token, navigate])

    return <Outlet />
}

import ReactInputMask, { Props } from 'react-input-mask'
import { InputProps } from 'src/types/html'
import { Icon } from 'src/assets/icons'
import classNames from 'classnames'

import styles from './styles.module.scss'

export type TimePickerProps = InputProps & Partial<Props>

export const TimePicker = (props: TimePickerProps): JSX.Element => {
    const { mask = '99:99', maskPlaceholder = 'HH:MM', placeholder = 'HH:MM', className, ...rest } = props

    return (
        <div className={styles.inputWrapper}>
            <ReactInputMask
                mask={mask}
                maskPlaceholder={maskPlaceholder}
                placeholder={placeholder}
                className={classNames(styles.input, className)}
                {...rest}
            />
            <Icon className={styles.icon} type='calendar' size={14} />
        </div>
    )
}

import { useSetAtom } from 'jotai'
import { useState } from 'react'
import { cancelOrderAtom, cancelReasonAtom } from 'src/atoms/allOrders/cancelOrderAtom'
import { closeDialogAtom } from 'src/atoms/global/dialogAtom'
import { setCloseSubModalAtom } from 'src/atoms/global/modalAtom'
import { AppImage, Button, EButtonVariant, MediumText, Nbsp, RegularText, SemiBoldItalicText, TextArea } from 'src/components'
import { Assets } from 'src/utils'
import { useAtomValue } from 'jotai/index'

import { currentOrderAtom } from '../../atoms/allOrders/ordersAtom'

const CancelReasonModal = (): JSX.Element => {
    const [reason, setReason] = useState('')
    const cancelOrder = useSetAtom(cancelOrderAtom)
    const closeSubModal = useSetAtom(setCloseSubModalAtom)

    return (
        <div className='flex flex-col justify-center items-center gap-5' style={{ minHeight: '300px' }}>
            <SemiBoldItalicText>~Напишите причину отмены~</SemiBoldItalicText>
            <TextArea placeholder='Причина отмены' rows={5} value={reason} onChange={e => setReason(e.currentTarget.value)} />
            <div className='flex gap-2'>
                <Button onClick={() => cancelOrder(reason)} className='w-32' variant={EButtonVariant.PRIMARY}>
                    <RegularText>ОК</RegularText>
                </Button>
                <Button onClick={closeSubModal} className='w-32'>
                    <RegularText>Отменить</RegularText>
                </Button>
            </div>
        </div>
    )
}

const CancelOrder = (): JSX.Element => {
    const currentOrder = useAtomValue(currentOrderAtom)
    const closeDialog = useSetAtom(closeDialogAtom)
    const confirmCancel = useSetAtom(cancelReasonAtom)

    return (
        <div className='flex flex-col items-center justify-center h-full p-8'>
            <div className='size-14'>
                <AppImage src={Assets.sadSmiley} />
            </div>
            <div className='flex-1 flex justify-center items-center'>
                <RegularText>
                    Отменить заказ <Nbsp />
                </RegularText>
                <MediumText>№{currentOrder?.orderNumber}</MediumText>
            </div>
            <div className='flex gap-2'>
                <Button className='!w-44 flex justify-center !py-5' variant={EButtonVariant.RED} onClick={confirmCancel}>
                    <RegularText className='!text-base'>Отменить</RegularText>
                </Button>
                <Button className='!w-44 !py-5 flex justify-center' onClick={closeDialog}>
                    <RegularText>Отмена</RegularText>
                </Button>
            </div>
        </div>
    )
}

export { CancelOrder, CancelReasonModal }

import classNames from 'classnames'
import { PropsWithChildren, ReactElement, useState } from 'react'
import { DivProps } from 'src/types/html'

import styles from './styles.module.scss'

type DropdownProps = DivProps & {
    parent: ReactElement
}

export const Dropdown = (props: PropsWithChildren<DropdownProps>): JSX.Element => {
    const { parent, children, ...rest } = props

    const [isDropdownVisible, setDropdownVisible] = useState(false)

    const handleMouseEnter = () => setDropdownVisible(true)

    const handleMouseLeave = () => setDropdownVisible(false)

    return (
        <div {...rest} className={classNames('relative', rest.className)} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {parent}
            {isDropdownVisible && (
                <div className={classNames('absolute top-1/2 left-1/2 z-20 shadow-lg rounded-lg', styles.dropdownContent)}>{children}</div>
            )}
        </div>
    )
}

import { useSetAtom } from 'jotai'
import { closeDialogAtom } from 'src/atoms/global/dialogAtom'
import { AppImage, Button, EButtonVariant, RegularText } from 'src/components'
import { Assets } from 'src/utils'

const NotifyRestaurantSuccess = ({ success }: { success: boolean }): JSX.Element => {
    const closeDialog = useSetAtom(closeDialogAtom)

    return (
        <div className='flex flex-col items-center justify-center h-full p-8'>
            <div className='size-14'>
                <AppImage src={success ? Assets.happySmiley : Assets.sadSmiley} />
            </div>
            <div className='flex-1 flex justify-center items-center'>
                <RegularText className='text-center'>
                    {success ? 'Уведомление успешно отправлено ресторану!' : 'Возникла ошибка при отправке уведомления!'}
                </RegularText>
            </div>
            <div className='flex gap-2'>
                <Button className='!w-44 !py-5 flex justify-center' onClick={closeDialog} variant={EButtonVariant.PRIMARY}>
                    <RegularText>ОК</RegularText>
                </Button>
            </div>
        </div>
    )
}

export { NotifyRestaurantSuccess }

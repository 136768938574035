import { atom } from 'jotai'
import React from 'react'

import { AdminCreateSchedule } from '../../features/AdminsEditModals/AdminCreateSchedule'
import { AdminCreateSalaryRate } from '../../features/AdminsEditModals/AdminCreateSalaryRate'
import { AdminEditSchedule } from '../../features/AdminsEditModals/AdminEditSchedule'
import { AdminAvailability } from '../../services/LocaliRepository/LocaliRepositorySchemas/AdminAvailabilitySchema'
import { DeleteAdminDialog } from '../../features/AdminsEditModals/DeleteAdminDialog'
import { FireCourierDialog } from '../../features/CourierEditModals/FireCourierDialog'
import { FireReasonModal } from '../../features/CourierEditModals/FireCourierReasonModal'
import { FireAdminReasonModal } from '../../features/AdminsEditModals/FireAdminReasonModal'
import { FireAdminDialog } from '../../features/AdminsEditModals/FireAdminDialog'

import { subModalAtom } from '../global/modalAtom'
import { dialogStateAtom } from '../global/dialogAtom'

export const setEditAdminScheduleModalAtom = atom(null, (_, set, initialData: AdminAvailability | null = null) => {
    set(subModalAtom, {
        isOpen: true,
        title: initialData ? 'Редактирование графика доступности администратора' : 'Добавление графика доступности администратора',
        children: initialData ? (
            <AdminEditSchedule
                initialData={{
                    avialschedule_id: initialData.avialschedule_id,
                    title: initialData.title,
                    schedule: initialData.schedule,
                    scheduleStart: initialData.scheduleStart,
                    dayCount: initialData.dayCount,
                }}
            />
        ) : (
            <AdminCreateSchedule />
        ),
        width: 750,
        height: 700,
    })
})
const setAdminPaymentModalAtom = atom(null, (_, set) => {
    set(subModalAtom, {
        isOpen: true,
        title: 'Ставка оплаты труда ',
        children: <AdminCreateSalaryRate />,
        width: 1200,
        height: 700,
    })
})

type DeleteUpdate = {
    admin_id?: string
    firstName?: string
}

const setAdminDeleteAtom = atom(null, (_, set, update: DeleteUpdate) => {
    if (update.admin_id) {
        set(dialogStateAtom, {
            isOpen: true,
            children: <DeleteAdminDialog admin_id={update.admin_id} name={update.firstName} />,
        })
    }
})

type FireUpdate = {
    admin_id?: string
    firstName?: string
}

const setAdminFireAtom = atom(null, (_, set, update: FireUpdate) => {
    if (update.admin_id) {
        set(dialogStateAtom, {
            isOpen: true,
            children: <FireAdminDialog admin_id={update.admin_id} name={update.firstName} />,
        })
    }
})

// const setAdminFireReasonAtom = atom(null, (_, set, update: FireUpdate) => {
//   if(update.courier_id) {
//     set(subModalAtom, {
//       isOpen: true,
//       title: 'Причина отмены',
//       children: <FireAdminReasonModal courier_id={update.courier_id}/>,
//       height: 350,
//       width: 400,
//       zIndex: 50
//     });
//   }
// });
export { setAdminPaymentModalAtom, setAdminDeleteAtom, setAdminFireAtom }

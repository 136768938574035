import { atom } from 'jotai'
import { atomWithRefresh } from 'jotai/utils'
import { Admin, LokaliApi } from 'src/services'
import { HttpStatusCode } from 'src/types'
import { Simulate } from 'react-dom/test-utils'

const currentAdminAtom = atom<Admin | null>(null)

const adminsListAtom = atom<Admin[]>([])

const getAdminsAtom = atomWithRefresh(async () => {
    try {
        const res = await LokaliApi.getAdmins()

        if (res.status === HttpStatusCode.OK) {
            return {
                isLoading: false,
                data: res.data,
                error: null,
            }
        }
        return {
            isLoading: false,
            data: [],
            error: null,
        }
    } catch (e) {
        return {
            isLoading: false,
            data: [],
            error: e as Error,
        }
    }
})

const setCurrentAdminAtom = atom(null, (_, set, update: Admin) => {
    set(currentAdminAtom, update)
})

const refreshAdminsTableAtom = atom(null, (_, set) => {
    set(getAdminsAtom)
})

export { adminsListAtom, currentAdminAtom, setCurrentAdminAtom, getAdminsAtom, refreshAdminsTableAtom }

import { atom, useAtom } from 'jotai'
import { NotSavedDialog } from 'src/features/Dialogs/NotSavedDialog'
import React from 'react'

import AccountingRestModal from '../../pages/AccountingRestPage/components/AccountingRestModal'
import { FinancesAccounting, RestAccounting } from '../../services'

import { ModalAtomState, modalAtom, setCloseAllModals, subModalAtom } from '../global/modalAtom'
import { dialogStateAtom, closeDialogAtom } from '../global/dialogAtom'

const currentRestAccountingAtom = atom<FinancesAccounting | null>(null)

const setRestAccountingModalAtom = atom(
    null,
    (
        _,
        set,
        args: {
            id: string
            setRestAccounting: (accounting: FinancesAccounting[]) => void
        },
    ) => {
        const update: ModalAtomState = {
            isOpen: true,
            children: <AccountingRestModal restaurantId={args.id} setRestAccounting={args.setRestAccounting} />,
            title: 'Бухгалтерия оплата ресторану',
            onClose: () =>
                set(dialogStateAtom, {
                    isOpen: true,
                    children: <NotSavedDialog onAccept={() => set(setCloseAllModals)} onAbort={() => set(closeDialogAtom)} />,
                }),
        }

        set(modalAtom, update)
    },
)

/*const setCourierScheduleModalAtom = atom( null , (_, set) => {
  set(subModalAtom, {
    isOpen: true,
    title: 'Добавление графика доступности курьера',
    children: <CourierCreateSchedule/>,
    width: 750,
    height: 700
  });
});

const setSalaryRateSubmodalAtom = atom(null, (_, set)=> {
  set(subModalAtom, {
    isOpen: true,
    title: 'Ставка оплаты труда',
    children: <CourierCreateSalaryRate/>,
    width: 1200,
    height: 700
  });
});

const setCourierFormOrderAtom = atom(null, (_, set)=> {
  set(subModalAtom, {
    isOpen:true,
    title: 'Сформировать заказ',
    children: <CourierCreateFormOrder/>,
    width: 1200,
    height: 1000
  });
});

const setCourierAssignOrderAtom = atom(null, (_, set)=> {
  set(subModalAtom, {
    isOpen: true,
    title: 'Присвоить заказ',
    children: <CourierCreateAssign/>,
    width: 1200,
    height: 1000
  });
});

const setCourierAddGroupAtom = atom(null, (_, set)=> {
  set(modalAtom, {
    isOpen: true,
    title: 'Группа',
    children: <AddCourierGroup/>,
  });
});*/

export { setRestAccountingModalAtom, currentRestAccountingAtom }

import { atom } from 'jotai'
import { atomWithImmer } from 'jotai-immer'

export type AdminRight = {
    label: string
    checked: boolean
    id: string
}

const createParentAtom = (rightAtom: any) =>
    atom(get => {
        const data = get(rightAtom) as AdminRight[]
        return data.every(el => el.checked)
    })

const createGeneralRightAtom = atomWithImmer<AdminRight[]>([
    { label: 'Просмотр к каждому блоку', checked: false, id: 'general1' },
    { label: 'Экспорт', checked: false, id: 'general2' },
])

const createClientRightAtom = atomWithImmer<AdminRight[]>([
    { label: 'Просмотр', checked: false, id: 'client1' },
    { label: 'Создание удаление групп', checked: false, id: 'client2' },
    { label: 'Добавление клиента', checked: false, id: 'client3' },
    { label: 'Удаление клиента', checked: false, id: 'client4' },
    { label: 'Редактирование клиента', checked: false, id: 'client5' },
    { label: 'Экспорт', checked: false, id: 'client6' },
    { label: 'Редактирование клиента', checked: false, id: 'client7' },
    { label: 'Наличие бонусов', checked: false, id: 'client8' },
    { label: 'Рассылка уведомлений', checked: false, id: 'client9' },
])

const createCourierRightAtom = atomWithImmer<AdminRight[]>([
    { label: 'Просмотр таблицы', checked: false, id: 'courier1' },
    { label: 'Просмотр карты', checked: false, id: 'courier2' },
    { label: 'Добавление', checked: false, id: 'courier3' },
    { label: 'Удаление', checked: false, id: 'courier4' },
    { label: 'Редактирование', checked: false, id: 'courier5' },
    { label: 'Назначение удаление заказов', checked: false, id: 'courier6' },
    { label: 'Просмотр статистики заказа', checked: false, id: 'courier7' },
])

const createRestRightAtom = atomWithImmer<AdminRight[]>([
    { label: 'Добавление удаление группы', checked: false, id: 'rest1' },
    { label: 'Добавление ресторана', checked: false, id: 'rest2' },
    { label: 'Удаление', checked: false, id: 'rest3' },
    { label: 'Редактирование', checked: false, id: 'rest4' },
    { label: 'Просмотр карты', checked: false, id: 'rest5' },
    { label: 'Просмотр статистики по заказам', checked: false, id: 'rest6' },
])

const createOrderRightAtom = atomWithImmer<AdminRight[]>([
    { label: 'Просмотр', checked: false, id: 'order1' },
    { label: 'Добавление', checked: false, id: 'order2' },
    { label: 'Удаление', checked: false, id: 'order3' },
    { label: 'Назначение удаление курьеру', checked: false, id: 'order4' },
])

const createAdminRightAtom = atomWithImmer<AdminRight[]>([
    { label: 'Бухгалтерия', checked: false, id: 'admin1' },
    { label: 'Добавление админов', checked: false, id: 'admin2' },
    { label: 'Удаление админов', checked: false, id: 'admin3' },
    { label: 'Редактирование админов', checked: false, id: 'admin4' },
])

const createGeneralParentAtom = createParentAtom(createGeneralRightAtom)
const createClientParentAtom = createParentAtom(createClientRightAtom)
const createCourierParentAtom = createParentAtom(createCourierRightAtom)
const createRestParentAtom = createParentAtom(createRestRightAtom)
const createOrderParentAtom = createParentAtom(createOrderRightAtom)
const createAdminParentAtom = createParentAtom(createAdminRightAtom)

export {
    createGeneralParentAtom,
    createClientParentAtom,
    createCourierParentAtom,
    createOrderParentAtom,
    createAdminParentAtom,
    createAdminRightAtom,
    createClientRightAtom,
    createCourierRightAtom,
    createGeneralRightAtom,
    createOrderRightAtom,
    createRestParentAtom,
    createRestRightAtom,
}

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useAtom, useSetAtom } from 'jotai'
import { AppSelect, Button, DateInput, Divider, MediumText, RegularText, TextInput } from 'src/components'
import { EButtonVariant } from 'src/components/Button'
import moment from 'moment'

import { setCloseSubModalAtom } from '../../atoms/global/modalAtom'
import { currentAdminAtom } from '../../atoms/admins/adminsTableAtom'
import { createAdminPaymentAtom, getAdminPaymentsAtom } from '../../atoms/admins/adminPaymentAtom'
import { LokaliApi } from '../../services'

export const AdminCreateSalaryRate = (): JSX.Element => {
    const [loading, setLoading] = useState(false)
    const closeSubModal = useSetAtom(setCloseSubModalAtom)
    const [currentAdmin] = useAtom(currentAdminAtom)
    const createAdminPayment = useSetAtom(createAdminPaymentAtom)
    const [, getAdminPayments] = useAtom(getAdminPaymentsAtom)
    const [salary, setSalary] = useState<string>('')
    const [rate, setRate] = useState<string>('')
    // const [, getAdminPayments] = useAtom(getAdminPaymentsAtom);
    const [schedule, setSchedule] = useState<string>('')
    const [dateFrom, setDateFrom] = useState<string>('')

    const isSaveDisabled =
        (!salary && !rate) || !schedule || !moment(dateFrom, 'DD.MM.YYYY').isValid() || dateFrom?.replace('_', '')?.length < 10

    const handleCreatePayment = async () => {
        if (!currentAdmin?.admin_id || isSaveDisabled) return

        const req = {
            dateFrom: moment(dateFrom, 'DD.MM.YYYY').toISOString(),
            dateTo: '',
            salary,
            rate,
            schedule,
            admin_id: currentAdmin.admin_id,
            avance: '0',
        }

        setLoading(true)
        const response = await createAdminPayment(req)
        if (response === 200) {
            closeSubModal()
            getAdminPayments().then()
        }
        setLoading(false)
    }

    const calculateSalary = useCallback(async () => {
        try {
            const data = await LokaliApi.calculateAdminPayment({
                admin_id: currentAdmin!.admin_id,
                schedule: 'rate',
                rate,
                salary: '',
            })

            if (data?.calculatedSalary) {
                setSalary(data?.calculatedSalary)
            }
        } catch (e) {
            console.warn(e)
        }
    }, [rate, currentAdmin])

    useEffect(() => {
        if (schedule !== 'rate' || !currentAdmin?.admin_id) return

        const timer = setTimeout(() => {
            calculateSalary().then()
        }, 1000)

        return () => clearInterval(timer)
    }, [rate])

    const calculateRate = useCallback(async () => {
        try {
            const data = await LokaliApi.calculateAdminPayment({
                admin_id: currentAdmin!.admin_id,
                schedule: 'salary',
                rate: '',
                salary,
            })

            if (data?.calculatedRate) {
                setRate(data?.calculatedRate)
            }
        } catch (e) {
            console.warn(e)
        }
    }, [salary, currentAdmin])

    useEffect(() => {
        if (schedule !== 'salary' || !currentAdmin?.admin_id) return

        const timer = setTimeout(() => {
            calculateRate().then()
        }, 1000)

        return () => clearInterval(timer)
    }, [salary])

    const handleDelete = () => {
        setSalary('')
        setRate('')
        setSchedule('')
        setDateFrom('')
    }

    return (
        <div className='p-6'>
            <div className='flex mb-10'>
                <div className='w-2/5 mr-20'>
                    <div className='mb-10'>
                        <AppSelect
                            options={[{ value: currentAdmin?.admin_id || '', label: 'Администратор' }]}
                            placeholder='Администратор'
                            value={{ value: currentAdmin?.admin_id || '', label: 'Администратор' }}
                        />
                    </div>
                    <div>
                        <AppSelect
                            options={[
                                { value: 'salary', label: 'Оклад' },
                                { value: 'rate', label: 'Тариф' },
                            ]}
                            placeholder='График'
                            value={schedule ? { value: schedule, label: schedule === 'salary' ? 'Оклад' : 'Тариф' } : null}
                            onChange={value => {
                                setRate('')
                                setSalary('')
                                setSchedule(value?.value || '')
                            }}
                        />
                    </div>
                </div>
                <div>
                    <div className='flex mb-4 space-x-4'>
                        <TextInput
                            type='number'
                            placeholder='Оклад'
                            value={salary}
                            onChange={e => setSalary(e.target.value)}
                            className={`flex-1 p-2 border rounded ${schedule !== 'salary' && 'opacity-60'}`}
                            disabled={schedule !== 'salary'}
                        />

                        <TextInput
                            type='number'
                            placeholder='Тариф'
                            value={rate}
                            onChange={e => setRate(e.target.value)}
                            className={`flex-1 p-2 border rounded ${schedule !== 'rate' && 'opacity-60'}`}
                            disabled={schedule !== 'rate'}
                        />
                    </div>
                </div>
            </div>

            <Divider />

            <div className='mb-6 mt-10 w-fit'>
                <MediumText className='mb-2 block'>Дата применения</MediumText>
                <DateInput value={dateFrom} onChange={event => setDateFrom(event.target.value)} />
            </div>

            <div className='flex justify-between items-center'>
                <Button variant={EButtonVariant.QUARTERY} className='mr-2' onClick={handleDelete}>
                    <MediumText>Очистить</MediumText>
                </Button>

                <div className='flex space-x-2'>
                    <Button
                        icon='save'
                        variant={EButtonVariant.PRIMARY}
                        onClick={handleCreatePayment}
                        disabled={isSaveDisabled}
                        loader={loading}>
                        <MediumText>Сохранить</MediumText>
                    </Button>
                    <Button onClick={closeSubModal}>
                        <RegularText>Отмена</RegularText>
                    </Button>
                </div>
            </div>
        </div>
    )
}

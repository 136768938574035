import { z } from 'zod'

const ClientGroupSchema = z.object({
    clientgroup_id: z.string(),
    title: z.string(),
    comment: z.string().nullish(),
    orderPrice: z.number().nullish(),
    createdAt: z.string(),
    updatedAt: z.string(),
})

type ClientGroup = z.infer<typeof ClientGroupSchema>

export { ClientGroupSchema }

export type { ClientGroup }

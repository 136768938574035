import React from 'react'
import { MediumText, RegularText, SemiBoldText } from 'src/components'
import { ShadowContainer } from 'src/components/ShadowContainer'
import { useAtomValue } from 'jotai'

import { mainStatisticsAtom } from '../../atoms/mainStatistics/getMainStatisticsAtom'

export const MainOrdersAmount = () => {
    const mainStatistics = useAtomValue(mainStatisticsAtom)

    const getPercentageColor = (percent: number | undefined) => {
        if (percent === undefined) return 'text-gray-800'
        return percent > 100 ? 'text-green-800' : 'text-red-700'
    }

    const getPercentageChange = (percent: number | undefined) => {
        if (percent === undefined) return '0%'
        return `${percent - 100}%`
    }

    return (
        <ShadowContainer className='flex gap-40 justify-between'>
            <div className='flex gap-40'>
                <div className='flex flex-col gap-5 w-60 justify-between'>
                    <SemiBoldText className='text-xl'>Количество заказов:</SemiBoldText>
                    <MediumText className='text-blue-900 text-4xl'>{mainStatistics?.totalOrderCount ?? 0}</MediumText>
                    <div className='flex items-center gap-2'>
                        <RegularText className={getPercentageColor(mainStatistics?.orderPercent)}>
                            {getPercentageChange(mainStatistics?.orderPercent)}
                        </RegularText>
                        <RegularText>(за день)</RegularText>
                    </div>
                </div>
                <div className='flex flex-col gap-5 w-60 justify-between'>
                    <SemiBoldText className='text-xl'>Процент заказов:</SemiBoldText>
                    <MediumText className='text-blue-900 text-4xl'>
                        {mainStatistics?.orderPercent ? `${mainStatistics.orderPercent}%` : '0%'}
                    </MediumText>
                    <div className='flex items-center gap-2'>
                        <RegularText className={getPercentageColor(mainStatistics?.orderPercent)}>
                            {getPercentageChange(mainStatistics?.orderPercent)}
                        </RegularText>
                        <RegularText>(за день)</RegularText>
                    </div>
                </div>
            </div>
        </ShadowContainer>
    )
}

import { useAtomValue } from 'jotai'
import classNames from 'classnames'
import { isNavbarFullAtom } from 'src/atoms/leftNavBar/leftNavBarAtom'

import styles from './styles.module.scss'
import { LeftNavBarHeader } from './LeftBarHeader'
import { NavBar } from './NavBar'

export const LeftNavBar = (): JSX.Element => {
    const isLeftBarOpen = useAtomValue(isNavbarFullAtom)

    return (
        <div className={classNames(styles.container, { [styles.minimized]: !isLeftBarOpen })}>
            <LeftNavBarHeader />
            <NavBar />
        </div>
    )
}

import { atom } from 'jotai'
import { ModalClientDBAddClient } from 'src/features/ClientDatabaseModals/ModalClientDBAddClient'

import { modalAtom } from '../global/modalAtom'
import { setNotSavedDialogAtom } from '../global/dialogAtom'

const addClientDBAtom = atom(null, (_, set) => {
    set(modalAtom, {
        title: 'Клиенты',
        children: <ModalClientDBAddClient />,
        isOpen: true,
        onClose: () => set(setNotSavedDialogAtom),
    })
})

export { addClientDBAtom }

import { useAtomValue, useSetAtom } from 'jotai'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { clientGroupsSelectAtom } from 'src/atoms/clientDb/clientGroupsAtom'
import {
    Button,
    DateInput,
    EButtonVariant,
    GenderOptions,
    MediumText,
    NamedSelect,
    NamedTextInput,
    Nbsp,
    RegularText,
    SemiBoldItalicText,
    SemiBoldText,
    TextArea,
} from 'src/components'
import { createClientDBRequestStatusAtom } from 'src/atoms/clientDb/createClientDBAtom'
import { HttpStatusCode } from 'src/types'
import { setCloseAllModals } from 'src/atoms/global/modalAtom'
import { DateUtils } from 'src/utils'

import { ESelectVariant } from '../../components/AppCitySelect'
import { NamedCitySelect } from '../../components/NamedCitySelect'

import { AddClientFormData } from './types'
import { FC } from 'react'

export const ModalDBClientInfoFooter = (): JSX.Element => {
    const networkState = useAtomValue(createClientDBRequestStatusAtom)
    const closeAllModals = useSetAtom(setCloseAllModals)

    return (
        <div className='flex justify-between px-5 py-3'>
            <div className='flex flex-1 justify-end gap-3'>
                <Button loader={networkState === HttpStatusCode.LOADING} type='submit' icon='save' variant={EButtonVariant.PRIMARY}>
                    <MediumText>Сохранить</MediumText>
                </Button>
                <Button type='button' onClick={closeAllModals} variant={EButtonVariant.SECONDARY}>
                    <RegularText>Выйти</RegularText>
                </Button>
            </div>
        </div>
    )
}

export const ModalClientInfo = (): JSX.Element => {
    const {
        control,
        formState: { errors },
        getValues,
        setValue,
    } = useFormContext<AddClientFormData>()
    const birthday = useWatch<AddClientFormData>({ name: 'birthday' })
    const groups = useAtomValue(clientGroupsSelectAtom)

    return (
        <div className='flex gap-20 justify-center mt-8'>
            <div className='flex flex-col gap-2'>
                <SemiBoldItalicText className='self-center text-blue-700 text-lg'>~Основная информация~</SemiBoldItalicText>
                <Controller
                    name='clientgroup_id'
                    control={control}
                    render={props => (
                        <NamedSelect
                            name='Группа'
                            selectProps={{
                                className: 'bg-white',
                                options: groups,
                                ...props.field,
                            }}
                        />
                    )}
                />
                <Controller
                    name='lastName'
                    control={control}
                    render={props => (
                        <NamedTextInput name='Фамилия' placeholder='Фамилия' textInputProps={{ ...props.field, error: errors.lastName }} />
                    )}
                />
                <Controller
                    name='firstName'
                    control={control}
                    render={props => (
                        <NamedTextInput name='Имя' placeholder='Имя' textInputProps={{ ...props.field, error: errors.firstName }} />
                    )}
                />
                <Controller
                    name='middleName'
                    control={control}
                    render={props => (
                        <NamedTextInput
                            name='Отчество'
                            placeholder='Отчество'
                            textInputProps={{ ...props.field, error: errors.middleName }}
                        />
                    )}
                />
                <Controller
                    name='gender'
                    control={control}
                    render={props => <NamedSelect name='Пол' selectProps={{ ...props.field, options: GenderOptions }} />}
                />
                <Controller
                    name='phoneNumber'
                    control={control}
                    render={props => (
                        <NamedTextInput
                            name='Телефон'
                            placeholder='Телефон'
                            textInputProps={{ ...props.field, error: errors.phoneNumber }}
                        />
                    )}
                />
                <Controller
                    name='email'
                    control={control}
                    render={props => (
                        <NamedTextInput name='Email' placeholder='Email' textInputProps={{ ...props.field, error: errors.email }} />
                    )}
                />
                <Controller
                    name='whatsapp'
                    control={control}
                    render={props => (
                        <NamedTextInput
                            name='Whatsapp'
                            placeholder='Whatsapp'
                            textInputProps={{ ...props.field, error: errors.whatsapp }}
                        />
                    )}
                />
                <Controller
                    name='telegram'
                    control={control}
                    render={props => (
                        <NamedTextInput
                            name='Telegram'
                            placeholder='Telegram'
                            textInputProps={{ ...props.field, error: errors.telegram }}
                        />
                    )}
                />
                <Controller
                    name='paymentInfo'
                    control={control}
                    render={props => (
                        <NamedTextInput name='Карта' placeholder='Карта' textInputProps={{ ...props.field, error: errors.paymentInfo }} />
                    )}
                />
                <div className='flex justify-center gap-3 mb-3'>
                    <div>
                        <SemiBoldText className='text-xs'>Дата рождения</SemiBoldText>
                        <Controller control={control} name='birthday' render={props => <DateInput {...props.field} />} />
                    </div>
                    <div className='flex items-end'>
                        <SemiBoldText className='text-sm'>Возраст:</SemiBoldText>
                        <Nbsp />
                        <RegularText className='text-sm'>{DateUtils.calculateAge(birthday as string)}</RegularText>
                    </div>
                </div>
            </div>
            <div className='flex flex-col gap-2'>
                <SemiBoldItalicText className='self-center text-blue-700 text-lg'>~Доп. информация~</SemiBoldItalicText>
                <div className='flex justify-between gap-2'>
                    <Controller
                        name='address'
                        control={control}
                        render={props => (
                            <NamedCitySelect
                                name='Адрес'
                                address
                                selectProps={{
                                    ...props.field,
                                    selectVariant: ESelectVariant.SECONDARY,
                                    error: errors.address?.message,
                                    value: getValues('address')
                                        ? { value: getValues('address')!, label: getValues('address')! }
                                        : undefined,
                                    onChange: val => {
                                        setValue('address', val?.label || '')
                                        setValue('longitude', val?.value ? Number(val.value.split(',')[0]) : 0)
                                        setValue('latitude', val?.value ? Number(val.value.split(',')[1]) : 0)
                                    },
                                }}
                            />
                        )}
                    />
                    {/*<Controller
            name="house"
            control={control}
            render={props=> (
              <NamedTextInput
                name="Дом"
                textInputProps={{ className: 'w-inputS', ...props.field, error: errors.house }}/>
            )}
          />*/}
                </div>
                <div className='flex justify-between gap-2'>
                    {/*<Controller
            name="street"
            control={control}
            render={props=> (
              <NamedTextInput
                name="Улица"
                textInputProps={{ className: 'w-inputM', ...props.field, error: errors.street }}
              />
            )}
          />
          <Controller
            name="corpus"
            control={control}
            render={props => (
              <NamedTextInput
                name="Корпус"
                textInputProps={{ className: 'w-inputS', ...props.field, error: errors.corpus }}
              />
            )}
          />*/}
                </div>
                <div>
                    {/*<Controller
            name="apartment"
            control={control}
            render={props => (
              <NamedTextInput
                name="Квартира"
                textInputProps={{ className: 'w-inputS', ...props.field, error: errors.apartment }}
              />
            )}
          />*/}
                </div>
                <Controller
                    control={control}
                    name='comment'
                    render={props => <TextArea placeholder='Комментарий' className='!w-full h-24' {...props.field} />}
                />
            </div>
        </div>
    )
}

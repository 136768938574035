import { atom } from 'jotai'
import { ModalSMSTemplate } from 'src/features/ClientDatabase'

import { LokaliApi } from '../../services'
import {
    CreateSmsTemplateRequest,
    EditSmsTemplateRequest,
    MassNotification,
    SmsTemplate,
} from '../../services/LocaliRepository/types/pushNotifications'

import { modalAtom } from '../global/modalAtom'
import { dialogStateAtom, setNotSavedDialogAtom } from '../global/dialogAtom'

export const manageSMSAtom = atom(null, (_, set, params?: { activeTabIndex: number }) => {
    set(modalAtom, {
        isOpen: true,
        children: <ModalSMSTemplate activeTabIndex={params?.activeTabIndex} />,
        title: 'Уведомления',
        onClose: () => set(setNotSavedDialogAtom),
    })
})

const smsTemplatesAtom = atom<SmsTemplate[]>([])
const smsTemplateLoadingAtom = atom(false)
const currentMassSendAtom = atom<MassNotification | null>(null)

const createSmsTemplateAtom = atom(null, async (_, set, req: CreateSmsTemplateRequest) => {
    set(smsTemplateLoadingAtom, true)

    try {
        const response = await LokaliApi.createSmsTemplate(req)

        set(smsTemplateLoadingAtom, false)
        return response
    } catch (e) {
        console.warn(e)
    }
})

const editSmsTemplateAtom = atom(null, async (_, set, req: EditSmsTemplateRequest) => {
    set(smsTemplateLoadingAtom, true)

    try {
        const response = await LokaliApi.editSmsTemplate(req)

        set(smsTemplateLoadingAtom, false)
        return response
    } catch (e) {
        console.warn(e)
    }
})

const openDeleteSmsTemplateDialogAtom = atom(null, (_, set, data: { children: React.JSX.Element }) => {
    set(dialogStateAtom, {
        isOpen: true,
        children: data.children,
    })
})

export {
    smsTemplateLoadingAtom,
    createSmsTemplateAtom,
    editSmsTemplateAtom,
    smsTemplatesAtom,
    openDeleteSmsTemplateDialogAtom,
    currentMassSendAtom,
}

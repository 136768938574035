import classNames from 'classnames'
import { SpanProps } from 'src/types'

type ErrorTextProps = {
    error?: string
} & SpanProps

export const ErrorText = (props: ErrorTextProps): JSX.Element => {
    const { className, error, ...rest } = props
    return (
        <span className={classNames('text-red-800 text-xs', className)} {...rest}>
            {error}
        </span>
    )
}

/* eslint-disable max-len */
import L from 'leaflet'
import { useMemo } from 'react'
import { Marker, MarkerProps } from 'react-leaflet'

type CourierMarkerProps = MarkerProps

const CourierMarker = (props: CourierMarkerProps): JSX.Element => {
    const { position = [51.505, -0.09], ...rest } = props

    const locationMarker = useMemo(
        () =>
            L.divIcon({
                html: `
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_5099_2043)">
      <circle cx="20" cy="16" r="16" fill="#FF9900"/>
      </g>
      <circle cx="20.5" cy="36.5" r="2.5" fill="#FF9900"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M23.9551 11.1062C25.5179 11.4688 26.1157 12.3826 26.8652 13.6403H23.9551V11.1062ZM14.8223 13.746C15.1283 13.746 15.3504 13.8937 15.3504 14.2388C15.3504 14.5299 15.1148 14.7661 14.8238 14.7669H11.6335C11.3419 14.7669 11.1055 15.0034 11.1055 15.295C11.1055 15.5866 11.3419 15.823 11.6335 15.823H16.3862C16.6806 15.823 16.9163 16.0593 16.9163 16.3511C16.9163 16.6428 16.6799 16.8792 16.3882 16.8792H11.6335C11.3419 16.8792 11.1055 17.1156 11.1055 17.4072C11.1055 17.6989 11.3419 17.9353 11.6335 17.9353H12.7953V19.5195C12.7953 19.8112 13.0317 20.0476 13.3234 20.0476H14.3849C14.5741 20.9797 15.3977 21.667 16.3686 21.667C17.3394 21.667 18.163 20.9797 18.3523 20.0476H23.7141C23.9033 20.9797 24.7269 21.667 25.6978 21.667C26.6687 21.667 27.4922 20.9797 27.6815 20.0476H28.6022C28.8938 20.0476 29.1302 19.8112 29.1302 19.5195V16.3511C29.1302 14.8017 27.496 14.6988 27.4944 14.6965H23.4271C23.1354 14.6965 22.899 14.4601 22.899 14.1684V11H13.3234C13.0317 11 12.7953 11.2364 12.7953 11.5281V12.6898H12.1616C11.8699 12.6898 11.6335 12.9262 11.6335 13.2179C11.6335 13.5096 11.8699 13.746 12.1616 13.746H14.8223ZM26.3823 18.9582C26.7604 19.3362 26.7604 19.9494 26.3823 20.3274C25.7742 20.9355 24.7296 20.5029 24.7296 19.6427C24.7296 18.7827 25.7742 18.3501 26.3823 18.9582ZM17.0531 18.9582C17.4312 19.3362 17.4312 19.9494 17.0531 20.3274C16.445 20.9355 15.4004 20.5029 15.4004 19.6427C15.4004 18.7827 16.445 18.3501 17.0531 18.9582Z" fill="white"/>
      <defs>
      <filter id="filter0_d_5099_2043" x="0" y="0" width="40" height="40" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="4"/>
      <feGaussianBlur stdDeviation="2"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.27679 0 0 0 0 0.634679 0 0 0 0 0.27679 0 0 0 0.5 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5099_2043"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5099_2043" result="shape"/>
      </filter>
      </defs>
      </svg>
      `,
                className: 'svg-icon',
            }),
        [],
    )

    return <Marker icon={locationMarker} position={position} {...rest} />
}

export { CourierMarker }

import React, { useCallback, useEffect } from 'react'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { Button, Checkbox, InputWrapper, MediumText, RegularText, TextInput } from 'src/components'
import { EButtonVariant } from 'src/components/Button'
import { ETextInputVariant } from 'src/components/TextInput'
import { RoundedWrapper } from 'src/components/RoundedWrapper'
import { setCourierInfoModalAtom } from 'src/atoms/allCouriers/courierCreateModalAtom'
import { fetchAllCouriersAtom, selectedCourierAtom, courierSearchQueryAtom, couriersAtom } from 'src/atoms/allCouriers/allCouriersAtom'
import { openMoveCourierGroupAtom } from 'src/atoms/allCouriers/modalCourierAtom'
import { showWorkingCouriersAtom } from 'src/atoms/allCouriers/allCouriersAtom'

import { Excel } from '../../services/Excel'
import { setCourierDeleteAtom } from '../../atoms/allCouriers/courierEditModalAtom'

import styles from './styles.module.scss'
const THEADS = [
    'Таб. номер',
    'Статус смены',
    'Фамилия',
    'Телефон',
    'Статус заказа',
    'Общая сумма доставок',
    'Общее количество доставок',
    'Среднее количество доставок в день',
    'Пройденное расстояние',
    'Среднее пройденное расстояние',
    'Расстояние в пустую',
    'Среднее время доставки',
    'Время впустую на смене',
    'Дата увольнения',
    'Комментарий',
]
export const CouriersTableLowFilters = (): JSX.Element => {
    const onClickCreateCourier = useSetAtom(setCourierInfoModalAtom)
    const selectedCourier = useAtomValue(selectedCourierAtom)
    const openMoveCourier = useSetAtom(openMoveCourierGroupAtom)
    const refreshCouriers = useSetAtom(fetchAllCouriersAtom)
    const [searchQuery, setSearchQuery] = useAtom(courierSearchQueryAtom)
    const [showOnlyWorkingCouriers, setShowOnlyWorkingCouriers] = useAtom(showWorkingCouriersAtom)
    const [, fetchCouriers] = useAtom(fetchAllCouriersAtom)
    const setDeleteCourierDialog = useSetAtom(setCourierDeleteAtom)
    const { data: couriersData } = useAtomValue(couriersAtom)

    const handleSearchChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchQuery(e.target.value)
        },
        [setSearchQuery],
    )

    const handleCheckboxChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setShowOnlyWorkingCouriers(event.target.checked)
            refreshCouriers()
        },
        [setShowOnlyWorkingCouriers, refreshCouriers],
    )

    const handleDeleteCourier = () => {
        if (!selectedCourier?.item?.courier_id) return

        setDeleteCourierDialog({
            courier_id: selectedCourier.item.courier_id,
            firstName: selectedCourier.item.firstName,
        })
    }

    useEffect(() => {
        fetchCouriers()
    }, [fetchCouriers])

    const onDownloadClick = () => {
        if (!couriersData) return
        const KEYS = [
            'item.tabNumber',
            'isWorkingToday',
            'item.surname',
            'item.phoneNumber',
            'item.orderStatus',
            'item.activeOrderCount',
            'item.totalOrderPrice',
            'item.averageOrdersPerDay',
            'item.walkingDistance',
            'averageWalkage',
            'pointlessWalking',
            'averageOrderTime',
            'pointlessWorkTime',
            'item.fire_date',
            'item.comment',
        ]

        const FN = (key: string, node: any) => {
            switch (key) {
                case 'isWorkingToday':
                    return node[key] ? 'Работает' : 'Не работает'
            }

            return ''
        }

        Excel.generateFile({
            heads: THEADS,
            keys: KEYS,
            data: couriersData,
            fn: FN,
            fileName: 'couriers',
        })
    }

    return (
        <div className={styles.courierContainer}>
            <div className='flex items-center justify-between'>
                <div className='flex items-center'>
                    <Button onClick={onClickCreateCourier} icon='plus' variant={EButtonVariant.PRIMARY} className='mr-1.5'>
                        <RegularText>Добавить курьера</RegularText>
                    </Button>
                    <TextInput
                        variant={ETextInputVariant.SECONDARY}
                        rightIcon='search'
                        placeholder='Поиск'
                        className='w-inputL'
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    <div className='flex items-center gap-2 ml-4'>
                        <RoundedWrapper icon='refresh' onClick={() => refreshCouriers()} />
                        <RoundedWrapper icon='trash' onClick={handleDeleteCourier} disabled={!selectedCourier} />
                        <RoundedWrapper icon='move' onClick={openMoveCourier} disabled={!selectedCourier} />
                    </div>
                </div>
                <div className='flex gap-3 pr-2'>
                    <InputWrapper className='self-end w-full' name='Только на смене'>
                        <Checkbox checked={Boolean(showOnlyWorkingCouriers)} onChange={handleCheckboxChange} />
                    </InputWrapper>
                    <Button variant={EButtonVariant.GREEN} icon='excel' onClick={onDownloadClick} disabled={!couriersData}>
                        <MediumText>Download</MediumText>
                    </Button>
                </div>
            </div>
        </div>
    )
}

import { AppSelect, ColoredSquare, RegularText, SelectOption, TextInput } from 'src/components'
import { ESelectVariant } from 'src/components/AppSelect'
import { RoundedWrapper } from 'src/components/RoundedWrapper'
import { useEffect, useState } from 'react'
import { useAtomValue } from 'jotai/index'
import { useSetAtom } from 'jotai'
import { PropsValue } from 'react-select'
import classNames from 'classnames'

import { AppCitySelect } from '../../components/AppCitySelect'
import { getRestGroupAtom } from '../../atoms/restaraunts/createRestGroupAtom'
import { restMapAtom, restMapZoomCoordinatesAtom, updateRestMapFiltersAtom } from '../../atoms/restaraunts/restMapAtom'

import styles from './styles.module.scss'

const openTypeOptions = [
    { value: 'onlyOpenedAndClosed', label: 'Открытые и закрытые' },
    { value: 'onlyOpened', label: 'Только открытые' },
    { value: 'onlyClosed', label: 'Только закрытые' },
    { value: 'onlyBlocked', label: 'Только заблокированные' },
]

export const RestaurantsMapFilters = (): JSX.Element => {
    const { data: restGroups } = useAtomValue(getRestGroupAtom)
    const updateRestMapFilters = useSetAtom(updateRestMapFiltersAtom)
    const setRestMapZoomCoordinates = useSetAtom(restMapZoomCoordinatesAtom)
    const rests = useAtomValue(restMapAtom)
    const [restDropdownOptions, setRestDropdownOptions] = useState<SelectOption[]>([])

    const [searchVal, setSearchVal] = useState<string>('')
    const [selectedCity, setSelectedCity] = useState<SelectOption>()
    const [selectedGroup, setSelectedGroup] = useState<SelectOption>()
    const [selectedOpenType, setSelectedOpenType] = useState<SelectOption>(openTypeOptions[0])

    const onRefreshClick = () => {
        setSearchVal('')
    }

    const handleSelectCity = (val: PropsValue<SelectOption>) => {
        const value = val as SelectOption
        setSelectedCity(value)
        updateRestMapFilters({ city: value?.label ? value.label.split(',')[0] : '' })

        if ((val as SelectOption)?.value?.length) {
            const [lat, long] = (val as SelectOption)?.value.split(',')
            setRestMapZoomCoordinates({ lat: Number(lat), lng: Number(long) })
        }
    }

    const handleGroup = (val: PropsValue<SelectOption>) => {
        const value = val as SelectOption
        setSelectedGroup(value)
        updateRestMapFilters({ groupId: value?.value })
    }

    const handleOpenType = (val: PropsValue<SelectOption>) => {
        const value = val as SelectOption
        setSelectedOpenType(value)
        updateRestMapFilters({ openedType: value?.value })
    }

    const handleNameDropdown = (val: PropsValue<SelectOption>) => {
        if (!val) return
        const selected = val as SelectOption
        if (!selected?.value) return

        const selectedMapRest = rests.find(item => item.rest_id === selected.value)

        if (selectedMapRest?.rest_id) {
            setRestMapZoomCoordinates({ lat: selectedMapRest.longitude, lng: selectedMapRest.latitude })
        }
    }

    useEffect(() => {
        if (rests?.length) {
            setRestDropdownOptions(
                rests.map(item => ({
                    value: item.rest_id,
                    label: item.restName,
                })),
            )
        }
    }, [rests])

    return (
        <div
            className={classNames('flex justify-between p-6 border-b-primaryColor border-b-2 border-solid items-center', styles.filtersRow)}
        >
            <div className='flex gap-2'>
                <AppSelect
                    selectVariant={ESelectVariant.SECONDARY}
                    options={restDropdownOptions}
                    placeholder='Поиск по названию'
                    onChange={handleNameDropdown}
                    isClearable
                    isSearchable
                />
            </div>
            <div className='flex justify-start gap-3 items-center'>
                <AppCitySelect
                    selectVariant={ESelectVariant.SECONDARY}
                    placeholder={'Все города'}
                    isClearable
                    onChange={handleSelectCity}
                    value={selectedCity}
                    coordinatesForValue
                />
                <AppSelect
                    selectVariant={ESelectVariant.SECONDARY}
                    options={restGroups?.length ? restGroups.map(item => ({ value: item.restgroup_id, label: item.title })) : []}
                    placeholder='Все рестораны (группы)'
                    onChange={handleGroup}
                    isClearable
                    value={selectedGroup}
                />
                <AppSelect
                    selectVariant={ESelectVariant.SECONDARY}
                    options={openTypeOptions}
                    placeholder='Все рестораны'
                    isClearable
                    value={selectedOpenType}
                    onChange={handleOpenType}
                />
            </div>
        </div>
    )
}

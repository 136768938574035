import { atomWithRefresh } from 'jotai/utils'
import { Delivery, LokaliApi } from 'src/services'
import { DefaultRequestState } from 'src/types'
import { atom } from 'jotai'

import { currentCourierAtom } from '../allOrders/couriersListAtom'
export const courierDelivDateRangeAtom = atom({
    dateFrom: '',
    dateTo: '',
})

export const courierDelivSearchQueryAtom = atom('')

const getCourierDeliveries = atomWithRefresh<Promise<DefaultRequestState<Delivery | null>>>(async get => {
    const currentCourier = get(currentCourierAtom)
    const dateFilters = get(courierDelivDateRangeAtom)
    const searchQuery = get(courierDelivSearchQueryAtom)

    const currentCourierId = currentCourier?.item.courier_id

    try {
        if (currentCourierId) {
            const data = await LokaliApi.getDeliveries({
                courier_id: currentCourierId,
                dateFrom: dateFilters.dateFrom || undefined,
                dateTo: dateFilters.dateTo || undefined,
                q: searchQuery || undefined,
            })
            return {
                data,
                error: null,
            }
        }
        console.log('No courier ID, returning null')
        return {
            data: null,
            error: null,
        }
    } catch (e) {
        console.error('Error fetching deliveries:', e)
        return {
            error: e as Error,
            data: null,
        }
    }
})

export { getCourierDeliveries }

import { atom } from 'jotai'
import { ModalClientDBAddGroup } from 'src/features/ClientDatabaseModals'
import { NotSavedDialog } from 'src/features/Dialogs/NotSavedDialog'
import { HttpStatusCode } from 'src/types'
import { ClientGroupEditRequest, CreateClientGroupRequest, LokaliApi } from 'src/services/LocaliRepository'
import { MassBonusModal } from 'src/features/ClientDatabaseModals/MassBonusModal'
import { Icon } from 'src/assets/icons'

import { ModalClientDBEditGroup } from '../../features/ClientDatabaseModals/ModalClientDBEditGroup'

import { modalAtom, setCloseAllModals, subModalAtom } from '../global/modalAtom'
import { dialogStateAtom, closeDialogAtom } from '../global/dialogAtom'

import { allClientGroupsAtom } from './clientGroupsAtom'

export const createClientDBGroupStatusAtom = atom<HttpStatusCode | null>(null)

export const addClientDBGroupAtom = atom(null, (_, set) => {
    set(modalAtom, {
        isOpen: true,
        children: <ModalClientDBAddGroup />,
        title: 'Группы',
        onClose: () => {
            set(dialogStateAtom, {
                isOpen: true,
                children: <NotSavedDialog onAccept={() => set(setCloseAllModals)} onAbort={() => set(closeDialogAtom)} />,
            })
        },
    })
})

export const editClientDBGroupAtom = atom(null, (_, set) => {
    set(modalAtom, {
        isOpen: true,
        children: <ModalClientDBEditGroup />,
        title: 'Группы',
        onClose: () => {
            set(dialogStateAtom, {
                isOpen: true,
                children: <NotSavedDialog onAccept={() => set(setCloseAllModals)} onAbort={() => set(closeDialogAtom)} />,
            })
        },
    })
})

export const openMassBonusAtom = atom(null, (_, set) => {
    set(subModalAtom, {
        isOpen: true,
        children: <MassBonusModal />,
        title: 'Массовое начисление бонусов',
        icon: <Icon type='whiteGift' />,
        height: 700,
    })
})

export const createClientDBGroupAtom = atom(null, async (_, set, req: CreateClientGroupRequest) => {
    try {
        set(createClientDBGroupStatusAtom, HttpStatusCode.LOADING)
        const response = await LokaliApi.createClientGroup(req)

        if (response === HttpStatusCode.OK) {
            await Promise.resolve(set(createClientDBGroupStatusAtom, HttpStatusCode.OK))
            set(allClientGroupsAtom)
            set(setCloseAllModals)
        }

        set(createClientDBGroupStatusAtom, null)
    } catch (e) {
        set(createClientDBGroupStatusAtom, HttpStatusCode.INTERNAL_SERVER_ERROR)
    }
})

export const clientDBGroupEditAtom = atom(null, async (_, set, req: ClientGroupEditRequest) => {
    try {
        const response = await LokaliApi.clientGroupEdit(req)

        if (response === HttpStatusCode.OK) {
            set(allClientGroupsAtom)
            set(setCloseAllModals)
        }
    } catch (e) {}
})

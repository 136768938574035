import { PropsWithChildren } from 'react'
import classNames from 'classnames'
import { DivProps } from 'src/types'

import { RegularText } from '../RegularText'

type InputWrapperProps = {
    name?: string
} & DivProps

export const InputWrapper = (props: PropsWithChildren<InputWrapperProps>): JSX.Element => {
    const { children, className, name, ...rest } = props
    return (
        <div className={classNames('flex gap-1 justify-end', className)} {...rest}>
            <RegularText>{name}</RegularText>
            {children}
        </div>
    )
}

import { atom } from 'jotai'
import { EditRestRequest, LokaliApi, RestWorkSchedule } from 'src/services'
import { HttpStatusCode } from 'src/types'
import { WORK_DAYS, WorkDay } from 'src/features/Restaraunts/utils'
import { atomWithImmer } from 'jotai-immer'
import { EditRestFormData } from 'src/features/Restaraunts/EditRestSchema'

import { setModalCloseAtom } from '../global/modalAtom'

import { currentRestAtom, getRestAtom } from './restAtom'
import { editRestDeliveryZonesAtom, editRestMapMarkersAtom } from './editRestDeliveryZoneAtom'

const editAllDaysAtom = atomWithImmer<WorkDay>({
    workStart: '',
    workEnd: '',
    lunchStart: '',
    lunchEnd: '',
    day: 'ВСЕ',
    isActive: false,
})

const editWorkTimeAtom = atomWithImmer<WorkDay[]>(WORK_DAYS)
const editWorkTimeModeAtom = atom<boolean>(false)
const editWorkTimeIndexAtom = atom<number>(-1)

const editRestNetworkStateAtom = atom<HttpStatusCode>(HttpStatusCode.I_AM_A_TEAPOT)

const editRestAtom = atom(null, async (get, set, update: EditRestFormData) => {
    const currentRestId = get(currentRestAtom)?.rest_id
    const deliveryZone = get(editRestDeliveryZonesAtom)

    const workTime = get(editWorkTimeAtom)
    const mode = get(editWorkTimeModeAtom)

    const allDays = get(editAllDaysAtom)

    const eachDay: RestWorkSchedule[] = workTime.map((time, idx) => ({
        day: (idx + 1).toString(),
        from: time.workStart,
        to: time.workEnd,
        breakFrom: time.lunchStart,
        breakTo: time.lunchEnd,
    }))

    const all: RestWorkSchedule[] = ['1', '2', '3', '4', '5', '6', '7'].map(item => ({
        day: item,
        from: allDays.workStart,
        to: allDays.workEnd,
        breakFrom: allDays.lunchStart,
        breakTo: allDays.lunchEnd,
    }))

    set(editRestNetworkStateAtom, HttpStatusCode.LOADING)

    try {
        if (currentRestId) {
            const req: EditRestRequest = {
                ...update,
                group_id: update.group_id || '',
                paymentMethods: update.paymentMethods,
                rest_id: currentRestId,
                schedule: mode ? all : eachDay,
                isOurCouriers: update.isOurCouriers === 'Lokali',
                minPrepareTime: Number(update.minPrepareTime),
                order_region: deliveryZone.map(zone => zone.latLng) as unknown as number[][],
            }
            const response = await LokaliApi.editRest(req)

            if (response === HttpStatusCode.OK) {
                set(editRestNetworkStateAtom, HttpStatusCode.OK)
                set(setModalCloseAtom)
                set(getRestAtom)
            }
            set(editRestNetworkStateAtom, HttpStatusCode.I_AM_A_TEAPOT)
        }
    } catch (e) {
        set(editRestNetworkStateAtom, HttpStatusCode.INTERNAL_SERVER_ERROR)
    }
})

const addEditRestDeliveryZoneAtom = atom(null, (get, set, update: number) => {
    set(editRestDeliveryZonesAtom, old => [
        ...old,
        {
            title: `Зона доставки ${update}`,
            description: 'Описание',
            latLng: get(editRestMapMarkersAtom),
        },
    ])
    set(editRestMapMarkersAtom, [])
})

export {
    editRestAtom,
    editAllDaysAtom,
    editRestNetworkStateAtom,
    editWorkTimeAtom,
    editWorkTimeModeAtom,
    editWorkTimeIndexAtom,
    addEditRestDeliveryZoneAtom,
}

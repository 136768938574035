import { TextAreaProps } from 'src/types/html'
import classNames from 'classnames'
import { FieldError } from 'react-hook-form'

import { ErrorText } from '../ErrorText'

import styles from './styles.module.scss'

type TextAreaComponentProps = TextAreaProps & {
    error?: FieldError
}

export const TextArea = (props: TextAreaComponentProps): JSX.Element => {
    const { className, error, ...rest } = props

    return (
        <div className='flex flex-col'>
            <textarea className={classNames(styles.textArea, className)} {...rest} />
            {error?.message && <ErrorText error={error.message} />}
        </div>
    )
}

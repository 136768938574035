import { PropsWithChildren } from 'react'
import { SpanProps } from 'src/types/html'
import classNames from 'classnames'

import styles from './styles.module.scss'

export const LightText = (props: PropsWithChildren<SpanProps>): JSX.Element => (
    <span {...props} className={classNames(styles.text, props.className)}>
        {props.children}
    </span>
)

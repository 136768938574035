import { Button, RegularText, TextInput } from 'src/components'
import { EButtonVariant } from 'src/components/Button'
import { RoundedWrapper } from 'src/components/RoundedWrapper'
import { atom, useAtomValue, useSetAtom } from 'jotai/index'
import { useEffect } from 'react'
import { useAtom } from 'jotai'

import { fetchAllCouriersAtom } from '../../atoms/allCouriers/allCouriersAtom'
import { currentCourierAtom, ordersCouriersSearchAtom } from '../../atoms/allOrders/couriersListAtom'
import { openAssignDialogAtom } from '../../atoms/allOrders/assignOrderAtom'
import { currentOrderAtom } from '../../atoms/allOrders/ordersAtom'

import styles from './styles.module.scss'

export const AllOrderAssignCourier = (): JSX.Element => {
    const assignOrder = useSetAtom(openAssignDialogAtom)
    const currentOrder = useAtomValue(currentOrderAtom)
    const currentCourier = useAtomValue(currentCourierAtom)
    const refreshCouriers = useSetAtom(fetchAllCouriersAtom)
    const [ordersCouriersSearch, setOrdersCouriersSearch] = useAtom(ordersCouriersSearchAtom)

    const onRefreshClick = () => {
        setOrdersCouriersSearch('')
        refreshCouriers().then()
    }

    useEffect(() => {
        refreshCouriers().then()
    }, [])

    return (
        <div className={styles.assignContainer}>
            <TextInput
                rightIcon='search'
                placeholder='Поиск курьера'
                className='w-inputM'
                value={ordersCouriersSearch}
                onChange={ev => setOrdersCouriersSearch(ev.target.value)}
            />
            <RoundedWrapper icon='refresh' onClick={onRefreshClick} />
            <Button
                icon='plus'
                variant={EButtonVariant.PRIMARY}
                className='mr-1.5'
                disabled={!currentOrder || !currentCourier}
                onClick={() => assignOrder()}
            >
                <RegularText>Назначить</RegularText>
            </Button>
        </div>
    )
}

import { AppSelect, Button, DateInput, Divider, MediumText, RegularText, AppTable } from 'src/components'
import { EButtonVariant } from 'src/components/Button'
import { useSetAtom } from 'jotai'

import { setCloseSubModalAtom } from '../../atoms/global/modalAtom'

const THEADS = ['Должность', 'Оклад', 'Тариф руб./час', 'График']

export const CourierCreateSalaryRate = (): JSX.Element => {
    const closeSubModal = useSetAtom(setCloseSubModalAtom)

    return (
        <div>
            <AppTable theads={THEADS} tbody={[]} renderCellFn={() => <div />} table />
            <Divider />
            <div className='py-5 flex'>
                <AppSelect options={[]} />
                <div className='ml-24'>
                    <MediumText>Дата применения</MediumText>
                    <DateInput />
                </div>
            </div>
            <div className='flex h-full justify-end items-end'>
                <Button icon='save' variant={EButtonVariant.BLUE} className='mr-2' type='submit'>
                    <MediumText>Сохранить</MediumText>
                </Button>
                <Button onClick={closeSubModal}>
                    <RegularText>Отмена</RegularText>
                </Button>
            </div>
        </div>
    )
}

import { PropsWithChildren, ReactNode } from 'react'

import styles from './styles.module.scss'

type BaseLayoutProps = {
    leftChild: ReactNode
    filtersChild?: ReactNode
}

export const BaseLayout = (props: PropsWithChildren<BaseLayoutProps>): JSX.Element => {
    const { leftChild, children, filtersChild } = props
    return (
        <div className={styles.baseLayout}>
            <div className={styles.leftChild}>{leftChild}</div>
            <div className={styles.rightElement}>
                <div className={styles.filters}>{filtersChild}</div>
                <div className={styles.children}>{children}</div>
            </div>
        </div>
    )
}

import { atom } from 'jotai'
import { atomWithImmer } from 'jotai-immer'
import { HttpStatusCode } from 'src/types'

import { LokaliApi } from '../../services/LocaliRepository/lokali'
import { CreateAdminPaymentRequest } from '../../services/LocaliRepository/types/createAdminPayment'
import { AdminPaymentsResponse } from '../../services/LocaliRepository/types/createAdminPayment'

import { currentAdminAtom } from './adminsTableAtom'

export const adminPaymentsAtom = atomWithImmer<AdminPaymentsResponse | null>(null)

export const createAdminPaymentAtom = atom(null, async (get, set, payload: CreateAdminPaymentRequest) => {
    try {
        const response = await LokaliApi.createAdminPayment(payload)
        if (response === HttpStatusCode.OK) {
            const currentAdmin = get(currentAdminAtom)
            if (currentAdmin) {
                const updatedPayments = await LokaliApi.getAdminPayment({ admin_id: currentAdmin.admin_id })
                if (Array.isArray(updatedPayments)) {
                    set(adminPaymentsAtom, updatedPayments)
                }
            }
            return HttpStatusCode.OK
        }
        return response
    } catch (error) {
        console.error('Error creating admin payment:', error)
        return HttpStatusCode.INTERNAL_SERVER_ERROR
    }
})

export const getAdminPaymentsAtom = atom(null, async (get, set) => {
    try {
        const currentAdmin = get(currentAdminAtom)
        if (currentAdmin) {
            const payments = await LokaliApi.getAdminPayment({ admin_id: currentAdmin.admin_id })
            set(adminPaymentsAtom, payments)
        }
    } catch (error) {
        console.error('Error fetching admin payments:', error)
    }
})

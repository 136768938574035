import React from 'react'
import classNames from 'classnames'
import { InputProps } from 'src/types'

import styles from './styles.module.scss'

type SwitchProps = {
    className?: string
} & InputProps

const Switch = (props: SwitchProps): JSX.Element => {
    const { onChange, className, checked, onClick, id, ...rest } = props

    return (
        <div className={classNames('flex justify-center items-center', className)}>
            <input
                className={styles.switchCheckbox}
                id={id}
                checked={checked ?? false}
                onChange={onChange}
                onClick={onClick}
                type='checkbox'
                {...rest}
            />
            <label
                htmlFor={id}
                className={classNames(styles.switchLabel, {
                    [styles.switchLabelActive]: checked,
                })}
            >
                <span className={styles.switchButton} />
            </label>
        </div>
    )
}

export { Switch }

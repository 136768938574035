import { atom } from 'jotai'
import { ModalOrderInfo } from 'src/features/Orders'

import { modalAtom } from '../global/modalAtom'

const openOrderInfoModalAtom = atom(null, (_, set) => {
    set(modalAtom, {
        isOpen: true,
        title: 'Информация о заказе',
        children: <ModalOrderInfo />,
    })
})

export { openOrderInfoModalAtom }

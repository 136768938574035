import { useSetAtom } from 'jotai'
import { useState } from 'react'
import { setCloseSubModalAtom } from 'src/atoms/global/modalAtom'
import { createRestGroupAtom } from 'src/atoms/restaraunts/createRestGroupAtom'
import { Button, EButtonVariant, MediumText, RegularText, TextInput } from 'src/components'

export const ModalCreateRestGroup = (): JSX.Element => {
    const [loader, setLoader] = useState(false)
    const [title, setTitle] = useState('')
    const createRestGroup = useSetAtom(createRestGroupAtom)
    const closeModal = useSetAtom(setCloseSubModalAtom)

    const save = async () => {
        setLoader(true)
        await createRestGroup({ title })
        setLoader(false)
    }

    return (
        <div className='bg-aqua-400 flex flex-col flex-1'>
            <div className='flex-1 flex gap-2 flex-col justify-center items-center'>
                <div className='py-5'>
                    <MediumText className='text-xs'>Название группы</MediumText>
                    <TextInput
                        autoFocus
                        onChange={event => setTitle(event.target.value)}
                        className='w-inputL'
                        placeholder='Название группы'
                    />
                </div>
            </div>
            <div className='h-16 border-t-2 border-solid border-darkBlue-600 flex justify-center items-center'>
                <div className='flex justify-center gap-4 items-center'>
                    <Button onClick={closeModal} className='w-32'>
                        <RegularText>Отменить</RegularText>
                    </Button>
                    <Button
                        disabled={!Boolean(title.trim())}
                        onClick={save}
                        loader={loader}
                        variant={EButtonVariant.PRIMARY}
                        className='w-32'>
                        <RegularText>Создать</RegularText>
                    </Button>
                </div>
            </div>
        </div>
    )
}

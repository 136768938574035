import { atom } from 'jotai'
import { CreateCourierGroupRequest, LokaliApi } from 'src/services/LocaliRepository'
import { HttpStatusCode, NetworkStatusState } from 'src/types'

import { modalAtom } from '../global/modalAtom'

import { allCourierGroupsAtom } from './allCourierGroupsAtom'

const createCourierGroupNetworkStateAtom = atom<NetworkStatusState>({
    statusCode: HttpStatusCode.SERVICE_UNAVAILABLE,
})

const createCourierGroupAtom = atom(null, async (_, set, req: CreateCourierGroupRequest) => {
    try {
        set(createCourierGroupNetworkStateAtom, {
            statusCode: HttpStatusCode.LOADING,
        })

        const statusCode = await LokaliApi.createCourierGroup(req)

        if (statusCode === HttpStatusCode.OK) {
            set(createCourierGroupNetworkStateAtom, {
                statusCode: HttpStatusCode.OK,
            })
            set(allCourierGroupsAtom)
        }

        set(modalAtom, {
            isOpen: false,
            children: null,
            title: '',
        })
    } catch (e) {
        set(createCourierGroupNetworkStateAtom, {
            statusCode: HttpStatusCode.INTERNAL_SERVER_ERROR,
        })
    }
})

export { createCourierGroupAtom, createCourierGroupNetworkStateAtom }

import { z } from 'zod'

import { CourierGroupSchema } from './CouriersGroupSchema'

export const PassDataSchema = z
    .object({
        passInn: z.string().optional(),
        passCity: z.string().optional(),
        passHouse: z.string().optional(),
        passSnils: z.string().optional(),
        passNumber: z.any().optional(),
        passSeries: z.string().optional(),
        passStreet: z.string().optional(),
        passEndDate: z.string().optional(),
        passHousing: z.string().optional(),
        courierPhoto: z.string().optional(),
        passIssuedBy: z.string().optional(),
        passApartment: z.string().optional(),
        passIssueDate: z.string().optional(),
    })
    .optional()
    .nullish()

export const CourierItemSchema = z.object({
    courier_id: z.string(),
    group_id: z.string().nullish(),
    isSalary: z.boolean().nullish(),
    isWorking: z.boolean().nullish(),
    login: z.string().nullish(),
    birthday: z.string().nullish(),
    sysName: z.string().nullish(),
    tabNumber: z.string().nullish(),
    firstName: z.string(),
    name: z.string(),
    patronymic: z.string().nullish(),
    phoneNumber: z.string(),
    surname: z.string().nullish(),
    weekends: z.string().nullish(),
    password: z.string().nullish(),
    lunchBreak: z.boolean().nullish(),
    lunchBreakTime: z.string().nullish(),
    dept: z.string().nullish(),
    gender: z.string().nullish(),
    whatsApp: z.string().nullish(),
    telegram: z.string().nullish(),
    email: z.string().nullish(),
    comment: z.string().nullish(),
    cityKey: z.string().nullish(),
    restaurant_id: z.string().nullish(),
    createdAt: z.string(),
    updatedAt: z.string(),
    passData: PassDataSchema,
    fire_date: z.string().optional(),
    fired: z.boolean().optional(),
})

export const CouriersSchema = z.object({
    item: CourierItemSchema,
    groupObject: z
        .object({
            group_id: z.string(),
            title: z.string(),
            createdAt: z.string(),
            updatedAt: z.string(),
            range: z.number().nullish(),
            cargo_size: z.number().nullish(),
            mass: z.number().nullish(),
            comment: z.string().nullish(),
        })
        .optional(),
    totalOrderCount: z.number(),
    totalOrderPrice: z.number(),
    averageOrderTime: z.number().optional(),
    averageOrdersPerDay: z.number().optional(),
    averageWalkage: z.number().optional(),
    isWorkingToday: z.boolean().optional(),
    pointlessWalking: z.number().optional(),
    activeOrderCount: z.number().optional(),
    pointlessWorkTime: z.number().optional(),
    walkingDistance: z.number().optional(),
})

export type Courier = z.infer<typeof CouriersSchema>

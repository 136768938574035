import React, { useState } from 'react'
import classNames from 'classnames'
import { AppImage } from 'src/components'

import styles from './styles.module.scss'

export const PassportImageHover = ({ photoUrl }: { photoUrl: string | null }): JSX.Element => {
    const [isHovered, setIsHovered] = useState(false)
    const isPhoto = Boolean(photoUrl)

    return (
        <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={classNames(styles.todoContainer, { [styles.hovered]: isHovered && photoUrl })}
        >
            {isPhoto ? <div className='text-orange-500'>Фото</div> : <div>Нет фото</div>}

            {isHovered && isPhoto && (
                <div className={styles.photoContainer}>
                    <AppImage className={styles.photo} src={photoUrl!} />
                </div>
            )}
        </div>
    )
}

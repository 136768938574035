import { useAtomValue, useSetAtom } from 'jotai'
import { useState } from 'react'
import { clientGiveGroupBonusAtom } from 'src/atoms/clientDb/clientBonusAtom'
import { allClientGroupsAtom } from 'src/atoms/clientDb/clientGroupsAtom'
import { setCloseSubModalAtom } from 'src/atoms/global/modalAtom'
import { Button, Checkbox, Divider, EButtonVariant, MediumText, RegularText, TextInput } from 'src/components'

type ClientGroupProps = {
    title: string
    checked: boolean
    onChange: () => void
}

const ClientGroup = (props: ClientGroupProps): JSX.Element => (
    <div className='flex gap-2 items-center ml-7'>
        <Checkbox onChange={props.onChange} checked={props.checked} />
        <RegularText>{props.title}</RegularText>
    </div>
)

export const MassBonusModal = (): JSX.Element => {
    const groups = useAtomValue(allClientGroupsAtom)
    const closeSubModal = useSetAtom(setCloseSubModalAtom)

    const giveGroupBonus = useSetAtom(clientGiveGroupBonusAtom)

    const [checkedIds, setCheckedIds] = useState<string[]>([])
    const [bonuses, setBonuses] = useState(0)

    const onChangeGroup = (id: string) => {
        if (checkedIds.includes(id)) {
            setCheckedIds(old => old.filter(arg => arg !== id))
            return
        }
        setCheckedIds(old => [...old, id])
    }

    const onAllGroups = () => {
        const isAllSelected = checkedIds.length === groups.data?.length
        if (isAllSelected) {
            setCheckedIds([])
        } else {
            const mapped = groups.data?.map(gr => gr.clientgroup_id) || []
            setCheckedIds(mapped)
        }
    }

    return (
        <div className='flex flex-col justify-between flex-1'>
            <div className='flex justify-center p-7 items-center gap-2'>
                <RegularText>Начислить бонусы</RegularText>
                <TextInput
                    className='w-inputM'
                    type='number'
                    placeholder='Начислить бонусы'
                    value={bonuses}
                    onChange={e => setBonuses(parseInt(e.target.value))}
                />
            </div>
            <Divider />
            <div className='px-7 flex flex-col gap-2'>
                <div className='flex gap-2 items-center'>
                    <Checkbox checked={checkedIds.length === groups.data?.length} onChange={onAllGroups} />
                    <MediumText>Выбрать все группы клиентов</MediumText>
                </div>
                <div className='h-96 overflow-scroll py-2'>
                    {groups.data?.map(group => (
                        <ClientGroup
                            key={group.clientgroup_id}
                            onChange={() => onChangeGroup(group.clientgroup_id)}
                            checked={checkedIds.includes(group.clientgroup_id)}
                            title={group.title}
                        />
                    ))}
                </div>
            </div>
            <Divider />
            <div className='flex justify-center items-center p-5 gap-4'>
                <Button onClick={closeSubModal}>
                    <RegularText>Отменить</RegularText>
                </Button>
                <Button
                    disabled={!bonuses || !checkedIds.length}
                    onClick={() =>
                        giveGroupBonus({
                            bonusCount: bonuses,
                            group_ids: checkedIds,
                            action: 'add',
                        })
                    }
                    variant={EButtonVariant.PRIMARY}
                >
                    <RegularText>Начислить</RegularText>
                </Button>
            </div>
        </div>
    )
}

import { atom } from 'jotai'
import { AllCourierParams, LokaliApi, ReAssignCourierGroupRequest } from 'src/services/LocaliRepository'
import { Courier, CourierGroup } from 'src/services/LocaliRepository/LocaliRepositorySchemas'
import { DefaultRequestState, HttpStatusCode } from 'src/types'

import { setCloseSubModalAtom } from '../global/modalAtom'
import { currentCourierAtom } from '../allOrders/couriersListAtom'

import { courierDateRangeAtom } from './allCouriersQueryParamsAtom'

const selectedCourierGroupAtom = atom<CourierGroup | null>(null)

const onSelectCourierAtom = atom(null, (_, set, update: CourierGroup | null) => {
    set(selectedCourierGroupAtom, update)
    set(currentCourierAtom, null)
    set(fetchAllCouriersAtom)
})
const selectedCourierAtom = atom<Courier | null>(null)

const selectCourierAtom = atom(null, (_, set, update: Courier) => {
    set(selectedCourierAtom, update)
})

const currentGroupIdAtom = atom(async get => {
    const id = get(selectedCourierGroupAtom)?.item.group_id
    return id
})

const showOnlyFiredCouriersAtom = atom<boolean | null>(false)
const courierSearchQueryAtom = atom('')

const showOnlyWorkingCouriersAtom = atom<boolean | null>(true)

const showWorkingCouriersAtom = atom(
    get => get(showOnlyWorkingCouriersAtom),
    (get, set, data: boolean | null) => {
        set(showOnlyWorkingCouriersAtom, data)
        get(fetchAllCouriersAtom)
    },
)

const showFiredCouriersAtom = atom(
    get => get(showOnlyFiredCouriersAtom),
    (get, set, data: boolean | null) => {
        set(showOnlyFiredCouriersAtom, data)
        get(fetchAllCouriersAtom)
    },
)
const couriersAtom = atom<DefaultRequestState<Courier[]>>({
    data: [],
    error: null,
    isLoading: false,
})
const couriersNoFilterAtom = atom<Courier[]>([])

const fetchAllCouriersAtom = atom(null, async (get, set) => {
    const id = await get(currentGroupIdAtom)
    const { dateFrom, dateTo } = get(courierDateRangeAtom)
    const showOnlyWorkingCouriers = get(showWorkingCouriersAtom)
    const showOnlyFiredCouriers = get(showFiredCouriersAtom)

    const params: AllCourierParams = {
        group_id: id,
        dateFrom,
        dateTo,
    }

    set(couriersAtom, {
        isLoading: true,
        data: [],
        error: null,
    })

    try {
        const data = await LokaliApi.getAllCouriers(params)

        set(couriersNoFilterAtom, data)
        let filteredData = data

        if (typeof showOnlyFiredCouriers === 'boolean') {
            filteredData = filteredData.filter(courier => Boolean(courier.item.fired) === showOnlyFiredCouriers)
        }
        if (showOnlyWorkingCouriers === true) {
            filteredData = filteredData.filter(courier => Boolean(courier.isWorkingToday))
        }

        set(couriersAtom, {
            isLoading: false,
            data: filteredData,
            error: null,
        })
    } catch (e) {
        set(couriersAtom, {
            isLoading: false,
            data: [],
            error: e as Error,
        })
    }
})
const reAssignCourierGroupAtom = atom(null, async (_, set, req: ReAssignCourierGroupRequest) => {
    try {
        const response = await LokaliApi.reAssignCourierGroup(req)

        if (response === HttpStatusCode.OK) {
            set(setCloseSubModalAtom)
            set(fetchAllCouriersAtom)
        }
    } catch (e) {
        throw e
    }
})

export {
    selectedCourierGroupAtom,
    couriersNoFilterAtom,
    onSelectCourierAtom,
    couriersAtom,
    selectedCourierAtom,
    selectCourierAtom,
    reAssignCourierGroupAtom,
    fetchAllCouriersAtom,
    courierSearchQueryAtom,
    showWorkingCouriersAtom,
    showFiredCouriersAtom,
}

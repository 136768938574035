import { atom } from 'jotai'
import { LokaliApi } from 'src/services/LocaliRepository'
import { CreateClientDBRequest } from 'src/services/LocaliRepository/types/createClientDB'
import { HttpStatusCode } from 'src/types'

import { setCloseAllModals } from '../global/modalAtom'

import { dbClientsAtom } from './clientsAtom'

const createClientDBRequestStatusAtom = atom<HttpStatusCode | null>(null)

const createClientDBAtom = atom(null, async (_, set, req: CreateClientDBRequest) => {
    set(createClientDBRequestStatusAtom, HttpStatusCode.LOADING)

    try {
        const response = await LokaliApi.createClientDB(req)

        if (response === HttpStatusCode.CREATED) {
            set(createClientDBRequestStatusAtom, HttpStatusCode.OK)
            set(dbClientsAtom)
        }

        set(setCloseAllModals)
        set(createClientDBRequestStatusAtom, null)
        set(dbClientsAtom)
    } catch (e) {
        set(createClientDBRequestStatusAtom, HttpStatusCode.INTERNAL_SERVER_ERROR)
    }
})

export { createClientDBAtom, createClientDBRequestStatusAtom }

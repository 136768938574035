import { atom } from 'jotai'
import { NavigateFunction } from 'react-router-dom'
import { LokaliApi } from 'src/services/LocaliRepository'
import { LoginRequest } from 'src/services/LocaliRepository/types/login'
import { ERoutes } from 'src/router/routes'
import { atomWithStorage } from 'jotai/utils'
import { AppLocalStorage } from 'src/services/LocalStorage'

type AuthAtomState = {
    hasError: boolean
}

type LoginDataState = {
    phoneNumber: string
    password: string
}

type Navigation = { navigate: NavigateFunction }

const loginDataAtom = atom<LoginDataState>({
    phoneNumber: '',
    password: '',
})

const authTokenAtom = atomWithStorage<string | null>('token', AppLocalStorage.getToken())

const authStateAtom = atom<AuthAtomState>({ hasError: false })

const isAuthenticatedAtom = atom(get => {
    const token = get(authTokenAtom)

    return !!token
})

const authAtom = atom(null, async (_, set, update: LoginRequest & Navigation) => {
    const { phoneNumber, password, navigate } = update

    try {
        const response = await LokaliApi.login({ phoneNumber, password })

        set(authTokenAtom, response.data)

        navigate(ERoutes.ROOT + ERoutes.MAIN_MAIN)
    } catch (e) {
        set(authStateAtom, { hasError: true })
    }
})

const logoutAtom = atom(null, (_, set) => {
    set(authTokenAtom, null)
})

export { authStateAtom, authAtom, loginDataAtom, logoutAtom, authTokenAtom, isAuthenticatedAtom }

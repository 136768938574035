import React from 'react'
import { AppBarChart, SemiBoldText, ShadowContainer } from 'src/components'
import { useAtomValue } from 'jotai'

import { mainStatisticsAtom } from '../../atoms/mainStatistics/getMainStatisticsAtom'

interface TurnoverData {
    name: string
    pv: number
    amt: number
}

interface MainStatistics {
    totalTurnover: TurnoverData[]
}

export const MainCashTurnOver = () => {
    const mainStatistics = useAtomValue(mainStatisticsAtom) as MainStatistics | null

    const chartData = mainStatistics?.totalTurnover || []

    const sortedChartData = [...chartData].sort((a, b) => {
        const dateA = new Date(a.name.split('.').reverse().join('-'))
        const dateB = new Date(b.name.split('.').reverse().join('-'))
        return dateA.getTime() - dateB.getTime()
    })

    const totalTurnover = sortedChartData.reduce((sum, item) => sum + item.pv, 0)

    return (
        <ShadowContainer className='flex flex-col gap-10'>
            <div className='flex justify-between'>
                <div>
                    <SemiBoldText>Общий оборот: {totalTurnover.toFixed(2)}</SemiBoldText>
                </div>
            </div>

            <div className='w-full h-40'>
                {sortedChartData.length > 0 ? (
                    <AppBarChart data={sortedChartData} tooltip={'Общий оборот'} />
                ) : (
                    <div>Нет данных о прибыли</div>
                )}
            </div>
        </ShadowContainer>
    )
}

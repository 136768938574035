import React from 'react'
import { Button, RegularText, SemiBoldText } from 'src/components'
import { EButtonVariant } from 'src/components/Button'
import { DateUtils } from 'src/utils'

import { statusNames } from '../../utils/statusNames'

type CourierCreateAssignOrderItemProps = {
    orderNumber: number
    orderDate: string
    orderPrice: number
    orderStatus: string
    spentTimeOnCooking: number | null
    onClick: () => void
    onOpenClick: () => void
    isSelected: boolean
    restAddress: string
    deliveryAddress: string
}

export const CourierCreateAssignOrderItem: React.FC<CourierCreateAssignOrderItemProps> = ({
    orderNumber,
    orderDate,
    orderPrice,
    orderStatus,
    spentTimeOnCooking,
    onClick,
    onOpenClick,
    isSelected,
    restAddress,
    deliveryAddress,
}) => (
    <div
        className={`w-full h-50 flex border-solid border border-mainBackgroundColor justify-between p-4 cursor-pointer 
    ${isSelected ? 'bg-blue-100' : ''}`}
        onClick={onClick}
    >
        <div className='flex flex-col gap-2'>
            <RegularText>
                {DateUtils.formatDate(orderDate)} | №{orderNumber}
            </RegularText>
            <SemiBoldText>Заказ на {orderPrice}р</SemiBoldText>
            <RegularText>
                {restAddress || 'не указан'} | {deliveryAddress || 'не указан'}
            </RegularText>
            <RegularText>Время приготовления: {spentTimeOnCooking?.toString() || 'не указано'}</RegularText>
        </div>
        <div className='flex flex-col justify-between'>
            <Button>
                <RegularText className='text-nowrap whitespace-nowrap'>{statusNames[orderStatus] || orderStatus}</RegularText>
            </Button>
            <Button variant={EButtonVariant.QUARTERY} className={'text-nowrap whitespace-nowrap'} onClick={onOpenClick}>
                <RegularText className='underline'>Открыть заказ</RegularText>
            </Button>
        </div>
    </div>
)

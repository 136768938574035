import { atom } from 'jotai'
import { LokaliApi } from 'src/services'
import { HttpStatusCode } from 'src/types'

import { getCourierAvailabilityAtom } from './courierAvailabilityAtom'

const deleteCourierAvailAtom = atom(null, async (_, set, id?: string) => {
    try {
        if (id) {
            const response = await LokaliApi.deleteCourierAvailability(id)
            if (response === HttpStatusCode.OK) {
                set(getCourierAvailabilityAtom)
            }
        }
    } catch (e) {}
})

export { deleteCourierAvailAtom }

import React, { Suspense, useMemo } from 'react'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectedCourierGroupAtom, onSelectCourierAtom } from 'src/atoms/allCouriers/allCouriersAtom'
import { allCourierGroupsAtom } from 'src/atoms/allCouriers/allCourierGroupsAtom'
import { setCourierEditGroupAtom } from 'src/atoms/allCouriers/courierEditModalAtom'
import { Spinner, GridTable, GridTableHead, GridTableItem } from 'src/components/'

const THEADS = ['Группа курьеров', 'Прибыль']

export const CouriersList = (): JSX.Element => {
    const currentCourierGroup = useAtomValue(selectedCourierGroupAtom)
    const onSelectCourier = useSetAtom(onSelectCourierAtom)
    const editCourierGroup = useSetAtom(setCourierEditGroupAtom)
    const { data } = useAtomValue(allCourierGroupsAtom)
    const totalOrderPrice = useMemo(() => (data?.length ? data.reduce((accum, item) => accum + (item.orderPrice || 0), 0) : 0), [data])

    return (
        <Suspense fallback={<Spinner />}>
            <div className='h-full'>
                <GridTable
                    theads={THEADS.map(el => (
                        <GridTableHead key={el} title={el} />
                    ))}>
                    <tr>
                        <GridTableItem
                            onClick={() => onSelectCourier(null)}
                            isActive={currentCourierGroup === null}
                            child={'Все курьеры'}
                        />
                        <GridTableItem
                            onClick={() => onSelectCourier(null)}
                            isActive={currentCourierGroup === null}
                            child={totalOrderPrice}
                        />
                    </tr>
                    {data &&
                        data.map(group => {
                            const isActive = group?.item.group_id === currentCourierGroup?.item.group_id
                            return (
                                <tr key={group.item.group_id}>
                                    <GridTableItem
                                        onClick={() => onSelectCourier(group)}
                                        onDoubleClick={() => editCourierGroup(group)}
                                        isActive={isActive}
                                        child={group.item.title}
                                    />
                                    <GridTableItem
                                        onClick={() => onSelectCourier(group)}
                                        onDoubleClick={() => editCourierGroup(group)}
                                        isActive={isActive}
                                        child={group.orderPrice}
                                    />
                                </tr>
                            )
                        })}
                </GridTable>
            </div>
        </Suspense>
    )
}

import { useSetAtom } from 'jotai'
import { closeDialogAtom } from 'src/atoms/global/dialogAtom'
import { AppImage, Button, EButtonVariant, MediumText, RegularText } from 'src/components'
import { Assets } from 'src/utils'

export type BonusDialogProps = {
    type: 'plus' | 'minus'
    amount: number
}

export const BonusDialog = (props: BonusDialogProps): JSX.Element => {
    const closeDialog = useSetAtom(closeDialogAtom)

    return (
        <div className='flex flex-col items-center justify-center h-full p-8'>
            <div className='size-14'>
                {props.type === 'minus' ? <AppImage src={Assets.sadSmiley} /> : <AppImage src={Assets.happySmiley} />}
            </div>
            <div className='flex-1 flex flex-col justify-center items-center'>
                {props.type === 'minus' ? <RegularText>Отнято</RegularText> : <RegularText>Начислено</RegularText>}
                {props.type === 'minus' ? (
                    <MediumText className='text-red-800'>{props.amount}</MediumText>
                ) : (
                    <MediumText className='text-orange-500'>{props.amount}</MediumText>
                )}
            </div>
            <div className='flex gap-2'>
                <Button className='!w-44 flex justify-center !py-5' variant={EButtonVariant.PRIMARY} onClick={closeDialog}>
                    <RegularText className='!text-base'>Ок</RegularText>
                </Button>
            </div>
        </div>
    )
}

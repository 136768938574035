import React, { useState, useEffect } from 'react'
import { Tab, Tabs } from 'src/components'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useSetAtom, useAtom } from 'jotai'
import { createAdminAtom, createAdminPhotoAtom } from 'src/atoms/admins/adminCreateAtom'
import {
    createGeneralRightAtom,
    createClientRightAtom,
    createCourierRightAtom,
    createRestRightAtom,
    createOrderRightAtom,
    createAdminRightAtom,
} from 'src/atoms/admins/adminCreateRightsAtom'

import { ModalCreateAdminInfo } from './ModalCreateAdminInfo'
import { ModalCreateAdminFooter } from './ModalCreateAdminFooter'
import { ModalCreateAdminPass } from './ModalCreateAdminPass'
import { ModalCreateRights } from './ModalCreateRights'
import { AdminCreateData, AdminCreateSchema } from './schema'

const MAX_FILE_SIZE = 5 * 1024 * 1024

export const ModalCreateAdmin = (): JSX.Element => {
    const methods = useForm<AdminCreateData>({
        resolver: zodResolver(AdminCreateSchema),
    })
    const [loading, setLoading] = useState(false)
    const createAdmin = useSetAtom(createAdminAtom)
    const [file, setFile] = useAtom(createAdminPhotoAtom)
    const [fileError, setFileError] = useState<string | null>(null)

    const [generalRights, setGeneralRights] = useAtom(createGeneralRightAtom)
    const [clientRights, setClientRights] = useAtom(createClientRightAtom)
    const [courierRights, setCourierRights] = useAtom(createCourierRightAtom)
    const [restRights, setRestRights] = useAtom(createRestRightAtom)
    const [orderRights, setOrderRights] = useAtom(createOrderRightAtom)
    const [adminRights, setAdminRights] = useAtom(createAdminRightAtom)

    const onSubmit = async (data: AdminCreateData) => {
        if (!fileError) {
            setLoading(true)
            await createAdmin(data)
            setLoading(false)
        }
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0]
        if (file) {
            if (file.size > MAX_FILE_SIZE) {
                setFileError('File size exceeds 5MB limit')
                setFile(null)
            } else {
                setFileError(null)
                setFile(file)
            }
        }
    }

    const resetRights = (setRightsFn: any) => {
        setRightsFn((prev: any) => prev.map((right: any) => ({ ...right, checked: false })))
    }

    const onReset = () => {
        methods.reset({})
        setFile(null)
        setFileError(null)
        resetRights(setGeneralRights)
        resetRights(setClientRights)
        resetRights(setCourierRights)
        resetRights(setRestRights)
        resetRights(setOrderRights)
        resetRights(setAdminRights)
    }

    useEffect(() => {
        const subscription = methods.watch(() => methods.trigger())
        return () => subscription.unsubscribe()
    }, [methods])

    return (
        <form onSubmit={methods.handleSubmit(onSubmit)} onReset={onReset}>
            <FormProvider {...methods}>
                <Tabs
                    tabs={[
                        {
                            tabOption: {
                                tabName: 'Инфо',
                            },
                            child: <ModalCreateAdminInfo />,
                            footerChild: <ModalCreateAdminFooter loading={loading} />,
                        },
                        {
                            tabOption: {
                                tabName: 'Паспорт',
                            },
                            child: <ModalCreateAdminPass fileError={fileError} onFileChange={handleFileChange} />,
                            footerChild: <ModalCreateAdminFooter loading={loading} />,
                        },
                        {
                            tabOption: {
                                tabName: 'Права',
                            },
                            child: <ModalCreateRights />,
                            footerChild: <ModalCreateAdminFooter loading={loading} />,
                        },
                    ]}
                />
            </FormProvider>
        </form>
    )
}

import { FC } from 'react'

export const Error502: FC = () => {
    return (
        <div className='flex justify-center items-center h-full'>
            <h1 className='font-bold' style={{ fontSize: '40px' }}>
                Проводятся технические работы
            </h1>
        </div>
    )
}

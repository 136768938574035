import { SuspenseWithSpinner, Tab, Tabs } from 'src/components'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useAtomValue, useSetAtom } from 'jotai'
import { editClientDBAtom } from 'src/atoms/clientDb/editClientDBAtom'
import { currentClientAtom } from 'src/atoms/clientDb/clientsAtom'
import { useEffect, useState } from 'react'
import { allClientGroupsAtom, clientGroupsSelectAtom, currentClientGroupAtom } from 'src/atoms/clientDb/clientGroupsAtom'
import { DateUtils } from 'src/utils'

import { ClientGroup } from '../../services'

import { EditClientFormData } from './types'
import { EditClientSchema } from './schema'
import { ModalEditClientDBOrders } from './ModalEditClientDBOrders'
import { ModalEditClientInfo } from './ModalEditClientInfo'
import { ModalEditClientInfoFooter } from './ModalEditClientInfoFooter'

export const ModalEditClientDB = (): JSX.Element => {
    const editClient = useSetAtom(editClientDBAtom)
    const currentClient = useAtomValue(currentClientAtom)
    const groups = useAtomValue(allClientGroupsAtom)
    const [loader, setLoader] = useState<boolean>(false)

    const methods = useForm<EditClientFormData>({
        resolver: zodResolver(EditClientSchema),
    })

    useEffect(() => {
        let thisGroup: ClientGroup | undefined
        if (currentClient?.clientData?.clientgroup_id && groups?.data?.length) {
            thisGroup = groups.data.find(item => item.clientgroup_id === currentClient.clientData.clientgroup_id)
        }
        if (currentClient?.clientData) {
            const item = currentClient.clientData
            methods.setValue('tabNumber', item.tabNumber ?? undefined)
            methods.setValue('comment', item.comment ?? undefined)
            methods.setValue('phoneNumber', item.phoneNumber ?? undefined)
            methods.setValue('paymentInfo', item.paymentInfo ?? undefined)
            methods.setValue('birthday', DateUtils.formatDate(item.birthday) ?? undefined)
            methods.setValue('address', item.address ?? undefined)
            methods.setValue('firstName', item.firstName ?? undefined)
            methods.setValue('middleName', item.middleName ?? undefined)
            methods.setValue('lastName', item.lastName ?? undefined)
            methods.setValue('email', item.email ?? undefined)
            methods.setValue('whatsapp', item.whatsapp ?? undefined)
            methods.setValue('telegram', item.telegram ?? undefined)
            methods.setValue('house', item.house ?? undefined)
            methods.setValue('street', item.street ?? undefined)
            methods.setValue('corpus', item.corpus ?? undefined)
            methods.setValue('apartment', item.apartment ?? undefined)
            methods.setValue('isBanned', item.isBanned)
            if (thisGroup) {
                methods.setValue('clientgroup_id', {
                    label: thisGroup.title,
                    value: thisGroup.clientgroup_id,
                })
            }
            if (['Мужской', 'М', 'Женский', 'Ж'].includes(item.gender || '')) {
                methods.setValue('gender', {
                    label: ['Мужской', 'М'].includes(item.gender || '') ? 'Мужской' : 'Женский',
                    value: ['Мужской', 'М'].includes(item.gender || '') ? 'М' : 'Ж',
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentClient])

    const onSubmit = async (data: EditClientFormData) => {
        setLoader(true)
        const {
            firstName,
            middleName,
            lastName,
            house,
            corpus,
            street,
            clientgroup_id,
            cityKey,
            gender,
            whatsapp,
            telegram,
            birthday,
            phoneNumber,
            email,
            paymentInfo,
            apartment,
            comment,
            isBanned,
        } = data

        if (currentClient?.clientData.client_id) {
            const body = {
                firstName,
                middleName,
                lastName,
                house,
                corpus,
                street,
                clientgroup_id: clientgroup_id?.value || '',
                cityKey: cityKey?.includes(',') ? cityKey.split(',')[0] : cityKey,
                client_id: currentClient?.clientData.client_id,
                gender: gender?.value,
                whatsapp,
                telegram,
                birthday: birthday ? DateUtils.parseDateInput(birthday) : undefined,
                phoneNumber,
                email,
                paymentInfo,
                apartment,
                comment,
                isBanned: Boolean(isBanned),
            }

            await editClient(body)
            setLoader(false)
        }
    }

    return (
        <form
            onSubmit={methods.handleSubmit(onSubmit, ev => {
                console.log(ev)
            })}>
            <FormProvider {...methods}>
                <SuspenseWithSpinner>
                    <Tabs
                        tabs={[
                            {
                                tabOption: {
                                    tabName: 'Инфо',
                                },
                                child: <ModalEditClientInfo />,
                                footerChild: <ModalEditClientInfoFooter loader={loader} />,
                            },
                            {
                                tabOption: {
                                    tabName: 'Заказы',
                                },
                                child: <ModalEditClientDBOrders />,
                                footerChild: <ModalEditClientInfoFooter loader={loader} />,
                            },
                        ]}
                    />
                </SuspenseWithSpinner>
            </FormProvider>
        </form>
    )
}

import { Button, EButtonVariant, RegularText } from 'src/components'

type LogOutDialogProps = {
    onAccept: VoidFunction
    onAbort: VoidFunction
}

export const LogOutDialog = (props: LogOutDialogProps): JSX.Element => (
    <div className='flex flex-col h-full gap-4 justify-center items-center pt-4 pb-4'>
        <div className='text-center m-6'>
            <RegularText>Вы уверены, что хотите выйти?</RegularText>
        </div>
        <div className='flex gap-2'>
            <Button onClick={props.onAccept} variant={EButtonVariant.PRIMARY} className='!w-44 flex justify-center !py-5'>
                <RegularText>Да</RegularText>
            </Button>
            <Button onClick={props.onAbort} className='!w-44 !py-5 flex justify-center'>
                <RegularText>Отмена</RegularText>
            </Button>
        </div>
    </div>
)

import React, { Suspense, useEffect, useState } from 'react'
import { Tab, Tabs } from 'src/components'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useAtom, useAtomValue } from 'jotai'
import { currentAdminAtom } from 'src/atoms/admins/adminsTableAtom'
import { editAdminAtom, initialAdminPhotoAtom } from 'src/atoms/admins/adminEditAtom'
import {
    adminRightAtom,
    clientRightAtom,
    courierRightAtom,
    generalRightAtom,
    orderRightAtom,
    restRightAtom,
} from 'src/atoms/admins/adminRightsAtom'
import { adminAvailabilitiesAtom, getAdminAvailabilityAtom } from 'src/atoms/admins/adminAvailabilityAtom'

import { ModalEditAdminInfo } from './ModalEditAdminInfo'
import { ModalEditAdminInfoFooter } from './ModalEditAdminInfoFooter'
import { ModalEditAdminPassport } from './ModalEditAdminPassport'
import { ModalEditAdminPassportFooter } from './ModalEditAdminPassportFooter'
import { ModalEditAdminAccess } from './ModalEditAdminAccess'
import { ModalEditAdminAccessFooter } from './ModalEditAdminAccessFooter'
import { ModalEditAdminPayment } from './ModalEditAdminPayment'
import { ModalEditAdminPaymentFooter } from './ModalEditAdminPaymentFooter'
import { SuspenseModalEditAdminAvailability } from './ModalEditAdminAvailability'
import { ModalEditAdminAvailabilityFooter } from './ModalEditAdminAvailabilityFooter'
import { AdminEditData, AdminEditSchema } from './schema'

const EditAdmin = (): JSX.Element => {
    const currentAdmin = useAtomValue(currentAdminAtom)
    const [loading, setLoading] = useState(false)
    const [initialPhoto, setInitialPhoto] = useAtom(initialAdminPhotoAtom)
    const [editAdmin, setEditAdmin] = useAtom(editAdminAtom)
    const adminAvailabilities = useAtomValue(adminAvailabilitiesAtom)

    const [generalRight, setGeneralRight] = useAtom(generalRightAtom)
    const [clientRight, setClientRight] = useAtom(clientRightAtom)
    const [courierRight, setCourierRight] = useAtom(courierRightAtom)
    const [restRight, setRestRight] = useAtom(restRightAtom)
    const [orderRight, setOrderRight] = useAtom(orderRightAtom)
    const [adminRight, setAdminRight] = useAtom(adminRightAtom)
    const adminAvailabilityResult = useAtomValue(getAdminAvailabilityAtom)

    const methods = useForm<AdminEditData>({
        resolver: zodResolver(AdminEditSchema),
    })
    const [initialData, setInitialData] = useState<AdminEditData | null>(null)
    const [initialRights, setInitialRights] = useState({
        generalRight: [],
        clientRight: [],
        courierRight: [],
        restRight: [],
        orderRight: [],
        adminRight: [],
    })

    const [tabs, setTabs] = useState<Tab[]>([])

    useEffect(() => {
        const updatedTabs: Tab[] = [
            {
                tabOption: {
                    tabName: 'Инфо',
                },
                child: (
                    <Suspense>
                        <ModalEditAdminInfo />
                    </Suspense>
                ),
                footerChild: <ModalEditAdminPassportFooter loading={loading} />,
            },
            {
                tabOption: {
                    tabName: 'Паспорт',
                },
                child: <ModalEditAdminPassport />,
                footerChild: <ModalEditAdminPassportFooter loading={loading} />,
            },
            {
                tabOption: {
                    tabName: 'Права',
                },
                child: <ModalEditAdminAccess />,
                footerChild: <ModalEditAdminAccessFooter loading={loading} />,
            },
            {
                tabOption: {
                    tabName: 'Доступность',
                },
                child: <SuspenseModalEditAdminAvailability />,
                footerChild: <ModalEditAdminAvailabilityFooter loading={loading} />,
            },
        ]

        if (adminAvailabilityResult?.data?.length > 0) {
            updatedTabs.push({
                tabOption: {
                    tabName: 'Оплата',
                },
                child: <ModalEditAdminPayment />,
                footerChild: <ModalEditAdminPaymentFooter loading={loading} />,
            })
        }

        setTabs(updatedTabs)
    }, [adminAvailabilityResult])

    const onSubmit = async (data: AdminEditData) => {
        setLoading(true)
        await setEditAdmin(data)
        setLoading(false)
    }

    useEffect(() => {
        if (currentAdmin) {
            if (currentAdmin?.passData?.adminPhoto) {
                setInitialPhoto(currentAdmin.passData.adminPhoto)
            } else {
                setInitialPhoto(undefined)
            }

            const data: AdminEditData = {
                systemName: currentAdmin.systemName,
                tabNumber: currentAdmin.tabNumber,
                firstName: currentAdmin.firstName,
                middleName: currentAdmin.middleName,
                lastName: currentAdmin.lastName,
                gender: currentAdmin.gender,
                birthday: currentAdmin.birthday,
                phone: currentAdmin.phone,
                whatsapp: currentAdmin.whatsapp,
                telegram: currentAdmin.telegram,
                email: currentAdmin.email,
                comment: currentAdmin.comment,
                login: currentAdmin.login,
                password: currentAdmin.password,
                passSeries: currentAdmin.passData.passSeries,
                passNum: currentAdmin.passData.passNum,
                passIssuedBy: currentAdmin.passData.passIssuedBy,
                passIssuedWhen: currentAdmin.passData.passIssuedWhen,
                passActualTo: currentAdmin.passData.passActualTo,
                snils: currentAdmin.passData.snils,
                inn: currentAdmin.passData.inn,
                city: currentAdmin.passData.city,
                street: currentAdmin.passData.street,
                house: currentAdmin.passData.house,
                corpus: currentAdmin.passData.corpus,
                apartment: currentAdmin.passData.apartment,
            }

            setInitialData(data)
            methods.reset(data)

            const updateRights = (rights, setRightFn) => {
                const updatedRights = rights.map(right => ({
                    ...right,
                    checked: currentAdmin.rights && currentAdmin.rights.includes(right.id),
                }))
                setRightFn(updatedRights)
                return updatedRights
            }

            setInitialRights({
                generalRight: updateRights(generalRight, setGeneralRight),
                clientRight: updateRights(clientRight, setClientRight),
                courierRight: updateRights(courierRight, setCourierRight),
                restRight: updateRights(restRight, setRestRight),
                orderRight: updateRights(orderRight, setOrderRight),
                adminRight: updateRights(adminRight, setAdminRight),
            })
        }
    }, [
        currentAdmin,
        setInitialPhoto,
        methods.reset,
        setGeneralRight,
        setClientRight,
        setCourierRight,
        setRestRight,
        setOrderRight,
        setAdminRight,
    ])

    const onReset = () => {
        if (initialData) {
            methods.reset(initialData)
        }
        if (currentAdmin?.passData?.adminPhoto) {
            setInitialPhoto(currentAdmin.passData.adminPhoto)
        } else {
            setInitialPhoto(undefined)
        }

        setGeneralRight(initialRights.generalRight)
        setClientRight(initialRights.clientRight)
        setCourierRight(initialRights.courierRight)
        setRestRight(initialRights.restRight)
        setOrderRight(initialRights.orderRight)
        setAdminRight(initialRights.adminRight)
    }

    return (
        <form
            onSubmit={methods.handleSubmit(onSubmit, e => {
                console.log(e)
            })}
            onReset={onReset}>
            <FormProvider {...methods}>
                <Tabs
                    tabs={[
                        {
                            tabOption: {
                                tabName: 'Инфо',
                            },
                            child: (
                                <Suspense>
                                    <ModalEditAdminInfo />
                                </Suspense>
                            ),
                            footerChild: <ModalEditAdminPassportFooter loading={loading} />,
                        },
                        {
                            tabOption: {
                                tabName: 'Паспорт',
                            },
                            child: <ModalEditAdminPassport />,
                            footerChild: <ModalEditAdminPassportFooter loading={loading} />,
                        },
                        {
                            tabOption: {
                                tabName: 'Права',
                            },
                            child: <ModalEditAdminAccess />,
                            footerChild: <ModalEditAdminAccessFooter loading={loading} />,
                        },
                        {
                            tabOption: {
                                tabName: 'Доступность',
                            },
                            child: <SuspenseModalEditAdminAvailability />,
                            footerChild: <ModalEditAdminAvailabilityFooter loading={loading} />,
                        },
                        {
                            tabOption: {
                                tabName: adminAvailabilityResult?.data?.length > 0 ? 'Оплата' : '',
                            },
                            child: adminAvailabilityResult?.data?.length > 0 ? <ModalEditAdminPayment /> : null,
                            footerChild:
                                adminAvailabilityResult?.data?.length > 0 ? <ModalEditAdminPaymentFooter loading={loading} /> : null,
                        },
                    ]}
                />
            </FormProvider>
        </form>
    )
}

export const ModalEditAdmin = () => (
    <Suspense>
        <EditAdmin />
    </Suspense>
)

import { AppSelect, ColoredSquare, RegularText, SelectOption } from 'src/components'
import { ESelectVariant } from 'src/components/AppSelect'
import { useAtomValue, useSetAtom } from 'jotai/index'
import { PropsValue } from 'react-select'

import {
    freeMapCouriersCountAtom,
    mapCouriersCityCoordinatesAtom,
    mapCouriersDropdownAtom,
    mapCouriersSelectedAtom,
    updateMapCouriersFiltersAtom,
    workingMapCouriersCountAtom,
} from '../../atoms/allCouriers/mapCouriersAtom'
import { courierGroupsAtom } from '../../atoms/allCouriers/allCourierGroupsAtom'
import { CourierGroup } from '../../services'
import { AppCitySelect } from '../../components/AppCitySelect'

export const CourierMapFilters = (): JSX.Element => {
    const updateMapCouriersFilters = useSetAtom(updateMapCouriersFiltersAtom)
    const setMapCouriersCityCoordinates = useSetAtom(mapCouriersCityCoordinatesAtom)
    const freeMapCouriersCount = useAtomValue(freeMapCouriersCountAtom)
    const workingMapCouriersCount = useAtomValue(workingMapCouriersCountAtom)
    const mapCouriersDropdownOptions = useAtomValue(mapCouriersDropdownAtom)
    const setMapCouriersSelected = useSetAtom(mapCouriersSelectedAtom)

    const courierGroups: CourierGroup[] = useAtomValue(courierGroupsAtom)

    const handleNameDropdown = (val: PropsValue<SelectOption>) => {
        if (!val) return
        const selected = val as SelectOption
        if (!selected?.value) return

        const selectedMapCourier = mapCouriersDropdownOptions.find(item => item.value === selected.value)

        if (selectedMapCourier?.value) {
            setMapCouriersSelected(selectedMapCourier)
        }
    }

    const handleCity = (val: PropsValue<SelectOption>) => {
        const [lat, long] = (val as SelectOption)?.value.split(',')
        setMapCouriersCityCoordinates([Number(long), Number(lat)])
    }

    const handleClientGroup = (val: PropsValue<SelectOption>) => {
        updateMapCouriersFilters({ groupId: (val as SelectOption)?.value || '' })
    }

    const handleBusyType = (val: PropsValue<SelectOption>) => {
        updateMapCouriersFilters({ busyType: ((val as SelectOption)?.value as 'busy' | 'free') || '' })
    }

    return (
        <div className='flex justify-between p-6 border-b-primaryColor border-b-2 border-solid items-center'>
            <div className='flex gap-2'>
                {/*<TextInput
          value={searchVal}
          onChange={ev => setSearchVal(ev.target.value)}
          rightIcon="search"
          placeholder="Поиск"
        />*/}
                <AppSelect
                    selectVariant={ESelectVariant.SECONDARY}
                    options={mapCouriersDropdownOptions}
                    placeholder='Поиск по названию'
                    onChange={handleNameDropdown}
                    isClearable
                    isSearchable
                />
                {/*<RoundedWrapper
          icon="refresh"
          onClick={onRefreshClick}
        />*/}
            </div>
            <div className='flex justify-start gap-3 items-center'>
                <AppCitySelect
                    selectVariant={ESelectVariant.SECONDARY}
                    placeholder='Все города'
                    onChange={handleCity}
                    coordinatesForValue
                />
                <AppSelect
                    selectVariant={ESelectVariant.SECONDARY}
                    options={
                        courierGroups?.length ? courierGroups.map(item => ({ label: item.item.title, value: item.item.group_id })) : []
                    }
                    placeholder='Все курьеры (группа)'
                    onChange={handleClientGroup}
                    isClearable
                />
                <AppSelect
                    selectVariant={ESelectVariant.SECONDARY}
                    options={[
                        { label: 'Занятые', value: 'busy' },
                        { label: 'Свободные', value: 'free' },
                    ]}
                    placeholder='Все курьеры (занятость)'
                    onChange={handleBusyType}
                    isClearable
                />
                <div className='ml-10'>
                    <ColoredSquare text={workingMapCouriersCount.toString() || '0'} className='bg-red-700' />
                    <ColoredSquare text={freeMapCouriersCount.toString() || '0'} className='bg-green-800' />
                </div>
                <div className='h-full bg-grey-400 w-0.5' />
                <div className='flex flex-col'>
                    <RegularText>Занятые курьеры</RegularText>
                    <RegularText>Свободные курьеры</RegularText>
                </div>
            </div>
        </div>
    )
}

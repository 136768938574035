import { atomWithRefresh } from 'jotai/utils'
import {
    ClientDB,
    GetOrdersFilters,
    GetRestOrdersRequest,
    GetRestRequest,
    GetRestStatsRequest,
    LokaliApi,
    Rest,
    RestOrder,
    RestStatsResponse,
} from 'src/services'
import { DefaultRequestState } from 'src/types'
import { atom } from 'jotai'

import { ChangeClientGroupModal } from '../../features/ClientDatabaseModals/ChangeClientGroupModal'
import { Icon } from '../../assets/icons'
import { ChangeRestGroupModal } from '../../features/Restaraunts/ChangeRestGroupModal'

import { ordersFiltersAtom } from '../allOrders/ordersAtom'
import { subModalAtom } from '../global/modalAtom'

import { currentRestGroupAtom } from './restGroupAtom'

const currentRestAtom = atom<Rest | null>(null)

const getRestFiltersAtom = atom<GetRestRequest | object>({})

const restsAtom = atom<Rest[]>([])

const updateRestFiltersAtom = atom(null, (get, set, update: GetRestRequest) => {
    const filters = get(getRestFiltersAtom)
    const newFilters = { ...filters, ...update }
    set(getRestFiltersAtom, newFilters)
})

const getRestAtom = atomWithRefresh<Promise<DefaultRequestState<Rest[]>>>(async get => {
    const currentGroupId = get(currentRestGroupAtom)?.restgroup_id
    const getRestFilters = get(getRestFiltersAtom)

    try {
        const data = await LokaliApi.getRest({ restgroup_id: currentGroupId || undefined, ...getRestFilters })

        return {
            isLoading: false,
            data: data,
            error: null,
        }
    } catch (e) {
        return {
            isLoading: false,
            data: [],
            error: e as Error,
        }
    }
})

const restOrdersFiltersAtom = atom<GetRestOrdersRequest | object>({})

const updateRestOrdersFiltersAtom = atom(null, (get, set, update: GetRestOrdersRequest) => {
    const filters = get(restOrdersFiltersAtom)
    const newFilters = { ...filters, ...update }
    set(restOrdersFiltersAtom, newFilters)
})

const getRestOrdersAtom = atomWithRefresh(async get => {
    const restId = get(currentRestAtom)?.rest_id
    const params = get(restOrdersFiltersAtom)

    if (!restId) {
        return {
            isLoading: false,
            data: [],
            error: null,
        }
    }

    try {
        if (restId) {
            const data = await LokaliApi.getRestOrders({ rest_id: restId, ...params })

            return {
                isLoading: false,
                data: data,
                error: null,
            }
        }

        return {
            data: [],
            isLoading: false,
            error: null,
        }
    } catch (e) {
        return {
            isLoading: false,
            data: [],
            error: e as Error,
        }
    }
})

const openChangeRestGroupAtom = atom(null, (_, set) => {
    set(subModalAtom, {
        isOpen: true,
        children: <ChangeRestGroupModal />,
        title: 'Переместить в группу',
        icon: <Icon type='moveWhite' width={20} height={20} />,
        height: 700,
    })
})

const restStatsFiltersAtom = atom<GetRestStatsRequest>({
    dateFrom: '',
    dateTo: '',
    orderStatus: '',
})

const updateRestStatsFiltersAtom = atom(null, (get, set, update: Partial<GetRestStatsRequest>) => {
    const currentFilters = get(restStatsFiltersAtom)
    set(restStatsFiltersAtom, { ...currentFilters, ...update })
})

const getRestStatsAtom = atomWithRefresh(async get => {
    const filters = get(restStatsFiltersAtom)

    const restId = get(currentRestAtom)?.rest_id

    if (!restId) {
        return {
            isLoading: false,
            data: {},
            error: null,
        }
    }

    try {
        if (restId) {
            const data: RestStatsResponse = await LokaliApi.getRestStats({
                ...filters,
                rest_id: restId,
            })

            return {
                isLoading: false,
                data: data,
                error: null,
            }
        }

        return {
            data: {},
            isLoading: false,
            error: null,
        }
    } catch (e) {
        return {
            isLoading: false,
            data: {},
            error: e as Error,
        }
    }
})
export {
    getRestAtom,
    currentRestAtom,
    getRestOrdersAtom,
    getRestFiltersAtom,
    updateRestFiltersAtom,
    openChangeRestGroupAtom,
    updateRestStatsFiltersAtom,
    getRestStatsAtom,
    restsAtom,
    updateRestOrdersFiltersAtom,
}

import { useAtomValue, useSetAtom } from 'jotai'
import { clientDbCopiedTabNumberAtom, setShowBannedClientsAtom, showBannedClientsAtom } from 'src/atoms/clientDb/clientsAtom'
import { Checkbox, HeaderLayout, InputWrapper, RegularText } from 'src/components'

export const ClientDatabaseHeader = (): JSX.Element => {
    const isCopied = useAtomValue(clientDbCopiedTabNumberAtom)
    const showBannedClients = useAtomValue(showBannedClientsAtom)
    const setShowBannedClients = useSetAtom(setShowBannedClientsAtom)

    return (
        <HeaderLayout title='Клиенты / База данных' className='min-w-12 pr-5'>
            <div className='ml-6 flex w-full'>
                {isCopied && <RegularText className='text-green-800'>Скопировано!</RegularText>}
                <div></div>
                <InputWrapper className='self-end w-full' name='Показывать заблокированных'>
                    <Checkbox checked={showBannedClients} onChange={() => setShowBannedClients(!showBannedClients)} />
                </InputWrapper>
            </div>
        </HeaderLayout>
    )
}

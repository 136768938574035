import { atom } from 'jotai'
import { atomWithRefresh } from 'jotai/utils'
import { LokaliApi } from 'src/services/LocaliRepository'
import { CourierGroup } from 'src/services/LocaliRepository/LocaliRepositorySchemas'
import { DefaultRequestState } from 'src/types/network'

const courierGroupsAtom = atom<CourierGroup[]>([])

const allCourierGroupsAtom = atomWithRefresh<Promise<DefaultRequestState<CourierGroup[]>>>(async () => {
    try {
        const data = await LokaliApi.getCourierGroups()
        return {
            isLoading: false,
            data: data,
            error: null,
        }
    } catch (e) {
        return {
            isLoading: false,
            data: [],
            error: e as Error,
        }
    }
})

const allCourierGroupOptionsAtom = atom(async get => {
    const allCourierGroups = await get(allCourierGroupsAtom)
    const options = allCourierGroups.data?.map(el => ({
        ...el,
        label: el.item.title,
        value: el.item.group_id,
    }))

    return options
})

export { courierGroupsAtom, allCourierGroupsAtom, allCourierGroupOptionsAtom }

import { osrm } from 'src/axios'
import { decode } from '@googlemaps/polyline-codec'

class OsrmRepository {
    private static instance: OsrmRepository

    static getInstance(): OsrmRepository {
        if (!OsrmRepository.instance) {
            OsrmRepository.instance = new OsrmRepository()
        }

        return OsrmRepository.instance
    }

    async getPolylineFromApi(
        markers: number[][],
        profile: string = 'driving',
    ): Promise<{
        position: [number, number][]
        distance: number | undefined
    }> {
        const coordinates = markers.map((marker: number[]) => `${marker[1]},${marker[0]}`).join(';')

        try {
            const response = await osrm.get(`/route/v1/${profile}/${coordinates}`)

            if (response.status === 200 && response.data.code === 'Ok') {
                const encoded = response.data.routes[0].geometry

                const decodedCoordinates = decode(encoded, 5)

                return {
                    position: decodedCoordinates.map(coord => [coord[0], coord[1]]),
                    distance: response.data.routes.length > 0 ? response.data.routes[0].distance : undefined,
                }
            } else {
                throw Error(`Error fetching route data: ${response.data}`)
            }
        } catch (error) {
            throw Error(`Error fetching route data: ${error}`)
        }
    }
}

const osrmApi = OsrmRepository.getInstance()

export { osrmApi }

import { atom } from 'jotai'
import { ModalMoveCourierGroup } from 'src/features/Couriers/ModalMoveCourierGroup'
import { DialogDownloadExcel } from 'src/features/Couriers/DialogDownloadExcel'

import { subModalAtom } from '../global/modalAtom'
import { dialogStateAtom } from '../global/dialogAtom'

const openMoveCourierGroupAtom = atom(null, (_, set) => {
    set(subModalAtom, {
        isOpen: true,
        children: <ModalMoveCourierGroup />,
        title: 'Переместить в группу',
        width: 360,
    })
})

const openDownloadExcelDialogAtom = atom(null, (_, set) => {
    set(dialogStateAtom, {
        isOpen: true,
        children: <DialogDownloadExcel />,
    })
})

export { openMoveCourierGroupAtom, openDownloadExcelDialogAtom }

import { z } from 'zod'

const IndepOrderSchema = z.object({
    order_id: z.string(),
    title: z.string(),
    restId: z.string(),
    imageUrl: z.string(),
    imagesUrlArray: z.array(z.string()),
    dishIdArray: z.array(z.number()),
    paid: z.boolean(),
    courier_id: z.string(),
    status: z.string(),
    clientId: z.string(),
    courierStatus: z.string(),
    price: z.number(),
    paymentType: z.string(),
    deliveryPrice: z.number(),
    foodCost: z.number(),
    shipCost: z.number(),
    serviceFee: z.number(),
    deliveryTime: z.number().nullish(),
    isDeliveryServices: z.boolean(),
    comment: z.string(),
    orderNumber: z.number(),
    cancel_reason: z.string(),
    createdAt: z.string(),
    updatedAt: z.string(),
    to_apartment: z.string().optional(),
    to_city: z.string().optional(),
    to_corpus: z.string().optional(),
    to_house: z.string().optional(),
    to_street: z.string().optional(),
})

const CourierDelivSchema = z.object({
    order_id: z.string(),
    order_data: z.string(),
    title: z.string(),
    restId: z.string(),
    phoneNumber: z.string().optional(),
    imageUrl: z.string().optional(),
    smallImageUrl: z.string().optional(),
    dishIdArray: z.array(z.number()),
    paid: z.boolean(),
    courier_id: z.string(),
    status: z.string(),
    clientId: z.string(),
    courierStatus: z.string().optional(),
    price: z.number(),
    paymentType: z.string().optional(),
    deliveryPrice: z.number(),
    foodCost: z.number(),
    shipCost: z.number(),
    serviceFee: z.number(),
    deliveryTime: z.string().optional(),
    isOurCouriers: z.boolean(),
    isDeliveryServices: z.boolean(),
    comment: z.string().optional(),
    orderNumber: z.number(),
    cancel_reason: z.string(),
    shipType: z.string(),
    servtype: z.string().optional(),
    pickup: z.boolean(),
    tookDate: z.string().optional(),
    deliveryDate: z.string().optional(),
    discountBonus: z.number(),
    realPrice: z.number(),
    was_paid_by_lokaly: z.string().optional(),
    createdAt: z.string().optional(),
    updatedAt: z.string().optional(),
    restAddress: z.string().optional(),
    orderTookDate: z.string().optional(),
    spentTimeOnDelivery: z.string().optional(),
    clientName: z.string().optional(),
    deliveryAddress: z.string().optional(),
    clientPhoneNumber: z.string().optional(),
    restName: z.string().optional(),
})

const DeliveriesSchema = z.object({
    indepOrders: z.array(IndepOrderSchema),
    courierDeliv: z.array(CourierDelivSchema),
})

type Delivery = z.infer<typeof DeliveriesSchema>

export type { Delivery }

export { DeliveriesSchema }

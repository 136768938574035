export enum ERoutes {
    ROOT = '/',
    LOGIN = '/login',
    MAIN = 'main',
    MAIN_MAIN = 'info-main',
    SETTINGS_MAIN = 'settings-main',
    CLIENTS = 'clients',
    GROUPS = 'groups',
    SMS_TEMPLATE = 'sms-template',
    ADMINS = 'admins',
    KURA = 'couriers',
    KURA_MAP = 'couriers-map',
    REST = 'restaraunts',
    REST_MAP = 'restaraunts-map',
    ACCOUNTING_COURIERS = 'accounting-couriers',
    ACCOUNTING_REST = 'accounting-rest',
    ORDERS = 'orders',
    SETTINGS = 'settings',
    ALL_KURAS = 'all-couriers',
    ALL_ORDERS = 'all-orders',
    CLIENTS_DATABASE = 'client-database',
    CLIENT_GENERAL_STATISTICS = 'client-general-statistics',
    CLIENT_GROUPS = 'client-groups',
    CLIENT_PUSH_NOTIFICATION = 'client-push-notification',
    ERROR_SERVER = 'error-server',
}

import { AppSelect, AppSelectProps } from '../AppSelect'
import { RegularText } from '../RegularText'

type NamedSelectProps = {
    name: string
    selectProps?: AppSelectProps
}

export const NamedSelect = (props: NamedSelectProps): JSX.Element => {
    const { name } = props
    return (
        <div className='flex gap-3 justify-end'>
            <RegularText>{name}</RegularText>
            <AppSelect {...props.selectProps} placeholder={props.selectProps?.placeholder ? props.selectProps?.placeholder : name} />
        </div>
    )
}

export const GenderOptions = [
    { label: 'Мужской', value: 'М' },
    { label: 'Женский', value: 'Ж' },
]

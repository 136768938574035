import { z, ZodType } from 'zod'

import { CourierEditFormData } from './types'

export const CourierEditSchema: ZodType<CourierEditFormData> = z.object({
    deliveryType: z.object(
        {
            label: z.string(),
            value: z.string(),
        },
        { required_error: 'Обязательное поле' },
    ),
    password: z.string({ required_error: 'Обязательное поле' }).min(1, { message: 'Обязательное поле' }),
    login: z.string({ required_error: 'Обязательное поле' }).min(1, { message: 'Обязательное поле' }),
    patronymic: z.string().optional(),
    firstName: z.string({ required_error: 'Обязательное поле' }).min(1, { message: 'Обязательное поле' }),
    surname: z.string().optional(),
    phoneNumber: z.string({ required_error: 'Обязательное поле' }).min(1, { message: 'Обязательное поле' }),
    passSeries: z.string().optional(),
    passIssuedBy: z.string().optional(),
    passIssueDate: z.string().optional(),
    passEndDate: z.string().optional(),
    passSnils: z.string().optional(),
    passInn: z.string().optional(),
    passCity: z.string().optional(),
    passStreet: z.string().optional(),
    passHouse: z.string().optional(),
    passHousing: z.string().optional(),
    passNumber: z.string().optional(),
    passApartment: z.string().optional(),
    sysName: z.string().optional(),
    tabNumber: z.string().optional(),
    gender: z
        .object({
            label: z.string(),
            value: z.string(),
        })
        .optional(),
    birthday: z.string().optional(),
    whatsApp: z.string().optional(),
    telegram: z.string().optional(),
    email: z.string().optional(),
    comment: z.string().optional(),
    courierPhoto: z.string().optional(),
    cityKey: z.string().optional(),
    rest_id: z.string().optional(),
    isSalary: z.string().optional(),
    weekends: z.string().optional(),
})

import { DialogDeleteRestGroup } from 'src/features/Restaraunts/DialogDeleteRestGroup'
import { atom } from 'jotai'
import { HttpStatusCode } from 'src/types'
import { LokaliApi } from 'src/services'

import { closeDialogAtom, dialogStateAtom } from '../global/dialogAtom'
import { subModalAtom } from '../global/modalAtom'

import { currentRestGroupAtom } from './restGroupAtom'
import { getRestGroupAtom } from './createRestGroupAtom'

const deleteRestGroupNetworkStateAtom = atom<HttpStatusCode>(HttpStatusCode.I_AM_A_TEAPOT)

const openDeleteRestGroupDialogAtom = atom(null, (_, set, name?: string) => {
    set(dialogStateAtom, {
        isOpen: true,
        children: <DialogDeleteRestGroup name={name} />,
    })
})

const deleteRestGroupAtom = atom(null, async (get, set) => {
    const id = get(currentRestGroupAtom)?.restgroup_id
    try {
        set(deleteRestGroupNetworkStateAtom, HttpStatusCode.LOADING)
        if (id) {
            const response = await LokaliApi.deleteRestGroup({
                group_id: id,
            })

            if (response === HttpStatusCode.OK) {
                set(deleteRestGroupNetworkStateAtom, HttpStatusCode.OK)
                set(subModalAtom, {
                    isOpen: false,
                    title: '',
                    children: null,
                })
                set(closeDialogAtom)
                set(getRestGroupAtom)
            }
        }

        set(deleteRestGroupNetworkStateAtom, HttpStatusCode.I_AM_A_TEAPOT)
    } catch (e) {
        set(deleteRestGroupNetworkStateAtom, HttpStatusCode.INTERNAL_SERVER_ERROR)
    }
})

export { openDeleteRestGroupDialogAtom, deleteRestGroupAtom }

import { useAtomValue, useSetAtom } from 'jotai'
import { useState } from 'react'
import { setCloseSubModalAtom } from 'src/atoms/global/modalAtom'
import { Button, Divider, EButtonVariant, RegularText } from 'src/components'

import { LokaliApi } from '../../services'
import { currentRestAtom } from '../../atoms/restaraunts/restAtom'
import { refreshRestsAtom } from '../../atoms/restaraunts/refreshRestsAtom'
import { HttpStatusCode } from '../../types'
import { getRestGroupAtom } from '../../atoms/restaraunts/createRestGroupAtom'

import styles from './styles.module.scss'

type RestGroupProps = {
    title: string
    checked: boolean
    onClick: () => void
}

const RestGroup = (props: RestGroupProps): JSX.Element => (
    <div className='flex gap-2 items-center ml-7 mb-1 pressableSimple align-middle' onClick={props.onClick}>
        <div className={styles.wrapper}>{props.checked && <div className={styles.inner}></div>}</div>
        <RegularText>{props.title}</RegularText>
    </div>
)

export const ChangeRestGroupModal = (): JSX.Element => {
    const currentRest = useAtomValue(currentRestAtom)
    const groups = useAtomValue(getRestGroupAtom)
    const closeSubModal = useSetAtom(setCloseSubModalAtom)
    const refreshRests = useSetAtom(refreshRestsAtom)

    const [checkedId, setCheckedId] = useState<string>('')
    const [movingLoading, setMovingLoading] = useState<boolean>(false)

    const onGroupClick = (id: string) => {
        setCheckedId(id)
    }

    const onMoveClick = async () => {
        try {
            if (!checkedId || !currentRest?.rest_id) return

            setMovingLoading(true)
            const data = await LokaliApi.editRest({
                login: currentRest.login,
                password: currentRest.password,
                restName: currentRest.restName, // they were required
                rest_id: currentRest?.rest_id,
                group_id: checkedId,
            })

            if (data !== HttpStatusCode.OK) return

            closeSubModal()
            refreshRests()
        } catch (e) {
            console.warn(e)
        } finally {
            setMovingLoading(false)
        }
    }

    return (
        <div className='flex flex-col justify-between flex-1'>
            <div className='px-7 flex flex-col gap-2'>
                <div className='h-96 overflow-scroll py-2'>
                    {groups.data?.map(group => (
                        <RestGroup
                            key={group.restgroup_id}
                            onClick={() => onGroupClick(group.restgroup_id)}
                            checked={checkedId === group.restgroup_id}
                            title={group.title}
                        />
                    ))}
                </div>
            </div>
            <Divider />
            <div className='flex justify-center items-center p-5 gap-4'>
                <Button onClick={closeSubModal} disabled={movingLoading}>
                    <RegularText>Отменить</RegularText>
                </Button>
                <Button disabled={!checkedId || movingLoading} onClick={onMoveClick} variant={EButtonVariant.PRIMARY}>
                    <RegularText>Переместить</RegularText>
                </Button>
            </div>
        </div>
    )
}

import classNames from 'classnames'
import { PropsWithChildren } from 'react'
import { DivProps } from 'src/types'

export const ShadowContainer = (props: PropsWithChildren<DivProps>): JSX.Element => {
    const { children, className, ...divProps } = props
    return (
        <div className={classNames('shadow-md rounded-3xl px-7 py-5', className)} {...divProps}>
            {children}
        </div>
    )
}

import { atom } from 'jotai'
import { ModalEditAdmin } from 'src/features/AdminsEditModals'
import { ModalCreateAdmin } from 'src/features/AdminsCreateModals'

import { modalAtom } from '../global/modalAtom'
import { setNotSavedDialogAtom } from '../global/dialogAtom'

const openEditAdminAtom = atom(null, (_, set) => {
    set(modalAtom, {
        isOpen: true,
        children: <ModalEditAdmin />,
        title: 'Редактирование администратора',
        onClose: () => set(setNotSavedDialogAtom),
    })
})

const openCreateAdminAtom = atom(null, (_, set) => {
    set(modalAtom, {
        isOpen: true,
        children: <ModalCreateAdmin />,
        title: 'Создание администратора',
        onClose: () => set(setNotSavedDialogAtom),
    })
})

export { openEditAdminAtom, openCreateAdminAtom }

import { useSetAtom } from 'jotai'
import debounce from 'lodash.debounce'
import { addClientDBAtom } from 'src/atoms/clientDb/addClientDBAtom'
import {
    cityFilterClientsAtom,
    clientDbSearchParamAtom,
    clientsAtom,
    currentClientAtom,
    dbClientsAtom,
    openChangeClientGroupAtom,
    openClientDeleteModalAtom,
    refreshDbClientsAtom,
    setCityFilterClientsAtom,
} from 'src/atoms/clientDb/clientsAtom'
import { manageSMSAtom } from 'src/atoms/clientDb/manageSMSAtom'
import { Button, EButtonVariant, ESelectVariant, MediumText, SemiBoldText, TextInput } from 'src/components'
import { RoundedWrapper } from 'src/components/RoundedWrapper'
import { useAtomValue } from 'jotai/index'

import { AppCitySelect } from '../../components/AppCitySelect'
import { Excel } from '../../services/Excel'

const THEADS = [
    'Таб. номер',
    'Телефон',
    'ФИО',
    'Город',
    'Заказ',
    'Общая сумма',
    'Общее количество заказов',
    'Бонусы',
    'Статус активности',
    'Статус блокировки',
    'Комментарий',
]

export const ClientDatabaseFilters = (): JSX.Element => {
    const currentClient = useAtomValue(currentClientAtom)
    const addDBClient = useSetAtom(addClientDBAtom)
    const manageSMS = useSetAtom(manageSMSAtom)
    const refreshDbClients = useSetAtom(refreshDbClientsAtom)
    const setSearch = useSetAtom(clientDbSearchParamAtom)
    const openClientDeleteModal = useSetAtom(openClientDeleteModalAtom)
    const openChangeClientGroup = useSetAtom(openChangeClientGroupAtom)
    const cityFilterClients = useAtomValue(cityFilterClientsAtom)
    const setCityFilterClients = useSetAtom(setCityFilterClientsAtom)
    const data = useAtomValue(clientsAtom)

    const onChangeSearch = debounce((arg: string) => setSearch(arg), 500)

    const onClickDelete = () => {
        openClientDeleteModal(currentClient)
    }

    const onClickMove = () => {
        openChangeClientGroup(currentClient)
    }

    const onDownloadClick = () => {
        if (!data) return
        const KEYS = [
            'clientData.tabNumber',
            'clientData.phoneNumber',
            'clientData.firstName',
            'clientData.cityKey',
            'hasActiveOrder',
            'totalPrice',
            'orderCount',
            'clientData.bonuscount',
            'clientData.isActive',
            'clientData.isBanned',
            'clientData.comment',
        ]

        const FN = (key: string, node: any) => {
            if (key === 'clientData.firstName') {
                return `${node.clientData.firstName || ''} ${node.clientData.middleName || ''} ${node.clientData.lastName || ''}`
            } else if (key === 'hasActiveOrder') {
                return node.hasActiveOrder ? 'Есть активный' : 'Нет активных'
            } else if (key === 'clientData.isActive') {
                return node.clientData.isActive ? 'Активный' : 'Неактивный'
            } else if (key === 'clientData.isBanned') {
                return node.clientData.isBanned ? 'Забанен' : 'Активный'
            }

            return ''
        }

        Excel.generateFile({
            heads: THEADS,
            keys: KEYS,
            data: data,
            fn: FN,
            fileName: 'clients',
        })
    }

    return (
        <div className='flex gap-2 justify-between w-full' style={{ minWidth: '1124px' }}>
            <div className='flex gap-4'>
                <SemiBoldText className='text-lg'>Клиенты</SemiBoldText>
                <div className='flex gap-2'>
                    <Button icon='plus' onClick={addDBClient} variant={EButtonVariant.PRIMARY}>
                        <MediumText>Добавить клиента</MediumText>
                    </Button>
                    <TextInput className='w-inputM' rightIcon='search' placeholder='Поиск' onChange={e => onChangeSearch(e.target.value)} />
                    <RoundedWrapper icon='refresh' onClick={refreshDbClients} />
                    <RoundedWrapper disabled={!currentClient} icon='trash' onClick={onClickDelete} />
                    <RoundedWrapper disabled={!currentClient} icon='move' onClick={onClickMove} />
                    <RoundedWrapper icon='sms' onClick={() => manageSMS()} />
                </div>
            </div>

            <div className='flex gap-2 justify-between'>
                <AppCitySelect
                    value={cityFilterClients ? { value: cityFilterClients, label: cityFilterClients.split(',')[0] } : undefined}
                    onChange={val => setCityFilterClients(val?.value || '')}
                    selectVariant={ESelectVariant.SECONDARY}
                    placeholder='Все города'
                    isClearable
                />
                <Button variant={EButtonVariant.GREEN} icon='excel' onClick={onDownloadClick}>
                    <MediumText>Download</MediumText>
                </Button>
            </div>
        </div>
    )
}

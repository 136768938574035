import { useAtomValue, useSetAtom } from 'jotai'
import classNames from 'classnames'
import { NavLink, useNavigate } from 'react-router-dom'
import { isNavbarFullAtom } from 'src/atoms/leftNavBar/leftNavBarAtom'
import { Accordion, RegularText, Dropdown, Row } from 'src/components'
import { Icon } from 'src/assets/icons'
import { logoutAtom } from 'src/atoms/auth/authAtom'
import { ERoutes } from 'src/router/routes'
import { currentCategoryIndexAtom, setCurrentCategoryIndexAtom } from 'src/atoms/leftNavBar/navbarCategoryAtom'

import { setCloseAllModals, setModalAtom, subModalAtom } from '../../atoms/global/modalAtom'
import { closeDialogAtom, dialogStateAtom } from '../../atoms/global/dialogAtom'

import { ModalClientDBAddGroup } from '../ClientDatabaseModals'
import { NotSavedDialog } from '../Dialogs/NotSavedDialog'
import { LogOutDialog } from '../Dialogs/LogOutDialog'

import { MENU_CONFIG, MenuConfig } from './utils'
import { MenuCategory } from './MenuCategory'
import { CategoryItem } from './CategoryItem'
import { AutoDistribution } from './AutoDistribution'
import styles from './styles.module.scss'

export const NavBar = (): JSX.Element => {
    const isLeftBarOpen = useAtomValue(isNavbarFullAtom)
    const logout = useSetAtom(logoutAtom)
    const navigate = useNavigate()
    const currentIndex = useAtomValue(currentCategoryIndexAtom)
    const setCurrentIndex = useSetAtom(setCurrentCategoryIndexAtom)
    const setModal = useSetAtom(subModalAtom)
    const closeDialog = useSetAtom(setCloseAllModals)

    const onClickCategory = (index: number) => {
        setCurrentIndex(currentIndex === index ? -1 : index)
    }

    const onClickLogout = () => {
        setModal({ isOpen: true, children: <LogOutDialog onAccept={doLogout} onAbort={closeDialog} />, title: 'Выход' })
    }

    const doLogout = () => {
        closeDialog()
        logout()
        navigate(ERoutes.LOGIN)
    }

    const getChildrenWhenLeftBarFull = (children?: MenuConfig[]): JSX.Element | null =>
        isLeftBarOpen ? (
            <div
                className={classNames(
                    'flex flex-col gap-1',
                    { [styles.minimizedItemContainer]: !isLeftBarOpen },
                    styles.menuChildrenWhenLeftBarFull,
                )}
            >
                {children &&
                    children.map((child, index) => (
                        <NavLink to={child.path} key={child.title + index}>
                            {({ isActive }) => <CategoryItem isActive={isActive}>{child.title}</CategoryItem>}
                        </NavLink>
                    ))}
            </div>
        ) : null

    const getMenuParent = (item: MenuConfig, index: number) => (
        <MenuCategory
            title={isLeftBarOpen ? item.title : ''}
            icon={item.icon}
            isActive={currentIndex === index}
            isExpanded={isLeftBarOpen}
            onClick={() => onClickCategory(index)}
        />
    )

    if (!isLeftBarOpen) {
        return (
            <div
                className={classNames(
                    'flex flex-col gap-1.5 h-full justify-between',
                    {
                        'p-2': !isLeftBarOpen,
                        'p-4': isLeftBarOpen,
                    },
                    styles.navWrapper,
                )}
            >
                <div className='flex flex-col gap-2'>
                    {MENU_CONFIG.map((item, index) => (
                        <Dropdown key={item.title + index} parent={getMenuParent(item, index)}>
                            {item.children &&
                                item.children.map(child => (
                                    <NavLink to={child.path} key={item.path + child.path} id='child'>
                                        <Row includeHover>
                                            <RegularText>{child.title}</RegularText>
                                        </Row>
                                    </NavLink>
                                ))}
                        </Dropdown>
                    ))}
                </div>
                <div className='flex flex-col gap-4'>
                    <div>
                        <AutoDistribution />
                    </div>
                    <div className='flex items-center justify-center cursor-pointer' onClick={onClickLogout}>
                        <Icon type='exit' />
                        {isLeftBarOpen && <RegularText className='!text-white underline'>Выйти</RegularText>}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div
            className={classNames(
                'flex flex-col gap-1.5 h-full justify-between',
                { 'p-2': !isLeftBarOpen, 'p-4': isLeftBarOpen },
                styles.navWrapper,
            )}
        >
            <div className='flex flex-col gap-1'>
                {MENU_CONFIG.map((item, index) => (
                    <Accordion
                        key={item.path + index}
                        keyVal={item.path + index}
                        isActive={currentIndex === index}
                        parent={getMenuParent(item, index)}
                    >
                        {getChildrenWhenLeftBarFull(item.children)}
                    </Accordion>
                ))}
            </div>
            <div className='flex flex-col gap-4'>
                <AutoDistribution />
                <div className='flex gap-2 pressableSimple' onClick={onClickLogout}>
                    <Icon type='exit' />
                    {isLeftBarOpen && <RegularText className='!text-white underline'>Выйти</RegularText>}
                </div>
            </div>
        </div>
    )
}

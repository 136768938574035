import { atom } from 'jotai'

import { AllCourierParams } from '../../services'

type DateRange = {
    dateFrom?: string
    dateTo?: string
}

const couriersFiltersAtom = atom<AllCourierParams>({})

const updateCouriersFiltersAtom = atom(null, (get, set, update: Partial<AllCourierParams>) => {
    const filters = get(couriersFiltersAtom)
    const newFilters = { ...filters, ...update }
    set(couriersFiltersAtom, newFilters)
})

const courierDateRangeAtom = atom<DateRange>({})

export { courierDateRangeAtom, updateCouriersFiltersAtom }

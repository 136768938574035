import styles from './styles.module.scss'

type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement>

export const Checkbox = (props: CheckboxProps): JSX.Element => {
    const { onChange, checked, ...rest } = props

    return (
        <div className={styles.wrapper}>
            <label className={styles.container}>
                <input type='checkbox' onChange={onChange} checked={checked} {...rest} />
                <span className={styles.checkmark} />
            </label>
        </div>
    )
}

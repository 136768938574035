import { atom } from 'jotai'
import { DeleteCourierRequest, EditCourierRequest, FireCourierRequest, LokaliApi } from 'src/services/LocaliRepository'
import { HttpStatusCode, NetworkStatusState } from 'src/types'

import { setCloseAllModals } from '../global/modalAtom'

import { fetchAllCouriersAtom } from './allCouriersAtom'

const editCourierPhotoAtom = atom<File | null>(null)

const initialCourierPhotoAtom = atom<string | undefined>(undefined)

const editCourierNetworkStateAtom = atom<NetworkStatusState>({
    statusCode: HttpStatusCode.I_AM_A_TEAPOT,
})

const deleteCourierNetworkStateAtom = atom<NetworkStatusState>({
    statusCode: HttpStatusCode.I_AM_A_TEAPOT,
})

const fireCourierNetworkStateAtom = atom<NetworkStatusState>({
    statusCode: HttpStatusCode.I_AM_A_TEAPOT,
})

const editCourierAtom = atom(null, async (get, set, req: EditCourierRequest) => {
    const photo = get(editCourierPhotoAtom)
    const passData = req.passData ? { ...req.passData } : {}

    try {
        set(editCourierNetworkStateAtom, {
            statusCode: HttpStatusCode.LOADING,
        })

        let photoId

        if (photo) {
            const formData = new FormData()
            formData.append('file', photo)

            photoId = await LokaliApi.uploadFiles(formData)

            if (photoId?.file) {
                passData.courierPhoto = photoId.file
            }
        }

        const finalRequest: EditCourierRequest = {
            ...req,
            passData: passData,
        }

        const statusCode = await LokaliApi.editCourier(finalRequest)

        if (statusCode === HttpStatusCode.OK) {
            set(setCloseAllModals)
            set(editCourierNetworkStateAtom, {
                statusCode: HttpStatusCode.OK,
            })
            set(fetchAllCouriersAtom)
        }
    } catch (e) {
        set(editCourierNetworkStateAtom, {
            statusCode: HttpStatusCode.INTERNAL_SERVER_ERROR,
        })
    }
})

const deleteCourierAtom = atom(null, async (_, set, req: DeleteCourierRequest) => {
    try {
        set(deleteCourierNetworkStateAtom, {
            statusCode: HttpStatusCode.LOADING,
        })

        const statusCode = await LokaliApi.deleteCourier(req)

        if (statusCode === HttpStatusCode.OK) {
            set(setCloseAllModals)
            set(deleteCourierNetworkStateAtom, {
                statusCode: HttpStatusCode.OK,
            })

            set(fetchAllCouriersAtom)
        }
    } catch (e) {
        set(deleteCourierNetworkStateAtom, {
            statusCode: HttpStatusCode.INTERNAL_SERVER_ERROR,
        })
    }
})

const fireCourierAtom = atom(null, async (_, set, req: FireCourierRequest) => {
    try {
        set(fireCourierNetworkStateAtom, {
            statusCode: HttpStatusCode.LOADING,
        })

        const statusCode = await LokaliApi.fireCourier(req)

        if (statusCode === HttpStatusCode.OK) {
            set(setCloseAllModals)
            set(fireCourierNetworkStateAtom, {
                statusCode: HttpStatusCode.OK,
            })

            set(fetchAllCouriersAtom)
        }
    } catch (e) {
        set(fireCourierNetworkStateAtom, {
            statusCode: HttpStatusCode.INTERNAL_SERVER_ERROR,
        })
    }
})

export {
    editCourierNetworkStateAtom,
    editCourierAtom,
    deleteCourierAtom,
    deleteCourierNetworkStateAtom,
    fireCourierNetworkStateAtom,
    fireCourierAtom,
    editCourierPhotoAtom,
    initialCourierPhotoAtom,
}

import classNames from 'classnames'
import dayjs from 'dayjs'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import {
    createCourierAvailabilityAtom,
    onClickTimeAtom,
    scheduleConfigAtom,
    scheduleDaysAtom,
    scheduleStartAtom,
    scheduleTitleAtom,
} from 'src/atoms/allCouriers/courierAvailabilityAtom'
import { setCloseSubModalAtom } from 'src/atoms/global/modalAtom'
import {
    Button,
    DateInput,
    Divider,
    MediumText,
    RegularText,
    SemiBoldText,
    TextInput,
    EButtonVariant,
    ETextInputVariant,
} from 'src/components'
import { useState } from 'react'

export const isDay = (index: number) => {
    switch (index) {
        case 1:
            return 'Пн'
        case 2:
            return 'Вт'
        case 3:
            return 'Ср'
        case 4:
            return 'Чт'
        case 5:
            return 'Пт'
        case 6:
            return 'Сб'
        case 7:
            return 'Вс'
        default:
            return ''
    }
}

export const CourierCreateSchedule = (): JSX.Element => {
    const [loading, setLoading] = useState(false)
    const createCourierAvailability = useSetAtom(createCourierAvailabilityAtom)
    const scheduleConfig = useAtomValue(scheduleConfigAtom)
    const onClickTime = useSetAtom(onClickTimeAtom)
    const onChangeTitle = useSetAtom(scheduleTitleAtom)
    const closeSubModal = useSetAtom(setCloseSubModalAtom)
    const [scheduleStart, setScheduleStart] = useAtom(scheduleStartAtom)
    const setScheduleDays = useSetAtom(scheduleDaysAtom)
    const [dateBlurred, setDateBlurred] = useState(false)

    const save = async () => {
        setLoading(true)
        await createCourierAvailability()
        setLoading(false)
    }

    return (
        <div className='flex flex-col h-full'>
            <div className='flex gap-4 p-7'>
                <div>
                    <SemiBoldText className='text-xs'>Название</SemiBoldText>
                    <TextInput
                        placeholder='Название'
                        variant={ETextInputVariant.SECONDARY}
                        onChange={e => onChangeTitle(e.currentTarget.value)}
                    />
                </div>

                <div>
                    <SemiBoldText className='text-xs'>Начало действия</SemiBoldText>
                    <DateInput onChange={e => setScheduleStart(e.target.value)} />
                    {dateBlurred && scheduleStart && !dayjs(scheduleStart).isValid() && (
                        <RegularText className='text-red-700'>Введите корректную дату</RegularText>
                    )}
                </div>

                <div>
                    <MediumText>Кол-во дней</MediumText>
                    <TextInput type='number' className='w-inputS' onChange={e => setScheduleDays(Number(e.target.value))} />
                </div>
            </div>

            <div className='m-1.5 flex max-h-96 overflow-scroll'>
                {scheduleConfig.map((schedule, parentIndex) => (
                    <div key={parentIndex} className='flex flex-col w-full px-px'>
                        <div className='bg-aqua-400 flex justify-center items-center p-2'>{isDay(parentIndex + 1)}</div>
                        {schedule.map((item, index) => (
                            <div
                                onClick={() => onClickTime({ parentIndex, index })}
                                className={classNames(
                                    'flex justify-center items-center cursor-pointer',
                                    'p-2',
                                    index % 2 !== 0 && 'bg-aqua-100',
                                    item.selected && '!bg-aqua-600',
                                )}
                                key={item.time}>
                                {item.time}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div className='p-6'>
                <div className='mt-2'>
                    <Divider />
                </div>
                <div className='flex h-full justify-end items-end pt-4'>
                    <Button onClick={save} loader={loading} icon='save' variant={EButtonVariant.BLUE} className='mr-2'>
                        <MediumText>Сохранить</MediumText>
                    </Button>
                    <Button onClick={closeSubModal}>
                        <RegularText>Отмена</RegularText>
                    </Button>
                </div>
            </div>
        </div>
    )
}

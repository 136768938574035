import { atom } from 'jotai'
import { DeleteOrder } from 'src/features/Orders'
import { LokaliApi, Order } from 'src/services'
import { HttpStatusCode } from 'src/types'

import { dialogStateAtom } from '../global/dialogAtom'
import { setCloseAllModals } from '../global/modalAtom'

import { getOrdersAtom } from './ordersAtom'

const deleteOrderNetworkStateAtom = atom<HttpStatusCode>(HttpStatusCode.I_AM_A_TEAPOT)

const deleteOrderAtom = atom(null, async (_, set, update: Order) => {
    set(deleteOrderNetworkStateAtom, HttpStatusCode.LOADING)

    try {
        const response = await LokaliApi.deleteOrder({
            order_id: update.order_id,
        })

        if (response === HttpStatusCode.OK) {
            set(deleteOrderNetworkStateAtom, HttpStatusCode.LOADING)
            set(getOrdersAtom)
        }

        set(setCloseAllModals)
        set(deleteOrderNetworkStateAtom, HttpStatusCode.I_AM_A_TEAPOT)
    } catch (e) {
        set(deleteOrderNetworkStateAtom, HttpStatusCode.INTERNAL_SERVER_ERROR)
    }
})

const openDeleteOrderDialogAtom = atom(null, (_, set) => {
    set(dialogStateAtom, {
        isOpen: true,
        children: <DeleteOrder />,
    })
})

export { openDeleteOrderDialogAtom, deleteOrderAtom }

import React, { ReactNode } from 'react'
import { Icon } from 'src/assets/icons'
import classNames from 'classnames'

import { MediumText } from '../MediumText'
import { BoldText } from '../BoldText'

import styles from './styles.module.scss'

export enum EBorderVariant {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
}

export enum ETableVariant {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
}

export type TableProps<T> = {
    theads: string[]
    tbody: T[] | null
    renderCellFn?: (body: T, index: number, arr: T[]) => ReactNode
    borderVariant?: EBorderVariant
    tableVariant?: ETableVariant
    className?: string
    table?: boolean
    wrapperClassname?: string
}

// Данных может быть много и рендер большого количества элементов таблицы может
// негативно сказаться на производительности
// поэтому по необходимости необходимо использовать библиотеку react-windowed

export function AppTable<T>(props: TableProps<T>): JSX.Element | null {
    const {
        theads,
        tbody = [],
        renderCellFn,
        borderVariant = EBorderVariant.PRIMARY,
        tableVariant = ETableVariant.PRIMARY,
        className,
        table,
        wrapperClassname,
    } = props

    return (
        <div className={classNames(styles.tableWrapper, wrapperClassname)}>
            <table
                className={classNames(
                    styles.table,
                    {
                        [styles.primary]: tableVariant === ETableVariant.PRIMARY,
                        [styles.secondary]: tableVariant === ETableVariant.SECONDARY,
                        [styles.tertiary]: tableVariant === ETableVariant.TERTIARY,
                        ['block']: !table,
                        ['table']: table,
                    },
                    className,
                )}
            >
                <thead>
                    <tr
                        className={classNames(
                            styles.head,
                            { [styles.borderPrimary]: borderVariant === EBorderVariant.PRIMARY },
                            { [styles.borderSecondary]: borderVariant === EBorderVariant.SECONDARY },
                            { [styles.borderTertiary]: borderVariant === EBorderVariant.TERTIARY },
                        )}
                    >
                        {theads.map((head, index) => (
                            <th key={head + index} className='px-4'>
                                <div className='flex items-center gap-1'>
                                    <MediumText className='text-nowrap whitespace-nowrap'>{head}</MediumText>
                                    <span>
                                        <Icon type='arrowSm' size={12} />
                                    </span>
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>{renderCellFn && tbody && tbody.map(renderCellFn)}</tbody>
            </table>
            {tbody && tbody.length === 0 && (
                <div className='flex justify-center'>
                    <BoldText>Нет данных</BoldText>
                </div>
            )}
        </div>
    )
}

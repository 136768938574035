import { atom } from 'jotai'
import { ModalCreateRest, ModalCreateRestDeliveryZone } from 'src/features/Restaraunts'
import { ModalEditRest } from 'src/features/Restaraunts/ModalEditRest'
import { NotSavedDialog } from 'src/features/Dialogs/NotSavedDialog'
import { ModalEditRestDeliveryZone } from 'src/features/Restaraunts/ModalEditRestDeliveryZone'

import { modalAtom, setCloseAllModals, subModalAtom } from '../global/modalAtom'
import { closeDialogAtom, dialogStateAtom, setNotSavedDialogAtom } from '../global/dialogAtom'

export const setCreateRestModal = atom(null, (_, set) => {
    set(modalAtom, {
        isOpen: true,
        children: <ModalCreateRest />,
        title: 'Ресторан',
        onClose: () =>
            set(dialogStateAtom, {
                isOpen: true,
                children: <NotSavedDialog onAccept={() => set(setCloseAllModals)} onAbort={() => set(closeDialogAtom)} />,
            }),
    })
})

export const setEditRestModal = atom(null, (_, set) => {
    set(modalAtom, {
        isOpen: true,
        children: <ModalEditRest />,
        title: 'Ресторан',
        onClose: () => set(setNotSavedDialogAtom),
    })
})

export const setCreateRestDeliveryZonesAtom = atom(null, (_, set) => {
    set(subModalAtom, {
        isOpen: true,
        children: <ModalCreateRestDeliveryZone />,
        title: 'Зоны доставки заведения',
        width: 1200,
        height: 700,
    })
})

export const setEditRestDeliveryZonesAtom = atom(null, (_, set) => {
    set(subModalAtom, {
        isOpen: true,
        children: <ModalEditRestDeliveryZone />,
        title: 'Зоны доставки заведения',
        width: 1200,
        height: 700,
    })
})

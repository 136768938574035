import { z } from 'zod'

export const AdminCreateSchema = z.object({
    systemName: z.string({ required_error: 'Обязательное поле' }).min(1, 'Минимум 1 символ'),
    tabNumber: z.string().optional(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    middleName: z.string().optional(),
    gender: z.string().optional(),
    birthday: z.string().optional(),
    phone: z.string().optional(),
    whatsapp: z.string().optional(),
    telegram: z.string().optional(),
    email: z.string().optional(),
    comment: z.string().optional(),
    login: z.string({ required_error: 'Обязательное поле' }).min(1, 'Минимум 1 символ'),
    password: z.string({ required_error: 'Обязательное поле' }).min(1, 'Минимум 1 символ'),
    passNum: z.string().optional(),
    passSeries: z.string().optional(),
    passIssuedBy: z.string().optional(),
    passIssuedWhen: z.string().optional(),
    passActualTo: z.string().optional(),
    snils: z.string().optional(),
    inn: z.string().optional(),
    city: z.string().optional(),
    street: z.string().optional(),
    house: z.string().optional(),
    corpus: z.string().optional(),
    apartment: z.string().optional(),
    adminPhoto: z.string().optional(),
    rights: z.array(z.string()).optional(),
})

export type AdminCreateData = z.infer<typeof AdminCreateSchema>

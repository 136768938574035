export const colors = {
    mainBackgroundColor: '#c0daed',
    white: '#ffffff',
    grey: {
        100: '#cacaca',
        200: '#b2c1cc',
        300: '#e7eff6',
        400: '#E9E9E9',
    },
    primaryColor: '#658399',
    secondaryColor: '#bac7d0',
    gradientColor1: '#181818',
    gradientColor2: '#535353',
    aqua: {
        200: '#8599a7',
        300: '#adbfcc',
        400: '#edf7ff',
        500: '#e2ebf2',
        600: '#2698f0',
        700: '#cde8fb',
        800: '#a3b9c9',
        900: '#f6fbff',
    },
    green: {
        900: '#06ba00',
        800: '#00BA00',
    },
    darkBlue: {
        900: '#153750',
        800: '#27465d',
        700: '#93cdf5',
    },
    blue: {
        900: '#2a9bec',
        800: '#2b9bec',
        400: '#90cbf5',
    },
    red: {
        800: '#f42d2e',
        700: '#F42E2E',
    },
    errorTextColor: '#f42e2e',
    regularTextColor: '#3a3a45',
}

import classNames from 'classnames'
import { PropsWithChildren } from 'react'
import { ButtonProps } from 'src/types/html'
import { Icon, IconType } from 'src/assets/icons'

import styles from './styles.module.scss'

export enum EButtonVariant {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
    QUARTERY = 'quartery',
    GREEN = 'green',
    BLUE = 'blue',
    RED = 'red',
    DARK_BLUE = 'dark-blue',
}

type AppButtonProps = {
    variant?: EButtonVariant
    icon?: IconType
    loader?: boolean
} & ButtonProps

export const Button = (props: PropsWithChildren<AppButtonProps>): JSX.Element => {
    const { variant = EButtonVariant.SECONDARY, icon, type = 'button', ...rest } = props
    return (
        <button
            {...rest}
            type={type}
            disabled={props.disabled || props.loader}
            className={classNames(
                {
                    [styles.primary]: variant === EButtonVariant.PRIMARY,
                    [styles.secondary]: variant === EButtonVariant.SECONDARY,
                    [styles.tertiary]: variant === EButtonVariant.TERTIARY,
                    [styles.quartery]: variant === EButtonVariant.QUARTERY,
                    [styles.green]: variant === EButtonVariant.GREEN,
                    [styles.blue]: variant === EButtonVariant.BLUE,
                    [styles.red]: variant === EButtonVariant.RED,
                    [styles.darkBlue]: variant === EButtonVariant.DARK_BLUE,
                    ['gap-2.5']: !!icon,
                    ['disabled:opacity-50']: props.disabled,
                    'flex justify-center items-center': true,
                    pressableSimple: !props.disabled,
                },
                rest.className,
                'cursor-pointer',
            )}>
            <div>{props.loader ? <div className={styles.loader} /> : icon && <Icon type={icon} size={16} />}</div>
            <div>{rest.children}</div>
        </button>
    )
}

import { ButtonProps } from 'src/types/html'
import classNames from 'classnames'
import { Icon, IconType } from 'src/assets/icons'

import styles from './styles.module.scss'

type RoundedWrapperProps = ButtonProps & {
    icon: IconType
    variant?: ERoundedWrapperVariant
}

export enum ERoundedWrapperVariant {
    PRIMARY = 'primary',
    BLUE = 'blue',
}

export const RoundedWrapper = (props: RoundedWrapperProps): JSX.Element => {
    const { variant = ERoundedWrapperVariant.PRIMARY, icon, className, disabled, onClick, ...rest } = props

    const handleClick = ev => {
        if (disabled) return
        if (onClick) onClick(ev)
    }

    return (
        <button
            type='button'
            onClick={handleClick}
            className={classNames(styles.wrapper, className, {
                'bg-aqua-500': variant === ERoundedWrapperVariant.PRIMARY,
                'bg-aqua-600': variant === ERoundedWrapperVariant.BLUE,
                'opacity-50': disabled,
            })}
            {...rest}
        >
            <Icon type={icon} size={15} />
        </button>
    )
}

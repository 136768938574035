import { HeaderLayout, NamedCheckbox } from 'src/components'
import { useSetAtom } from 'jotai/index'
import { useAtomValue } from 'jotai'

import { getRestFiltersAtom, updateRestFiltersAtom } from '../../atoms/restaraunts/restAtom'
import { GetRestRequest } from '../../services'

export const RestTitle = (): JSX.Element => {
    const restFilters = useAtomValue(getRestFiltersAtom)
    const updateRestFilters = useSetAtom(updateRestFiltersAtom)

    const handleCheckBox = () => {
        const val = (restFilters as GetRestRequest)?.showBlocked
        updateRestFilters({ showBlocked: !val })
    }
    return (
        <HeaderLayout title='Рестораны' className='justify-between pr-5'>
            <div>
                <NamedCheckbox
                    onChange={handleCheckBox}
                    checked={(restFilters as GetRestRequest)?.showBlocked}
                    name='Показывать заблокированных'
                />
            </div>
        </HeaderLayout>
    )
}

import { PropsWithChildren, useRef } from 'react'
import { InputProps } from 'src/types'

type ImageInputProps = InputProps

type Props = PropsWithChildren<ImageInputProps>

export const ImageInput = (props: Props): JSX.Element => {
    const { children, ...rest } = props

    const hiddenFileInput = useRef<HTMLInputElement>(null)

    const handleClick = () => {
        if (hiddenFileInput.current) {
            hiddenFileInput.current.click()
            hiddenFileInput.current.value = ''
        }
    }

    return (
        <>
            <div onClick={handleClick}>{children}</div>
            <input
                {...rest}
                type='file'
                ref={hiddenFileInput}
                style={{ display: 'none' }} // Make the file input element invisible
            />
        </>
    )
}

import { Assets } from 'src/utils'
import { useSetAtom } from 'jotai'
import { closeDialogAtom } from 'src/atoms/global/dialogAtom'
import { ReactNode } from 'react'

import { AppImage } from '../AppImage'
import { RegularText } from '../RegularText'
import { Button, EButtonVariant } from '../Button'

type ConfirmationDialogProps = {
    onClickOk: () => void
    desc: ReactNode
    loading: boolean
}

export const ConfirmationDialog = (props: ConfirmationDialogProps): JSX.Element => {
    const closeDialog = useSetAtom(closeDialogAtom)

    return (
        <div className='flex flex-col items-center justify-center h-full p-8'>
            <div className='size-14'>
                <AppImage src={Assets.sadSmiley} />
            </div>
            <div className='p-2 text-center'>{props.desc}</div>
            <div className='flex gap-2'>
                <Button
                    loader={props.loading}
                    className='!w-44 flex justify-center !py-5'
                    variant={EButtonVariant.RED}
                    onClick={props.onClickOk}>
                    <RegularText className='!text-base'>Удалить</RegularText>
                </Button>
                <Button className='!w-44 !py-5 flex justify-center' onClick={closeDialog}>
                    <RegularText>Отмена</RegularText>
                </Button>
            </div>
        </div>
    )
}

import { PropsWithChildren } from 'react'
import { SpanProps } from 'src/types/html'
import classNames from 'classnames'

import styles from './styles.module.scss'

export const SemiBoldText = (props: PropsWithChildren<SpanProps>): JSX.Element => {
    const { className = 'text-base', children, ...rest } = props

    return (
        <span className={classNames(styles.text, className)} {...rest}>
            {children}
        </span>
    )
}

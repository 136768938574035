class AppLocalStorage {
    static setAuthToken(token: string): void {
        localStorage.setItem('token', token)
        localStorage.setItem('isAuthenticated', 'true')
    }

    static deleteAuthToken(): void {
        localStorage.removeItem('token')
        localStorage.setItem('isAuthenticated', 'false')
    }

    static getToken(): string | null {
        const token = localStorage.getItem('token')

        try {
            if (typeof token === 'string') {
                return JSON.parse(token)
            }
        } catch (e) {
            return null
        }
        return null
    }
}

export { AppLocalStorage }

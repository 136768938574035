import dayjs from 'dayjs'

abstract class DateUtils {
    static formatDate(date: string | null | undefined): string {
        return dayjs(date).format('DD.MM.YYYY')
    }

    static calculateAge(date: string | null | undefined): number {
        const parsed = dayjs(date, 'DD.MM.YYYY')
        return Math.abs(dayjs(parsed).diff(dayjs(), 'years'))
    }

    static formatDateTime(date: string | null | undefined): string {
        return dayjs(date).format('DD/MM/YYYY HH:mm')
    }

    static formatToDay(date: string | null | undefined): string {
        return dayjs(date).format('DD MMMM, dd')
    }

    static parseDateInput(date: string | null | undefined): string {
        try {
            return dayjs(date, 'DD.MM.YYYY').toISOString()
        } catch (e) {
            return new Date().toISOString()
        }
    }
}

export { DateUtils }

import { useAtomValue } from 'jotai'
import { Controller, FieldError, useFormContext, useWatch } from 'react-hook-form'
import { allCourierGroupOptionsAtom } from 'src/atoms/allCouriers/allCourierGroupsAtom'
import {
    MediumItalic,
    NamedDateInput,
    NamedSelect,
    NamedTextInput,
    RegularText,
    SemiBoldText,
    TextArea,
    ESelectVariant,
    InputWrapper,
    AppSelect,
} from 'src/components'
import { DateUtils } from 'src/utils'

import { CourierCreateFormData } from './types'

const GENDER_OPTIONS = [
    { label: 'Мужской', value: 'М' },
    { label: 'Женский', value: 'Ж' },
]

export const CourierCreateInfo = (): JSX.Element => {
    const options = useAtomValue(allCourierGroupOptionsAtom)

    const {
        control,
        formState: { errors },
    } = useFormContext<CourierCreateFormData>()
    const birthday = useWatch({ name: 'birthday', control })

    const age = DateUtils.calculateAge(birthday)

    return (
        <div>
            <div className='flex mt-8 justify-evenly p-4'>
                <div className='left flex flex-col gap-3'>
                    <MediumItalic className='flex justify-center'>~Основные данные 1~</MediumItalic>
                    <ul className='flex flex-col gap-3'>
                        <li>
                            <Controller
                                control={control}
                                name='deliveryType'
                                render={props => (
                                    <InputWrapper name='Группа'>
                                        <AppSelect
                                            placeholder='Группа'
                                            options={options}
                                            selectVariant={ESelectVariant.SECONDARY}
                                            {...props.field}
                                            error={errors.deliveryType?.message ? 'Обязательное поле' : ''}
                                        />
                                    </InputWrapper>
                                )}
                            />
                        </li>
                        <li>
                            <Controller
                                control={control}
                                name='sysName'
                                render={props => (
                                    <NamedTextInput
                                        name='Имя в системе'
                                        textInputProps={{
                                            className: 'w-inputL',
                                            placeholder: 'Имя в системе',
                                            ...props.field,
                                            error: errors.sysName,
                                        }}
                                    />
                                )}
                            />
                        </li>
                        <li>
                            <Controller
                                control={control}
                                name='surname'
                                render={props => (
                                    <NamedTextInput
                                        name='Фамилия'
                                        textInputProps={{
                                            className: 'w-inputL',
                                            placeholder: 'Фамилия',
                                            ...props.field,
                                            error: errors.surname,
                                        }}
                                    />
                                )}
                            />
                        </li>
                        <li>
                            <Controller
                                control={control}
                                name='firstName'
                                render={props => (
                                    <NamedTextInput
                                        name='Имя'
                                        textInputProps={{
                                            className: 'w-inputL',
                                            placeholder: 'Имя',
                                            ...props.field,
                                            error: errors.firstName,
                                        }}
                                    />
                                )}
                            />
                        </li>
                        <li>
                            <Controller
                                control={control}
                                name='patronymic'
                                render={props => (
                                    <NamedTextInput
                                        name='Отчество'
                                        textInputProps={{
                                            className: 'w-inputL',
                                            placeholder: 'Отчество',
                                            ...props.field,
                                            error: errors.patronymic,
                                        }}
                                    />
                                )}
                            />
                        </li>
                        <li>
                            <Controller
                                control={control}
                                name='gender'
                                render={props => (
                                    <NamedSelect
                                        name='Пол'
                                        selectProps={{
                                            options: GENDER_OPTIONS,
                                            selectVariant: ESelectVariant.SECONDARY,
                                            ...props.field,
                                        }}
                                    />
                                )}
                            />
                        </li>
                    </ul>
                    <div className='flex items-center gap-4 justify-between mt-5'>
                        <div>
                            <Controller
                                control={control}
                                name='birthday'
                                render={props => (
                                    <NamedDateInput name='Дата рождения' dateInputProps={{ ...props.field, error: errors.birthday }} />
                                )}
                            />
                        </div>
                        <div>
                            <SemiBoldText className='text-sm'>
                                Возраст: <span>&nbsp;</span>
                            </SemiBoldText>
                            <RegularText className='text-sm'>{age}</RegularText>
                        </div>
                    </div>
                </div>
                <div className='right flex flex-col gap-3'>
                    <MediumItalic className='flex justify-center'>~Основные данные 2~</MediumItalic>
                    <Controller
                        control={control}
                        name='phoneNumber'
                        render={props => (
                            <NamedTextInput
                                name='Телефон'
                                textInputProps={{
                                    className: 'w-inputL',
                                    placeholder: 'Телефон',
                                    ...props.field,
                                    error: errors.phoneNumber?.message ? { type: 'required', message: 'Обязательное поле' } : undefined,
                                }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='whatsApp'
                        render={props => (
                            <NamedTextInput
                                name='Whatsapp'
                                textInputProps={{
                                    className: 'w-inputL',
                                    placeholder: 'Whatsapp',
                                    ...props.field,
                                    error: errors.whatsApp,
                                }}
                            />
                        )}
                    />
                    <Controller
                        name='telegram'
                        control={control}
                        render={props => (
                            <NamedTextInput
                                name='Telegram'
                                textInputProps={{
                                    className: 'w-inputL',
                                    placeholder: 'Telegram',
                                    ...props.field,
                                    error: errors.telegram,
                                }}
                            />
                        )}
                    />
                    <Controller
                        name='email'
                        control={control}
                        render={props => (
                            <NamedTextInput
                                name='Email'
                                textInputProps={{
                                    className: 'w-inputL',
                                    placeholder: 'Email',
                                    ...props.field,
                                    error: errors.email,
                                }}
                            />
                        )}
                    />

                    <div className='flex justify-end'>
                        <Controller
                            control={control}
                            name='comment'
                            render={props => <TextArea {...props.field} error={errors.comment} placeholder='Комментарий' />}
                        />
                    </div>
                    <MediumItalic className='flex justify-center mt-5'>~Входные данные~</MediumItalic>
                    <Controller
                        name='login'
                        control={control}
                        render={props => (
                            <NamedTextInput
                                name='Логин'
                                textInputProps={{
                                    className: 'w-inputL',
                                    ...props.field,
                                    error: errors.login?.message ? { type: 'required', message: 'Обязательное поле' } : undefined,
                                }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='password'
                        render={props => (
                            <NamedTextInput
                                name='Пароль'
                                textInputProps={{
                                    className: 'w-inputL',
                                    ...props.field,
                                    error: errors.password?.message ? { type: 'required', message: 'Обязательное поле' } : undefined,
                                }}
                            />
                        )}
                    />
                </div>
            </div>
        </div>
    )
}

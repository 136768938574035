import { z } from 'zod'

const ClientDBSchema = z.object({
    activeOrderCount: z.string().optional(),
    clientData: z.object({
        client_id: z.string(),
        phoneNumber: z.string().nullish(),
        paymentInfo: z.string().nullish(),
        address: z.string().nullish(),
        cityKey: z.string().nullish(),
        tabNumber: z.string().nullish(),
        gender: z.string().nullish(),
        firstName: z.string().nullish(),
        lastName: z.string().nullish(),
        middleName: z.string().nullish(),
        email: z.string().nullish(),
        whatsapp: z.string().nullish(),
        telegram: z.string().nullish(),
        house: z.string().nullish(),
        street: z.string().nullish(),
        corpus: z.string().nullish(),
        apartment: z.string().nullish(),
        comment: z.string().nullish(),
        bonuscount: z.number(),
        clientgroup_id: z.string().optional(),
        createdAt: z.string(),
        updatedAt: z.string(),
        birthday: z.string().optional(),
        isBanned: z.boolean(),
    }),
    orderCount: z.number(),
    totalPrice: z.number(),
    hasActiveOrder: z.boolean(),
    isActive: z.boolean(),
})

type ClientDB = z.infer<typeof ClientDBSchema>

export type { ClientDB }

export { ClientDBSchema }

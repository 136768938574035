import { useSetAtom } from 'jotai'
import { deleteCourierGroupAtom } from 'src/atoms/allCouriers/deleteCourierGroupAtom'
import { ConfirmationDialog, MediumText, Nbsp, RegularText } from 'src/components'
import { useState } from 'react'

type DialogDeleteCourierGroupProps = {
    group_id?: string
    title?: string
}

const DialogDeleteCourierGroup = (props: DialogDeleteCourierGroupProps): JSX.Element => {
    const [loading, setLoading] = useState(false)
    const desc = (
        <>
            <RegularText>Вы действительно хотите удалить группу</RegularText>
            <Nbsp /> <MediumText>"{props.title}"?</MediumText>
        </>
    )
    const onClickDeleteCourierGroup = useSetAtom(deleteCourierGroupAtom)

    const deleteClick = async () => {
        setLoading(true)
        await onClickDeleteCourierGroup({ group_id: props.group_id })
        setLoading(false)
    }

    return (
        <div>
            <ConfirmationDialog loading={loading} desc={desc} onClickOk={deleteClick} />
        </div>
    )
}

export { DialogDeleteCourierGroup }

import React, { useEffect, useState } from 'react'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import dayjs from 'dayjs'
import classNames from 'classnames'
import { setCloseSubModalAtom } from 'src/atoms/global/modalAtom'
import {
    Button,
    DateInput,
    Divider,
    MediumText,
    RegularText,
    SemiBoldText,
    TextInput,
    EButtonVariant,
    ETextInputVariant,
} from 'src/components'
import moment from 'moment'

import {
    editAdminAvailabilityAtom,
    onClickTimeAtom,
    scheduleConfigAtom,
    scheduleDaysAtom,
    scheduleStartAtom,
    scheduleTitleAtom,
} from '../../atoms/admins/adminAvailabilityAtom'
import { isDay } from '../CourierEditModals'

interface AdminEditScheduleProps {
    initialData: {
        avialschedule_id: string
        title: string
        schedule: { day: number; time: string[] }[]
        scheduleStart: string
        dayCount: number
    }
}

export const AdminEditSchedule: React.FC<AdminEditScheduleProps> = ({ initialData }) => {
    const [loading, setLoading] = useState(false)
    const editAdminAvailability = useSetAtom(editAdminAvailabilityAtom)
    const [scheduleConfig, setScheduleConfig] = useAtom(scheduleConfigAtom)
    const onClickTime = useSetAtom(onClickTimeAtom)
    const [scheduleTitle, setScheduleTitle] = useAtom(scheduleTitleAtom)
    const closeSubModal = useSetAtom(setCloseSubModalAtom)
    const [scheduleStart, setScheduleStart] = useAtom(scheduleStartAtom)
    const [scheduleDays, setScheduleDays] = useAtom(scheduleDaysAtom)
    const [error, setError] = useState<string | null>(null)
    const [dateBlurred, setDateBlurred] = useState(false)

    useEffect(() => {
        if (initialData) {
            setScheduleTitle(initialData.title)
            setScheduleStart(moment(initialData.scheduleStart).format('DD.MM.YYYY'))
            setScheduleDays(initialData.dayCount || 0)
            const newSchedule = initialData.schedule.map(item =>
                new Array(24).fill('').map((_, t) => ({
                    time: `${t}:00`,
                    selected: item.time.includes(`${t}:00`),
                })),
            )
            setScheduleConfig(newSchedule)
        }
    }, [initialData, setScheduleTitle])

    const handleSave = async () => {
        if (!initialData || !initialData.avialschedule_id) {
            setError('Missing avialschedule_id for editing admin availability')
            return
        }
        if (!scheduleStart) {
            setError('Please enter a start date')
            return
        }

        if (!scheduleDays || scheduleDays <= 0) {
            setError('Please enter a valid number of days')
            return
        }

        const schedule = scheduleConfig.map((item, index) => ({
            day: index + 1,
            time: item.filter(time => time.selected).map(filtered => filtered.time),
        }))

        if (schedule.length === 0) {
            setError('Please select at least one time slot')
            return
        }

        try {
            setLoading(true)
            const response = await editAdminAvailability({
                scheduleStart: moment(scheduleStart, 'DD.MM.YYYY').toISOString(),
                title: scheduleTitle,
                schedule,
                avialschedule_id: initialData.avialschedule_id,
                dayCount: scheduleDays,
            })
            setError(null)
            closeSubModal()
        } catch (error) {
            console.error('Failed to edit admin availability:', error)
            setError('Failed to edit admin availability. Please try again.')
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className='flex flex-col h-full'>
            {/*{error && (
        <RegularText className="text-red-500 mb-2">{error}</RegularText>
      )}*/}
            <div className='flex gap-4 p-7'>
                <div>
                    <SemiBoldText className='text-xs'>Название</SemiBoldText>
                    <TextInput
                        placeholder='Название'
                        variant={ETextInputVariant.SECONDARY}
                        value={scheduleTitle}
                        onChange={e => setScheduleTitle(e.currentTarget.value)}
                    />
                </div>

                <div>
                    <SemiBoldText className='text-xs'>Начало действия</SemiBoldText>
                    <DateInput value={scheduleStart} onChange={e => setScheduleStart(e.target.value)} />
                    {dateBlurred && scheduleStart && !dayjs(scheduleStart).isValid() && (
                        <RegularText className='text-red-700'>Введите корректную дату</RegularText>
                    )}
                </div>

                <div>
                    <MediumText>Кол-во дней</MediumText>
                    <TextInput
                        type='number'
                        className='w-inputS'
                        value={scheduleDays === null ? '' : scheduleDays}
                        onChange={e => {
                            const { value } = e.target
                            setScheduleDays(value === '' ? null : Number(value))
                        }}
                    />
                </div>
            </div>

            <div className='m-1.5 flex max-h-96 overflow-scroll'>
                {scheduleConfig.map((schedule, parentIndex) => (
                    <div key={parentIndex} className='flex flex-col w-full px-px'>
                        <div className='bg-aqua-400 flex justify-center items-center p-2'>{isDay(parentIndex + 1)}</div>
                        {schedule.map((item, index) => (
                            <div
                                onClick={() => onClickTime({ parentIndex, index })}
                                className={classNames(
                                    'flex justify-center items-center cursor-pointer',
                                    'p-2',
                                    index % 2 !== 0 && 'bg-aqua-100',
                                    item.selected && '!bg-aqua-600',
                                )}
                                key={item.time}>
                                {item.time}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div className='p-6'>
                <div className='mt-2'>
                    <Divider />
                </div>
                <div className='flex h-full justify-end items-end pt-4'>
                    <Button loader={loading} onClick={handleSave} icon='save' variant={EButtonVariant.BLUE} className='mr-2' type='submit'>
                        <MediumText>Сохранить изменения</MediumText>
                    </Button>
                    <Button onClick={closeSubModal}>
                        <RegularText>Отмена</RegularText>
                    </Button>
                </div>
            </div>
        </div>
    )
}

import { useSetAtom } from 'jotai'
import { closeDialogAtom } from 'src/atoms/global/dialogAtom'
import { AppImage, Button, EButtonVariant, RegularText } from 'src/components'
import { Assets } from 'src/utils'

export const DeleteClientOrderDialog = (): JSX.Element => {
    const closeDialog = useSetAtom(closeDialogAtom)

    return (
        <div className='flex flex-col items-center justify-center h-full p-8'>
            <div className='size-14'>
                <AppImage src={Assets.sadSmiley} />
            </div>
            <div className='flex-1 flex justify-center items-center'>
                <RegularText>Удалить заказ</RegularText>
            </div>
            <div className='flex gap-2'>
                <Button className='!w-44 flex justify-center !py-5' variant={EButtonVariant.RED}>
                    <RegularText className='!text-base'>Да, удалить</RegularText>
                </Button>
                <Button className='!w-44 !py-5 flex justify-center' onClick={closeDialog}>
                    <RegularText>Назад</RegularText>
                </Button>
            </div>
        </div>
    )
}

import { useAtom, useSetAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { setCourierAssignOrderAtom, setCourierFormOrderAtom } from 'src/atoms/allCouriers/courierCreateModalAtom'
import { courierDelivDateRangeAtom, courierDelivSearchQueryAtom, getCourierDeliveries } from 'src/atoms/allCouriers/editDeliveryAtoms'
import { Button, MediumText, TextInput, SuspenseWithSpinner, GridTable, GridTableHead, GridTableItem } from 'src/components'
import { EButtonVariant } from 'src/components/Button'
import { datePeriodOptions, DateUtils } from 'src/utils'

import { DateFilterAuto } from '../../components/DateFilterAuto'
import { getCourierStatusNames } from '../../utils/statusNames'
import { Order, Delivery } from '../../services'
import { updateCouriersFiltersAtom } from '../../atoms/allCouriers/allCouriersQueryParamsAtom'
import { DateFilterSelect } from '../../components/DateFilterSelect'

const THEADS = [
    'Номер заказа',
    'Статус курьера',
    'Дата/время поступления заказа',
    'Дата/время взятия заказа',
    'Дата/время заказа',
    'Сумма заказа',
    'Тип оплаты',
    'Состав заказа',
    'Заведение',
    'Адрес заведения',
    'Примерное время доставки',
    'Время доставки',
    'Имя клиента',
    'Адрес клиента',
    'Контактный номер телефона клиента',
    'Комментарий клиента',
]

export const CourierDelivery = (): JSX.Element => {
    const onClickFormOrder = useSetAtom(setCourierFormOrderAtom)
    const onClickAssignOrder = useSetAtom(setCourierAssignOrderAtom)
    const [deliveries, refreshDeliveries] = useAtom(getCourierDeliveries)
    const [sortedDeliveries, setSortedDeliveries] = useState<Delivery['courierDeliv']>([])
    const [dateRange, setDateRange] = useAtom(courierDelivDateRangeAtom)
    const setSearchQuery = useSetAtom(courierDelivSearchQueryAtom)
    const updateFilters = useSetAtom(updateCouriersFiltersAtom)
    const [inputVal, setInputVal] = useState('')
    const setDate = (fieldName: 'dateFrom' | 'dateTo') => (val: string) => {
        setDateRange(prev => ({ ...prev, [fieldName]: val }))
    }

    const DishDetails = ({ order }: { order: Delivery['courierDeliv'][0] }) => {
        const { dishIdArray, isDeliveryServices, order_data } = order
        if (isDeliveryServices) return order_data

        const dishes = dishIdArray as unknown
        if (!dishes || !Array.isArray(dishes) || dishes.length === 0) {
            return <span>Нет данных</span>
        }

        return (
            <ul>
                {dishes.map((dish, index) => (
                    <li key={index}>
                        {dish.title} (x{dish.amount}) - {dish.dishPrice} руб.
                    </li>
                ))}
            </ul>
        )
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setSearchQuery(inputVal)
        }, 300)

        return () => clearTimeout(timer)
    }, [inputVal])

    useEffect(() => {
        if (deliveries.data && deliveries.data.courierDeliv) {
            const sorted = [...deliveries.data.courierDeliv].sort((a, b) => {
                const statusOrder = {
                    new: 0,
                    going: 1,
                    complete: 2,
                    cancelled: 3,
                }
                const priorityA = statusOrder[a.courierStatus || 'cancelled']
                const priorityB = statusOrder[b.courierStatus || 'cancelled']

                if (priorityA !== priorityB) {
                    return priorityA - priorityB
                }

                return new Date(b.createdAt || 0).getTime() - new Date(a.createdAt || 0).getTime()
            })

            setSortedDeliveries(sorted)
        }
    }, [deliveries])

    console.log(deliveries)

    return (
        <div className='flex flex-col'>
            <div className='pl-5 pt-5 flex flex-col gap-6'>
                <div className='justify-between'>
                    <DateFilterSelect
                        dateFrom={dateRange.dateFrom || ''}
                        dateTo={dateRange.dateTo || ''}
                        setDateFrom={setDate('dateFrom')}
                        setDateTo={setDate('dateTo')}
                        selectProps={{ options: datePeriodOptions }}
                    />
                </div>
                <div className='flex gap-8'>
                    <div className='flex gap-3'>
                        <TextInput value={inputVal} onChange={ev => setInputVal(ev.target.value)} placeholder='Поиск по номеру' />
                    </div>

                    <div className='flex gap-3'>
                        <Button icon='plus' variant={EButtonVariant.PRIMARY} onClick={() => onClickAssignOrder()}>
                            <MediumText>Присвоить заказ</MediumText>
                        </Button>
                        <Button icon='plus' variant={EButtonVariant.GREEN} onClick={() => onClickFormOrder()}>
                            <MediumText>Сформировать заказ</MediumText>
                        </Button>
                    </div>
                </div>
            </div>
            <div className='mx-2 h-96 overflow-y-auto'>
                <SuspenseWithSpinner>
                    <GridTable
                        theads={THEADS.map(head => (
                            <GridTableHead key={head} title={head} />
                        ))}>
                        {sortedDeliveries.map(order => (
                            <tr key={order.order_id}>
                                <GridTableItem child={order.orderNumber} />
                                <GridTableItem child={getCourierStatusNames(order as unknown as Order)} />
                                <GridTableItem child={DateUtils.formatDateTime(order.createdAt)} />
                                <GridTableItem child={order.orderTookDate ? DateUtils.formatDateTime(order.orderTookDate) : '-'} />
                                <GridTableItem child={order.deliveryDate ? DateUtils.formatDateTime(order.deliveryDate) : '-'} />
                                <GridTableItem child={order.price} />
                                <GridTableItem child={order.paymentType === 'cash' ? 'Наличными' : 'Картой'} />
                                <GridTableItem child={<DishDetails order={order} />} />
                                <GridTableItem child={order.restName} />
                                <GridTableItem child={order.restAddress || '-'} />
                                <GridTableItem child={order.deliveryTime || '-'} />
                                <GridTableItem child={order.spentTimeOnDelivery || '-'} />
                                <GridTableItem child={order.clientName || '-'} />
                                <GridTableItem child={order.deliveryAddress || '-'} />
                                <GridTableItem child={order.clientPhoneNumber || '-'} />
                                <GridTableItem child={order.comment || '-'} />
                            </tr>
                        ))}
                    </GridTable>
                </SuspenseWithSpinner>
            </div>
        </div>
    )
}

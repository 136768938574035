import { atom } from 'jotai'
import { NotSavedDialog } from 'src/features/Dialogs/NotSavedDialog'
import React from 'react'
import { atomWithRefresh } from 'jotai/utils'

import AccountingCouriersModal from '../../pages/AccountingCouriersPage/components/AccountingCouriersModal'
import { CouriersAccounting, FinancesAccounting, FinancesCourierAccounting, LokaliApi } from '../../services'
import { CreateCourierPaymentRequest } from '../../services/LocaliRepository/types/createCourierPayment'

import { ModalAtomState, modalAtom, setCloseAllModals } from '../global/modalAtom'
import { dialogStateAtom, closeDialogAtom } from '../global/dialogAtom'
import { currentCourierAtom } from '../allOrders/couriersListAtom'

const setCouriersAccountingModalAtom = atom(
    null,
    (
        _,
        set,
        args: {
            id: string
            setCouriersAccounting: (couriers: FinancesCourierAccounting[]) => void
            courierAccounting: FinancesCourierAccounting
        },
    ) => {
        const update: ModalAtomState = {
            isOpen: true,
            children: (
                <AccountingCouriersModal
                    courierId={args.id}
                    setCouriersAccounting={args.setCouriersAccounting}
                    courierAccounting={args.courierAccounting}
                />
            ),
            title: 'Бухгалтерия оплата курьеру',
            onClose: () =>
                set(dialogStateAtom, {
                    isOpen: true,
                    children: <NotSavedDialog onAccept={() => set(setCloseAllModals)} onAbort={() => set(closeDialogAtom)} />,
                }),
        }

        set(modalAtom, update)
    },
)

const createCourierPaymentAtom = atom(null, async (_, __, req: CreateCourierPaymentRequest) => {
    try {
        const response = await LokaliApi.createCourierPayment(req)
        return response
    } catch (e) {
        throw e
    }
})

const getCourierPaymentAtom = atomWithRefresh(async get => {
    const currentCourierId = await get(currentCourierAtom)?.item.courier_id

    try {
        if (currentCourierId) {
            const data = await LokaliApi.getCourierAccountingPayments({ courier_id: currentCourierId })

            return {
                data: data,
                error: null,
            }
        }
        return {
            data: [],
            error: null,
        }
    } catch (e) {
        console.error('Error in getCourierPaymentAtom:', e)
        return {
            data: [],
            error: e as Error,
        }
    }
})
export { setCouriersAccountingModalAtom, createCourierPaymentAtom, getCourierPaymentAtom }

import { useAtom, useAtomValue } from 'jotai'
import { useEffect } from 'react'
import {
    createAdminParentAtom,
    createAdminRightAtom,
    createClientParentAtom,
    createClientRightAtom,
    createCourierParentAtom,
    createCourierRightAtom,
    createGeneralParentAtom,
    createGeneralRightAtom,
    createOrderParentAtom,
    createOrderRightAtom,
    createRestParentAtom,
    createRestRightAtom,
} from 'src/atoms/admins/adminCreateRightsAtom'
import { MediumText, RegularText, Switch } from 'src/components'

type CheckboxValue = {
    label: string
    checked: boolean
    id: string
}

type SwitchGroupProps = {
    parent: string
    children: CheckboxValue[]
    onClick: (index: number) => void
    onClickParent: () => void
    parentChecked: boolean
}

const SwitchGroup = ({ parent, children, onClick, onClickParent, parentChecked }: SwitchGroupProps): JSX.Element => (
    <div className='flex flex-col gap-3'>
        <div className='flex gap-2'>
            <Switch
                id={parent}
                checked={parentChecked}
                onClick={e => {
                    e.stopPropagation()
                    onClickParent()
                }}
            />
            <MediumText>{parent}</MediumText>
        </div>
        {children.map((child, idx) => (
            <div key={child.label} className='flex gap-2 pl-5'>
                <Switch onClick={() => onClick(idx)} checked={child.checked} id={child.id} />
                <RegularText className='text-nowrap whitespace-nowrap'>{child.label}</RegularText>
            </div>
        ))}
    </div>
)

const useRights = () => {
    const rightsAtoms = [
        { parentAtom: createGeneralParentAtom, childAtom: createGeneralRightAtom, label: 'Общая статистика' },
        { parentAtom: createClientParentAtom, childAtom: createClientRightAtom, label: 'Клиенты' },
        { parentAtom: createCourierParentAtom, childAtom: createCourierRightAtom, label: 'Курьеры' },
        { parentAtom: createRestParentAtom, childAtom: createRestRightAtom, label: 'Рестораны' },
        { parentAtom: createOrderParentAtom, childAtom: createOrderRightAtom, label: 'Заказы' },
        { parentAtom: createAdminParentAtom, childAtom: createAdminRightAtom, label: 'Админы' },
    ]

    return rightsAtoms.map(({ parentAtom, childAtom, label }) => {
        const [children, setChildren] = useAtom(childAtom)
        const parentChecked = useAtomValue(parentAtom)

        const toggleChild = (idx: number) =>
            setChildren(draft => {
                draft[idx].checked = !draft[idx].checked
            })

        const toggleParent = () =>
            setChildren(old =>
                old.map(child => ({
                    ...child,
                    checked: !parentChecked,
                })),
            )

        return { parent: label, children, onClickParent: toggleParent, onClick: toggleChild, parentChecked }
    })
}

export const ModalCreateRights = (): JSX.Element => {
    const rights = useRights()

    return (
        <div className='grid grid-cols-3 p-4 gap-2'>
            {rights.map(({ parent, children, onClickParent, onClick, parentChecked }) => (
                <div key={parent} className='flex flex-col gap-10'>
                    <SwitchGroup
                        parent={parent}
                        children={children}
                        onClickParent={onClickParent}
                        onClick={onClick}
                        parentChecked={parentChecked}
                    />
                </div>
            ))}
        </div>
    )
}

import { HeaderLayout, BaseLayout, SuspenseWithSpinner } from 'src/components'
import { AddCourier, CouriersList, CouriersTableFilter, CouriersTable } from 'src/features/Couriers'

import { CouriersTableLowFilters } from '../../features/Couriers/CouriersTableLowFilters'

import styles from './styles.module.scss'

export const AllCouriersPage = (): JSX.Element => (
    <div className={styles.container}>
        <HeaderLayout title='Курьеры' className='pr-5'>
            <CouriersTableFilter />
        </HeaderLayout>

        <BaseLayout
            filtersChild={<CouriersTableLowFilters />}
            leftChild={
                <div className={styles.leftChild}>
                    <AddCourier />

                    <SuspenseWithSpinner>
                        <CouriersList />
                    </SuspenseWithSpinner>
                </div>
            }
        >
            <SuspenseWithSpinner>
                <CouriersTable />
            </SuspenseWithSpinner>
        </BaseLayout>
    </div>
)

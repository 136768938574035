import { Controller, useFormContext, useWatch } from 'react-hook-form'
import {
    AppSelect,
    DateInput,
    GenderOptions,
    InputWrapper,
    MediumItalic,
    RegularText,
    SemiBoldText,
    TextArea,
    TextInput,
} from 'src/components'
import { DateUtils } from 'src/utils'
import React from 'react'

import { AdminCreateData } from './schema'

export const ModalCreateAdminInfo = (): JSX.Element => {
    const {
        control,
        formState: { errors },
        reset,
    } = useFormContext<AdminCreateData>()
    const birthday = useWatch({ control: control, name: 'birthday' })

    React.useEffect(() => {
        const subscription = control._subjects.state.subscribe({
            next: formState => {
                if (formState.isSubmitSuccessful) {
                    reset({})
                }
            },
        })

        return () => subscription.unsubscribe()
    }, [reset, control])

    return (
        <div className='pb-4'>
            <div className='flex mt-12 justify-evenly'>
                <div className='left flex flex-col gap-3'>
                    <MediumItalic className='flex justify-center'>~Основные данные 1~</MediumItalic>
                    <ul className='flex flex-col gap-3'>
                        <li>
                            <InputWrapper name='Имя в системе'>
                                <Controller
                                    control={control}
                                    name='systemName'
                                    render={props => <TextInput placeholder='Имя в системе' {...props.field} error={errors.systemName} />}
                                />
                            </InputWrapper>
                        </li>
                        <li>
                            <InputWrapper name='Фамилия'>
                                <Controller
                                    control={control}
                                    name='firstName'
                                    render={props => <TextInput placeholder='Фамилия' {...props.field} error={errors.firstName} />}
                                />
                            </InputWrapper>
                        </li>
                        <li>
                            <InputWrapper name='Имя'>
                                <Controller
                                    control={control}
                                    name='middleName'
                                    render={props => <TextInput placeholder='Имя' {...props.field} error={errors.middleName} />}
                                />
                            </InputWrapper>
                        </li>
                        <li>
                            <InputWrapper name='Отчество'>
                                <Controller
                                    control={control}
                                    name='lastName'
                                    render={props => <TextInput placeholder='Отчество' {...props.field} error={errors.lastName} />}
                                />
                            </InputWrapper>
                        </li>
                        <li>
                            <InputWrapper name='Пол'>
                                <Controller
                                    control={control}
                                    name='gender'
                                    render={props => (
                                        <AppSelect
                                            placeholder='Пол'
                                            options={GenderOptions}
                                            onChange={val => props.field.onChange(val?.value)}
                                            value={GenderOptions.find(gender => props.field.value === gender.value)}
                                            error={errors.gender?.message}
                                        />
                                    )}
                                />
                            </InputWrapper>
                        </li>
                    </ul>

                    <div className='flex items-center gap-4 justify-between mt-5'>
                        <div>
                            <InputWrapper name='Дата рождения'>
                                <Controller
                                    control={control}
                                    name='birthday'
                                    render={props => <DateInput {...props.field} error={errors.birthday} />}
                                />
                            </InputWrapper>
                        </div>
                        <div>
                            <SemiBoldText className='text-sm'>
                                Возраст: <span>&nbsp;</span>
                            </SemiBoldText>
                            <RegularText className='text-sm'>{DateUtils.calculateAge(birthday)}</RegularText>
                        </div>
                    </div>
                </div>
                <div className='right flex flex-col gap-3'>
                    <MediumItalic className='flex justify-center'>~Основные данные 2~</MediumItalic>
                    <InputWrapper name='Телефон'>
                        <Controller
                            control={control}
                            name='phone'
                            render={props => <TextInput placeholder='Телефон' {...props.field} error={errors.phone} />}
                        />
                    </InputWrapper>
                    <InputWrapper name='Whatsapp'>
                        <Controller
                            control={control}
                            name='whatsapp'
                            render={props => <TextInput placeholder='Whatsapp' {...props.field} error={errors.whatsapp} />}
                        />
                    </InputWrapper>
                    <InputWrapper name='Telegram'>
                        <Controller
                            control={control}
                            name='telegram'
                            render={props => <TextInput placeholder='Telegram' {...props.field} error={errors.telegram} />}
                        />
                    </InputWrapper>
                    <InputWrapper name='Email'>
                        <Controller
                            control={control}
                            name='email'
                            render={props => <TextInput placeholder='Email' {...props.field} error={errors.email} />}
                        />
                    </InputWrapper>
                    <div className='flex justify-end'>
                        <Controller
                            control={control}
                            name='comment'
                            render={({ field }) => <TextArea placeholder='Комментарий' {...field} value={field.value || ''} />}
                        />
                    </div>
                    <MediumItalic className='flex justify-center mt-5'>~Входные данные~</MediumItalic>
                    <InputWrapper name='Логин'>
                        <Controller
                            control={control}
                            name='login'
                            render={props => <TextInput placeholder='Логин' {...props.field} error={errors.login} />}
                        />
                    </InputWrapper>
                    <InputWrapper name='Пароль'>
                        <Controller
                            control={control}
                            name='password'
                            render={props => <TextInput placeholder='Пароль' {...props.field} error={errors.password} />}
                        />
                    </InputWrapper>
                </div>
            </div>
        </div>
    )
}

import { useAtomValue, useSetAtom } from 'jotai'
import { deleteOrderAtom } from 'src/atoms/allOrders/deleteOrderAtom'
import { closeDialogAtom } from 'src/atoms/global/dialogAtom'
import { AppImage, Button, EButtonVariant, MediumText, Nbsp, RegularText } from 'src/components'
import { Assets } from 'src/utils'

import { currentOrderAtom } from '../../atoms/allOrders/ordersAtom'
import { useState } from 'react'

const DeleteOrder = (): JSX.Element => {
    const [loading, setLoading] = useState(false)
    const currentOrder = useAtomValue(currentOrderAtom)
    const closeDialog = useSetAtom(closeDialogAtom)
    const deleteOrder = useSetAtom(deleteOrderAtom)

    const clickDelete = async () => {
        setLoading(true)
        await deleteOrder(currentOrder!)
        setLoading(false)
    }

    return (
        <div className='flex flex-col items-center justify-center h-full p-8'>
            <div className='size-14'>
                <AppImage src={Assets.sadSmiley} />
            </div>
            <div className='flex-1 flex justify-center items-center'>
                <RegularText>
                    Удалить заказ <Nbsp />
                </RegularText>
                <MediumText>№{currentOrder?.orderNumber}</MediumText>
            </div>
            <div className='flex gap-2'>
                <Button
                    className='!w-44 flex justify-center !py-5'
                    variant={EButtonVariant.RED}
                    loader={loading}
                    onClick={clickDelete}
                    disabled={!currentOrder}>
                    <RegularText className='!text-base'>Удалить</RegularText>
                </Button>
                <Button className='!w-44 !py-5 flex justify-center' onClick={closeDialog}>
                    <RegularText>Отмена</RegularText>
                </Button>
            </div>
        </div>
    )
}

export { DeleteOrder }

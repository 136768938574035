import { useEffect } from 'react'
import { createPortal } from 'react-dom'
import classNames from 'classnames'
import { Icon } from 'src/assets/icons'
import { useAtomValue, useSetAtom } from 'jotai'
import { setCloseSubModalAtom, subModalAtom } from 'src/atoms/global/modalAtom'

import { RegularText } from '../RegularText'
import { SuspenseWithSpinner } from '../SuspenseWithSpinner'

import styles from './styles.module.scss'

export const SubModal = (): JSX.Element => {
    const { isOpen, title, children, width = 440, height = 440, zIndex, icon } = useAtomValue(subModalAtom)
    const setCloseModal = useSetAtom(setCloseSubModalAtom)

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [isOpen])

    return (
        <>
            {createPortal(
                <div
                    className={classNames({
                        [styles.modal]: true,
                        [styles.show]: isOpen,
                    })}
                    style={{ zIndex: zIndex }}
                >
                    <div
                        style={{
                            width: width,
                            height: 'auto',
                            maxHeight: height,
                        }}
                        className={classNames(styles.subModalContent, 'rounded-lg shadow-lg flex flex-col')}
                    >
                        <div className={styles.header}>
                            <div className='flex gap-2'>
                                {icon}
                                <RegularText className='!text-white'>{title}</RegularText>
                            </div>

                            <Icon type='close' className='cursor-pointer' onClick={setCloseModal} />
                        </div>
                        <SuspenseWithSpinner>{children}</SuspenseWithSpinner>
                    </div>
                </div>,
                document.getElementById('sub-modal') as HTMLElement,
            )}
        </>
    )
}

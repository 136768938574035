import { atomWithRefresh } from 'jotai/utils'
import { LokaliApi } from 'src/services'
import { atom } from 'jotai'
import { atomWithImmer } from 'jotai-immer'
import { HttpStatusCode } from 'src/types'
import { CreateCourierAvailabilityRequest } from 'src/services/LocaliRepository/types/createAvailability'
import dayjs from 'dayjs'

import { currentCourierAtom } from '../allOrders/couriersListAtom'
import { setCloseSubModalAtom } from '../global/modalAtom'

const SCHEDULE = [
    { time: '0:00', selected: false },
    { time: '1:00', selected: false },
    { time: '2:00', selected: false },
    { time: '3:00', selected: false },
    { time: '4:00', selected: false },
    { time: '5:00', selected: false },
    { time: '6:00', selected: false },
    { time: '7:00', selected: false },
    { time: '8:00', selected: false },
    { time: '9:00', selected: false },
    { time: '10:00', selected: false },
    { time: '11:00', selected: false },
    { time: '12:00', selected: false },
    { time: '13:00', selected: false },
    { time: '14:00', selected: false },
    { time: '15:00', selected: false },
    { time: '16:00', selected: false },
    { time: '17:00', selected: false },
    { time: '18:00', selected: false },
    { time: '19:00', selected: false },
    { time: '20:00', selected: false },
    { time: '21:00', selected: false },
    { time: '22:00', selected: false },
    { time: '23:00', selected: false },
]

type Update = {
    parentIndex: number
    index: number
}
const scheduleConfigAtom = atomWithImmer<(typeof SCHEDULE)[]>([SCHEDULE, SCHEDULE, SCHEDULE, SCHEDULE, SCHEDULE, SCHEDULE, SCHEDULE])

const scheduleTitleAtom = atom('')

const onClickTimeAtom = atom(null, (_, set, { parentIndex, index }: Update) => {
    set(scheduleConfigAtom, old => {
        old[parentIndex][index].selected = !old[parentIndex][index].selected
    })
})

export const refreshCourierAvailabilityAtom = atom(null, (_, set) => {
    set(getCourierAvailabilityAtom)
})
const getCourierAvailabilityAtom = atomWithRefresh(async get => {
    const currentCourierId = await get(currentCourierAtom)?.item.courier_id

    try {
        if (currentCourierId) {
            const data = await LokaliApi.getCourierAvailability(currentCourierId)

            return {
                data: data,
                error: null,
            }
        }
        return {
            data: [],
            error: null,
        }
    } catch (e) {
        return {
            data: [],
            error: e as Error,
        }
    }
})

const createAvailabilityNetworkStateAtom = atom<HttpStatusCode>(HttpStatusCode.REQUEST_TIMEOUT)
const createAvailabilityStartAtom = atom<string | undefined>(undefined)
const scheduleStartAtom = atom<string | undefined>(undefined)
const scheduleDaysAtom = atom<number>(0)

const createCourierAvailabilityAtom = atom(null, async (get, set) => {
    const title = get(scheduleTitleAtom)
    const schedule = get(scheduleConfigAtom)
    const courierId = get(currentCourierAtom)?.item.courier_id
    const scheduleStart = get(scheduleStartAtom)
    const daysCount = get(scheduleDaysAtom)

    const mappedSchedule = schedule.map((el, index) => ({
        day: index + 1,
        time: el.filter(time => time.selected).map(filtered => filtered.time),
    }))

    try {
        if (courierId && scheduleStart) {
            const req: CreateCourierAvailabilityRequest = {
                title,
                schedule: mappedSchedule,
                courier_id: courierId,
                scheduleStart: dayjs(scheduleStart, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                dayCount: daysCount,
            }
            set(createAvailabilityNetworkStateAtom, HttpStatusCode.LOADING)

            const statusCode = await LokaliApi.createCourierAvailability(req)

            if (statusCode === HttpStatusCode.OK) {
                set(setCloseSubModalAtom)
                set(createAvailabilityNetworkStateAtom, HttpStatusCode.OK)
                set(refreshCourierAvailabilityAtom)
                set(scheduleConfigAtom, [SCHEDULE, SCHEDULE, SCHEDULE, SCHEDULE, SCHEDULE, SCHEDULE, SCHEDULE])
                set(scheduleTitleAtom, '')
            }
        }
        set(createAvailabilityNetworkStateAtom, HttpStatusCode.I_AM_A_TEAPOT)
    } catch (e) {
        set(createAvailabilityNetworkStateAtom, HttpStatusCode.INTERNAL_SERVER_ERROR)
    }
})

export {
    getCourierAvailabilityAtom,
    createCourierAvailabilityAtom,
    createAvailabilityNetworkStateAtom,
    onClickTimeAtom,
    scheduleConfigAtom,
    scheduleTitleAtom,
    createAvailabilityStartAtom,
    scheduleStartAtom,
    scheduleDaysAtom,
}

import { atom } from 'jotai'
import { DialogDeleteCourierGroup } from 'src/features/Couriers/DialogDeleteCourierGroup'
import { HttpStatusCode } from 'src/types'
import { DeleteCourierGroupRequest, LokaliApi } from 'src/services'

import { dialogStateAtom } from '../global/dialogAtom'
import { setCloseAllModals } from '../global/modalAtom'

import { allCourierGroupsAtom } from './allCourierGroupsAtom'

const deleteCourierGroupNetworkStateAtom = atom<HttpStatusCode>(HttpStatusCode.I_AM_A_TEAPOT)

const openDeleteCourierGroupDialogAtom = atom(null, (_, set, arg: { group_id?: string; title?: string }) => {
    set(dialogStateAtom, {
        isOpen: true,
        children: <DialogDeleteCourierGroup group_id={arg.group_id} title={arg.title} />,
    })
})

const deleteCourierGroupAtom = atom(null, async (_, set, req: DeleteCourierGroupRequest) => {
    try {
        set(deleteCourierGroupNetworkStateAtom, HttpStatusCode.LOADING)

        const statusCode = await LokaliApi.deleteCourierGroup(req)

        if (statusCode === HttpStatusCode.OK) {
            set(setCloseAllModals)
            set(deleteCourierGroupNetworkStateAtom, HttpStatusCode.OK)

            set(allCourierGroupsAtom)
        }
    } catch (e) {
        set(deleteCourierGroupNetworkStateAtom, HttpStatusCode.INTERNAL_SERVER_ERROR)
    }
})

export { openDeleteCourierGroupDialogAtom, deleteCourierGroupAtom }

import { atom } from 'jotai'
import { atomWithImmer } from 'jotai-immer'

export type AdminRight = {
    label: string
    checked: boolean
    id: string
}

const createParentAtom = (rightAtom: any) =>
    atom(get => {
        const data = get(rightAtom) as AdminRight[]
        return data.some(el => el.checked)
    })

export const generalRightAtom = atomWithImmer<AdminRight[]>([
    { label: 'Просмотр к каждому блоку', checked: false, id: 'general1' },
    { label: 'Экспорт', checked: false, id: 'general2' },
])

export const clientRightAtom = atomWithImmer<AdminRight[]>([
    { label: 'Просмотр', checked: false, id: 'client1' },
    { label: 'Создание удаление групп', checked: false, id: 'client2' },
    { label: 'Добавление клиента', checked: false, id: 'client3' },
    { label: 'Удаление клиента', checked: false, id: 'client4' },
    { label: 'Редактирование клиента', checked: false, id: 'client5' },
    { label: 'Экспорт', checked: false, id: 'client6' },
    { label: 'Редактирование клиента', checked: false, id: 'client7' },
    { label: 'Наличие бонусов', checked: false, id: 'client8' },
    { label: 'Рассылка уведомлений', checked: false, id: 'client9' },
])

export const courierRightAtom = atomWithImmer<AdminRight[]>([
    { label: 'Просмотр таблицы', checked: false, id: 'courier1' },
    { label: 'Просмотр карты', checked: false, id: 'courier2' },
    { label: 'Добавление', checked: false, id: 'courier3' },
    { label: 'Удаление', checked: false, id: 'courier4' },
    { label: 'Редактирование', checked: false, id: 'courier5' },
    { label: 'Назначение удаление заказов', checked: false, id: 'courier6' },
    { label: 'Просмотр статистики заказа', checked: false, id: 'courier7' },
])

export const restRightAtom = atomWithImmer<AdminRight[]>([
    { label: 'Добавление удаление группы', checked: false, id: 'rest1' },
    { label: 'Добавление ресторана', checked: false, id: 'rest2' },
    { label: 'Удаление', checked: false, id: 'rest3' },
    { label: 'Редактирование', checked: false, id: 'rest4' },
    { label: 'Просмотр карты', checked: false, id: 'rest5' },
    { label: 'Просмотр статистики по заказам', checked: false, id: 'rest6' },
])

export const orderRightAtom = atomWithImmer<AdminRight[]>([
    { label: 'Просмотр', checked: false, id: 'order1' },
    { label: 'Добавление', checked: false, id: 'order2' },
    { label: 'Удаление', checked: false, id: 'order3' },
    { label: 'Назначение удаление курьеру', checked: false, id: 'order4' },
])

export const adminRightAtom = atomWithImmer<AdminRight[]>([
    { label: 'Бухгалтерия', checked: false, id: 'admin1' },
    { label: 'Добавление админов', checked: false, id: 'admin2' },
    { label: 'Удаление админов', checked: false, id: 'admin3' },
    { label: 'Редактирование админов', checked: false, id: 'admin4' },
])

export const generalParentAtom = createParentAtom(generalRightAtom)
export const clientParentAtom = createParentAtom(clientRightAtom)
export const courierParentAtom = createParentAtom(courierRightAtom)
export const restParentAtom = createParentAtom(restRightAtom)
export const orderParentAtom = createParentAtom(orderRightAtom)
export const adminParentAtom = createParentAtom(adminRightAtom)

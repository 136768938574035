import { useAtomValue, useSetAtom } from 'jotai'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { Icon } from 'src/assets/icons'
import { clientGroupsSelectAtom } from 'src/atoms/clientDb/clientGroupsAtom'
import {
    Button,
    DateInput,
    EButtonVariant,
    GenderOptions,
    MediumText,
    NamedSelect,
    NamedTextInput,
    RegularText,
    SemiBoldItalicText,
    SemiBoldText,
    TextArea,
    TextInput,
} from 'src/components'
import { changeBonusAtom, clientBonusNetworkStateAtom, openBonusDialogAtom } from 'src/atoms/clientDb/clientBonusAtom'
import { useEffect, useState } from 'react'
import { currentClientAtom, currentClientBonusesAtom, openClientDeleteModalAtom } from 'src/atoms/clientDb/clientsAtom'
import { EChangeBonusType } from 'src/services/LocaliRepository'
import { HttpStatusCode } from 'src/types'
import { DateUtils, saveToClipboard } from 'src/utils'

import { EditClientFormData } from './types'

export const ModalEditClientInfo = (): JSX.Element => {
    const {
        control,
        formState: { errors },
        register,
        getValues,
        setValue,
    } = useFormContext<EditClientFormData>()
    const groups = useAtomValue(clientGroupsSelectAtom)
    const currentClient = useAtomValue(currentClientAtom)
    const clientBonuses = useAtomValue(currentClientBonusesAtom)
    const bonusNetworkState = useAtomValue(clientBonusNetworkStateAtom)
    const changeBonus = useSetAtom(changeBonusAtom)
    const openBonusDialog = useSetAtom(openBonusDialogAtom)
    const openClientDeleteModal = useSetAtom(openClientDeleteModalAtom)

    const birthday = useWatch({ name: 'birthday', control: control })

    const [decBonus, setDecBonus] = useState(0)
    const [incBonus, setIncBonus] = useState(0)
    const [isCopied, setIsCopied] = useState(false)

    const decreaseBonus = () => {
        if (currentClient?.clientData.client_id) {
            changeBonus({
                type: EChangeBonusType.REDUCE,
                client_id: currentClient?.clientData.client_id,
                count: decBonus,
            }).then(() => {
                openBonusDialog({
                    type: 'minus',
                    amount: decBonus,
                })
                cleanInputs()
            })
        }
    }

    const increaseBonus = () => {
        if (currentClient?.clientData.client_id) {
            changeBonus({
                type: EChangeBonusType.ADD,
                client_id: currentClient?.clientData.client_id,
                count: incBonus,
            }).then(() => {
                openBonusDialog({
                    type: 'plus',
                    amount: incBonus,
                })
                cleanInputs()
            })
        }
    }

    const cleanInputs = () => {
        setIncBonus(0)
        setDecBonus(0)
    }

    const onDeleteClick = () => {
        openClientDeleteModal(currentClient)
    }

    /*useEffect(() => {
    if (currentClient?.clientData) {
      Object.entries(currentClient.clientData).forEach(([key, value]) => {
        if (value !== null && value !== undefined) {
          if (key === 'clientgroup_id') {
            // setValue(key as keyof EditClientFormData, { value: String(value), label: String(value) });
          } else if (key === 'isBanned') {
            setValue(key as keyof EditClientFormData, Boolean(value));
          } else if (key === 'cityKey') {
            setValue(key as keyof EditClientFormData, value as string);
          } else {
            setValue(key as keyof EditClientFormData, value as string | boolean);
          }
        }
      });
    }
  }, [currentClient, setValue]);*/

    return (
        <div className='flex gap-20 justify-center mt-8'>
            <div className='flex flex-col gap-2'>
                <SemiBoldItalicText className='self-center text-blue-700 text-lg'>~Основная информация~</SemiBoldItalicText>
                <Controller
                    name='clientgroup_id'
                    control={control}
                    render={props => (
                        <NamedSelect
                            name='Группа'
                            selectProps={{
                                className: 'bg-white',
                                options: groups,
                                ...props.field,
                            }}
                        />
                    )}
                />
                <Controller
                    name='lastName'
                    control={control}
                    render={props => (
                        <NamedTextInput name='Фамилия' placeholder='Фамилия' textInputProps={{ ...props.field, error: errors.lastName }} />
                    )}
                />
                <Controller
                    name='firstName'
                    control={control}
                    render={props => (
                        <NamedTextInput name='Имя' placeholder='Имя' textInputProps={{ ...props.field, error: errors.firstName }} />
                    )}
                />
                <Controller
                    name='middleName'
                    control={control}
                    render={props => (
                        <NamedTextInput
                            name='Отчество'
                            placeholder='Отчество'
                            textInputProps={{ ...props.field, error: errors.middleName }}
                        />
                    )}
                />
                <Controller
                    name='gender'
                    control={control}
                    render={props => <NamedSelect name='Пол' selectProps={{ ...props.field, options: GenderOptions }} />}
                />
                <Controller
                    name='phoneNumber'
                    control={control}
                    render={props => (
                        <NamedTextInput
                            name='Телефон'
                            placeholder='Телефон'
                            textInputProps={{ ...props.field, error: errors.phoneNumber }}
                        />
                    )}
                />
                <Controller
                    name='email'
                    control={control}
                    render={props => (
                        <NamedTextInput name='Email' placeholder='Email' textInputProps={{ ...props.field, error: errors.email }} />
                    )}
                />
                <Controller
                    name='whatsapp'
                    control={control}
                    render={props => (
                        <NamedTextInput
                            name='Whatsapp'
                            placeholder='Whatsapp'
                            textInputProps={{ ...props.field, error: errors.whatsapp }}
                        />
                    )}
                />
                <Controller
                    name='telegram'
                    control={control}
                    render={props => (
                        <NamedTextInput
                            name='Telegram'
                            placeholder='Telegram'
                            textInputProps={{ ...props.field, error: errors.telegram }}
                        />
                    )}
                />
                <Controller
                    name='paymentInfo'
                    control={control}
                    render={props => (
                        <NamedTextInput name='Карта' placeholder='Карта' textInputProps={{ ...props.field, error: errors.paymentInfo }} />
                    )}
                />
                <div className='flex justify-center gap-3 mb-3'>
                    <div>
                        <SemiBoldText className='text-xs'>Дата рождения</SemiBoldText>
                        <Controller
                            control={control}
                            name='birthday'
                            render={props => <DateInput {...props.field} error={errors.birthday} />}
                        />
                    </div>
                    <div className='flex items-end'>
                        <SemiBoldText className='text-sm'>Возраст:</SemiBoldText>
                        <RegularText className='text-sm'>{DateUtils.calculateAge(birthday)}</RegularText>
                    </div>
                </div>
                <div className='flex justify-between w-full gap-5 min-h-20 items-center'>
                    <div className='flex gap-2 items-center'>
                        <Button variant={EButtonVariant.QUARTERY} onClick={onDeleteClick}>
                            <RegularText className='underline !text-darkBlue-900'>Удалить</RegularText>
                        </Button>
                        <input
                            id={'checkbox_for_client_banned'}
                            className='w-5 h-5 cursor-pointer'
                            type='checkbox'
                            {...register('isBanned')}
                        />
                        <label htmlFor='checkbox_for_client_banned' className={'cursor-pointer'}>
                            <RegularText>Заблокировать</RegularText>
                        </label>
                    </div>
                    {Boolean(currentClient?.clientData.tabNumber) && (
                        <div className='flex flex-col relative'>
                            {isCopied && <RegularText className='text-green-800 absolute -top-6 left-0'>Скопировано</RegularText>}
                            <div className='flex gap-2'>
                                <Icon
                                    type='copy'
                                    className='cursor-pointer'
                                    onClick={() => {
                                        saveToClipboard(currentClient?.clientData.tabNumber)
                                        setIsCopied(true)
                                    }}
                                />
                                <SemiBoldText>Таб. номер:</SemiBoldText>
                                <RegularText>{currentClient?.clientData.tabNumber || '-'}</RegularText>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className='flex flex-col gap-2'>
                <SemiBoldItalicText className='self-center text-blue-700 text-lg'>~Доп. информация~</SemiBoldItalicText>
                <div className='flex justify-between gap-2'>
                    <Controller
                        name='address'
                        control={control}
                        render={props => (
                            <NamedTextInput
                                name='Адрес'
                                textInputProps={{ disabled: true, className: 'w-inputL', ...props.field, error: errors.address }}
                            />
                        )}
                    />
                </div>
                <div></div>
                <div>
                    <Controller
                        name='comment'
                        control={control}
                        render={props => <TextArea placeholder='Комментарий' className='!w-full h-24' {...props.field} />}
                    />
                </div>
                <div className='flex flex-col gap-2 mt-5'>
                    <SemiBoldItalicText className='self-center text-blue-700 text-lg'>~Бонусы~</SemiBoldItalicText>
                    <div className='flex justify-center'>
                        <SemiBoldText>Всего бонусов:</SemiBoldText>
                        <SemiBoldText className='text-green-800'>{clientBonuses}</SemiBoldText>
                    </div>
                    <div className='flex justify-between'>
                        <div className='flex relative'>
                            <TextInput
                                className='w-inputM relative'
                                type='number'
                                onChange={e => setDecBonus(Number(e.target.value))}
                                value={decBonus ? String(decBonus) : ''}
                            />
                            <Button
                                variant={EButtonVariant.DARK_BLUE}
                                className='absolute right-0 top-0'
                                onClick={decreaseBonus}
                                disabled={bonusNetworkState === HttpStatusCode.LOADING || decBonus <= 0 || decBonus > (clientBonuses || 0)}
                            >
                                <RegularText className='text-white text-xs'>Отнять</RegularText>
                            </Button>
                        </div>

                        <div className='flex relative'>
                            <TextInput
                                className='w-inputM relative'
                                type='number'
                                onChange={e => setIncBonus(Number(e.target.value))}
                                value={incBonus ? String(incBonus) : ''}
                            />
                            <Button
                                variant={EButtonVariant.DARK_BLUE}
                                className='absolute right-0 top-0'
                                onClick={increaseBonus}
                                disabled={bonusNetworkState === HttpStatusCode.LOADING || incBonus <= 0}
                            >
                                <RegularText className='text-white text-xs'>Начислить</RegularText>
                            </Button>
                        </div>
                    </div>
                    <div className='flex justify-center gap-2'>
                        <SemiBoldText>Статус:</SemiBoldText>
                        <RegularText>Активный</RegularText>
                    </div>
                </div>
            </div>
        </div>
    )
}

import { AppSelect, ESelectVariant, HeaderLayout } from 'src/components'
import React, { useEffect, useState } from 'react'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { PropsValue, SingleValue } from 'react-select'
import { RotatingLines } from 'react-loader-spinner'

import { DateFilterAuto } from '../../components/DateFilterAuto'
import { datePeriodOptions, EDateRangeOptions } from '../../utils'
import { mainStatisticsLoadingAtom, updateMainStatisticsFiltersAtom } from '../../atoms/mainStatistics/getMainStatisticsAtom'
import { AppCitySelect, SelectOption } from '../../components/AppCitySelect'
import { getRestAtom } from '../../atoms/restaraunts/restAtom'

import { kitchenTypes } from '../Restaraunts'
import { DateFilterSelect } from '../../components/DateFilterSelect'

export const MainPageHeader = (): JSX.Element => {
    const [dateFrom, setDateFrom] = useState('')
    const [dateTo, setDateTo] = useState('')
    const [city, setCity] = useState('')
    const [selectedKitchenType, setSelectedKitchenType] = useState<PropsValue<SelectOption>>()
    const [selectedRest, setSelectedRest] = useState<PropsValue<SelectOption>>()

    const updateMainStatisticsFilters = useSetAtom(updateMainStatisticsFiltersAtom)
    const [mainStatisticsLoading, setMainStatisticsLoading] = useAtom(mainStatisticsLoadingAtom)

    const { data: restData } = useAtomValue(getRestAtom)
    const [restOptions, setRestOptions] = useState<SelectOption[]>([])

    const handleCityChange = (val: SingleValue<SelectOption>) => {
        setCity(val?.value || '')
        updateMainStatisticsFilters({ city: val?.value.split(',')[0] || '' }).then()
    }

    const handleKitchenTypeChange = (val: SingleValue<SelectOption>) => {
        setSelectedKitchenType(val)
        updateMainStatisticsFilters({ restCategory: val?.value }).then()
    }

    const handleRestChange = (val: SingleValue<SelectOption>) => {
        setSelectedRest(val)
        updateMainStatisticsFilters({ restId: val?.value }).then()
    }

    useEffect(() => {
        if (!restData?.length) return

        setRestOptions(restData.map(item => ({ value: item.rest_id, label: item.restName })))
    }, [restData])

    useEffect(() => {
        setMainStatisticsLoading(true)
        const timer = setTimeout(() => {
            updateMainStatisticsFilters({ dateFrom, dateTo }).then()
        }, 1000)

        return () => clearTimeout(timer)
    }, [dateFrom, dateTo])

    return (
        <>
            <HeaderLayout title='Общая статистика' className='gap-5 items-center noBorder'>
                <DateFilterSelect
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    setDateFrom={setDateFrom}
                    setDateTo={setDateTo}
                    selectProps={{ options: datePeriodOptions }}
                    rightElement={
                        <div className='pr-5'>
                            {mainStatisticsLoading && (
                                <RotatingLines
                                    visible={true}
                                    width='40'
                                    strokeWidth='5'
                                    animationDuration='0.75'
                                    ariaLabel='rotating-lines-loading'
                                    strokeColor='#658399'
                                />
                            )}
                        </div>
                    }
                />
            </HeaderLayout>
            <div className='flex gap-4 px-8 pb-6 justify-end borderBottom'>
                <AppCitySelect
                    value={city ? { value: city, label: city.split(',')[0] } : undefined}
                    onChange={handleCityChange}
                    selectVariant={ESelectVariant.SECONDARY}
                    placeholder='Все города'
                    isClearable
                />
                <AppSelect
                    isClearable
                    value={selectedKitchenType}
                    onChange={handleKitchenTypeChange}
                    options={kitchenTypes}
                    placeholder='Категория ресторана'
                    selectVariant={ESelectVariant.SECONDARY}
                />
                <AppSelect
                    isClearable
                    value={selectedRest}
                    onChange={handleRestChange}
                    options={restOptions}
                    placeholder='Все заведения'
                    selectVariant={ESelectVariant.SECONDARY}
                />
            </div>
        </>
    )
}

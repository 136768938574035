import classNames from 'classnames'

import { Checkbox } from '../Checkbox'
import { RegularText } from '../RegularText'

type NamedCheckboxProps = {
    name: string
    checked?: boolean
    className?: string
    onChange?: () => void
}

export const NamedCheckbox = (props: NamedCheckboxProps): JSX.Element => {
    const { name, checked = false, className, onChange } = props
    return (
        <div className={classNames('flex items-center gap-3', className)}>
            <Checkbox checked={checked} className='mr-4' onChange={onChange} />
            <RegularText>{name}</RegularText>
        </div>
    )
}

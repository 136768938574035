import { PropsWithChildren } from 'react'
import { Icon } from 'src/assets/icons'
import { BoldText } from 'src/components/BoldText'
import { RegularText } from 'src/components/RegularText'
import classNames from 'classnames'

import styles from './styles.module.scss'

type CategoryItemProps = {
    isActive: boolean
}

export const CategoryItem = (props: PropsWithChildren<CategoryItemProps>): JSX.Element =>
    props.isActive ? (
        <div className={classNames(styles.menuChildrenItem, 'flex gap-2 items-center')}>
            <Icon size={10} type='boldArrow' className={styles.menuChildrenItemArrow} />
            <BoldText className='underline text-white '>{props.children}</BoldText>
        </div>
    ) : (
        <RegularText className={classNames(styles.menuChildrenItem, '!text-white')}>{props.children}</RegularText>
    )

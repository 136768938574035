import { useSetAtom } from 'jotai'
import { setCourierFireReasonAtom } from 'src/atoms/allCouriers/courierEditModalAtom'
import { closeDialogAtom } from 'src/atoms/global/dialogAtom'
import { AppImage, Button, EButtonVariant, MediumText, Nbsp, RegularText } from 'src/components'
import { Assets } from 'src/utils'

import { deleteAdminAtom, fireAdminAtom } from '../../atoms/admins/adminEditAtom'

type FireCourierProps = {
    admin_id: string
    name?: string
}

const FireAdminDialog = ({ admin_id, name }: FireCourierProps): JSX.Element => {
    const closeDialog = useSetAtom(closeDialogAtom)
    const fireAdmin = useSetAtom(fireAdminAtom)

    return (
        <div className='flex flex-col items-center justify-center h-full p-8'>
            <div className='size-14'>
                <AppImage src={Assets.sadSmiley} />
            </div>
            <div className='p-2 text-center'>
                <RegularText>
                    Вы действительно хотите уволить админа? <Nbsp />
                </RegularText>
                <MediumText>“{name}”?</MediumText>
            </div>
            <div className='flex gap-2'>
                <Button className='!w-44 flex justify-center !py-5' variant={EButtonVariant.RED} onClick={() => fireAdmin({ admin_id })}>
                    <RegularText className='!text-base'>Уволить</RegularText>
                </Button>
                <Button className='!w-44 !py-5 flex justify-center' onClick={closeDialog}>
                    <RegularText>Отмена</RegularText>
                </Button>
            </div>
        </div>
    )
}

export { FireAdminDialog }

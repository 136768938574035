import { RegularText } from '../RegularText'
import { TextInput, TextInputProps } from '../TextInput'

type NamedInputProps = {
    name: string
    textInputProps?: TextInputProps
    placeholder?: string
    nameClassName?: string
}

export const NamedTextInput = (props: NamedInputProps): JSX.Element => {
    const { name, textInputProps, placeholder, nameClassName } = props
    return (
        <div className='flex gap-3 justify-end'>
            <RegularText className={nameClassName}>{name}</RegularText>
            <TextInput {...textInputProps} placeholder={placeholder ? placeholder : name} />
        </div>
    )
}

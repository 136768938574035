import { z } from 'zod'

import { CourierStatusEnumSchema, OrderStatusEnumSchema, EOrderStatus } from './EnumSchemas'

const DishSchema = z.object({
    title: z.string(),
    amount: z.number(),
    bigimg: z.string(),
    dishId: z.string(),
    smallimg: z.string(),
    dishPrice: z.number(),
    modificatorIds: z.array(z.string()),
})

export const OrderSchema = z.object({
    order_id: z.string(),
    title: z.string(),
    restId: z.string(),
    imageUrl: z.string(),
    imagesUrlArray: z.array(z.string()),
    dishIdArray: z.array(DishSchema),
    order_data: z.string(),
    paid: z.boolean(),
    courier_id: z.string(),
    status: OrderStatusEnumSchema,
    clientId: z.string(),
    courierStatus: CourierStatusEnumSchema,
    courierName: z.string().nullish(),
    courierPhoneNumber: z.string().nullish(),
    courierTabNumber: z.string().nullish(),
    price: z.number(),
    paymentType: z.string().nullish(),
    deliveryPrice: z.string().nullish(),
    foodCost: z.string().nullish(),
    shipCost: z.string().nullish(),
    serviceFee: z.string().nullish(),
    deliveryTime: z.string().nullish(),
    timeOnWay: z.string().nullish(),
    isDeliveryServices: z.boolean().nullish(),
    isOurCouriers: z.boolean().nullish(),
    pickup: z.boolean().nullish(),
    comment: z.string().nullish(),
    orderNumber: z.number(),
    createdAt: z.string(),
    updatedAt: z.string(),
    cancel_reason: z.string().optional(),
    tookDate: z.string().optional(),
    orderTookDate: z.string().optional(),
    phoneNumber: z.string().optional(),
    restName: z.string().optional(),
    rest_name: z.string().optional(),
    clientName: z.string().optional(),
    client_name: z.string().optional(),
    clientPhoneNumber: z.string().optional(),
    restAddress: z.string().optional(),
    deliveryAddress: z.string().optional(),
    spentTimeOnCooking: z.number().nullish(),
})

export const ClientOrderSchema = z.object({
    order_id: z.string(),
    title: z.string().nullish(),
    restId: z.string(),
    phoneNumber: z.string(),
    order_data: z.string(),
    imageUrl: z.string(),
    smallImageUrl: z.string(),
    dishIdArray: z.array(DishSchema),
    paid: z.boolean(),
    courier_id: z.string().nullish(),
    status: OrderStatusEnumSchema.transform(
        el =>
            ({
                [EOrderStatus.BUSY]: 'Занят',
                [EOrderStatus.NEW]: 'Новый',
                [EOrderStatus.COOKING]: 'Готовка',
                [EOrderStatus.GOING]: 'В пути',
                [EOrderStatus.COOKED]: 'Готов',
                [EOrderStatus.SHIPPING]: 'Доставка',
                [EOrderStatus.COMPLETE]: 'Завершен',
            })[el],
    ),
    clientId: z.string(),
    courierStatus: CourierStatusEnumSchema.nullish(),
    price: z.number(),
    paymentType: z.string(),
    deliveryPrice: z.number().nullish(),
    foodCost: z.number().nullish(),
    shipCost: z.number(),
    serviceFee: z.number().nullish(),
    deliveryTime: z.number().nullish(),
    comment: z.string(),
    orderNumber: z.number(),
    cancel_reason: z.string().nullish(),
    shipType: z.string(),
    servtype: z.string().nullish(),
    isOurCouriers: z.boolean(),
    pickup: z.boolean(),
    tookDate: z.string().nullish(),
    orderTookDate: z.string().nullish(),
    deliveryDate: z.string().nullish(),
    createdAt: z.string().nullish(),
    updatedAt: z.string().nullish(),
})

export type ClientOrder = z.infer<typeof ClientOrderSchema>

export type Order = z.infer<typeof OrderSchema>

/* eslint-disable max-len */
import L from 'leaflet'
import { useMemo } from 'react'
import { Marker, MarkerProps } from 'react-leaflet'

enum ELocationMarkerVariant {
    GREEN = '#00BA00',
    RED = '#F42E2E',
}

type LocationMarkerProps = MarkerProps & {
    variant: ELocationMarkerVariant
}

const LocationMarker = (props: LocationMarkerProps): JSX.Element => {
    const { position = [51.505, -0.09], variant, ...rest } = props

    const locationMarker = useMemo(
        () =>
            L.divIcon({
                html: `
      <svg width="25" height="34" viewBox="0 0 14 18" fill=${variant} xmlns="http://www.w3.org/2000/svg">
    <path d="M7.00085 0.167969C3.47334 0.167969 0.603516 3.0378 0.603516 6.56527C0.603516 10.943 6.32851 17.3697 6.57225 17.6412C6.8012 17.8962 7.20092 17.8957 7.42946 17.6412C7.6732 17.3697 13.3982 10.943 13.3982 6.56527C13.3981 3.0378 10.5283 0.167969 7.00085 0.167969ZM7.00085 9.78394C5.22608 9.78394 3.78223 8.34005 3.78223 6.56527C3.78223 4.79049 5.22611 3.34665 7.00085 3.34665C8.7756 3.34665 10.2194 4.79053 10.2194 6.56531C10.2194 8.34009 8.7756 9.78394 7.00085 9.78394Z" />
    </svg>`,
                className: 'svg-icon',
                iconSize: [25, 34],
            }),
        [variant],
    )

    return <Marker icon={locationMarker} position={position} {...rest} />
}

export { LocationMarker, ELocationMarkerVariant }

import { BaseLayout, ContextMenuPortal, Spinner, SuspenseWithSpinner } from 'src/components'
import {
    ClientDatabaseFilters,
    ClientDatabaseAdd,
    ClientDatabaseGroup,
    ClientDatabaseHeader,
    ClientDatabaseTable,
    ClientDBContextMenu,
} from 'src/features/ClientDatabase'
import { useEffect } from 'react'
import { useAtomValue, useSetAtom } from 'jotai/index'

import { clientsAtom, dbClientsAtom } from '../../atoms/clientDb/clientsAtom'

import styles from './styles.module.scss'

export const ClientDatabasePage = (): JSX.Element => (
    <div>
        <ClientDatabaseHeader />
        <BaseLayout
            leftChild={
                <div className={styles.leftPart}>
                    <ClientDatabaseAdd />
                    <SuspenseWithSpinner>
                        <ClientDatabaseGroup />
                    </SuspenseWithSpinner>
                </div>
            }
            filtersChild={<ClientDatabaseFilters />}
        >
            <SuspenseWithSpinner>
                <Comp />
                <ClientDatabaseTable />
            </SuspenseWithSpinner>
            <ContextMenuPortal>
                <ClientDBContextMenu />
            </ContextMenuPortal>
        </BaseLayout>
    </div>
)

const Comp = (): JSX.Element => {
    // to avoid map re-rendering inside Suspense tag
    const getClients = useAtomValue(dbClientsAtom)
    const setClients = useSetAtom(clientsAtom)

    useEffect(() => {
        if (getClients?.data) {
            setClients(getClients?.data)
        }
    }, [getClients])

    return <></>
}

import { AppImage, Button, DateInput, ImageInput, MediumItalic, MediumText, NamedTextInput, SemiBoldText, TextArea } from 'src/components'
import { ETextInputVariant } from 'src/components/TextInput'
import { Assets } from 'src/utils/assets'
import { EButtonVariant } from 'src/components/Button'
import { EMediumItalicVariant } from 'src/components/MediumItalic'
import { Controller, useFormContext } from 'react-hook-form'
import { useAtom } from 'jotai'
import { createCourierPhotoAtom } from 'src/atoms/allCouriers/createCourierAtom'

import styles from './styles.module.scss'
import { CourierCreateFormData } from './types'

export const CourierCreatePassport = (): JSX.Element => {
    const {
        control,
        formState: { errors },
    } = useFormContext<CourierCreateFormData>()
    const [file, setFile] = useAtom(createCourierPhotoAtom)

    return (
        <div className='flex my-12 justify-evenly'>
            <div className='left flex flex-col gap-3'>
                <MediumItalic className='flex justify-center'>~Паспортные данные~</MediumItalic>
                <ul className='flex flex-col gap-3'>
                    <li>
                        <Controller
                            name='passSeries'
                            control={control}
                            render={props => (
                                <NamedTextInput
                                    name='Серия'
                                    textInputProps={{
                                        variant: ETextInputVariant.PRIMARY,
                                        placeholder: 'Серия',
                                        ...props.field,
                                        error: errors.passSeries,
                                    }}
                                />
                            )}
                        />
                    </li>
                    <li>
                        <Controller
                            name='passNumber'
                            control={control}
                            render={props => (
                                <NamedTextInput
                                    name='Номер'
                                    textInputProps={{
                                        variant: ETextInputVariant.PRIMARY,
                                        placeholder: 'Таб.номер  код',
                                        ...props.field,
                                        error: errors.passNumber,
                                    }}
                                />
                            )}
                        />
                    </li>
                    <li className='flex justify-end'>
                        <Controller
                            control={control}
                            name='passIssuedBy'
                            render={props => <TextArea placeholder='Кем выдан' {...props.field} error={errors.passIssuedBy} />}
                        />
                    </li>
                    <li>
                        <div className='flex justify-end gap-3'>
                            <div>
                                <SemiBoldText className='text-xs'>Дата выдачи</SemiBoldText>
                                <Controller
                                    control={control}
                                    name='passIssueDate'
                                    render={props => <DateInput {...props.field} error={errors.passIssueDate} />}
                                />
                            </div>
                            <div className='flex items-end'>-</div>
                            <div>
                                <SemiBoldText className='text-xs'>Действителен до</SemiBoldText>
                                <Controller
                                    control={control}
                                    name='passEndDate'
                                    render={props => <DateInput {...props.field} error={errors.passEndDate} />}
                                />
                            </div>
                        </div>
                    </li>
                    <li>
                        <Controller
                            control={control}
                            name='passSnils'
                            render={props => (
                                <NamedTextInput
                                    name='СНИЛС'
                                    textInputProps={{
                                        variant: ETextInputVariant.PRIMARY,
                                        placeholder: 'СНИЛС',
                                        ...props.field,
                                        error: errors.passSnils,
                                    }}
                                />
                            )}
                        />
                    </li>
                    <li>
                        <Controller
                            control={control}
                            name='passInn'
                            render={props => (
                                <NamedTextInput
                                    name='ИНН'
                                    textInputProps={{
                                        variant: ETextInputVariant.PRIMARY,
                                        placeholder: 'ИНН',
                                        ...props.field,
                                        error: errors.passInn,
                                    }}
                                />
                            )}
                        />
                    </li>
                    <li>
                        <div className='flex justify-between'>
                            <div className='flex flex-col gap-3'>
                                <Controller
                                    control={control}
                                    name='passCity'
                                    render={props => (
                                        <NamedTextInput
                                            name='Город'
                                            textInputProps={{
                                                className: '!w-40',
                                                variant: ETextInputVariant.PRIMARY,
                                                placeholder: 'Город',
                                                ...props.field,
                                                error: errors.passCity,
                                            }}
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name='passStreet'
                                    render={props => (
                                        <NamedTextInput
                                            name='Улица'
                                            textInputProps={{
                                                className: '!w-40',
                                                variant: ETextInputVariant.PRIMARY,
                                                placeholder: 'Улица',
                                                ...props.field,
                                                error: errors.passStreet,
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className='flex flex-col gap-3'>
                                <Controller
                                    control={control}
                                    name='passHouse'
                                    render={props => (
                                        <NamedTextInput
                                            name='Дом'
                                            textInputProps={{
                                                className: '!w-40',
                                                variant: ETextInputVariant.PRIMARY,
                                                placeholder: 'Дом',
                                                ...props.field,
                                                error: errors.passHouse,
                                            }}
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name='passHousing'
                                    render={props => (
                                        <NamedTextInput
                                            name='Корпус'
                                            textInputProps={{
                                                className: '!w-40',
                                                variant: ETextInputVariant.PRIMARY,
                                                placeholder: 'Корпус',
                                                ...props.field,
                                                error: errors.passHousing,
                                            }}
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name='passApartment'
                                    render={props => (
                                        <NamedTextInput
                                            name='Квартира'
                                            textInputProps={{
                                                className: '!w-40',
                                                variant: ETextInputVariant.PRIMARY,
                                                placeholder: 'Квартира',
                                                ...props.field,
                                                error: errors.passApartment,
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div className='right flex flex-col gap-3'>
                <MediumItalic className='flex justify-center'>~Фото курьера~</MediumItalic>
                <div className='flex justify-center items-center'>
                    <div className={styles.photoContainer}>
                        <AppImage className={styles.photo} src={!!file ? URL.createObjectURL(file as Blob) : Assets.imagePlaceholder} />
                    </div>
                </div>

                <div className='flex justify-center gap-2 my-5'>
                    <Button icon='minus' onClick={() => setFile(null)} variant={EButtonVariant.RED}>
                        <MediumText>Удалить</MediumText>
                    </Button>
                    <ImageInput
                        onChange={e => {
                            if (!e.target.files) {
                                return
                            }
                            setFile(e.target.files[0])
                        }}
                    >
                        <Button icon='download' variant={EButtonVariant.PRIMARY}>
                            <MediumText>Загрузить</MediumText>
                        </Button>
                    </ImageInput>
                </div>
                <div>
                    <MediumItalic variant={EMediumItalicVariant.SECONDARY} className='!text-3xl'>
                        Загрузите фото курьера
                    </MediumItalic>
                </div>
            </div>
        </div>
    )
}

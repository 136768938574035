import { CourierMap } from 'src/features/Couriers/CourierMap'
import { CourierMapFilters } from 'src/features/Couriers/CourierMapFilters'
import { CourierMapHeader } from 'src/features/Couriers'
import { useAtomValue, useSetAtom } from 'jotai/index'
import { Suspense, useEffect } from 'react'

import { adminsListAtom, getAdminsAtom } from '../../atoms/admins/adminsTableAtom'
import { allCourierGroupsAtom, courierGroupsAtom } from '../../atoms/allCouriers/allCourierGroupsAtom'

export const CourierMapPage = (): JSX.Element => (
    <div className='overflow-scroll'>
        <div className='relative' style={{ zIndex: 1000000 }}>
            <CourierMapHeader />
            <CourierMapFilters />
            <Suspense>
                <Comp />
            </Suspense>
        </div>

        <CourierMap />
    </div>
)

const Comp = (): JSX.Element => {
    // to avoid map re-rendering inside Suspense tag
    const getCouriers = useAtomValue(allCourierGroupsAtom)
    const setCouriers = useSetAtom(courierGroupsAtom)

    useEffect(() => {
        if (getCouriers?.data) {
            setCouriers(getCouriers?.data)
        }
    }, [getCouriers])

    return <></>
}

import {
    AppImage,
    AppSelect,
    Button,
    EButtonVariant,
    MediumText,
    RegularText,
    SelectOption,
    SemiBoldItalicText,
    SemiBoldText,
    TextArea,
    TextInput,
} from 'src/components'
import { RoundedWrapper } from 'src/components/RoundedWrapper'
import { useCallback, useEffect, useState } from 'react'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'

import {
    createSmsTemplateAtom,
    editSmsTemplateAtom,
    openDeleteSmsTemplateDialogAtom,
    smsTemplateLoadingAtom,
    smsTemplatesAtom,
} from '../../atoms/clientDb/manageSMSAtom'
import { EditSmsTemplateRequest, SmsTemplate } from '../../services/LocaliRepository/types/pushNotifications'
import { LokaliApi } from '../../services'
import { closeDialogAtom, dialogStateAtom } from '../../atoms/global/dialogAtom'
import { Assets } from '../../utils'

export const ModalManageSMSTemplate = (): JSX.Element => {
    const [smsTemplates, setSmsTemplates] = useAtom(smsTemplatesAtom)
    const [smsTemplateLoading, setSmsTemplateLoading] = useAtom(smsTemplateLoadingAtom)
    const createSmsTemplate = useSetAtom(createSmsTemplateAtom)
    const editSmsTemplate = useSetAtom(editSmsTemplateAtom)

    const openDeleteDialog = useSetAtom(openDeleteSmsTemplateDialogAtom)
    const closeDialog = useSetAtom(closeDialogAtom)

    const [title, setTitle] = useState('')
    const [heading, setHeading] = useState('')

    const [text, setText] = useState('')
    const [selectedTemplate, setSelectedTemplate] = useState<SelectOption>()
    const [selectedTemplateFull, setSelectedTemplateFull] = useState<SmsTemplate>()
    const [isEditing, setIsEditing] = useState(false)

    const onSave = async () => {
        const body = { title, heading, text }

        if (isEditing) {
            if (!selectedTemplateFull) return
            ;(body as EditSmsTemplateRequest).pushtemplate_id = selectedTemplateFull.pushtemplate_id
            await editSmsTemplate(body as EditSmsTemplateRequest)
            setSelectedTemplate({ value: selectedTemplateFull.pushtemplate_id, label: body.title })
            setSelectedTemplateFull(body as SmsTemplate)
            clearInputs()
            updateSmsTemplates().then()
        } else {
            const newSmsTemplate = await createSmsTemplate(body)
            if (newSmsTemplate) {
                setSelectedTemplate({ value: newSmsTemplate.pushtemplate_id, label: newSmsTemplate.title })
                setSelectedTemplateFull(newSmsTemplate)
            }
            clearInputs()
            updateSmsTemplates().then()
        }
    }

    const handleSelect = (val: SelectOption) => {
        setSelectedTemplate(val)
        if (!smsTemplates?.length) return
        setSelectedTemplateFull(smsTemplates.find(item => item.pushtemplate_id === val.value))
    }

    const handleEditButton = () => {
        if (isEditing || !selectedTemplateFull) return
        setIsEditing(true)
        setTitle(selectedTemplateFull.title)
        setHeading(selectedTemplateFull.heading)
        setText(selectedTemplateFull.text)
    }

    const clearInputs = () => {
        setTitle('')
        setHeading('')
        setText('')
        if (isEditing) {
            setIsEditing(false)
        }
    }

    const onDelete = async () => {
        if (!selectedTemplate) return
        try {
            setSmsTemplateLoading(true)
            const data = await LokaliApi.deleteSmsTemplate({ pushtemplate_id: selectedTemplate?.value })
            if (data) {
                updateSmsTemplates().then()
                setSelectedTemplate(undefined)
                setSelectedTemplateFull({ heading: 'Удалено' } as SmsTemplate)
            }
            closeDialog()
        } catch (e) {
            console.warn(e)
        } finally {
            setSmsTemplateLoading(false)
        }
    }

    const updateSmsTemplates = useCallback(async () => {
        try {
            const data = await LokaliApi.getSmsTemplates()
            setSmsTemplates(data)
        } catch (e) {
            console.warn(e)
        }
    }, [])

    const onDeleteClick = () => {
        openDeleteDialog({
            children: (
                <div className='flex flex-col items-center justify-center h-full p-8'>
                    <div className='size-14'>
                        <AppImage src={Assets.sadSmiley} />
                    </div>
                    <div className='flex-1 flex justify-center items-center'>
                        <RegularText className='text-center'>
                            Вы действительно хотите удалить шаблон?
                            <br />
                            <MediumText>"{selectedTemplate?.label}"</MediumText>
                            <RegularText>&nbsp;?</RegularText>
                        </RegularText>
                    </div>
                    <div className='flex gap-2'>
                        <Button
                            className='!w-44 flex justify-center !py-5'
                            variant={EButtonVariant.RED}
                            onClick={onDelete}
                            disabled={smsTemplateLoading}
                        >
                            <RegularText className='!text-base'>Да, удалить</RegularText>
                        </Button>
                        <Button className='!w-44 !py-5 flex justify-center' onClick={closeDialog} disabled={smsTemplateLoading}>
                            <RegularText>Назад</RegularText>
                        </Button>
                    </div>
                </div>
            ),
        })
    }

    useEffect(() => {
        updateSmsTemplates().then()
    }, [])

    return (
        <div className='flex justify-center gap-20 p-10  h-full'>
            <div className='flex flex-col justify-between'>
                <div className='flex flex-col gap-2'>
                    <SemiBoldItalicText className='flex justify-center text-base text-blue-700'>
                        {isEditing ? '~Редактировать шаблон?~' : '~Создать новый шаблон?~'}
                    </SemiBoldItalicText>
                    <div>
                        <SemiBoldItalicText className='text-xs text-darkBlue-900'>Название шаблона ( не отсылается )</SemiBoldItalicText>
                        <TextInput value={title} onChange={ev => setTitle(ev.target.value)} />
                    </div>
                    <div>
                        <SemiBoldItalicText className='text-xs text-darkBlue-900'>Заголовок шаблона</SemiBoldItalicText>
                        <TextInput value={heading} onChange={ev => setHeading(ev.target.value)} />
                    </div>
                    <div className='flex flex-col gap-2'>
                        <SemiBoldItalicText className='text-xs text-darkBlue-900'>Текст уведомления</SemiBoldItalicText>
                        <TextArea
                            type={'text'}
                            placeholder='Текст уведомления'
                            value={text}
                            onChange={ev => {
                                const target = ev.target as HTMLTextAreaElement
                                setText(target.value)
                            }}
                        />
                    </div>
                </div>
                <div className='flex flex-col gap-10'>
                    <div className='h-px w-full bg-darkBlue-600 self-end' />
                    <div className='flex gap-16'>
                        <Button variant={EButtonVariant.QUARTERY} disabled={smsTemplateLoading}>
                            <RegularText onClick={clearInputs} className='underline'>
                                Очистить
                            </RegularText>
                        </Button>
                        <Button
                            onClick={onSave}
                            icon='save'
                            variant={EButtonVariant.PRIMARY}
                            disabled={smsTemplateLoading || !title || !heading || !text}
                        >
                            <RegularText className='underline'>Сохранить</RegularText>
                        </Button>
                    </div>
                </div>
            </div>
            <div className='flex flex-col items-center justify-between w-1/2'>
                <div className='flex flex-col gap-2 items-center'>
                    <SemiBoldItalicText>~Просмотр шаблонов~</SemiBoldItalicText>
                    <AppSelect
                        isSearchable
                        value={selectedTemplate}
                        onChange={val => handleSelect(val as SelectOption)}
                        options={smsTemplates?.length ? smsTemplates.map(val => ({ value: val.pushtemplate_id, label: val.title })) : []}
                    />
                    <div className='flex flex-col gap-2'>
                        <SemiBoldText className='text-center'>{selectedTemplateFull?.heading}</SemiBoldText>
                        <RegularText>{selectedTemplateFull?.text}</RegularText>
                    </div>
                </div>
                <div className='flex flex-col gap-10 w-full'>
                    <div className='h-px w-full bg-darkBlue-600 self-end' />
                    <div className='flex justify-between'>
                        <Button variant={EButtonVariant.QUARTERY} onClick={onDeleteClick} disabled={!selectedTemplate}>
                            <RegularText className='underline'>Удалить</RegularText>
                        </Button>
                        <Button
                            disabled={isEditing || !selectedTemplate}
                            onClick={handleEditButton}
                            icon='edit'
                            variant={EButtonVariant.SECONDARY}
                        >
                            <RegularText className='underline'>Редактировать</RegularText>
                        </Button>
                        <RoundedWrapper icon='refresh' className='ml-2' onClick={updateSmsTemplates} />
                    </div>
                </div>
            </div>
        </div>
    )
}

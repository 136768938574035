import { atom } from 'jotai'
import { LatLng } from 'leaflet'

import { DeliveryZone } from './createRestAtom'

const editRestMapMarkersAtom = atom<LatLng[]>([])
const editRestDeliveryZonesAtom = atom<DeliveryZone[]>([])

const editDeliveryZoneIndexAtom = atom<number>(-1)

const editRestDeliveryZoneAtom = atom(null, (get, set, update: number) => {
    set(editRestDeliveryZonesAtom, old => [
        ...old,
        {
            title: `Зона доставки ${update}`,
            description: 'Описание',
            latLng: get(editRestMapMarkersAtom),
        },
    ])
    set(editRestMapMarkersAtom, [])
})

const editCurrentDeliveryZoneAtom = atom<DeliveryZone | undefined>(undefined)

const editSelectDeliveryZoneAtom = atom(null, (_, set, update: DeliveryZone) => {
    set(editCurrentDeliveryZoneAtom, update)
})

const editDeleteDeliveryZoneAtom = atom(null, (get, set) => {
    const currentDeliveryZone = get(editCurrentDeliveryZoneAtom)

    set(editRestDeliveryZonesAtom, old => old.filter(zone => zone.title !== currentDeliveryZone?.title))
})

export {
    editDeliveryZoneIndexAtom,
    editRestDeliveryZoneAtom,
    editRestDeliveryZonesAtom,
    editRestMapMarkersAtom,
    editSelectDeliveryZoneAtom,
    editDeleteDeliveryZoneAtom,
    editCurrentDeliveryZoneAtom,
}

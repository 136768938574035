import classNames from 'classnames'
import { Login } from 'src/features/Login/Login'

import styles from './styles.module.scss'
import { useEffect } from 'react'

export const LoginPage = (): JSX.Element => {
    return (
        <div className={classNames('w-screen h-screen flex justify-center', styles.container)}>
            <Login />
        </div>
    )
}

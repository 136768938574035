import { useAtomValue, useSetAtom } from 'jotai'
import { currentClientAtom } from 'src/atoms/clientDb/clientsAtom'
import { editClientDBRequestStatusAtom, refreshEditClientAtom } from 'src/atoms/clientDb/editClientDBAtom'
import { setNotSavedDialogAtom } from 'src/atoms/global/dialogAtom'
import { Button, EButtonVariant, MediumText, RegularText } from 'src/components'
import { HttpStatusCode } from 'src/types'
import { FC } from 'react'

export const ModalEditClientInfoFooter: FC<{ loader: boolean }> = ({ loader }): JSX.Element => {
    const networkState = useAtomValue(editClientDBRequestStatusAtom)
    const setNotSavedDialog = useSetAtom(setNotSavedDialogAtom)

    const currentClient = useAtomValue(currentClientAtom)

    const onClickRefreshClient = useSetAtom(refreshEditClientAtom)

    return (
        <div className='flex justify-between px-5 py-3'>
            <div className='flex flex-col'>
                <MediumText>Общее количество доставок: {currentClient?.orderCount}</MediumText>
                <MediumText>Общая сумма доставок: {currentClient?.totalPrice}</MediumText>
            </div>
            <div className='flex flex-1 justify-end gap-3'>
                <Button icon='refresh' onClick={onClickRefreshClient} variant={EButtonVariant.SECONDARY}>
                    <RegularText>Обновить</RegularText>
                </Button>
                <Button
                    loader={loader}
                    disabled={networkState === HttpStatusCode.LOADING}
                    type='submit'
                    icon='save'
                    variant={EButtonVariant.PRIMARY}>
                    <MediumText>Сохранить</MediumText>
                </Button>
                <Button type='button' onClick={setNotSavedDialog} variant={EButtonVariant.SECONDARY}>
                    <RegularText>Выйти</RegularText>
                </Button>
            </div>
        </div>
    )
}

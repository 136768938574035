import { useAtomValue, useSetAtom } from 'jotai'
import { Controller, useFormContext } from 'react-hook-form'
import { allCourierGroupOptionsAtom } from 'src/atoms/allCouriers/allCourierGroupsAtom'
import {
    Button,
    MediumItalic,
    NamedDateInput,
    NamedSelect,
    NamedTextInput,
    RegularText,
    SemiBoldText,
    TextArea,
    EButtonVariant,
    ESelectVariant,
    GenderOptions,
} from 'src/components'
import { currentCourierAtom } from 'src/atoms/allOrders/couriersListAtom'
import { DateUtils } from 'src/utils'
import { setCourierDeleteAtom, setCourierFireAtom } from 'src/atoms/allCouriers/courierEditModalAtom'

import { CourierEditFormData } from './types'

export const CourierEditInfo = (): JSX.Element => {
    const options = useAtomValue(allCourierGroupOptionsAtom)
    const currentCourier = useAtomValue(currentCourierAtom)
    const setDeleteCourierDialog = useSetAtom(setCourierDeleteAtom)
    const setFireCourierDialog = useSetAtom(setCourierFireAtom)

    const {
        control,
        formState: { errors },
        watch,
    } = useFormContext<CourierEditFormData>()

    return (
        <div>
            <div className='flex mt-12 justify-evenly'>
                <div className='left flex flex-col gap-3'>
                    <MediumItalic className='flex justify-center'>~Основные данные 1~</MediumItalic>
                    <ul className='flex flex-col gap-3'>
                        <li>
                            <Controller
                                control={control}
                                name='deliveryType'
                                render={props => (
                                    <NamedSelect
                                        name='Группа'
                                        selectProps={{
                                            placeholder: 'Группа',
                                            options: options,
                                            selectVariant: ESelectVariant.SECONDARY,
                                            ...props.field,
                                        }}
                                    />
                                )}
                            />
                        </li>
                        <li>
                            <Controller
                                control={control}
                                name='sysName'
                                render={props => (
                                    <NamedTextInput
                                        name='Имя в системе'
                                        textInputProps={{
                                            className: 'w-inputL',
                                            placeholder: 'Имя в системе',
                                            ...props.field,
                                            error: errors.sysName,
                                        }}
                                    />
                                )}
                            />
                        </li>
                        <li>
                            <Controller
                                control={control}
                                name='surname'
                                render={props => (
                                    <NamedTextInput
                                        name='Фамилия'
                                        textInputProps={{
                                            className: 'w-inputL',
                                            placeholder: 'Фамилия',
                                            ...props.field,
                                            error: errors.surname,
                                        }}
                                    />
                                )}
                            />
                        </li>
                        <li>
                            <Controller
                                control={control}
                                name='firstName'
                                render={props => (
                                    <NamedTextInput
                                        name='Имя'
                                        textInputProps={{
                                            className: 'w-inputL',
                                            placeholder: 'Имя',
                                            ...props.field,
                                            error: errors.firstName,
                                        }}
                                    />
                                )}
                            />
                        </li>
                        <li>
                            <Controller
                                control={control}
                                name='patronymic'
                                render={props => (
                                    <NamedTextInput
                                        name='Отчество'
                                        textInputProps={{
                                            className: 'w-inputL',
                                            placeholder: 'Отчество',
                                            ...props.field,
                                            error: errors.patronymic,
                                        }}
                                    />
                                )}
                            />
                        </li>
                        <li>
                            <Controller
                                control={control}
                                name='gender'
                                render={props => (
                                    <NamedSelect
                                        name='Пол'
                                        selectProps={{
                                            options: GenderOptions,
                                            selectVariant: ESelectVariant.SECONDARY,
                                            ...props.field,
                                        }}
                                    />
                                )}
                            />
                        </li>
                    </ul>
                    <div className='flex items-center gap-4 justify-between mt-5'>
                        <div>
                            <Controller
                                control={control}
                                name='birthday'
                                render={props => (
                                    <NamedDateInput
                                        name='Дата рождения'
                                        dateInputProps={{
                                            ...props.field,
                                            error: errors.birthday,
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div>
                            <SemiBoldText className='text-sm'>
                                Возраст: <span>&nbsp;</span>
                            </SemiBoldText>
                            <RegularText className='text-sm'>{DateUtils.calculateAge(watch('birthday'))}</RegularText>
                        </div>
                    </div>
                </div>
                <div className='right flex flex-col gap-3'>
                    <MediumItalic className='flex justify-center'>~Основные данные 2~</MediumItalic>
                    <Controller
                        control={control}
                        name='phoneNumber'
                        render={props => (
                            <NamedTextInput
                                name='Телефон'
                                textInputProps={{
                                    className: 'w-inputL',
                                    placeholder: 'Телефон',
                                    ...props.field,
                                    error: errors.phoneNumber,
                                }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='whatsApp'
                        render={props => (
                            <NamedTextInput
                                name='Whatsapp'
                                textInputProps={{
                                    className: 'w-inputL',
                                    placeholder: 'Whatsapp',
                                    ...props.field,
                                    error: errors.whatsApp,
                                }}
                            />
                        )}
                    />
                    <Controller
                        name='telegram'
                        control={control}
                        render={props => (
                            <NamedTextInput
                                name='Telegram'
                                textInputProps={{
                                    className: 'w-inputL',
                                    placeholder: 'Telegram',
                                    ...props.field,
                                    error: errors.telegram,
                                }}
                            />
                        )}
                    />
                    <Controller
                        name='email'
                        control={control}
                        render={props => (
                            <NamedTextInput
                                name='Email'
                                textInputProps={{
                                    className: 'w-inputL',
                                    placeholder: 'Email',
                                    ...props.field,
                                    error: errors.email,
                                }}
                            />
                        )}
                    />

                    <div className='flex justify-end'>
                        <Controller
                            control={control}
                            name='comment'
                            render={props => <TextArea placeholder='Комментарий' {...props.field} error={errors.comment} />}
                        />
                    </div>
                    <MediumItalic className='flex justify-center mt-5'>~Входные данные~</MediumItalic>
                    <Controller
                        name='login'
                        control={control}
                        render={props => (
                            <NamedTextInput
                                name='Логин'
                                textInputProps={{
                                    className: 'w-inputL',
                                    ...props.field,
                                    error: errors.login,
                                }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='password'
                        render={props => (
                            <NamedTextInput
                                name='Пароль'
                                textInputProps={{
                                    className: 'w-inputL',
                                    ...props.field,
                                    error: errors.password,
                                }}
                            />
                        )}
                    />
                </div>
            </div>
            <div className='bottom flex justify-between py-5'>
                <div className='flex ml-9 gap-3'>
                    <Button
                        type='button'
                        onClick={() =>
                            setFireCourierDialog({
                                courier_id: currentCourier?.item.courier_id,
                                firstName: currentCourier?.item.firstName,
                            })
                        }
                        variant={EButtonVariant.QUARTERY}
                    >
                        <RegularText className='text-sm underline'>Уволить</RegularText>
                    </Button>
                    <Button
                        type='button'
                        onClick={() =>
                            setDeleteCourierDialog({
                                courier_id: currentCourier?.item.courier_id,
                                firstName: currentCourier?.item.firstName,
                            })
                        }
                        variant={EButtonVariant.QUARTERY}
                    >
                        <RegularText className='text-sm underline'>Удалить безвозвратно</RegularText>
                    </Button>
                </div>
                <div className='mr-10'>
                    <SemiBoldText>
                        Статус: <span>&nbsp;</span>
                    </SemiBoldText>
                    <RegularText>{currentCourier?.item.isWorking ? 'Занят' : 'Свободен'}</RegularText>
                </div>
            </div>
        </div>
    )
}
